/**
 * @flow
 */
 
import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import CoveredProIcon from '../../../components/Typografy/CoveredProIcon';

const BenefitIconStyle = styled(CoveredProIcon)`
  color: ${theme.icon.color};
  //margin-right: 0.25em;
  font-size: 2em;
`;

const ProfileIcon = () => {
  return (
    <BenefitIconStyle>&#xe80E;</BenefitIconStyle>
  );
};

export default ProfileIcon;

