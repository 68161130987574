export const hasAccount = function (profile) {
    return (profile && (profile.accountId || hasMultiple(profile)));
};

export const hasMultiple = function (profile) {
    return (profile.accounts && Object.keys(profile.accounts).length > 0);
};

export const hasSelectedAccount = function (profile) {
    return (profile && profile.accountId) || (hasMultiple(profile) && profile.selectedAccount );
};

export const getAccount = function (profile) {
    return profile.selectedAccount || profile.accountId
}

export const hasWebapp = function (account) {
    return !!(account && account.customModules && account.customModules.webApp && account.customModules.webApp.active);

}

export const hasSuperAdminAccess = function (profile) {
    if(profile && profile.role === 'SuperAdmin') return true
};


export const hasAdminAccessToAccount = function (profile, accountId) {
    if(profile && profile.role === 'SuperAdmin') return true

    if(hasMultiple(profile)){
        return profile.accounts[accountId] && ( profile.accounts[accountId] === 'admin' || profile.accounts[accountId] === 'superadmin')
    }
    return (profile && profile.accountId);
};

export const hasAccessToAccount = function (profile, accountId) {
    if(profile && profile.role === 'SuperAdmin') return true

    if(hasMultiple(profile)){
        return (profile.accounts[accountId] !== undefined)
    }
    return (profile && profile.accountId);
};

export const isLegacy = function (profile) {
    return (profile && (!profile.role || profile.role === 'admin') && !hasMultiple(profile) && profile.accountId === '111222333')
};


export const accountName = (profile, accounts) => {
    if(profile && accounts && profile.accountId && accounts[profile.accountId]){
        return accounts[profile.accountId].name
    }
    return ''
}
