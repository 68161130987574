/**
 * @flow
 */

import auth0Data from '../config/auth/auth0';
import type {
  TResendVerificationEmailData,
  TSignInData,
  TSignUpData,
} from '../types/models/TAuth';

export const signInBody = (data: TSignInData) => { return {
  username: data.email,
  password: data.password,
  realm: 'standard',
}};

export const signUpBody = (data: TSignUpData, accountId) => JSON.stringify({
  client_id: auth0Data.clientId,
  email: data.email,
  password: data.password,
  user_metadata: {
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    homeAddress: data.homeAddress,
    signupAccount: accountId
  },
  connection: 'standard',
});

export const resendBody = (data: TResendVerificationEmailData) => JSON.stringify({
  email: data.email,
  accountId: data.accountId
});

export const resetPasswordBody = (data: TSignUpData) => JSON.stringify({
  client_id: auth0Data.clientId,
  email: data.email,
  connection: 'standard',
});

export const auth0DelegateOptions = () => ({
  scope: 'openid profile',
  api_type: 'firebase',
  target: auth0Data.clientId,
  allowedConnections: ['standard'],
});


export const auth0DelegateBody = (authResult) => JSON.stringify({
    client_id: auth0Data.clientId,
    grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
    id_token : authResult.id_token,
    target: auth0Data.clientId,
    scope: "openid",
    api_type: "firebase"
});

