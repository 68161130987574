/**
 * @flow
 */

import createReducer from '../createReducer';
import type { TCardState } from './TCardState';
import type { THandler } from '../types/THandler';
import type { TCardData } from '../types/models/TCard';
import type { TCardResponse } from '../types/api/TCard';

const initialState: TCardState = {
  data: {},
  error: null,
  isLoaded: false,
};

const _buildCardData = (data: TCardResponse): TCardData => {
  return data;
};

const handlers: THandler<TCardState> = {
  LOAD_CARD_SUCCESS: (state, action) => {
    const card = _buildCardData(action.payload);
    return { ...state, error: null, isLoaded: true, data: card };
  },
};

export default createReducer(initialState, handlers);
