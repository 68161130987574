/**
 * @flow
 */
import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import CoveredProIcon from '../../../components/Typografy/CoveredProIcon';

const ProfileIconStyle = styled(CoveredProIcon)`
  color: ${theme.icon.subHeaderIcon};
  margin-right: 0.25em;
`;

const ProfileIcon = () => (
  <ProfileIconStyle>&#xe80E;</ProfileIconStyle>
);

export default ProfileIcon;

