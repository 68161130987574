import React, {useMemo, useState} from 'react';
import {Box, Flex} from "reflexbox";
import styled from "styled-components";
import theme from "../../../theme";
import FileSaver from 'file-saver';
import {FormattedDate, s2ab} from "./StatsItem";
import {SaveButton} from "../../../components/Buttons";
import XLSX from "xlsx";
import H3 from "../../../components/Typografy/H3";
import {AiOutlineDelete, FaRegEdit, MdDelete} from "react-icons/all";

const StatsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 1em;
`;

const StatsInfoContainer = styled.div`
  padding: 1.5em 2.5em 1.5em 2.5em;
`;

const StatsTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const StatsText = styled.div`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 0.2em;
`;

const CodeTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CodeButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  transition: all .2s;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    opacity: 0.2;
    transform: scale(0.98);
  }
`

const CodeInnerList = ({list, accountId, setEditCode, deleteOneTimeCode}) => {
	if (!list) return null
	
	return <StatsText>
		<Flex wrap={true} justify="space-between" style={{gap: ".2em", maxHeight: "300px", overflow: "scroll"}}>
			<CodeItem list={list} setEditCode={setEditCode} deleteOneTimeCode={deleteOneTimeCode} accountId={accountId}/>
		</Flex>
	</StatsText>;
}

const CreateCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  inset: 0;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
`

const CreateCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;

  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  padding: 20px;
`

const CreateCodeInput = styled.input`
  padding: 10px 20px;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  outline: none;

  &:active {
    border: 1px solid rgb(200, 200, 200);
  }
`

const CreateCodeButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  transition: all .2s;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    opacity: 0.2;
    transform: scale(0.98);
  }`

const CancelCreateCodeButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: rgb(255, 82, 82);
  transition: all .2s;
  color: white;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    opacity: 0.2;
    transform: scale(0.98);
  }`

const EditCodeButton = styled.div`
  transition: all .2s;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    opacity: 0.2;
    transform: scale(0.98);
  }
`

const CreateCodeDivider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.08);
  margin: 10px 10px;
`

const DateInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  padding: 0.5em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    background: none;
  }

  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }

  ${props => props.disabled && 'opacity: 0.2;'}
`

function CreateCodeComponent({accountId, setCreateCode, createOneTimeCode}) {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [createdAt, setCreatedAt] = useState(
		(new Date((new Date()).getTime() - new Date().getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
	);
	
	function createCode(e) {
		if (email.length > 0 && code.length > 0 && createdAt && accountId) {
			createOneTimeCode(code, email, new Date(createdAt), accountId)
			setCreateCode(false);
		}
	}
	
	return (
		<CreateCodeWrapper onClick={(e) => setCreateCode(false)}>
			<CreateCodeContainer onClick={(e) => {
				e.stopPropagation();
			}}>
				<H3>Create new one time code</H3>
				
				<CreateCodeInput
					value={email}
					name={"email"}
					placeholder={"Email"}
					onChange={(e) => setEmail(e.target.value)}
				/>
				
				<CreateCodeInput
					value={code}
					name={"code"}
					placeholder={"Code"}
					onChange={(e) => setCode(e.target.value)}
				/>
				
				<DateInput
					type={"datetime-local"}
					value={createdAt}
					onChange={e => setCreatedAt(e.target.value)}
					name={"createdAt"}
				/>
				
				<CreateCodeDivider/>
				
				<CreateCodeButton onClick={createCode}>Create</CreateCodeButton>
				<CancelCreateCodeButton onClick={() => setCreateCode(false)}>Cancel</CancelCreateCodeButton>
			</CreateCodeContainer>
		</CreateCodeWrapper>
	)
}

function EditCodeComponent({accountId, oneTimeCode, setEditCode, editOneTimeCode}) {
	console.log("code", oneTimeCode);
	const [email, setEmail] = useState(oneTimeCode?.email ?? "");
	const [code, setCode] = useState(oneTimeCode?.code ?? "");
	const [createdAt, setCreatedAt] = useState(
		oneTimeCode?.timestamp ?
			(new Date((new Date(oneTimeCode.timestamp))
				.getTime() - new Date(oneTimeCode.timestamp).getTimezoneOffset() * 60000
			)
				.toISOString())
				.slice(0, -1)
			:
			(new Date((new Date()).getTime() - new Date().getTimezoneOffset() * 60000)
				.toISOString())
				.slice(0, -1)
	);
	
	const {codeId} = oneTimeCode;
	
	console.log("CODE ID", codeId);
	
	function editCode(e) {
		if (email.length > 0 && code.length > 0 && createdAt && accountId) {
			editOneTimeCode(codeId, code, email, new Date(createdAt), accountId)
			
			console.log("edit code", codeId, code, email, new Date(createdAt), accountId);
			
			setEditCode(null);
		}
	}
	
	return (
		<CreateCodeWrapper onClick={(e) => setEditCode(null)}>
			<CreateCodeContainer onClick={(e) => {
				e.stopPropagation();
			}}>
				<H3>Edit one time code</H3>
				
				<CreateCodeInput
					value={email}
					name={"email"}
					placeholder={"Email"}
					onChange={(e) => setEmail(e.target.value)}
				/>
				
				<CreateCodeInput
					value={code}
					name={"code"}
					placeholder={"Code"}
					onChange={(e) => setCode(e.target.value)}
				/>
				
				<DateInput
					type={"datetime-local"}
					value={createdAt}
					onChange={e => setCreatedAt(e.target.value)}
					name={"createdAt"}
				/>
				
				<CreateCodeDivider/>
				
				<CreateCodeButton onClick={editCode}>Edit</CreateCodeButton>
				<CancelCreateCodeButton onClick={() => setEditCode(null)}>Cancel</CancelCreateCodeButton>
			</CreateCodeContainer>
		</CreateCodeWrapper>
	)
}

const toCSV = (list) => {
	if (list) {
		
		
		const dataRows = list.map(row => [row.email, row.code, row.timestamp])
		
		const mergedRows = [["Email", "Code", "Created at"]].concat(dataRows)
		
		const worksheet = XLSX.utils.aoa_to_sheet(mergedRows);
		const new_workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(new_workbook, worksheet, "Unverified");
		const wbout = XLSX.write(new_workbook, {type: 'binary', bookType: "xlsx"});
		
		
		FileSaver.saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), "Unused_codes.xlsx");
		
		
	}
	
}

const CodeItem = ({list, setEditCode, accountId, deleteOneTimeCode}) => {
	const result = [];
	list.forEach((item, i) => {
		result.push(<Box
			key={i + "1"}
			w={[1 / 3]}
		>
			{item.email}
		</Box>)
		
		result.push(<Box
			key={i + "2"}
			w={[1 / 3]}
		>
			{item.code}
		</Box>)
		
		result.push(<Box
			key={i + "3"}
			w={[1 / 4]}
			style={{
				display: "flex",
				justifyContent: "space-between",
				paddingRight: 10,
				alignItems: "center",
			}}
		>
			<FormattedDate dateString={item.timestamp}/>
			
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: ".5em",
				}}
			>
				<EditCodeButton onClick={() => setEditCode(item)}>
					<FaRegEdit/>
				</EditCodeButton>
				
				<EditCodeButton onClick={() => deleteOneTimeCode(item.codeId, accountId)}>
					<AiOutlineDelete/>
				</EditCodeButton>
			</div>
		</Box>)
	});
	
	return result;
}

function CodeList({stat, list, accountId, editOneTimeCode, createOneTimeCode, deleteOneTimeCode}) {
	const [createCode, setCreateCode] = useState(false);
	const [oneTimeCode, setEditCode] = useState(null);
	
	return (
		<div>
			<StatsCard>
				<Flex wrap>
					<Box w={[1]}>
						<StatsInfoContainer>
							<CodeTitleWrapper>
								<StatsTitle>{stat.title}: {stat.data} </StatsTitle>
								
								<CodeButton
									onClick={(e) => {
										e.preventDefault();
										
										setCreateCode(true);
									}}
								>
									New code
								</CodeButton>
							</CodeTitleWrapper>
							
							<CodeInnerList
								list={list}
								setEditCode={setEditCode}
								deleteOneTimeCode={deleteOneTimeCode}
								accountId={accountId}
							/>
							
							{list && <SaveButton onClick={() => toCSV(list)}>Download list</SaveButton>}
						</StatsInfoContainer>
					</Box>
					
					{oneTimeCode ?
						<EditCodeComponent
							accountId={accountId}
							setEditCode={setEditCode}
							oneTimeCode={oneTimeCode}
							editOneTimeCode={editOneTimeCode}
						/> :
						null
					}
					
					{createCode ?
						<CreateCodeComponent
							accountId={accountId}
							setCreateCode={setCreateCode}
							createOneTimeCode={createOneTimeCode}
						/> :
						null
					}
				</Flex>
			</StatsCard>
		</div>
	);
}

export default CodeList;
