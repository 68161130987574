/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import BenefitReportIcon from './components/BenefitReportIcon';
import theme from '../../../theme/index';
import Wrapper from '../../../components/Typografy/Wrapper';
import ArrowIcon from './components/ArrowIcon';
import Link from '../../../components/Typografy/Link';


const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png').default});
  padding-top: 1.6em;
  padding-bottom: 1em;
`;

const ReportEditHeader = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;
const BackLinkWrapper = styled.div`
   padding: 0 0 0.5em 0;
`;


type TProps = {
  hasReport: boolean;
};

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    return (
      <Container >
        <Wrapper>
          <BackLinkWrapper>
          <BackLink to="/admin/benefit-report">
            <ArrowIcon />
            Reported Benefits
          </BackLink>
          </BackLinkWrapper>
          <ReportEditHeader>
            <BenefitReportIcon />
            {this.props.hasReport ? 'Edit report' : 'Add report'}
          </ReportEditHeader>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
