/**
 * @flow
 */

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {polyfill} from 'smoothscroll-polyfill';


import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'


class PrivacyPage extends Component<void, void, void> {


  render() {
    polyfill();
    return (
      <div>
        <StickyMenu />
        <MessageSection
          headlineText={'Politik for cookies og privatliv'}
          full={
        <div>

            <div className="text_holder">
                <p><strong>Cookie- og privatlivspolitik</strong></p>
                <p><strong>Introduktion</strong><br/>
                    Når du besøger vores website eller våre mobile apper indsamles der oplysninger om dig, som bruges til tekniske funktionalitet, bla.
                    til at kunde tilpasse visning av personalegoder. Trafikmåling, så vi kan se hvor mange der besøger vores side og hvilke sider
                    der besøges. Hvis du ikke ønsker, at der indsamles oplysninger, bør du slette dine cookies (<a
                        href="http://minecookies.org/cookiehandtering">se vejledning</a>), og alle mobile apper, og undlade videre brug af websitet/apper.
                    Nedenfor har vi uddybet, hvilke informationer der indsamles, deres formål og hvilke tredjeparter, der har
                    adgang til dem.</p>
                <p><strong>Cookies</strong><br/>
                    Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din computer, mobil el. tilsvarende med det
                    formål at genkende den, huske indstillinger og udføre statistik. Der er ingen personlige oplysninger gemt i
                    vores cookies og kan ikke indeholde skadelig kode som f.eks. virus. Det er muligt at slette eller blokere
                    for cookies. Se vejledning: <a
                        href="http://minecookies.org/cookiehandtering">http://minecookies.org/cookiehandtering</a></p>
                <p>Websitet indeholder cookies fra tredjeparter. Vi bruger Google Analytics til at analysere, hvordan alle vores
                    brugere anvender hjemmesiden. Google indsamlede oplysninger om brug (trafikdata og IP-adresse), sendes til
                    og gemmes på Googles servere. Vi beordrer Google Analytics til kun at bruge en del af IP-adressen og ikke
                    hele adressen til geoplacering.</p>
                <p><strong>Hjemmesiden indeholder følgende cookies<br/>
                    Funktionalitet:<br/>
                </strong>
                    <p>Analytics<br/>
                        Navn_utmv. Udløbstid: 2 år<br/>
                        Denne cookie fra Google bruges til at overvåge trafikken på vores hjemmeside og spore hvilken kategori af
                        besøgende du er.</p>
                    <p>_utmz. Udløbstid: 180 dage<br/>
                        Denne cookie fra Google bruges til at overvåge trafikken på vores hjemmeside og spore hvor den besøgende
                        kommer fra (søgemaskine, søgeord, link)</p>
                    <p>_utma. Udløbstid: 2 år<br/>
                        Denne cookie fra Google bruges til overvågning af trafik på vores hjemmeside og sporing af hver besøgendes
                        antal besøg, tidspunktet for det første besøg, tidligere besøg og det aktuelle besøg.</p>
                    <p>_utmb. Udløbstid: Slettes efter session udløbstid<br/>
                        Denne cookie bruges til at spore, hvor lang tid den besøgende tilbringer på en webplacering: når et besøg
                        starter og når den ender. Hvis du observerer cookieændringer (f.eks. ved brug af firecookie), vil du se
                        regelmæssige ændringer.</p>
                    <p>Mobile apper bruger Google Analytics til at analysere, hvordan alle vores
                        brugere anvender appene. Google indsamlede oplysninger om brug (trafikdata og IP-adresse), sendes til
                        og gemmes på Googles servere. Vi beordrer Google Analytics til kun at bruge en del af IP-adressen og ikke
                        hele adressen til geoplacering.</p>
                    <p><strong>Opbevaring af bruger- og hændelses data<br/>
                    </strong>Opbevarings perioden for data, som er knyttet til cookies, bruger-id’er eller annoncerings-id’er er
                        lavest mulig Google tillader, nemlig 14 måneder. Bruger-id nulstilles efter hver ny aktivitet. Data, som er
                        knyttet til bruger-id’et, slettes automatisk efter opbevarings perioden.</p>
                    <p>Vi uploader ikke data, der tillader Google personligt at identificere en person (f.eks. Bestemte navne,
                        e-mailadresser eller lignende data) eller data, der permanent identificerer en bestemt enhed (f.eks. En unik
                        enhedsidentifikator, hvis sådan en identifikator ikke kan nulstilles). Du kan fravælge cookies fra Google
                        Analytics her: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
                    </p>
                    <p>Hvis du vælger at tilmelde dig vores e-mail-nyhedsbrev, sendes den email-adresse, du sender til os, til
                        Mailgun, der giver os e-mailmarkedsføringstjenester. Vi anser Mailgun for at være en tredjeparts
                        dataprocessor.</p>
                    <p>Når du tilmelder dig nyhedsbrevet afgiver du din mailadresse og dit navn. Vi anvender udelukkende
                        mailadressen til at udsende nyhedsbrevet til dig, og navnet til at personalisere indholdet. Din
                        e-mail-adresse forbliver i vores egen – og i Mailguns database, indtil du specifikt ønsker at blive
                        fjernet fra listen. Du kan gøre dette ved at afmelde dig ved hjælp af de afmeldings links, der er inkluderet
                        i vores nyhedsbreve, eller ved at anmode om fjernelse via e-mail.</p>
                    <p>Vi videregiver ikke dine oplysninger til 3. mand.</p>
                    <p><strong> </strong></p>
                    <p><strong>Personoplysninger</strong></p>
                    <p><strong>Generelt</strong><br/>
                        Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig. Når du
                        benytter vores website indsamler og behandler vi en række sådanne informationer. Det sker f.eks. ved alm.
                        tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev, registrerer dig som bruger øvrig brug af services eller foretager køb via websitet.</p>
                    <p>Vi indsamler og behandler typisk følgende typer af oplysninger: Hvilke sider du klikker på (interesser) og i
                        det omfang du selv giver eksplicit samtykke hertil og selv indtaster informationerne behandles desuden:
                        Navn, telefonnummer, e-mail, adresse og betalingsoplysninger. Det vil typisk være i forbindelse med
                        oprettelse af login eller ved køb.</p>
                    <p>Vi videregiver ikke dine oplysninger til 3. mand.</p>
                    <p><strong>Sikkerhed</strong><br/>
                        Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller
                        ulovligt, offentliggjort, fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges eller i
                        øvrigt behandles i strid med lovgivningen.</p>
                    <p><strong>Formål</strong><br/>
                        Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have størst
                        sandsynlighed for at være relevante for dig, at registrere dine køb og betalinger, samt at kunne levere de
                        services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev. Herudover anvender vi oplysningerne,
                        til at optimere vores services og indhold.</p>
                    <p><strong>Periode for opbevaring</strong><br/>
                        Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter dem, når de
                        ikke længere er nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden for opbevaring. Det
                        er derfor ikke muligt at angive en generel tidsramme for, hvornår informationer slettes.</p>
                    <p><strong>Videregivelse af oplysninger</strong><br/>
                        Data om din brug af websitet, hva du evt. klikker på, geografisk placering, m.v.
                        videregives til tredjeparter i det omfang disse oplysninger er kendt. Du kan se hvilke tredjeparter, der er
                        tale om, i afsnittet om ”Cookies” ovenfor. Oplysningerne anvendes til forbedring av website og apper.</p>
                    <p>Vi benytter herudover en række tredjeparter til opbevaring og behandling af data. Disse behandler udelukkende
                        oplysninger på vores vegne og må ikke anvende dem til egne formål.</p>
                    <p><strong>Beskyttelse af personoplysninger</strong><br/>
                        Ifølge persondataloven skal dine personlige oplysninger opbevares sikkert og fortroligt. Vi gemmer dine
                        personlige oplysninger på computere med begrænset adgang, som er placeret i kontrollerede faciliteter, og
                        vores sikkerhedsforanstaltninger kontrolleres løbende for at afgøre, om vores brugeroplysninger håndteres
                        forsvarligt, og under stadig hensyntagen til dine rettigheder som bruger. Vi kan dog ikke garantere 100 %
                        sikkerhed ved dataoverførsler via internettet. Det betyder, at der kan være en risiko for, at andre
                        uberettiget tiltvinger sig adgang til oplysninger, når data sendes og opbevares elektronisk. Du afgiver
                        således dine personlige oplysninger på eget ansvar.</p>
                    <p>Personoplysninger slettes eller anonymiseres løbende efterhånden som det formål, de blev indsamlet til,
                        afsluttes. Personoplysninger gemmes højst i 12 måneder efter brug.</p>
                    <p>Den hurtige udvikling af internettet betyder, at ændringer i vores behandling af personoplysninger kan blive
                        nødvendige. Vi forbeholder os derfor ret til at opdatere og ændre nærværende retningslinjer for behandling
                        af personoplysninger. I tilfælde af væsentlige ændringer giver vi dig besked i form af en synlig meddelelse
                        på vores websites.</p>
                    <p><strong>Indsigt og klager<br/>
                    </strong>I det omfang, der behandles personoplysninger om dig, har du ifølge persondataloven ret til at få
                        oplyst, hvilke personoplysninger, der kan henføres til dig. Såfremt det viser sig, at de oplysninger eller
                        data, der behandles om dig, er urigtige eller vildledende, har du ret til at kræve disse berigtiget, slettet
                        eller blokeret. Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig. Du kan desuden
                        til enhver tid gøre indsigelse mod, at oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at
                        der bliver behandlet oplysninger om dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret
                        til at de bliver rettet eller slettet.</p>
                    <p>Henvendelse herom kan ske til: <a href="mailto:info@coveredpro.com">info@coveredpro.com</a>. Hvis du vil klage over
                        vores behandling af dine personoplysninger, har du også mulighed for at tage kontakt til <a
                            href="http://www.datatilsynet.dk/">Datatilsynet</a>.</p></p></div></div>

          }
        />
        <Footer />
      </div>);
  }
}

export default PrivacyPage;
