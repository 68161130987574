/**
 * @flow
 */
import {getFormValues} from 'redux-form';
import {connect} from 'react-redux';

import MessageEdit from './MessageEdit';
import type {TAppState} from '../../../../common/TAppState';
import {
    onMessagesLoadSuccess,
    updateMessage,
    onTokensLoadSuccess,
    updateMessageDraft, onMessageArchivesLoadSuccess, onMessageDraftsLoadSuccess
} from '../../../../common/messages/actions';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";

const MessageArchiveEditContainer = connect(
    (state: TAppState, dispatch) => {
        return {
            accountId: state.auth.profile.data.accountId,
            messages: state.messages.archiveData || [],
            tokens: state.messages.tokens || {},
            messageFormValues: getFormValues('MessageForm')(state),
            accounts: state.accounts.list,
            isDraft: false,
            isArchive: true,
        };
    },
    (dispatch) => ({
        onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
        onMessageArchivesLoadSuccess: (data) => dispatch(onMessageArchivesLoadSuccess(data)),
        onMessageDraftsLoadSuccess: (data) => dispatch(onMessageDraftsLoadSuccess(data)),
        onTokensLoadSuccess: (data) => dispatch(onTokensLoadSuccess(data)),
        updateMessage: (data, message, accounts) => dispatch(updateMessage(data, message, accounts)),
        updateMessageDraft: (data, message) => dispatch(updateMessageDraft(data, message)),
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    })
)(MessageEdit);

export default MessageArchiveEditContainer;
