/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../../theme/index';
import { mx } from '../../../utils';
import Wrapper from '../../../components/Typografy/Wrapper';
import H3 from "../../../components/Typografy/H3";
import Map from "../../MapPage/Map";

const BenefitsContainer = styled.div`
  background-color: ${theme.benefits.backgroundColor}
  min-height: 20em;
  background-position: right;
  margin-bottom: 3em;
  ${mx('lg')} {
    background-position: bottom;
  }
  `;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  `;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #222222;
  color: white;
  padding: 1em;
  margin:0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  `;

const MapWrapper = styled.div`
  height: 400px;
  margin:0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  `;


class MapSection extends Component<void, TProps, void> {
    render() {
        return (
            <BenefitsContainer>
                <Wrapper>
                    <Header>
                        <Title>Oversiktskart</Title>
                    </Header>
                </Wrapper>
                <Wrapper>
                    <MapWrapper>
                        <Map benefits={this.props.benefits} locations={this.props.locations} accountId={this.props.accountId} />
                    </MapWrapper>
                </Wrapper>
            </BenefitsContainer>
        );
    }
}


export default MapSection;

