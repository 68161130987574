/**
 * @flow
 */

import type { TColors, TTheme } from './types';

const colors: TColors = {
  navColor: '#212121',
  nameColor: '#fff',
  searchColor: '#7d7d7d',
  white: '#fff',
  grey: '#dbdbdb',
  black: '#000',
  labelColor: '#747474',
  menu: '#c6002e',
  red: '#f41a4d',
  menuGrey: '#363636',
  hoverButton: '#D30F3D',
};

const Theme: TTheme = {
  overlay: {
    overlayBackground: colors.black,
    backgroundImageOpacity: 0.13,
  },
  nav: {
    backgroundColor: colors.navColor,
    color: colors.nameColor,
    menu: colors.menu,
    link: colors.red,
    menuIcon: colors.white,
    menuContainer: colors.menuGrey,
  },
  search: {
    color: colors.searchColor,
    borderColor: colors.searchColor,
    placeholderColor: colors.searchColor,
  },
  icon: {
    color: colors.white,
    rateIconColor: '#ffba00',
    rateIconColorBase: '#c8c8c8',
    subHeaderIcon: '#e41a49',
    arrowIcon: colors.white,
    benefitsLinksIcon: colors.red,
  },
  subHeader: {
    profileColor: colors.white,
    buttonTextColor: colors.white,
    buttonSecondColor: colors.red,
    iconColor: colors.red,
  },
  benefits: {
    backgroundColor: colors.grey,
    detailsBackground: colors.white,
    textColor: colors.white,
    titleColor: '#525252',
    questionTitle: '#171717',
    benefitsBox: '#929292',
    shadow: '#888888',
  },
  input: {
    textColorUserCard: colors.black,
    textColor: colors.labelColor,
  },
  userProfile: {
    userCardsBackground: colors.grey,
    cardBackground: colors.white,
    cardBorder: colors.grey,
    label: colors.labelColor,
  },
  categories: {
    categoryColor: '#818181',
    activeCategoryColor: colors.white,
  },
  footer: {
    footerBackground: colors.navColor,
    textColor: '#818181',
  },
  card: {
    cardBorders: '#d6d6d6',
  },
  buttons: {
    defaultColor: colors.red,
    primary: colors.white,
    hover: colors.hoverButton,
  },
  modal: {
    overlayColor: 'rgba(0, 0, 0, 0.75)',
    contentBackground: colors.white,
    contentFont: colors.navColor,
    line: colors.grey,
  },
  checkbox: {
    backgroundColor: colors.red,
    checkColor: colors.white,
  },
};

export default Theme;
