/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TDomainApiData,
  TDomainResponse,
} from '../../../../common/types/api/TDomain';
import DomainsList from './DomainsList';
import DomainEdit from '../DomainEdit';
import SubHeader from './SubHeader';


const DomainsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const DomainBackground = styled.div`
  background-color: rgb(20, 20, 20);
  margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  domains: TDomainApiData;
  onDomainsLoadSuccess: (data: TDomainResponse) => void;
};

class DomainsPage extends React.Component<void, TProps, TState> {
  render() {
    return (
      <DomainsWrap>
        <DomainBackground>
          <SubHeader />
        </DomainBackground>
        <Wrapper>
          <DomainEdit
            accountId={this.props.accountId}
            updateDomain={this.props.addAccountToDomain}
            domainFormValues={this.props.domainFormValues}
          ></DomainEdit>
        </Wrapper>
        <Wrapper>

          <DomainsList
            accountId={this.props.accountId}
            domain={this.props.domain}
            onDomainsLoadSuccess={this.props.onDomainsLoadSuccess}
            removeFromDomain={this.props.removeAccountFromDomain}
          />
        </Wrapper>

      </DomainsWrap>
    );
  }
}

export default DomainsPage;
