/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import {imageMedium} from '../../../../common/config/images/endpoints';

const BenefitsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  padding-right: 1.5em;
  overflow: hidden;
  margin: 0.5vw;
`;

const BenefitInfoContainer = styled.div`
  padding: 0 2.5em 0 2.5em;
`;

const BenefitTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const BenefitText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 1.5em;
  border-bottom: 1px solid ${theme.categories.categoryColor};
`;

const SourceText = styled.div`
  font-size: 0.8em;
  font-style: italic;
  padding-right: 1.0em;
  color: ${theme.categories.categoryColor};
`;

const BenefitImage = styled.img`
  width: 8vw;
`;

type TProps = {
  benefit: TBenefitApiData;
  accountId: number;
};

const CabinItem = (props: TProps) => {
  const { cabin, accountId, isArchive, accounts, isDraft } = props;

  return (
    <LinkToDetails to={isArchive ? `/admin/cabins-archive/${cabin.cabinId}` : isDraft ? `/admin/cabins-draft/${cabin.cabinId}` : `/admin/cabins/${cabin.cabinId}`}>
      <BenefitsCard>
        <Flex wrap>
          <Box w={[1, 3/12]}>
            <ImageContainer>
                {cabin && cabin.images && cabin.images[0] && <BenefitImage src={cabin.images[0].path} alt="" />}
            </ImageContainer>
          </Box>
          <Box w={[1, 9/12]}>
            <BenefitInfoContainer>
              <BenefitTitle> {cabin.title} </BenefitTitle>
                {cabin.description && <BenefitText>
                {cabin.description.substr(0, 100)} {cabin.description.length > 100 && '...'}
              </BenefitText>}
            </BenefitInfoContainer>
          </Box>

        </Flex>
      </BenefitsCard>
    </LinkToDetails>
  );
};

export default CabinItem;
