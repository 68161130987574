/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_GOOGLE_SERVICES_SUCCESS: (state, action) => {
        console.log('LOAD_GOOGLE_SERVICES_SUCCESS')
        const data = action.payload
        return {...state, error: null, isLoaded: true, data: data};
    }
}


export default createReducer(initialState, handlers);
