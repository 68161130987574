/**
 * @flow
 */
 
import React from 'react';
import LinkStyleIcon from './LinkStyleIcon';

const WebSiteIcon = () => {
  return (
    <LinkStyleIcon>&#xe807;</LinkStyleIcon>
  );
};

export default WebSiteIcon;

