/**
 * @flow
 */


import { connect } from 'react-redux';
import SupportPage from './SupportPage';
import { onLoadFaqSuccess } from '../../../common/helpaccount/actions';
import type { TAppState } from '../../../common/TAppState';
import {loadHelp} from "../../../common/help/actions";

const SupportPageContainer = connect(
  (state: TAppState) => {
    return {
      faq: state.faq.list,
      helpData: state.help.data,
      loaded: state.help.isLoading,
    };
  },
  (dispatch) => ({
    onLoadFaqSuccess: (data) => dispatch(onLoadFaqSuccess(data)),
    loadHelp: () => dispatch(loadHelp()),
  }),
)(SupportPage);
export default SupportPageContainer;
