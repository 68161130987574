/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { mx } from '../../../utils';
import Switch from "react-toggle-switch";
import ImageWrapper from "./components/Images/ImageWrapper";
import ImageCarousel from "./components/Images/ImageCarousel";

const CabinContainer = styled.div`
  min-height: 20em;
  background-position: right;
  ${mx('lg')} {
    background-position: bottom;
  }
  `;

const ToggleLabel = styled.span`
  padding-left:10px;
  padding-right:10px;
  vertical-align:super;
  font-size: 20px;
`;

const ToggleWrapper = styled.div`
  text-align:right;
`;

const Content = styled(Flex)`
  justify-content: center;
  `;

const Wrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding-left: auto;
  padding-right: auto;
  ${mx('lg')} {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

const ImgSwitch = styled(Switch)`
    background-color: #fff !important;
`;

type TProps = {
    steps: Array<Object>,
};


class CabinImages extends Component<void, TProps, void> {

    constructor(props) {
        super(props);

        this.state = {
            season: 1,
            activeImage: 0,
            offset:0,
            limit:5,
        }
    }

    setNewActiveImage = (props) => {
        let maxImages = this.props.cabin.images.length;
        const { offset, limit } = this.state;

        let newImageId = props;

        if (props < 0) newImageId = maxImages-1
        if (props >= maxImages) newImageId = 0;

        if (offset+limit <= newImageId) this.setNewOffset(newImageId-limit+1);
        if (offset > newImageId) this.setNewOffset(newImageId);

        this.setState({
            activeImage: newImageId,
        })
    };

    setNewOffset = (prop) => {
        let maxImages = this.props.cabin.images.length;

        let newOffset = prop;
        if (prop < 0) newOffset = 0;
        if (prop+this.state.limit >= maxImages) newOffset = maxImages-this.state.limit;

        this.setState({
            offset: newOffset,
        })
    };

    handleKeyImage = (event) => {
        if (event.key === 'ArrowRight') {
            this.setNewActiveImage(this.state.activeImage+1)
        } else if (event.key === 'ArrowLeft') {
            this.setNewActiveImage(this.state.activeImage-1)
        }
    };

    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyImage, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyImage, false);
    }

    render() {



        return (
            <CabinContainer>
                <Wrapper>
                    <Content align="center" justify="space-between" wrap>
                        {/*<ToggleWrapper><ToggleLabel>Summer</ToggleLabel><ImgSwitch onClick={() => this.setState({season: !this.state.season})} on={this.state.season} /><ToggleLabel>Winter</ToggleLabel></ToggleWrapper>*/}
                        <ImageWrapper setNewActiveImage={this.setNewActiveImage} activeImageId={this.state.activeImage} {...this.props}/>

                    </Content>
                    <ImageCarousel limit={this.state.limit} offset={this.state.offset} setNewOffset={this.setNewOffset} setNewActiveImage={this.setNewActiveImage} activeImageId={this.state.activeImage} {...this.props} />
                </Wrapper>
            </CabinContainer>
        );
    }
}


export default CabinImages;

