/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import CabinAdminForm from './CabinAdminForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"
import { getAccounts, getCabins, getBookings} from "../components/Retrievers";
import CustomBooking from "./CustomBooking";
import {Container} from "../../../components/Elements";
import Wrapper from "../../../components/Typografy/Wrapper";

type TProps = {
    profile: TProfileData;
}

class CabinAdmin extends Component<void, TProps, void> {

    _getItem = () => {
        const id = this.props.match.params.id;
        return find(this.props.cabins || [], c => c.cabinId === id)
    }

    _getForm = (cabinId) => {
        return (
            <CabinAdminForm initialValues={{
                calendarFilter: {
                    bookingsPending: true,
                    bookingsBooked: true,
                    bookingsDeclined: true,
                    datebased:true,
                    periodbased:true,
                }
            }} cabinId={cabinId} {...this.props}/>
        );
    };

    _innerRender = (cabinId) => {
        return (
            <FirebaseContainer queries={getBookings(this.props.accounts, this.props.accountId, cabinId, this.props.onBookingsLoadSuccess)}>
                <Container>
                    <Wrapper>
                        <CustomBooking cabin={this._getItem()} initialValues={null} {...this.props} />
                        { this._getForm(cabinId)}
                    </Wrapper>
                </Container>
            </FirebaseContainer>
        )
    }

    renderInner() {
        const cabinId = this.props.match.params.id;
        return (
            <div>
                <SubHeader cabinId={cabinId} />
                <FirebaseContainer queries={getCabins(this.props.accounts, this.props.accountId, this.props.onCabinsLoadSuccess, false, false)}>
                    <div>
                        {this.props.isLoaded && this._innerRender(cabinId)}
                    </div>
                </FirebaseContainer>
            </div>
        );
    }

    render() {
        return (
                <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                        {this.props.accountsLoaded && this.renderInner()}
                </FirebaseContainer>
        );
    }
}

export default CabinAdmin;
