/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TAccountApiData,
  TAccountResponse,
} from '../../../../common/types/api/TAccount';
import AccountList from './AccountList';
import SubHeader from './SubHeader';


const AccountsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const AccountBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png').default});
   margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  accounts: TAccountApiData;
  onAccountsLoadSuccess: (data: TAccountResponse) => void;
};

class AccountPage extends React.Component<void, TProps, TState> {
  render() {
    return (
      <AccountsWrap>
        <AccountBackground>
          <SubHeader />
        </AccountBackground>
        <Wrapper>
          <AccountList
            accountId={this.props.accountId}
            accounts={this.props.accounts}
            onAccountsLoadSuccess={this.props.onAccountsLoadSuccess}
          />
        </Wrapper>
      </AccountsWrap>
    );
  }
}

export default AccountPage;
