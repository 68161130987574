/**
 * @flow
 */

import styled from 'styled-components';

const Text = styled.p`
  color: #fff;
  font-size: 1em;
  margin: 2em 0 0 0;
`;

export default Text;
