/**
 * @flow
 */

import React, {Component} from 'react';
import BigCalendar from "react-big-calendar";
import moment from "moment";
import 'moment/locale/nb';
import './calendar-style.css';
import styled from 'styled-components';

moment.locale('nb');

const localizer = BigCalendar.momentLocalizer(moment);

const CalendarWrapper = styled.div`
    height:500px;
`;

const EventWrapper = styled.div`
    padding: 2px 5px;
    border-radius: 5px;
    ${props => props.bgcolor}; // custom
`;

class Calendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            events: []
        }

    }

    componentDidMount() {

        this.setEventList(this.props);

    }

    componentWillReceiveProps(nextProps) {

        this.setEventList(nextProps)

    }

    setEventList = (props) => {
        let events = [];

        const filter = props.filter && props.filter.calendarFilter;
        const { bookings } = props;


        if (props && props.cabin && props.cabin.useAdvancedPricing && props.cabin.advancedPricing) {
            // Period Based
            const advancedPricing = props.cabin.advancedPricing;
            filter && filter.periodbased && advancedPricing.periodbased && advancedPricing.periodbased.forEach((item, index) => {
                if (item && item.dateFrom && item.dateTo) {
                    events.push({
                        title: item.title + " - NOK: " + item.price,
                        start: new Date(item.dateFrom),
                        end: new Date(item.dateTo),
                        allDay: true,
                        type: 5
                    })
                }
            })

            // Calendar Based Date Based
            filter && filter.datebased && advancedPricing.datebased && advancedPricing.datebased.forEach((item, index) => {
                if (item && item.dateFrom && item.dateTo) {
                    events.push({
                        title: item.title,
                        start: new Date(item.dateFrom),
                        end: new Date(item.dateTo),
                        allDay: true,
                        type: 4
                    })
                }
            })
        }

        // Bookings
        bookings && Object.keys(bookings).forEach((booking) => {
            bookings[booking].status === 1 && filter && filter.bookingsPending && events.push(this.buildBookingEvent(bookings[booking], 'Pending'));
            bookings[booking].status === 2 && filter && filter.bookingsBooked && events.push(this.buildBookingEvent(bookings[booking], 'Booked'));
            bookings[booking].status === 3 && filter && filter.bookingsDeclined && events.push(this.buildBookingEvent(bookings[booking], 'Declined'));
        })

        this.setState({
            events: events
        })
    }

    buildBookingEvent = (booking, status) => {
        return {
            title: status + ' - ' + booking.user_email,
            start: new Date(booking.start_date),
            end: new Date(booking.end_date),
            allDay: true,
            resource: null,
            type: booking.status
        }
    }

    render() {

        // console.log("FV", this.props.formValues);

        return (
            <CalendarWrapper>
                <BigCalendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    events={this.state.events}
                    components={{
                        event: MyEvent
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                        // console.log("Event", event);
                        // console.log("Start", start);
                        // console.log("End", end);
                        // console.log("isSelected", isSelected);
                    }}
                />
            </CalendarWrapper>
        );
    }
}

const MyEvent = (props) => (
    <EventWrapper bgcolor={
        props.event.type === 1 ? 'background-color:green; color: white;' :
        props.event.type === 2 ? 'background-color:blue; color: white;' :
        props.event.type === 3 ? 'background-color:red; color: white;' :
        props.event.type === 4 ? 'background-color:#eee; color: black' :
        props.event.type === 5 ? 'background-color:#bbb; color: black' :
        'background-color: #bbb; color black;'
        }>
        {props.title}
    </EventWrapper>
)

export default Calendar

