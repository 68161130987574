/**
 * @flow
 */

import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import ResponsiveImage from '../../../../components/Typografy/ResponsiveImage';
import Wrapper from '../../../../components/Typografy/Wrapper';
import { mx } from '../../../../utils';

import MenuIcon from './MenuIcon';
import Navbar from './Navbar';


const Container = styled(Flex)`
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 4;
  position: relative;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #141414;

  ${mx('lg')} {
    margin-bottom: 81px;
    box-shadow: 0px -5px 17px 8px rgba(0, 0, 0, 0.65);
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  ${mx('lg')} {
    display: none;
    }
  `;

const MenuButton = styled.div`
  padding: 0.5em 1em;
  color: #fff;
  border-radius: 1.5em;
  border: none;
  //text-transform: uppercase;
  background-color: #F41A4D;
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:focus {
    outline: none;
    }
  `;


const MenuContainer = styled.div`
  display: ${props => props.open ? 'flex' : 'none'};
  width: 100%;
  ${mx('lg')} {
    display: flex;
    width: auto;
    }
  `;

const Overlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: #000;
  z-index: 1;
  opacity: 0.4;
  display: ${props => props.open ? 'block' : 'none'};;
  ${mx('lg')} {
    display: none;
    }
  `;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  `;

const LogoWrapper = styled.div`
  flex: 1;
`;

type TProps = {
  bg?: boolean;
  onMenuItemClick: Function;
}

type TState = {
  open: boolean;
}

class StickyMenu extends Component<void, TProps, TState> {

  state: TState = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidUpdate(prevProps: TProps, prevState: TState) {
    if (prevState.open && !this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    } else if (!prevState.open && this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: hidden';
    }
  }

  componentWillUnmount() {
    // If we navigate out of this view and the menu is open, make sure to restore the overflow
    if (this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    }
  }

  render() {
    const { open } = this.state;
    const { hideMenu } = this.props;
    const showMenu = !hideMenu
    return (
      <div>
        <Container wrap align="center" justify="space-between">
          <HeaderWrapper>
            <LogoWrapper>
              <ResponsiveImage
                src="CoveredPro_logo.png"
                width={115}
                height={35}
                alt=""
              />
            </LogoWrapper>
            {showMenu &&
              <div>
            <ToggleContainer >
              <MenuButton onClick={this.toggleMenu}>
                <MenuIcon open={open}/>
                <span>Menu</span>
              </MenuButton>
            </ToggleContainer>
            < MenuContainer open={open} onClick={() => { this.setState({ open: false }); }} >
              <Navbar onMenuItemClick={this.props.onMenuItemClick} />
              </MenuContainer>
              </div>
            }
          </HeaderWrapper>
        </Container>
        <Overlay onClick={this.toggleMenu} open={open} />
      </div>
    );
  }
}
export default StickyMenu;
