/**
 * @flow
 */

import React from 'react';
import {Flex,} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';

import WhitelistDropper from '../WhitelistEdit/components/WhitelistDropper'
import type {TWhitelistApiData} from '../../../../common/types/api/TWhitelist';

const WhitelistsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 0.5em;
`;

const WhitelistInfoContainer = styled.div`
  padding: 0 2.5em 0 2.5em;
`;

const WhitelistTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const WhitelistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`


type TProps = {
  whitelist: TWhitelistApiData;
  accountId: number;
  removeFromWhitelist: any;
};

const WhitelistItem = (props: TProps) => {
  const { whitelist, accountId, removeFromWhiteList } = props;

  return (
      <WhitelistsCard>
        <WhitelistWrapper>
          <WhitelistTitle> {whitelist} </WhitelistTitle>
            <WhitelistDropper {...props}/>
        </WhitelistWrapper>
      </WhitelistsCard>
  );
};

export default WhitelistItem;
