/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  list: [],
  tokens: {},
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_MESSAGES_SUCCESS: (state, action) => {
        console.log('LOAD_MESSAGES_SUCCESS', action)
        const data = action.payload
        const messages = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const message = {...data[key], messageId: key}
                messages[key] = message
                list.push(message);
            });

        }
        list.reverse();
        return {...state, error: null, isLoaded: true, data: messages, list: list, tokens: state.tokens};
    },
	LOAD_MESSAGE_DRAFTS_SUCCESS: (state, action) => {
		console.log('LOAD_MESSAGE_DRAFTS_SUCCESS', action)
		const data = action.payload
		const messages = {};
		let list = [];
		if (data) {
			Object.keys(data).forEach((key) => {
				const message = {...data[key], messageId: key}
				messages[key] = message
				list.push(message);
			});
			
		}
		list.reverse();
		return {...state, error: null, isLoaded: true, data: messages, draftData: messages, list: list, draftList: list, tokens: state.tokens};
	},
	LOAD_MESSAGE_ARCHIVES_SUCCESS: (state, action) => {
		console.log('LOAD_MESSAGE_ARCHIVES_SUCCESS', action)
		const data = action.payload
		const messages = {};
		let list = [];
		if (data) {
			Object.keys(data).forEach((key) => {
				const message = {...data[key], messageId: key}
				messages[key] = message
				list.push(message);
			});
			
		}
		list.reverse();
		return {...state, error: null, isLoaded: true, data: messages, archiveData: messages, list: list, archiveList: list, tokens: state.tokens};
	},
    LOAD_TOKENS_SUCCESS: (state, action) => {
        console.log('LOAD_TOKENS_SUCCESS')
        const data = action.payload
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const message = {...data[key], userId: key}
                list.push(message);
            });
            return {
                ...state,
                error: null,
                isLoaded: true,
                tokens: list.sort(compare)
            };
        }
    },
	RESET_MESSAGES_SUCCESS: (state, action) => {
		return initialState;
	},
}

const compare = (a,b) => {
    if (a.email < b.email)
        return -1;
    if (a.email > b.email)
        return 1;
    return 0;
}


export default createReducer(initialState, handlers);
