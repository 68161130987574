/**
 * @flow
 */

import React, { Component } from 'react';
import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'

class TermsPageDk extends Component<void, void, void> {

    render() {
        return (
            <div>
                <StickyMenu />
                <MessageSection
                    headlineText={'Brugervilkår'}
                    full={
                        <div className="text_holder">
                            <p>
                                Læs venligst følgende vilkår og betingelser nøje, inden du bruger en af CoveredPro's apps ("appen"). Ved at bruge appen accepterer du at overholde og være bundet af disse vilkår og betingelser. Hvis du ikke er enig i disse vilkår og betingelser, bedes du ikke bruge appen.
                            </p>

                            <h2>1. Accept af vilkår</h2>
                            <p>
                                Ved at registrere dig og bruge appen bekræfter du, at du har læst, forstået og accepteret disse vilkår og betingelser. CoveredPro kan opdatere eller ændre disse vilkår og betingelser til enhver tid uden varsel. Din fortsatte brug af appen betyder, at du accepterer de reviderede vilkår og betingelser.
                            </p>

                            <h2>2. Brugerregistrering</h2>
                            <p>2.1. For at få adgang til fordelene og rabatkoderne, der er tilgængelige gennem appen, skal du gennemføre registreringsprocessen og give nøjagtige, komplette og opdaterede oplysninger.</p>
                            <p>2.2. Du er ansvarlig for at opretholde fortroligheden af din kontooplysning, inklusiv din e-mail, og for al aktivitet, der finder sted på din konto.</p>

                            <h2>3. Berettigelse</h2>
                            <p>3.1. Appen er beregnet til medarbejdere/medlemmer af registrerede virksomheder/organisationer, der er kvalificerede til at få adgang til fordelene og rabatkoderne, der tilbydes af deres arbejdsgiver, medlemsorganisation, CoveredPro og/eller eksterne leverandører.</p>
                            <p>3.2. Brugere skal bekræfte deres beskæftigelses-/medlemsstatus hos en registreret virksomhed/organisation gennem Appens udpegede proces.</p>

                            <h2>4. Fordele og rabatkoder</h2>
                            <p>4.1. Ved at bruge appen vil registrerede brugere få adgang til en liste over fordele og rabatkoder givet af deres arbejdsgiver/organisation. Disse fordele og rabatkoder kan ændres, og deres tilgængelighed bestemmes af den respektive arbejdsgiver/organisation.</p>
                            <p>4.2. Appen er ikke ansvarlig for nøjagtigheden, tilgængeligheden eller kvaliteten af fordele og rabatkoder, der er tilgængelige i appen. Brugere opfordres til at kontakte deres arbejdsgiver, organisation eller leverandør vedrørende eventuelle spørgsmål eller afvigelser.</p>

                            <h2>5. Brug af appen</h2>
                            <p>5.1. Du accepterer at bruge appen i overensstemmelse med alle gældende love, regler og forordninger.</p>
                            <p>5.2. Du accepterer ikke at deltage i aktiviteter, der kan skade, forstyrre eller forstyrre appens funktionalitet, sikkerhed eller integritet.</p>
                            <p>5.3. Appen kan indsamle og opbevare anonymiserede brugerdata med det formål at tilbyde og forbedre tjenesterne. Se vores fortrolighedspolitik for yderligere oplysninger.</p>

                            <h2>6. Opsigelse af konto</h2>
                            <p>6.1. Appen forbeholder sig retten til at opsige eller suspendere din konto efter eget skøn, med eller uden varsel, hvis du overtræder disse vilkår og betingelser.</p>
                            <p>6.2. Din konto vil også kunne slettes, hvis du ikke længere er ansat eller medlem af en registreret virksomhed/organisation, der giver adgang til appens fordele og rabatkoder.</p>
                            <p>6.3. Du kan også opsige din konto når som helst ved at kontakte vores kundeservice.</p>

                            <h2>7. Ansvarsbegrænsning</h2>
                            <p>7.1. Appen leveres "som den er" og "som tilgængelig". Vi giver ingen garantier eller repræsentationer vedrørende nøjagtigheden, pålideligheden eller tilgængeligheden af appen eller fordelene og rabatkoderne, der tilbydes.</p>
                            <p>7.2. Appen skal ikke holdes ansvarlig for nogen direkte eller indirekte skader, inklusive men ikke begrænset til tab af data, tab af fortjeneste eller andre tab som følge af din brug af appen.</p>

                            <h2>8. Ændringer i brugervilkår</h2>
                            <p>
                                Appen forbeholder sig retten til at ændre disse vilkår og betingelser når som helst. Eventuelle ændringer træder i kraft umiddelbart efter at de opdaterede vilkår og betingelser er offentliggjort på appen. Det er dit ansvar at gennemgå disse vilkår og betingelser regelmæssigt.
                            </p>

                            <h2>9. Kontaktinformation</h2>
                            <p>
                                Hvis du har spørgsmål, kommentarer eller bekymringer angående brugervilkårene, er du velkommen til at kontakte os. Vi værdsætter dit privatliv og er her for at hjælpe dig.
                            </p>
                            <p>
                                <strong>Coveredpro AS<br />Org.nr. 918 084 495</strong>
                            </p>
                            <p>
                                <a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a>
                            </p>
                        </div>
                    }
                />
                <Footer />
            </div>
        );
    }
}

export default TermsPageDk;
