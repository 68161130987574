import React, {Component} from "react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top:15px;
`;

class CustomWysiwyg extends Component {

    onChange = (props, editor) => {
        this.props.input.onChange(editor.getData());
    }

    render() {
        console.log("RENDERED");
        return (
            <Wrapper>
                <CKEditor
                    editor={ ClassicEditor }
                    data={this.props.input.value}
                    onChange={this.onChange}
                />
            </Wrapper>
        )
    }
}

export default CustomWysiwyg;
