/**
 * @flow
 */

/* eslint import/prefer-default-export: 0 */

import type { TDispatch } from '../../types/TDispatch';
import type {
  TAuth0SignUpResponse,
} from '../../types/api/TAuth';
import type {
  TSignUpData, TResendVerificationEmailData
} from '../../types/models/TAuth';
import type { TAuthError } from '../TAuthState';

import auth0Config from '../../config/auth/auth0';
import getHeaders from '../../config/auth/headers';
import {resendBody, signUpBody} from '../config';
import { signInAccount } from '../signIn/actions';

const _onSignUpRequest = () => ({
  type: 'AUTH0_SIGN_UP_REQUEST',
});

const _onSignUpFailure = (error: TAuthError) => ({
  type: 'AUTH0_SIGN_UP_FAILURE',
  payload: error,
});

const _onSignUpSuccess = () => ({
  type: 'AUTH0_SIGN_UP_SUCCESS',
});


const _onResendSignUpLinkRequest = () => ({
  type: 'AUTH0_RESEND_SIGN_UP_LINK_REQUEST',
});

const _onResendSignUpLinkFailure = (error: TAuthError) => ({
  type: 'AUTH0_RESEND_SIGN_UP_LINK_FAILURE',
  payload: error,
});

const _onResendSignUpLinkSuccess = () => ({
  type: 'AUTH0_RESEND_SIGN_UP_LINK_SUCCESS',
});

export const signUpReset = () => ({
  type: 'AUTH0_SIGN_UP_RESET',
});

export const signUpAccount = (data: TSignUpData, accountId) => async (dispatch: TDispatch) => {
  try {
    dispatch(_onSignUpRequest());
    const response = await fetch(`https://${auth0Config.domain}/dbconnections/signup`, {
      method: 'POST',
      headers: getHeaders(),
      body: signUpBody(data, accountId),
    });
    if (response.status >= 400) {
      const errorJson = await response.json();
      console.log("Autheresult invalid! - " + JSON.stringify(errorJson))
      dispatch(_onSignUpFailure({
        status: response.status,
        message: errorJson.code,
      }));
      return;
    }
    const authResult: TAuth0SignUpResponse = await response.json();
    if (authResult._id && authResult.email.toLowerCase() === data.email.toLowerCase()) {
      dispatch(_onSignUpSuccess());
      //dispatch(signInAccount(data));
    } else {
      console.log("Autheresult invalid! - " + JSON.stringify(authResult))
      dispatch(_onSignUpFailure({
        status: 500,
      }));
    }
  } catch (error) {
    console.error(JSON.stringify(error))
    dispatch(_onSignUpFailure({
      status: 500,
    }));
  }
};


export const resendSignUpLink = (data: TResendVerificationEmailData) => async (dispatch: TDispatch) => {
  try {
    dispatch(_onResendSignUpLinkRequest());
    console.log("Sending resend linke " + data.email)
    const response = await fetch(`https://us-central1-my-coveredpro.cloudfunctions.net/verify`, {
      method: 'POST',
      headers: getHeaders(),
      body: resendBody(data)
    });
    if (response.status >= 400) {
      const errorJson = await response.json();
      dispatch(_onResendSignUpLinkFailure({
        status: response.status,
        message: errorJson.error,
      }));
      return;
    }
    dispatch(_onResendSignUpLinkSuccess());


  } catch (error) {
    console.error(error)
    dispatch(_onResendSignUpLinkFailure({
      status: 500,
    }));
  }
};
