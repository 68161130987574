/**
 * @flow
 */

import createReducer from '../createReducer';
import type { THandler } from '../types/THandler';

const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
  accountId: null
};


const _buildCategories = (data) => {
  const benefits = {};
  const list = []
  Object.keys(data).forEach((key) => {
    benefits[key] = ({...data[key], categoryId: key});
    list.push(benefits[key])
  });

  return {data: benefits, list};
};


const handlers: THandler<TBenefitState> = {
  LOAD_CATEGORIES_SUCCESS: (state, action) => {
    console.log("LOAD_CATEGORIES_SUCCESS")

    const categories =  action.payload ? _buildCategories(action.payload) : null;
    if(!categories) return state

    if((action.accountId === state.accountId) &&  (action.isDefault && state.list.length > 0)) return state

    return { ...state, error: null, isLoaded: true, data: categories.data, list: categories.list, accountId:action.accountId};
  },
};

export default createReducer(initialState, handlers);
