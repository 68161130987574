/**
 * @flow
 */

/* eslint import/prefer-default-export: 0 */
import base64 from 'base64-url';

import type {TDispatch} from '../types/TDispatch';

import getHeaders from '../config/auth/headers';
import {
  contactBody,
} from './config';
import contactmeTask from '../config/webtasks/contactmeTask';
import {
  buildContactRequest,
} from '../config/firebase_config/endpoints';
import {firebaseDb} from "../config/firebase_config/firebase";
import {NotificationManager} from "react-notifications";
import slackConfigContact from '../config/slack/config';


const _onContactMeSuccess = () => ({
  type: 'LANDING_CONTACT_ME_SUCCESS',
});

const _onContactMeRequest = () => ({
  type: 'LANDING_CONTACT_ME_REQUEST',
});

const _onContactMeFailure = (error: TContactMeError) => ({
  type: 'LANDING_CONTACT_ME_FAILURE',
  payload: error,
});

export const registerContactMe = (data: string) => async(dispatch: TDispatch) => {
  try {
    dispatch(_onContactMeRequest());

    const response = await fetch(`${contactmeTask.uri}?contact=${data.email}`, {
      method: 'get',
      headers: getHeaders(),
    });
    if (response.status >= 400) {
      const errorJson = await response.json();
      dispatch(_onContactMeFailure({
        status: response.status,
        id: errorJson.error,
        message: errorJson.error_description,
      }));
      return;
    }
    dispatch(_onContactMeSuccess());
  } catch (error) {
    dispatch(_onContactMeFailure({
      status: 500,
    }));
  }
};

const sendEmailToSlack = (email) => {

  let message = `New mail request received from ${email}`;

  let Slack = require('slack-node');

  let webhookUri = slackConfigContact.hookUrl;

  let slack = new Slack();
  slack.setWebhook(webhookUri);

  slack.webhook({
    channel: slackConfigContact.channel,
    username: slackConfigContact.username,
    text: message,
  }, function(err, response) {
    console.log(response);
  });

};

export const registerContactMeDB = (data: string) => async(dispatch: TDispatch) => {
  try {
    dispatch(_onContactMeRequest());

    let updates = {};
    let storableData = JSON.parse(JSON.stringify(data.email));

    let email = '';

    if (storableData) {
      email = storableData;
    }

    let emailID = base64.encode(email);

    updates[buildContactRequest(emailID)] = email;

    await firebaseDb.ref().update(updates);

    sendEmailToSlack(email);

    dispatch(_onContactMeSuccess());
    NotificationManager.success('Success!', 'Account linked to domain!', 3000);
  } catch (err) {
    console.log("update domain ERROR: "+ JSON.stringify(err))

    dispatch(_onContactMeFailure());
  }
};

