/**
 * @flow
 */

import type { TDispatch } from '../../types/TDispatch';
import type { TProfileData } from '../../types/models/TAuth';
import type { TAuthError } from '../TAuthState';
import type { TAppState } from '../../TAppState';

import auth0Config from '../../config/auth/auth0';
import getHeaders from '../../config/auth/headers';
import {
  toAuth0Profile,
  toProfile,
} from './models/toProfile';

import {onBenefitsReset} from  '../../benefits/actions'

const _onUpdateProfileRequest = () => ({
  type: 'UPDATE_PROFILE_REQUEST',
});

const _onUpdateProfileFailure = (error: TAuthError) => ({
  type: 'UPDATE_PROFILE_FAILURE',
  payload: error,
});

export const onUpdateProfileSuccess = (payload: TProfileData) => ({
  type: 'UPDATE_PROFILE_SUCCESS',
  payload,
});

export const onUpdateSelectedAccountSuccess = (payload) => ({
    type: 'UPDATE_SELECTED_ACCOUNT_PROFILE_SUCCESS',
    payload,
});


export const updateSelectedAccountSuccess = (data, history) => {
    return async (dispatch: TDispatch) => {
        dispatch(onUpdateSelectedAccountSuccess(data))
        dispatch(onBenefitsReset())
        history.push('/admin/benefits')
    };
};

export const updateSelectedAccountAppSuccess = (data, history) => {
    return async (dispatch: TDispatch) => {
        dispatch(onUpdateSelectedAccountSuccess(data))
        dispatch(onBenefitsReset())
        history.push('/app/benefits')
    };
};


export const updateProfile = (data: TProfileData) => {
  return async (dispatch: TDispatch, getState: () => TAppState) => {
    try {
      dispatch(_onUpdateProfileRequest());

      const { userId } = data;
      const auth0Profile = toAuth0Profile(data);
      const bodyShape = {
        user_metadata: auth0Profile.user_metadata,
      };
      const response = await fetch(`https://${auth0Config.domain}/api/v2/users/${userId}`, {
        method: 'PATCH',
        headers: getHeaders(getState().auth.signIn.auth0Token),
        body: JSON.stringify(bodyShape),
      });
      if (response.status >= 400) {
        dispatch(_onUpdateProfileFailure({
          status: response.status,
        }));
      }
      const updatedAuth0Profile = await response.json();
      const profile = toProfile(updatedAuth0Profile);
      dispatch(onUpdateProfileSuccess(profile));
    } catch (error) {
      dispatch(_onUpdateProfileFailure({
        status: 500,
      }));
    }
  };
};


