/**
 * @flow
 */

import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../theme';
import Wrapper from '../../components/Typografy/Wrapper';
import BaseButton from '../../components/Typografy/BaseButton';
import CustomFieldInput from '../../components/Typografy/CustomFieldInput';
import type { TProfileData } from '../../../common/types/models/TAuth';

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;

const Card = styled(Flex)`
  margin-bottom: 2em;
  padding: 1.7em 1.8em 3em 1.8em;
  box-shadow: 0px 0px 13px 1px rgba(0,0,0,0.3);
  background: ${theme.userProfile.cardBackground};
  border-radius: 20px;
`;


const SaveButton = styled(BaseButton)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: #EAC2D3;
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const CardTitle = styled.h2`
  font-size: 1.25em;
  padding-bottom: 1em;
  border-bottom: 1px solid  ${theme.userProfile.cardBorder};
`;

type TProps = {
  profile: TProfileData;
}

type TState = {
  email: string;
}

class AboutClient extends Component<void, TProps, TState> {
  
  state: TState = {
    email: this.props.profile.email,
  };
  
  render() {
    return (
      <Container>
        <Wrapper>
          <Flex wrap>
            <Box w={[1, 8/12]} >
              <Card wrap>
                <Box w={[1]}>
                  <CardTitle>
                    About me
                  </CardTitle>
                </Box>
                <Box w={[1, 1/2]}>
                  <form >
                    <div>
                      <Field
                        name="firstName"
                        component={CustomFieldInput}
                        type="text"
                        placeholder="First name"
                        userCard
                        label="* First name"
                      />
                    </div>
                    <div>
                      <Field
                        name="lastName"
                        component={CustomFieldInput}
                        type="text"
                        placeholder="Last name"
                        userCard
                        label="* Last name"
                      />
                    </div>
                    <div>
                      <Field
                        name="email"
                        component={CustomFieldInput}
                        placeholder="Email"
                        type="Email"
                        userCard
                        label="* Email"
                        input={{
                          value: this.state.email,
                          onChange: (e) => this.setState({ email: e.target.value }),
                        }}
                      />
                      <Field
                        name="address"
                        component={CustomFieldInput}
                        placeholder="Address"
                        type="text"
                        userCard
                        label="Address"
                      />
                      <Field
                        name="phone"
                        component={CustomFieldInput}
                        placeholder="Phone"
                        type="tel"
                        userCard
                        label="Phone"
                      />
                      <SaveButton> Save </SaveButton>
                    </div>
                  </form>
                </Box>
              </Card>
              <Card wrap>
                <Box w={[1]}>
                  <CardTitle>
                    Preferences
                  </CardTitle>
                </Box>§
              </Card>
            </Box>
          </Flex>
        </Wrapper>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'UserInfoForm',
  field: ['firstName', 'lastName', 'email', 'address', 'phone'],
})(AboutClient);
