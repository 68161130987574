/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import {Box, Flex} from 'reflexbox';
import ProfileIcon from './components/ProfileIcon';
import theme from '../../theme';
import AdminMenu from './components/AdminMenu'

const ClientInfo = styled.span`
  font-size: 1em;
  color: ${theme.nav.color};
`;

const ImageContainer = styled.div`
  //flex: 1;
  //min-width: 2.5em;
`;

class UserInfo extends React.Component {
	render() {
		const {profile, history} = this.props;
		return (
			<Flex style={{userSelect: "none"}} wrap align="center" justify="space-between">
				<AdminMenu {...this.props}>
					<ClientInfo>
						{profile.email}
					</ClientInfo>
					
					<ImageContainer>
						<ProfileIcon/>
					</ImageContainer>
				</AdminMenu>
			</Flex>
		);
	}
}

export default UserInfo;
