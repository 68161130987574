/**
 * @flow
 */


import { connect } from 'react-redux';
import BenefitPage from './BenefitPage';
import {
  onBenefitsLoadSuccess,
  benefitDataTemplate,
  onBenefitStatsLoadSuccess
} from '../../../../common/benefits/actions/index';
import { onAccountsLoadSuccess } from '../../../../common/accounts/actions';
import { onCategoriesLoadSuccess } from '../../../../common/categories/actions/index';
import type { TAppState } from '../../../../common/TAppState';
import { onLanguagesLoadSuccess } from "../../../../common/languages/actions";
import { onBenefitOrderLoadSuccess, onVendorVisibilityLoadSuccess } from "../../../../common/benefits/actions/benefitsLoad";
import { changeBenefitOrder } from '../../../../common/benefits/actions/editBenefit';


const BenefitContainer = connect(
  (state: TAppState) => {
    return {
      languages: state.languages.data,
      accountId: state.auth.profile.data.accountId,
      benefits: state.benefits.data,
      categories: state.categories.list,
      isLoaded: state.benefits.isLoaded,
      accountsLoaded: state.accounts.isLoaded,
      accounts: state.accounts.data,
      benefitStats: state.benefits.benefitStats,
      hiddenBenefits: state.benefits.hiddenBenefits.list,
      benefitOrder: state.benefits.benefitOrder,
    };
  },
  (dispatch) => ({
    onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
    onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
    onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
    onBenefitStatsLoadSuccess: (data) => dispatch(onBenefitStatsLoadSuccess(data)),
    onVendorVisibilityLoadSuccess: (payload) => dispatch(onVendorVisibilityLoadSuccess(payload)),
    onBenefitOrderLoadSuccess: (payload) => dispatch(onBenefitOrderLoadSuccess(payload)),
    changeBenefitOrder: (benefitId, order) => dispatch(changeBenefitOrder(benefitId, order)),
  }),
)(BenefitPage);
export default BenefitContainer;
