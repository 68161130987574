  /**
 * @flow
 */
import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../components/Typografy/Wrapper';
import { mx } from '../../utils';

import List from './components/List/List';
import ListItem from './components/List/ListItem';
import H2 from '../../components/Typografy/H2';
import PromoImageWrapper from './components/PromoImageWrapper';
import Section from './components/Section';


import bgShape from '../../assets/images/bg_shape.svg';
import topBg from '../../assets/images/top-background.png';

const VideoTopWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  `;



const Container = styled.div`
    background-color: #141414;
  `;

const TopWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  margin-top: 5em;
  
  ${mx('lg')} {
    flex-direction: row-reverse;
    }
  `;

const VideoWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
    ${mx('lg')} {
        width: 60vw;
    }
  `;

const ScrollIcon = styled.div`
  background-image: url(${require('../../assets/images/scroll.png').default});
  margin-top: 0.5em;
  width: 24px;
  height: 48px;
  cursor: pointer;
  `;

const ScrollText = styled.span`
  font: 0.5em;
  //text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  `;

const ScrollWrapper = styled.div`
  display: none;
  margin-top: 1em;
  ${mx('lg')} {
    z-index: 2;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    }
  `;


const raw =   {__html:'<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="//fast.wistia.net/embed/iframe/6wvh1cxi8l?videoFoam=true" title="Wistia video player" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div> <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>'};


class VideoSection extends Component {
  render() {
    return (
      <Section>
        <Container>
          <VideoTopWrapper>
            <TopWrapper wrap>
             <VideoWrapper dangerouslySetInnerHTML={raw}/>
            </TopWrapper>
            <ScrollWrapper onClick={() => this.props.onMenuItemClick('promotional')}>
              <ScrollText>Les mer</ScrollText>
              <ScrollIcon />
            </ScrollWrapper>
          </VideoTopWrapper>
        </Container>
      </Section>
    );
  }
}

export default VideoSection;
