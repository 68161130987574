/**
 * @flow
 */

import React, { Component } from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import PromoPhone from './components/PromoPhone';

import Wrapper from '../../components/Typografy/Wrapper';
import { mx } from '../../utils';

import Title from '../components/Typography/Title';
import ShortLine from './components/ShortLine';
import SubTitle from '../components/Typography/SubTitle';
import H2 from '../../components/Typografy/H2';
import FuncIcon from './DescribeFunc/FuncIcon';
import DescriptionWrapper from './components/DescriptionWrapper';
import Section from './components/Section';

const Headline = styled(Title)`
  font-size: 38px;
  padding-bottom: 0.5em;
  `;

const SubHeading = styled(H2)`
  color: #171717;
  text-align: center;
  padding-bottom: 0.5em;
  font-weight: normal;
  `;

const Col = styled(Box)`
  text-align: center;
  padding-left:1em;
  padding-right:1em;
  flex:1;
  `;

const Caption = styled(SubTitle)`
  padding-bottom: 2em;
  `;

const PromoImg = styled.div`
  display: none;
  ${mx('lg')} {
    display: flex;
    justify-content: center;
    }
  `;

class DescribeFuncSection extends Component {
  render() {
    return (
      <Section>
        <Wrapper>
          <DescriptionWrapper>
            <Headline>
            CoveredPros nøkkelferdige løsning
            </Headline>
            <SubHeading>
              dekker alle behov og er brandet med din selskapsidentitet
            </SubHeading>
            <ShortLine />
          </DescriptionWrapper>
          <Flex wrap align="center" justify="space-between">
            <Col col={12} sm={12} lg={3}>
              <FuncIcon name="logInIcon" />
              <Caption>Enkel innlogging</Caption>
              <PromoImg>
                <PromoPhone name="logInPhone" />
              </PromoImg>
            </Col>
            <Col col={12} sm={12} lg={3}>
              <FuncIcon name="ratingIcon" />
              <Caption>Dine fordeler samlet</Caption>
              <PromoImg>
                <PromoPhone name="ratingPhone" />
              </PromoImg>
            </Col>
            <Col col={12} sm={12} lg={3}>
              <FuncIcon name="newsIcon" />
              <Caption>Hold brukere oppdatert</Caption>
              <PromoImg>
                <PromoPhone name="mapPhone" />
              </PromoImg>
            </Col>
            <Col col={12} sm={12} lg={3}>
              <FuncIcon name="employeeCardIcon" />
              <Caption>Digitalt ansattkort</Caption>
              <PromoImg>
                <PromoPhone name="employeeCardPhone" />
              </PromoImg>
            </Col>
          </Flex>
        </Wrapper>
      </Section>
    );
  }
}

export default DescribeFuncSection;
