/**
 * @flow
 */

import React, {Component} from 'react';
import {
	Flex,
	Box,
} from 'reflexbox';
import {SaveButton} from '../Buttons';
import {Card, CardTitle} from '../Elements'

import Dropzone from '../Dropzone';
import styled from 'styled-components';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';


import {NotificationManager} from "react-notifications";
import {uploadAnyImage} from "../../../common/benefits/actions/uploadImage";
import Modal from '../Modal'


const DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;
const CancelImageUpdateButton = styled(SaveButton)`
  margin-top: 40px;
`;

const AccountImageBorder = styled.div`
  border: 1px dashed darkgrey;
`;

type
TProps = {
	uploadUrl: string;
	showUrl: string;
	headline: string;
	minWidth: number;
	minHeight: number;
	aspectRatio: number;
	optional? : boolean;
}

type
TState = {}
const _URL = window.URL || window.webkitURL;

class ImageUploader extends Component<void, TProps, TState> {
	
	state: TState = {
		file: null,
		edit: false,
	};
	
	_onDrop = (acceptedFiles) => {
		const [file] = acceptedFiles;
		
		const setter = this.setState
		let img = new Image();
		
		const onloader = function (setter) {
			if (this.width >= setter.props.minWidth && this.height >= setter.props.minHeight) {
				setter.setState({file});
			} else {
				console.log("IMAGE TOO SMALL!")
				NotificationManager.error('Error', `Image too small (is ${this.width}x${this.height}, min ${setter.props.minWidth}x${setter.props.minHeight})`, 6000);
			}
		};
		
		const onloaderBound = onloader.bind(img, this)
		img.onload = onloaderBound;
		
		
		img.onerror = function () {
			console.log("IMAGE ERROR!")
			NotificationManager.error('Error', 'Invalid image', 3000);
		};
		
		img.src = _URL.createObjectURL(file);
	};
	
	_onImageUpload = () => {
		
		this.refs.cropperz.getCroppedCanvas().toBlob(blob => {
			uploadAnyImage(this.props.uploadUrl, blob, url => {
					if (this.props.input.onChange) {
						this.props.input.onChange(url);
					}
					
					this.setState({file: null, picture: url, edit: false});
					NotificationManager.success('Success', 'Image uploaded!', 3000);
				},
				errorCb => {
					NotificationManager.error('Error', 'Error uploading image', 3000)
				});
		});
	};
	
	
	_crop = () => {
		
		const data = this.refs.cropperz.getData();
		
		if (data.width < this.props.minWidth || data.height < this.props.minHeight) {
			data.width = this.props.minWidth;
			data.height = this.props.minHeight;
			this.refs.cropperz.setData(data);
		}
	}
	
	renderShow(picture, headline) {
		console.log("Picture:");
		console.log(picture);
		return (
			<div>
				<Box w={[1]}>
					<CardTitle>
						Click image to edit <span style={{textTransform: "capitalize", fontSize: "1em", fontWeight: "500"}}>( Optional )</span>
					</CardTitle>
				</Box>
				<AccountImageBorder onClick={(e) => this.onClickShow(e)}>
					<img onError={this.noImage}
					     src={picture + '&' + new Date().getTime()}
					     alt="preview"
					     style={{width: '100%', aspectRatio: this.props.aspectRatio ? `${this.props.aspectRatio}` : "unset", objectFit: "cover"}}
					/>
				</AccountImageBorder>
			</div>
		)
		
	}
	
	noImage(p) {
		p.target.src = 'https://firebasestorage.googleapis.com/v0/b/my-coveredpro.appspot.com/o/click-to-edit.png?alt=media&token=6927c3df-82d6-48cd-b0b2-0ddd301fece3';
	}
	
	
	renderEdit(headline) {
		return (
			<Modal header={headline}
			       onSaveClick={(e) => this.onSave(e)}
			       onCancelClick={() => this.setState({edit: false})}
			       saveButtonText={"Save"}
			       cancelButtonText={"Cancel"}
			       isOpen={this.state.edit}>
				<Box w={[1]}>
					{
						this.state.file ?
							<Cropper
								ref='cropperz'
								src={this.state.file.preview}
								style={{
									width: 780,
									height: 780,
								}}
								scalable={false}
								zoomable={false}
								x={0}
								y={0}
								width={780}
								height={780}
								crop={this._crop.bind(this)}
								aspectRatio={this.props.aspectRatio}
								guides={false}
							/>
							:
							<Dropzone onDrop={this._onDrop.bind(this)} multiple={false}>
								<DropzonePlaceholder>
									Drop file or click to select
								</DropzonePlaceholder>
							</Dropzone>
						
					}
				</Box>
			</Modal>)
	}
	
	onBlob = (blob) => {
		uploadAnyImage(this.props.uploadUrl, blob, this.onUrl,
			errorCb => {
				NotificationManager.error('Error', 'Error uploading image', 3000)
			});
	}
	
	onUrl = (url) => {
		this.setState({file: null, picture: url, edit: false});
		if (this.props.onChange) {
			this.props.onChange(url)
		} else if (this.props.input && this.props.input.onChange) {
			this.props.input.onChange(url)
		}
		NotificationManager.success('Success', 'Image uploaded!', 3000);
	}
	
	
	onSave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		this.refs.cropperz.getCroppedCanvas().toBlob((blob) => {
			this.onBlob(blob)
		});
	}
	
	onClickShow = (event) => {
		event.preventDefault()
		this.setState({...this.state, edit: true})
	}
	
	render() {
		const {headline, buttonText} = this.props
		
		const hasValue = (this.props.input && this.props.input.value && this.props.input.value.length > 0)
		
		let picture = hasValue ? this.props.input.value : this.props.showUrl;
		
		if (this.state.picture) {
			picture = this.state.picture;
		}
		
		return (this.props.children ? <div onClick={(event) => this.onClickShow(event)}>
					{this.props.children}
					{this.renderEdit(buttonText)}
				</div> :
				<Card>
					<Box w={[1]}>
						{this.renderShow(picture, headline)}
						{this.renderEdit(buttonText)}
					</Box>
				</Card>
		);
	}
}

export default ImageUploader

