import React, { Component } from  'react';
import { Flex, Box } from 'reflexbox';
import {CardTitle} from '../../../components/Elements'
import styled from "styled-components";
import {enDateFromTo} from "../../../../common/utils/formatDate";
import {SaveButton} from "../../../components/Buttons";
import theme from '../../../theme';

const Wrapper = styled.div`
  border-left:1px solid gray; 
  padding:15px;
  width:100%;
`;

const DetailWrapper = styled.div`
    margin-top:10px;
`;

const DetailLabel = styled.div`
    font-size:.8em;
`;

const DetailValue = styled.div`
    font-size:1.1em;
`;

const BookingWrapper = styled.div`
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  background-color: ${theme.userProfile.cardBackground};
  overflow:hidden;
  margin-bottom:15px;
`;

const BookingContent = styled.div`
    padding:10px;
`;

const StatusBarWrapper = styled.div`
    background-color: ${props => props.color ? props.color : 'inherit'}
`;

const StatusText = styled.div`
    font-size: 20px;
    font-weight:bold;
    text-align:center;
    padding:5px;
    color:white;
`;

class CabinBookingList extends Component {

    // Status 1 = Pending, 2 = Booked (Blå), 3 = Declined

    buildStatusBar = (status) => {
        return (
            <StatusBarWrapper color={status === 1 ? 'green' : status === 2 ? 'blue' : 'red'}>
                <StatusText>
                    {status === 1 ? 'Pending' : status === 2 ? 'Booked' : 'Declined'}
                </StatusText>
            </StatusBarWrapper>
        )
    }

    buildItem = (bookingId) => {
        const { userBookings, cabins } = this.props;
        const cabin = cabins[userBookings[bookingId].cabinId];
        console.log("CABINS", cabins);
        console.log("CABIN OF BOOKING CABIN ID", cabins[userBookings[bookingId].cabinId]);
        return (
            <BookingWrapper key={bookingId}>
                {this.buildStatusBar(userBookings[bookingId].status)}
                <BookingContent>
                    <Flex>
                        <Box w={1/2}>
                            <DetailWrapper>
                                <DetailLabel>
                                    Contact Person Name
                                </DetailLabel>
                                <DetailValue>
                                    {cabin && cabin.contact && cabin.contact.fullName}
                                </DetailValue>
                            </DetailWrapper>
                            <DetailWrapper>
                                <DetailLabel>
                                    Person Email
                                </DetailLabel>
                                <DetailValue>
                                    {cabin && cabin.contact && cabin.contact.email}
                                </DetailValue>
                            </DetailWrapper>
                        </Box>
                        <Box w={1/2}>
                            <DetailWrapper>
                                <DetailLabel>
                                    Cabin
                                </DetailLabel>
                                <DetailValue>
                                    {cabins && cabin && cabin.title}
                                </DetailValue>
                                <DetailLabel>
                                    Period
                                </DetailLabel>
                                <DetailValue>
                                    {enDateFromTo(userBookings[bookingId].start_date, userBookings[bookingId].end_date, true)}
                                </DetailValue>
                            </DetailWrapper>
                        </Box>
                    </Flex>
                    <SaveButton type={'button'} style={{width: '100%', marginTop: 15, marginBottom: 0}} onClick={() => console.log("Cancelled booking")}>KANSELLER</SaveButton>
                </BookingContent>
            </BookingWrapper>
        )
    }

    render() {
        const { userBookings } = this.props;

        const today = new Date();
        const todayTime = today.getTime();

        return(
            <Wrapper>
                <CardTitle>
                    Mine Bestillinger
                </CardTitle>
                {Object.keys(userBookings).length && Object.keys(userBookings).map((key) => {
                    const checkDate = new Date(userBookings[key].end_date);
                    const checkDateTime = checkDate.getTime();
                    if (checkDateTime > todayTime) {
                        return this.buildItem(key);
                    }
                })}
            </Wrapper>
        )
    }
}

export default CabinBookingList;
