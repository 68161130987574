import {
    buildCategories,
    buildCategoriesByAccount,
    buildAccounts,
    buildBenefits,
    buildBenefitsArchive,
    buildBenefitsDraft,
    buildLanguages,
    buildBenefitStats,
    buildHiddenVendorBenefits,
    buildBenefitOrder,
    buildBenefitOrders
} from "../../../../common/config/firebase_config/endpoints";


export const getAccounts = (onAccountsLoadSuccess) => {
    return ([{
        endpoint: buildAccounts(),
        method: 'on',
        eventType: 'value',
        callback: onAccountsLoadSuccess,
    }
    ]);
};

export const getVendorVisibility = (accountId, onVendorVisibilityLoadSuccess) => {
    return ([{
        endpoint: buildHiddenVendorBenefits(accountId),
        method: 'on',
        eventType: 'value',
        callback: onVendorVisibilityLoadSuccess,
    }])
}

export const getBenefitOrder = (accountId, onBenefitOrderLoadSuccess) => {
    console.log("getBenefitOrder", accountId);
    return ([{
        endpoint: buildBenefitOrders(accountId),
        method: "on",
        eventType: "value",
        callback: onBenefitOrderLoadSuccess,
    }])
}

export const getCategories = (accounts, accountId, onCategoriesLoadSuccess) => {

    const accountSettings = accounts[accountId]


    if (accountSettings.categories) {
        console.log(`Categories are completely custom. `)
        return ([{
            endpoint: buildCategoriesByAccount(accountId),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: accountId
        }])
    }
    else if (accountSettings.cloneCategories) {
        const cloneAccountId = accountSettings.cloneCategories
        console.log(`Categories will be cloned from account ${cloneAccountId}.`)
        return ([{
            endpoint: buildCategoriesByAccount(cloneAccountId),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: cloneAccountId
        }])
    }
    else if (accountSettings.defaultCategories || (!(accountSettings.categories || accountSettings.cloneCategories))) {
        console.log("Default categories will be used...")
        return ([{
            endpoint: buildCategories('default'),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: 'default'
        }])

    }


};

export const getBenefits = (accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft, onLanguagesLoadSuccess) => {



    const account = accounts[accountId]
    let queries = [{
        endpoint: isArchive ? buildBenefitsArchive(accountId) : isDraft ? buildBenefitsDraft(accountId) : buildBenefits(accountId),
        method: 'on',
        eventType: 'value',
        callback: onBenefitsLoadSuccess,
        metadata: accountId,
        config: account
    },
    {
        endpoint: buildLanguages,
        method: 'on',
        eventType: 'value',
        callback: onLanguagesLoadSuccess,
    }]

    if (isDraft || isArchive) return queries;
    console.log("Got again");
    if (account.vendors && account.vendors.length > 0 ) {
        account.vendors.forEach((vendor) => {
            if (vendor && vendor.type && (vendor.type.value || typeof vendor.type === 'string')) {
                queries.push({
                    endpoint: buildBenefits(vendor.type.value || vendor.type),
                    method: 'on',
                    eventType: 'value',
                    callback: onBenefitsLoadSuccess,
                    metadata: (vendor.type.value || vendor.type),
                    config: account
                })
            }
        })
    }


    return queries;
};

export const getBenefitStats = (accountId, callback) => {
    return ([{
        endpoint: buildBenefitStats(accountId),
        method: 'on',
        eventType: 'value',
        callback: callback,
    }
    ]);
};
