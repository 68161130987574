/**
 * @flow
 */

import createReducer from '../createReducer';

const initialState = {
  data: {},
  list: [],
  error: null,
  isLoading: false,
};

const handlers = {
  LOAD_FAQ_DATA_REQUEST: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  LOAD_FAQ_DATA_FAILURE: (state, action) => {
    return { ...state, error: action.payload, isLoading: false };
  },
  LOAD_FAQ_DATA_SUCCESS: (state, action) => {
    const data = action.payload;
    const faqs = {};
    let list = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        const faq = {...data[key]}
        faqs[key] = faq;
        list.push(faq);
      });

    }
    list.reverse();
    return {
      ...state,
      error: null,
      isLoading: true,
      data: faqs,
      list: list
    };
  },
  LOAD_ACCOUNT_DATA: (state, action) => {
    console.log('LOAD_ACCOUNT_DATA:' + JSON.stringify(action.payload));
    const data = action.payload;
    const account_data = {};
    let list = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        const faq = {...data[key]}
        account_data[key] = faq;
        list.push(faq);
      });

    }
    list.reverse();
    return {
      ...state,
      error: null,
      isLoading: true,
      data: account_data,
      list: list
    };
  },
};

export default createReducer(initialState, handlers);
