/**
 * @flow
 */

import React, {
    Component,
} from 'react';
import StickyMenu from './components/StickyMenu'
import MessageSection from './components/MessageSection'
import Footer from './components/Footer'
import parseSearch from "../../../common/utils/parseSearch";
import styled from "styled-components";
import { firebaseDb } from "../../../common/config/firebase_config";
import Spinner from "../../components/Spinner";
import H2 from "../../components/Typografy/H2";

let parsed = "";
let headline = "Henter din nedlastningslenke"
let subHeadline = "Dette kan ta noen sekunder...";
let subHeadLine2 = "";

type TProps = {
    getAppCodeForUser: (data) => void,
    getAppCodeAppleNotEnterprise: (data) => void,
    accountId: number,
    user_token: string,
    notEnterprise: string
}

const DownloadLink = styled.a`
  color: #ffffff;
  font-size: 1em;
  margin: auto 0.5em auto 0.5em;
  border-radius: 50px;
  text-decoration:none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f41a4d;
  display:block;
  
  &:hover, &:focus, &:active {
    opacity:0.8;
  }
`;

const RetryHeading = styled(H2)`
  color: #171717;;
  text-align: center;
  padding-bottom: 0.5em;
  font-weight: normal;
  margin-top:3vh;
  font-size:1em;
  `;

const RetryLink = styled.button`
  color: #ffffff;
  font-size: 0.8em;
  margin: auto auto 0.5em 0.5em;
  border-radius: 50px;
  text-decoration:none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #f41a4d;
  
  &:hover, &:focus, &:active {
    opacity:0.8;
  }
`;

const resetTexts = () => {
     headline = "Henter din nedlastningslenke"
     subHeadline = "Dette kan ta noen sekunder...";
     subHeadLine2 = "";

}

class VerifyAppleToken extends Component<void, TProps, void> {

    constructor(props) {
        super(props);

        this.state = {
            cpb: false
        }
    }


    componentDidMount = () => {
        this.generate();

    };

    reset(){
        resetTexts()
        this.generate(true)
    }

    generate() {
        parsed = parseSearch();
        if (parsed.accountId === undefined) {
            subHeadline = "En feil oppstod";
            subHeadLine2 = "Prøv lenken en gang til, hvis samme melding vises, kontakt: hjelp@coveredpro.com";
        }
        else {
          if (parsed.notEnterprise) {
              console.log("not ent")
              this.props.getAppCodeAppleNotEnterprise(parsed.accountId);
          }
          if (parsed.commonApp) {
              console.log("common")
              this.setState({
                  cpb:true
              });
              this.props.getAppCodeAppleCommon();
          }
          else {
              console.log("code")
              this.props.getAppCode(parsed.accountId);
          }
        }
    }

    renderContent = () => {
        return (
            <div>
                <StickyMenu/>
                <MessageSection
                    headlineText={headline}
                    subheadlineText={subHeadline}
                    subheadline2Text={subHeadLine2}
                />
                <Footer/>
            </div>
        )
    };

    buildButton = (param) => {
        return (
            <div>
              <DownloadLink href={param.link}>
                Last ned
              </DownloadLink>
                {(param.reads > 1) && <RetryHeading>Trøbbel med nedlastingen? <RetryLink onClick={() => this.reset()}>Klikk her</RetryLink> for å lage en ny nedlastingslenke</RetryHeading>}
              <em style={{fontSize: 15}}>
                Dette steget må gjøres på din iPhone. Merk: nedlastningen kan ta opptil noen minutter.
              </em>
            </div>
        )

    };

    render() {

        const {token} = this.props
        console.log(token);

        if (token && token.error) {
            subHeadline = "En feil oppstod";
            subHeadLine2 = "Prøv lenken en gang til, hvis samme melding vises, kontakt: hjelp@coveredpro.com";
        }
        if (this.state.cpb) {
            headline = "Klikk 'Last ned' å laste ned appen"
            subHeadline = "NB: Du må nå klikke \"Åpne i AppStore\"";
            subHeadLine2 = this.buildButton(token);
        }
        else if (token && (!token.loading) && token.link) {
            headline = "Klikk 'Last ned' å laste ned appen"
            subHeadline = "NB: Du må nå klikke \"Åpne i iTunes\", og deretter klikke \"Løs inn\" oppe i høyre hjørne.";
            subHeadLine2 = this.buildButton(token);
        }


        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }
}

export default VerifyAppleToken;
