/**
 * @flow
 */

import type { TContactState } from './TContactState';
import createReducer from '../createReducer';
import type { THandler } from '../types/THandler';

const initialState: TContactState = {
  sent: false,
  error: null,
  isLoading: false,
};

const handlers: THandler<TContactState> = {
  LANDING_CONTACT_ME_REQUEST: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  LANDING_CONTACT_ME_FAILURE: (state, action) => {
    return { ...state, error: action.payload, isLoading: false };
  },
  LANDING_CONTACT_ME_SUCCESS: (state, action) => {
    return {
      ...state,
      sent: true,
      isLoading: false,
    };
  },
};

export default createReducer(initialState, handlers);
