/**
 * @flow
 */

import createReducer from '../createReducer';
import type { THandler } from '../types/THandler';

//TODO: add flow typing for TLocationsState

const initialState = {
  data: {},
  error: null,
  isLoaded: false,
};

const _buildLocationsData = (data) => {
  return data;
};

const handlers: THandler = {
  LOAD_LOCATIONS_SUCCESS: (state, action) => {
    const locations = _buildLocationsData(action.payload);
    return { ...state, error: null, isLoaded: true, data: locations };
  },
};

export default createReducer(initialState, handlers);
