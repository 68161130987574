/**
 * @flow
 */


import { connect } from 'react-redux';
import MessagePage from './MessagePage';
import { onMessagesLoadSuccess } from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';

const MessageContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     messages: state.messages.list,
   };
 },
 (dispatch) => ({
   onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
 }),
)(MessagePage);
export default MessageContainer;
