import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {Card, CardTitle} from '../../../../components/Elements'
import { Field, FieldArray} from 'redux-form';

import styled from 'styled-components';

import ToggleSwitch from '../../../../components/ToggleSwitch'

import CloneCategories from './CloneCategories'
import CustomCategories from './CustomCategories'


class Categories extends Component<void, TProps, TState> {


    renderClone(){
        return <CloneCategories accountId={this.props.accountId} accounts={this.props.accounts}/>
    }
    renderCustom(){

        return <FieldArray name="categories" accountFormValues={this.props.accountFormValues} accountId={this.props.accountId}  component={CustomCategories}/>

    }

    render() {

        return (
            <Card wrap={true}>
                    <Box w={[1]}>
                        <CardTitle>
                            Benefit categories
                        </CardTitle>
                    </Box>
                    <Box w={[1]}>
                        <Field
                            name="customCategories"
                            component={ToggleSwitch}
                            label="Use completely custom categories"
                            type="checkbox"
                        />
                    </Box>
                <Box w={[1]}>
                {this.props.accountFormValues && this.props.accountFormValues.customCategories ? this.renderCustom() : this.renderClone() }
                </Box>
            </Card>
        );
    }
}

export default Categories

