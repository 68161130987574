/**
 * @flow
 */
import {
    buildCabinBooking,
    buildNewCabin,
    buildNewCabinBooking, buildUserBooking,
    buildUserBookings
} from "../../config/firebase_config/endpoints";
import {firebaseDb} from "../../config/firebase_config/firebase";
import {NotificationManager} from "react-notifications";
import base64 from 'base-64';

export const onBookingLoadSuccess = (payload , metadata, config) => ({
    type: 'LOAD_BOOKING_SUCCESS',
    payload,
    metadata,
    config
});

export const onAllBookingLoadSuccess = (payload , metadata, config) => ({
    type: 'LOAD_ALL_BOOKING_SUCCESS',
    payload,
    metadata,
    config
});

export const onUserBookingLoadSuccess = (payload , metadata, config) => ({
    type: 'LOAD_USER_BOOKING_SUCCESS',
    payload,
    metadata,
    config
});

const onBookingRequest = () => ({
    type: 'UPDATE_BOOKING_REQUEST'
})

const onBookingFail = () => ({
    type: 'UPDATE_BOOKING_FAIL'
})

const onBookingSuccess = () => ({
    type: 'UPDATE_BOOKING_SUCCESS',
});

export const approveBooking = (cabinId, bookingId, status) => async (dispatch, getState) => {
    try {
        dispatch(onBookingRequest());
        const store = getState();
        const accountId = store.auth.profile.data.accountId;

        let updates = {};

        updates[buildCabinBooking(accountId, cabinId, bookingId) + '/status'] = status;

        console.log("approveBooking called")
        await firebaseDb.ref().update(updates);

        dispatch(onBookingSuccess());
    } catch (err) {
        console.log("approveBooking ERROR: " + JSON.stringify(err))
        dispatch(onBookingFail());
    }
};

export const requestBooking = (data, cabinId ) => async (dispatch, getState) => {
    try {
        dispatch(onBookingRequest());
        const store = getState();
        const accountId = store.auth.profile.data.accountId;

        let updates = {};

        const newBookingEndpoint = buildNewCabinBooking(accountId, cabinId);
        let bookingKey = firebaseDb.ref().child(newBookingEndpoint).push().key;


        updates[buildCabinBooking(accountId, cabinId, bookingKey)] = data;
        updates[buildUserBooking(accountId, base64.encode(data.user_email), bookingKey)] = {...data, cabinId:cabinId};

        console.log("Request Booking Called")
        await firebaseDb.ref().update(updates);

        NotificationManager.success('Success!', 'Booking sent!', 3000);
        dispatch(onBookingSuccess());
    } catch (err) {
        console.log("Request Booking ERROR: ", err)
        NotificationManager.error('Error!', 'An error occurred, the booking was not sent.', 3000);
        dispatch(onBookingFail());
    }
};
