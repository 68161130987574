/**
 * @flow
 */


import {connect} from 'react-redux';
import CabinDetails from './CabinDetails';
import {onBenefitsLoadSuccess} from '../../../../common/benefits/actions/index';
import type {TAppState} from '../../../../common/TAppState';
import {archiveBenefit, copyBenefit} from '../../../../common/benefits/actions/editBenefit';
import {onAccountsLoadSuccess, setCustomCategory} from "../../../../common/accounts/actions";
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions";
import {benefitDataTemplate, updateBenefit} from "../../../../common/benefits/actions";
import {getFormValues} from "redux-form";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";
import {onCabinsLoadSuccess, updateCabin, updateDraftCabin} from "../../../../common/cabins/actions";
import {requestBooking} from "../../../../common/cabins/actions/bookings";

const CabinDetailsContainer = connect(
    (state: TAppState) => {
        return {
            profile: state.auth.profile.data,
            accountId: state.auth.profile.data.accountId,
            accountsLoaded: state.accounts.isLoaded,
            accounts: state.accounts.data,
            cabins: state.cabins.data,
            isLoaded: state.cabins.isLoaded,
            bookingFormValues: getFormValues('CabinBookingForm')(state),
            isDraft: true
        };
    },
    (dispatch) => ({
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        onCabinsLoadSuccess: (data, metadata, account) => dispatch(onCabinsLoadSuccess(data, metadata, account)),
        requestBooking: (data, cabinId) => dispatch(requestBooking(data, cabinId)),
        updateCabin: (data, cabin, publish) => dispatch(updateCabin(data,cabin,publish)),
    }),
)(CabinDetails);

export default CabinDetailsContainer;

