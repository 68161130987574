/**
 * @flow
 */

import React, { Component } from 'react';
import { polyfill } from 'smoothscroll-polyfill';

import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'

class TermsPageEn extends Component<void, void, void> {

    render() {
        polyfill();
        return (
            <div>
                <StickyMenu />
                <MessageSection
                    headlineText={'Terms And Conditions'}
                    full={
                        <div className="text_holder">
                            <p>
                                Please read the following Terms and Conditions carefully before using one of CoveredPros apps (the "App"). By using the App, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these Terms and Conditions, please do not use the App.
                            </p>

                            <h2>1. Acceptance of Terms</h2>
                            <p>
                                By registering and using the App, you acknowledge that you have read, understood, and accepted these Terms and Conditions. These Terms and Conditions may be updated or modified by the App at any time without notice. Your continued use of the App constitutes your acceptance of the revised Terms and Conditions.
                            </p>

                            <h2>2. User Registration</h2>
                            <p>
                                2.1. To access the benefits and discount codes available through the App, you must complete the registration process and provide accurate, complete, and current information.
                            </p>
                            <p>
                                2.2. You are responsible for maintaining the confidentiality of your account information, including your email, and for all activity that occurs under your account.
                            </p>

                            <h2>3. Eligibility</h2>
                            <p>
                                3.1. The App is intended for employees/members of registered companies/organizations who are eligible to access the benefits and discount codes offered by their employer, membership organization, CoveredPro and/or external providers.
                            </p>
                            <p>
                                3.2. Users must verify their employment/membership status with a registered company/organization through the App's designated process.
                            </p>

                            <h2>4. Benefits and Discount Codes</h2>
                            <p>
                                4.1. By using the App, registered users will have access to a list of benefits and discount codes provided by their employer/organization. These benefits and discount codes are subject to change, and their availability is determined by the respective employer/organization.
                            </p>
                            <p>
                                4.2. The App is not responsible for the accuracy, availability, or quality of benefits and discount codes available in the app. Users are encouraged to contact their employer, organization or the respective benefit provider for any concerns or discrepancies.
                            </p>

                            <h2>5. Use of the App</h2>
                            <p>
                                5.1. You agree to use the App in compliance with all applicable laws, rules, and regulations.
                            </p>
                            <p>
                                5.2. You agree not to engage in any activity that could harm, disrupt, or interfere with the App's functionality, security, or integrity.
                            </p>
                            <p>
                                5.3. The App may collect and store anonymized user data for the purpose of providing and improving the services. Please refer to our Privacy Policy for more information.
                            </p>

                            <h2>6. Termination of Account</h2>
                            <p>
                                6.1. The App reserves the right to terminate or suspend your account at its discretion, with or without notice, if you violate these Terms and Conditions.
                            </p>
                            <p>
                                6.2. Your account with the App will also be subject to termination if you are no longer employed or a member by a registered company/organization that provides access to the App's benefits and discount codes.
                            </p>
                            <p>
                                6.3. You may also terminate your account at any time by contacting our customer support.
                            </p>

                            <h2>7. Limitation of Liability</h2>
                            <p>
                                7.1. The App is provided "as is" and "as available." We make no warranties or representations regarding the accuracy, reliability, or availability of the App or the benefits and discount codes provided.
                            </p>
                            <p>
                                7.2. The App shall not be liable for any direct or indirect damages, including but not limited to loss of data, loss of profits, or other losses resulting from your use of the App.
                            </p>

                            <h2>8. Changes to Terms and Conditions</h2>
                            <p>
                                The App reserves the right to change these Terms and Conditions at any time. Any changes will be effective immediately upon posting the updated Terms and Conditions on the App. It is your responsibility to review these Terms and Conditions periodically.
                            </p>

                            <h2>9. Contact Information</h2>
                            <p>
                                If you have any questions, comments, or concerns regarding these Terms and Conditions, please feel free to reach out to us. We value your privacy and are here to assist you.
                            </p>
                            <p>
                                <strong>Coveredpro AS<br />Org.nr. 918 084 495</strong>
                            </p>
                            <p>
                                <a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a>
                            </p>
                        </div>
                    }
                />
                <Footer />
            </div>
        );
    }
}

export default TermsPageEn;
