import {
  Component,
  Children,
} from 'react';
import { connect } from 'react-redux';
import AsyncStorage from '../utils/AsyncStorage';

import * as keys from '../storageKeys';
import { restoreState } from './actions';


class Persistence extends Component {

  componentDidMount() {
    this.props.restoreState();
  }

  async _multiSet(kvPairs) {
    if (kvPairs.length > 0) {
      try {
        console.log("Persisting data: " + JSON.stringify(kvPairs))
        await AsyncStorage.multiSet(kvPairs);
      } catch (err) {
        console.error(err);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const kvPairs = Object.keys(this.props.storage)
      .filter(key => this.props.storage[key] && prevProps.storage[key] !== this.props.storage[key])
      .map(key => {
        return [key, typeof this.props.storage[key] === 'string' ?
          this.props.storage[key]
          : JSON.stringify(this.props.storage[key])];
      });

    this._multiSet(kvPairs);
  }

  render() {
    return this.props.children;
  }
}

const PersistenceContainer = connect(
  (state) => ({
    storage: {
      [keys.KEY_AUTH0_TOKEN]: state.auth.signIn.auth0Token,
      [keys.KEY_FIREBASE_TOKEN]: state.auth.signIn.firebaseToken,
      [keys.KEY_PROFILE]: state.auth.profile.data,
    },
  }),
  (dispatch) => ({
    restoreState: () => dispatch(restoreState()),
  }),
)(Persistence);

export default PersistenceContainer;
