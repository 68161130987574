import React from 'react';
import ReactDOM from 'react-dom';

import './web/assets/main.css';
import './web/assets/notifications.css';
import './web/assets/nav.css';
import App from './web/App';
//import { unregister } from './registerServiceWorker';
//unregister() ;
//tttt
import registerServiceWorker from './registerServiceWorker';
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
