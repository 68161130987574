/**
 * @flow
 */


import {connect} from 'react-redux';
import BenefitDetails from './BenefitDetails';
import {onBenefitsLoadSuccess} from '../../../../common/benefits/actions/index';
import type {TAppState} from '../../../../common/TAppState';
import {archiveBenefit, copyBenefit} from '../../../../common/benefits/actions/editBenefit';
import {onAccountsLoadSuccess, setCustomCategory} from "../../../../common/accounts/actions";
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions";
import {benefitDataTemplate, updateDraftBenefit} from "../../../../common/benefits/actions";
import supercluster from "supercluster";

const arrangeLocation = (locationArray, key, arranged) => {

    for (var n = 0; n < locationArray.length; n++) {
        const location = locationArray[n];
        if (location && location.latitude && location.longitude && location.heading) {

            const mappedKey = location.latitude + '-' + location.longitude
            if (arranged.mapped[mappedKey]) {
                const index = arranged.mapped[mappedKey].index
                arranged.parsed[index].properties.benefits.push({name: location.heading, id: key});
            }
            else {

                const new_loc = {
                    type: "Feature",
                    properties: {
                        scalerank: 2,
                        name: location.heading,
                        comment: null,
                        name_alt: null,
                        lat_y: location.latitude,
                        long_x: location.longitude,
                        region: "Norge",
                        subregion: null,
                        featureclass: "poi",
                        benefits: [{name: location.heading, id: key}],
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            location.longitude,
                            location.latitude
                        ]
                    }
                };

                arranged.parsed.push(new_loc);
                arranged.mapped[mappedKey] = {index: (arranged.parsed.length - 1)}
            }

        }

    }
    return arranged;
}

const arrangeLocations = (benefits, params) => {

    if (!getIsFiltered(params)) {
        return benefits.locations
    }

    let arranged = { parsed: [], mapped: {} };

    let selectedKey = params ? params.benefitId : null;

    const locationArray = benefits[selectedKey] ? benefits[selectedKey].locations : null;
    if (locationArray) {
        arranged = arrangeLocation(locationArray, selectedKey, arranged);
    }
    console.log("not filtered - key: " + selectedKey + " arranged " + JSON.stringify(arranged))
    let cluster = supercluster({radius: 60, maxZoom: 13,});
    cluster.load(arranged.parsed);

    return cluster;
}

const getIsFiltered = (params) => {
    return params && params.benefitId;
}

const BenefitDetailsContainer = connect(
    (state: TAppState, props) => {
        return {
            accountId: state.auth.profile.data.accountId,
            benefits: state.benefits.data.all || [],
            benefitsList: state.benefits.all,
            accounts: state.accounts.data,
            accountsLoaded: state.accounts.isLoaded,
            profile: state.auth.profile.data,
            categories: state.categories.list,
            locations: arrangeLocations(state.benefits.all || {}, {benefitId: props.match.params.id}),
            isFiltered: getIsFiltered(state.params),
            theme: state.theme.data,
        };
    },
    (dispatch) => ({
        onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
        onArchive: (accountId, benefit) => dispatch(archiveBenefit(accountId, benefit)),
        onCopy: (sourceAccountId, targetAccountId, benefit) => dispatch(copyBenefit(sourceAccountId, targetAccountId, benefit)),
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        onSetCustomCategory: (accountId, targetCategoryId, benefit) => dispatch(setCustomCategory(accountId, targetCategoryId, benefit)),
        onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
        onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
        updateDraftBenefit: (data, benefit, disable) => dispatch(updateDraftBenefit(data, benefit, disable)),
    }),
)(BenefitDetails);

export default BenefitDetailsContainer;

