/**
 * @flow
 */
import { firebaseDb } from '../config/firebase_config/firebase';
import { functionsConfig } from '../config/firebase_config/config';

import Papa from 'papaparse'

import {
    buildAccountArchive,
    buildAccount,
    buildAccountTheme,
    buildAccountCustomizeVendorBenefitCategory,
    buildAccountThemeExperimental,
    buildAppcodes,
    buildAndroidAppCode,
    buildAppleNotEnterpriseCode,
    buildAllAppcodes,
    buildAppcodePointer,
    buildAllAppcodePointers
} from '../config/firebase_config/endpoints';

import { NotificationManager } from 'react-notifications';
import history from '../utils/history'

import { buildTheme } from '../theme/native/base'
import { getRef } from "../firebase";
import { appcodeUpdater } from "./updateAppcodes";
import uploadTemplateString from "./uploadTemplate";

const TEST = false

export const onAccountsLoadSuccess = payload => ({
    type: 'LOAD_ACCOUNTS_SUCCESS',
    payload,
});

export const onTokensLoadSuccess = payload => ({
    type: 'LOAD_TOKENS_SUCCESS',
    payload,
});


export const appleTokenSuccess = payload => ({
    type: 'APPLE_TOKEN_SUCCESS',
    payload,
});
export const appleTokenRequest = () => ({
    type: 'APPLE_TOKEN_REQUEST',

});
export const appleTokenFailed = payload => ({
    type: 'APPLE_TOKEN_FAILED',
    payload
});


export const appleAllTokensSuccess = (accountId, data) => ({
    type: 'APPLE_ALL_TOKENS_SUCCESS',
    payload: { accountId, data },
});
export const appleAllTokensRequest = () => ({
    type: 'APPLE_ALL_TOKENS_REQUEST',

});
export const appleAllTokensFailed = () => ({
    type: 'APPLE_ALL_TOKENS_FAILED',

});


export const androidTokenSuccess = payload => ({
    type: 'ANDROID_TOKEN_SUCCESS',
    payload
});

export const androidTokenFail = payload => ({
    type: 'ANDROID_TOKEN_FAIL',
    payload
});


function updateAccountRequest() {
    return {
        type: 'UPDATE_ACCOUNT_REQUEST',
    };
}

function updateAccountSuccess() {
    return {
        type: 'UPDATE_ACCOUNT_SUCCESS',
    };
}

function updateAccountError(json) {
    return {
        type: 'UPDATE_ACCOUNT_ERROR',
    };
}


export const setCustomCategory = (accountId, targetCategoryId, benefit) => async (dispatch, getState) => {
    try {
        dispatch(updateAccountRequest());

        const endpoint = buildAccountCustomizeVendorBenefitCategory(accountId, benefit.benefitId)

        let updates = {};
        updates[endpoint] = targetCategoryId;
        await firebaseDb.ref().update(updates);

        dispatch(updateAccountSuccess());
        NotificationManager.success('Success!', 'Custom category saved!', 3000);


    } catch (err) {
        console.log("update account ERROR: " + JSON.stringify(err))

        dispatch(updateAccountError());
    }
}

export const updateAppCodes = (data, accountId, accountName) => async (dispatch, getState) => {
    console.log("entering updateAppcodes " + accountName + " " + accountId)
    await appcodeUpdater(data, accountId, accountName)
}

export const updateMembershiptTemplate = (data, accountId, accountName) => async (dispatch, getState) => {
    console.log("entering updateMembershiptTemplate " + accountName + " " + accountId)
    await uploadTemplateString(data, accountId, accountName)
}

const updateAccountTheme = async (accountId, storableData) => {
    let theme = {}

    if (storableData.theme && storableData.theme.overrides) {
        theme = storableData.theme.overrides
    }


    let updates = {};

    const builtTheme = buildTheme(theme)

    updates[buildAccountTheme(accountId)] = builtTheme;

    console.log("update theme called")
    return await firebaseDb.ref().update(updates);

}

export const updateAccountThemeExperimental = (accountId, theme) => async (dispatch, getState) => {

    let updates = {};

    const builtTheme = buildTheme(theme)

    updates[buildAccountThemeExperimental(accountId)] = builtTheme;

    console.log("update experimental theme called")
    await firebaseDb.ref().update(updates);


}


export const updateAccount = (formValues, account) => async (dispatch, getState) => {
    try {
        dispatch(updateAccountRequest());
        let updates = {};

        let storableData = JSON.parse(JSON.stringify(formValues));

        let accountId = storableData.accountId


        if (!accountId) {
            await firebaseDb.ref('/counters/accounts').transaction(function (count) {
                if (count) {
                    count++
                }
                return count;
            });

            const snap = await getRef('/counters/accounts')

            accountId = '10' + snap.val()

        }

        storableData.accountName = storableData.name
        storableData.id = accountId
        storableData.accountId = accountId

        if (storableData.isMultiAccount && storableData.includedAccountsPrepared !== null) {
            storableData.includedAccounts = storableData.includedAccountsPrepared.reduce((obj, item) => {
                obj[item.accountId] = true;
                return obj;
            }, {});
            storableData.includedAccountsPrepared = null;
        }

        updates[buildAccount(accountId)] = storableData;

        console.log("update account called")
        await firebaseDb.ref().update(updates);


        await updateAccountTheme(accountId, storableData)

        dispatch(updateAccountSuccess());
        NotificationManager.success('Success!', 'Account saved!', 3000);
        if (!account) {
            history.replace('/admin/accounts/' + storableData.accountId);
        }
    } catch (err) {
        console.log("update account ERROR: " + JSON.stringify(err))
        console.log(err);

        dispatch(updateAccountError());
    }
};


export const resetAppCode = () => async (dispatch, getState) => {
    dispatch(appleTokenRequest())

}

export const getAppCode = (accountId) => async (dispatch, getState) => {
    dispatch(appleTokenRequest())

    const tokenUrl = `${functionsConfig.appleTokenUrl}?accountId=${accountId}`

    try {
        return fetch(tokenUrl)
            .then(response => response.json())
            .then(json => dispatch(appleTokenSuccess(json)))
    } catch (error) {
        dispatch(appleTokenFailed(error))
    }

}


export const getAppCodeStatus = (accountId) => async (dispatch, getState) => {
    dispatch(appleAllTokensRequest())
    const snap = await getRef(buildAppcodePointer(accountId));

    const value = snap.val();
    if (value !== undefined && value !== null) {
        dispatch(appleAllTokensSuccess(accountId, value));
    }
    else dispatch(appleAllTokensFailed("Not found"));


}


export const getAppCodeStatusForAllAccounts = () => async (dispatch, getState) => {
    const snap = await getRef(buildAllAppcodePointers());

    const accounts = snap.val();
    dispatch(appleAllTokensSuccess(null, accounts));


}


export const getAppCodeAndroid = (accountId) => async (dispatch, getState) => {

    const snap = await getRef(buildAndroidAppCode(accountId));

    const value = snap.val();
    if (value !== undefined && value !== null) {
        dispatch(androidTokenSuccess(value));
    }
    else dispatch(androidTokenFail("Not found"));

};

export const getAppCodeAppleNotEnterprise = (accountId) => async (dispatch, getState) => {

    dispatch(appleTokenRequest());
    const snap = await getRef(buildAppleNotEnterpriseCode(accountId));

    let value = { result: null }

    if (snap.val() !== null) {
        value = {
            result: 'success',
            message: {
                link: snap.val(),
                error: null,
                loading: false
            }
        }
    }

    if (value.result !== undefined && value.result !== null) {
        dispatch(appleTokenSuccess(value));
    }
    else dispatch(appleTokenFailed({ result: 'failed', message: 'Not found' }));

};

export const getAppCodeAppleCommon = () => async (dispatch, getState) => {
    dispatch(appleTokenRequest());
    dispatch(appleTokenSuccess({
        result: 'success',
        message: {
            link: 'https://itunes.apple.com/no/app/coveredpro-avtaler/id1188443723?l=nb&mt=8',
            error: null,
            loading: false
        }
    }));
}

export const getAppCodeAndroidCommon = () => async (dispatch, getState) => {
    dispatch(androidTokenSuccess('https://play.google.com/store/apps/details?id=com.coveredpro.apps.coveredpro.unbranded3'));
}

export const archiveAccount = (account) => async (dispatch, getState) => {
    try {
        dispatch(updateAccountRequest());
        const store = getState();
        let accountId = account.accountId;


        let updates = {};

        updates[buildAccountArchive(accountId)] = account;
        updates[buildAccount(accountId)] = null;

        await firebaseDb.ref().update(updates);

        dispatch(updateAccountSuccess());
        NotificationManager.success('Success!', 'Account archived!', 3000);
        history.replace('/admin/accounts');

    } catch (err) {
        dispatch(updateAccountError());
    }
};




export const iconTextUpdated = active => ({
    type: 'TOGGLER_UPDATED',
    payload: { key: 'iconText', value: active },
});
