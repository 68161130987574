import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import {Card, CardTitle} from "../../../../components/Elements";
import Select from "../../../../components/Select";
import {SaveButton} from "../../../../components/Buttons";
import Modal from "../../../../components/Modal";
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import styled from "styled-components";
import Text from "../../../../components/Typografy/Text";

const BlackText = styled(Text)`
  color: darkgray;
  margin-bottom:20px;
`;


const options = (divisions) => {
    const multiValue = (divisions.multiValue || []).map((divs, index) => ({
        label: divs.name,
        value: `multi:${index}`
    }));
    const singleValue = (divisions.singleValue || []).map((divs, index) => ({
        label: divs.name,
        value: `single:${index}`
    }));

    return [...multiValue, ...singleValue]
}

const selectedNames = (selected, selectedId, divisions) => {
    if (!selected || !selectedId || !divisions) return []

    const index = selectedId.split(':')[1]

    const multi = divisions.multiValue[index].values

    return selected.filter(div => div === true).map((divs, index) => multi[index].name);
}




const getMulti = (form, divisions) => {
    if (!form || !divisions) return null

    if (form.limitByDivisions && form.limitByDivisions.length > 0) {
        const id = form.limitByDivisions[0].id

        const splits = id.split(':')

        const type = splits[0]

        if (type !== 'multi') return null

        const index = splits[1]

        return divisions.multiValue[index].values
    }
}

class LimitDivisions extends Component<void, TProps, TState> {
    state: TState = {
        edit: false,
    };

    renderInner(multi, form, divisions) {

        const selectedId = ((form.limitByDivisions[0] || {}).id)
        const selected = ((form.limitByDivisions[0] || {}).values)

        return (
            <Box w={[1]}>
                {selected && <Box>
                    <BlackText>Selected subdivisions: {selectedNames(selected, selectedId, divisions).join(', ')}</BlackText></Box>}
                <Box>
                    <SaveButton type="button" onClick={() => {
                        this.setState({edit: true})
                    }}> Select subdivisions </SaveButton>
                </Box>
                <Modal header={`Select subdivisions`}
                       onSaveClick={() => this.setState({edit: false})}
                       onCancelClick={() => this.setState({edit: false})}
                       saveButtonText={"Done"}
                       isOpen={this.state.edit}>

                    <Flex wrap={true}
                          justify="space-between">
                        {multi.map((value, index) =>
                            <Box w={[1, 7 / 12]} key={JSON.stringify(value)}>
                                <div>
                                    <Field
                                        name={`limitByDivisions[0].values.${index}`}
                                        component={CustomFieldInput}
                                        type="checkbox"
                                        userCard
                                        label={value.name}/>
                                </div>
                            </Box>
                        )}
                    </Flex>
                </Modal>


            </Box>
        );
    }


    render() {
        const {accounts, accountId, benefitFormValues} = this.props
       // console.log('LimitDivsions: ' + JSON.stringify(this.props))
        if (!accounts || !accountId || !accounts[accountId] || !accounts[accountId].divisions) return null

        const divisions = accounts[accountId].divisions
        const multi = getMulti(benefitFormValues, divisions)
        return (
            <div>
                <Card wrap={true}>
                    <Box w={[1]}>
                        <CardTitle>
                            Limit by Division
                        </CardTitle>
                    </Box>
                    <Box w={[1]}>
                        <Field
                            name="limitByDivisions[0].id"
                            component={Select}
                            options={options(divisions)}
                            label="Benefit is only available to:"/>
                    </Box>
                    {multi && this.renderInner(multi, benefitFormValues, divisions)}
                </Card>

            </div>
        );
    }
}

export default LimitDivisions
