/**
 * @flow
 */

import type {TDispatch} from '../types/TDispatch';

import {firebaseDb} from "../config/firebase_config/firebase";
import type {TDataLoadState} from "./TDataLoadState";


export const onLoadFaqSuccess = payload => ({
  type: 'LOAD_FAQ_DATA_SUCCESS',
  payload
});

export const onLoadAccountData = payload => ({
  type: 'LOAD_ACCOUNT_DATA',
  payload
});

const _onLoadFaqRequest = () => ({
  type: 'LOAD_FAQ_DATA_REQUEST',
});

const _onLoadFaqFailure = (error: TDataLoadState) => ({
  type: 'LOAD_FAQ_DATA_FAILURE',
  payload: error,
});

export const loadFaqData = () => async(dispatch: TDispatch) => {
  try {
    dispatch(_onLoadFaqRequest());

    let data = "";

    await firebaseDb.ref().child("config/faq").once("value", snapshot => {
      console.log("Snapshot: " + snapshot);
      data = snapshot.val();
    });

    dispatch(onLoadFaqSuccess());
  } catch (err) {
    console.log("ERROR: "+ JSON.stringify(err));

    dispatch(_onLoadFaqFailure());
  }
};

