/**
 * @flow
 */
import React, {Component} from 'react';
import {Flex} from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../components/Typografy/Wrapper';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import {mx} from '../../utils';

import List from './components/List/List';
import ListItem from './components/List/ListItem';
import H2 from '../../components/Typografy/H2';
import PromoImageWrapper from './components/PromoImageWrapper';
import Section from './components/Section';

import phoneBenefit from "../../assets/images/phone_benefit.png";

const PromotionalWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  `;

const Container = styled.div`
  position: relative;
  background-color: #141414;
  //padding: 10em 0;
  ${mx('lg')} {
    height: 100vh;
  }
  }
`;

const BoldText = styled.span`
  color: #fff;
  font-weight: bold;
  `;

const PromotionalText = styled(H2)`
  font-weight: normal;
  align-self: flex-start;
  justify-self: flex-start;
  ${mx('lg')} {
    font-size: 2em;
    }
`;

const ListText = styled.span`
  color: #fff;
  margin-bottom: 10px;
`;

const PromoPhone = styled(PromoImageWrapper)`
  position: relative;
`;

const TopWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  margin-top: 1em;
  ${mx('lg')} {
    margin-top: 1em;
    }
  `;

const DescriptionWrapper = styled.div`
  margin: 1em 0 2em 0;
  ${mx('lg')} {
    flex-direction: column;
    margin: 5em 0 2em 0;
    position: relative;
    display: flex;
    width: calc(100% - 550px);
    padding-top: 0;
    }
  `;

const ScrollIcon = styled.div`
  background-image: url(${require('../../assets/images/scroll.png').default});
  margin-top: 0.5em;
  width: 24px;
  height: 48px;
  cursor: pointer;
  `;

const ScrollText = styled.span`
  font: 0.5em;
  //text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  `;

const ScrollWrapper = styled.div`
  display: none;
  ${mx('lg')} {
    z-index: 2;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    }
  `;

const Example = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;


class PromotionalSection extends Component {
  render() {
    return (
      <Section>
        <Container>
          <PromotionalWrapper>
            <TopWrapper wrap>
                  <img
                      src={phoneBenefit}
                      height={600}
                      style={{
                        objectFit: "contain",
                        height: 600,
                          width: "100%",
                          maxWidth: 500,
                      }}
                      alt="promo_phone"
                  />

                <DescriptionWrapper>
                  <PromotionalText>
                    <BoldText> En løsning </BoldText>
                     som strukturerer, kommuniserer og forbedrer ansattgoder
                  </PromotionalText>
                  <List style={{alignSelf: "flex-end", justifySelf: "flex-end", marginTop: "2em"}}>
                    <ListItem>
                      <ListText>
                           Gjør dine ansatte mer fornøyde, lojale og stolte av selskapet sitt
                       </ListText>
                    </ListItem>
                    <ListItem>
                      <ListText>
                          Ingen integrasjon, mindre administrasjon og dramatisk forbedret tilbud
                      </ListText>
                    </ListItem>
                  </List>
                </DescriptionWrapper>
            </TopWrapper>
          </PromotionalWrapper>
        </Container>
      </Section>
    );
  }
}

export default PromotionalSection;
