/**
 * @flow
 */

import React, {Component} from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';


import ArrowIcon from '../../../components/ArrowIcon';
import BenefitCard from './BenefitCard';
import BenefitSection from './BenefitSection';
import AboutSection from './AboutSection';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {buildAccounts, buildBenefits, buildBenefitsArchive} from '../../../../common/config/firebase_config/endpoints';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import Spinner from "../../../components/Spinner";

import {getAccounts, getBenefitOrder, getBenefits, getCategories, getVendorVisibility} from '../components/Retrievers'

const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png').default});
  padding-top: 1.6em;
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;

type TProps = {
    accountId: number;
    benefits: TBenefitApiData;
    onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
    params: Object;
    saveRating: Function,
    isDraft: boolean,
    isArchive: boolean,
    updateBenefit: (data, benefit, boolean) => void;
    updateDraftBenefit: (data, benefit, boolean) => void
};
const getSteps = (stepsSource) => {
    const steps = [];
    for (const key in stepsSource) {
        if (stepsSource[key]) {
            steps.push({
                text: stepsSource[key].text,
                type: stepsSource[key].type,
                id: key,
            });
        }
    }
    return steps;
};

class BenefitDetails extends Component<void, TProps, void> {


    _getItem = () => {
        const id = this.props.match.params.id;
        const benefit = find(this.props.benefits || [], b => b.benefitId === id);
        if (!benefit) return (<div></div>);

        const steps = getSteps(benefit.steps);


        return (
            <div>
                <Container>
                    <Wrapper>
                        <BackLink to={this.props.isDraft ? "/admin/benefits-draft" : this.props.isArchive ? "/admin/benefits-archive" : "/admin/benefits"}>
                            <ArrowIcon/>
                            Benefits
                        </BackLink>
                        <BenefitCard benefit={benefit} benefitOrder={this.props.benefitOrder} hiddenStatus={this.props.hiddenBenefits.includes(benefit.benefitId)}  {...this.props} />
                    </Wrapper>
                </Container>
                <AboutSection benefit={benefit}/>
                <BenefitSection steps={steps}/>
            </div>
        );
    }


    renderInner() {
        const {accounts, accountId, onBenefitsLoadSuccess, onCategoriesLoadDefaultSuccess, onCategoriesLoadSuccess, isArchive, isDraft} = this.props
        return (
            <FirebaseContainer
                queries={[
                    ...getBenefits(accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft, this.props.onLanguagesLoadSuccess),
                    ...getCategories(accounts, accountId, onCategoriesLoadDefaultSuccess, onCategoriesLoadSuccess),
                    ...getVendorVisibility(accountId, this.props.onVendorVisibilityLoadSuccess),
                    ...getBenefitOrder(accountId, this.props.onBenefitOrderLoadSuccess)
                ]}>
                {this.props.benefits ? this._getItem() : null}
            </FirebaseContainer>
        );
    }

    render() {
        return (
            <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                {this.props.accountsLoaded ? this.renderInner() : <Spinner/>}
            </FirebaseContainer>
        );
    }
}


export default BenefitDetails;

