/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../../components/Typografy/Wrapper';
import BaseButton from '../../../../components/Typografy/BaseButton';
import Link from '../../../../components/Typografy/Link';
import { mx } from '../../../../utils';
import theme from '../../../../theme/index';

const CancelButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  background-color: transparent;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const DropButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.subHeader.buttonSecondColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;




const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top:1em
`;
const ButtonsContainer = styled.div`
   flex: 1;
   display: flex;
`;

const Dropper = (props) => {
  return(
    <Wrapper>
      <FooterFlex>
          <DropButton onClick={() => props.removeFromUserAdmin(props.user.email, props.accountId)}> Drop Admin</DropButton>
      </FooterFlex>
    </Wrapper>
)};


export default Dropper;
