/**
 * @flow
 */

import styled from 'styled-components';

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `;

export default DescriptionWrapper;
