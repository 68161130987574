/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import {imageMedium} from '../../../../common/config/images/endpoints';

const BenefitsCard = styled.div`
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  background-color: ${theme.userProfile.cardBackground}; 
  // border-radius:6px;
  overflow:hidden;
  min-height:320px;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  border-bottom:1px solid #111111;
  padding:15px;
`;

const BenefitInfoContainer = styled.div`
  padding: 0 0.5em 0 0.5em;
`;

const BenefitTitle = styled.h2`
  font-size: 1.3em;
  color: ${theme.categories.categoryColor};
  text-align:center;
`;

const BenefitText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 1.5em;
  // border-bottom: 1px solid ${theme.categories.categoryColor};
  text-align:center;
`;

const SourceText = styled.div`
  font-size: 0.8em;
  font-style: italic;
  padding-bottom: 0.5em;
  color: ${theme.categories.categoryColor};
  text-align:center;
`;

const BenefitImage = styled.img`
  width: 8vw;
  margin-left:auto;
  margin-right:auto;
  width:100%;
  margin-bottom:-0.3em;
`;

type TProps = {
  benefit: TBenefitApiData;
  accountId: number;
};

const BenefitItem = (props: TProps) => {
  const { benefit, accountId, isArchive, accounts, isDraft } = props;

  return (
    <LinkToDetails to={`/app/benefits/${benefit.benefitId}`}>
      <BenefitsCard>
        <Flex wrap>
          <Box w={1}>
            <ImageContainer>
              <BenefitImage src={imageMedium(benefit.sourceAccount || accountId, benefit.benefitId)} alt="" />
            </ImageContainer>
          </Box>
          <Box w={1}>
            <BenefitInfoContainer>
              <BenefitTitle> {benefit.heading} </BenefitTitle>
              {/*<BenefitText>*/}
                {/*{benefit.description}*/}
              {/*</BenefitText>*/}
            </BenefitInfoContainer>
              {/*{accounts && benefit.sourceAccount && benefit.sourceAccount !== accountId &&*/}
                      {/*<SourceText>Source: {accounts[benefit.sourceAccount].customerName}</SourceText>*/}
              {/*}*/}
          </Box>

        </Flex>
      </BenefitsCard>
    </LinkToDetails>
  );
};

export default BenefitItem;
