/**
 * @flow
 */


import { connect } from 'react-redux';
import RetrieveEmail from './RetrieveEmail';
import type { TAppState } from '../../../../common/TAppState';
import {onAllEmailLoadSuccess} from "../../../../common/retrievedata/actions";

const RetrieveEmailContainer = connect(
 (state: TAppState) => {
   return {
       emailData: state.retrievedata.list,
   };
 },
 (dispatch) => ({
     onAllEmailLoadSuccess: (data) => dispatch(onAllEmailLoadSuccess(data))
 }),
)(RetrieveEmail);

export default RetrieveEmailContainer;

