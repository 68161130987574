/**
 * @flow
 */


import {auth0AddAdmin, auth0RemoveAdmin, auth0Stat, auth0SetPassword} from '../../config/auth/auth0'

import type {TDispatch} from '../../types/TDispatch';

import type {
    TAuthError,
} from '../TAuthState';


import getHeaders from "../../config/auth/headers";
import {NotificationManager} from "react-notifications";

const _onAddAdminSuccess = () => ({
    type: 'AUTH0_ADD_ADMIN_SUCCESS'
});

const _onAddAdminRequest = () => ({
    type: 'AUTH0_ADD_ADMIN_REQUEST',
});

const _onAddAdminFailure = (error: TAuthError) => ({
    type: 'AUTH0_ADD_ADMIN_FAILURE',
    payload: error,
});


const _onSetPasswordSuccess = () => ({
    type: 'AUTH0_SET_PASSWORD_SUCCESS'
});

const _onSetPasswordRequest = () => ({
    type: 'AUTH0_SET_PASSWORD_REQUEST',
});

const _onSetPasswordFailure = (error: TAuthError) => ({
    type: 'AUTH0_SET_PASSWORD_FAILURE',
    payload: error,
});



const _onGetAdminUsersSuccess = (data, accountId) => ({
    type: 'AUTH0_GET_ADMIN_USERS_SUCCESS',
    payload: {data, accountId}
});


const _onGetAdminUsersRequest = () => ({
    type: 'AUTH0_GET_ADMIN_USERS_REQUEST',
});

const _onGetAdminUsersFailure = (error: TAuthError) => ({
    type: 'AUTH0_GET_ADMIN_USERS_FAILURE',
    payload: error,
});


export const setPassword = (data, accountId) => async (dispatch: TDispatch) => {
    try {
        const {email, password} = data

        dispatch(_onSetPasswordRequest());
        const response = await fetch(auth0SetPassword, {
            method: 'POST',
            headers: getHeaders(localStorage.getItem('access_token')),
            body: JSON.stringify({
                accountId,
                email,
                password
            }),
        });
        if (response.status >= 400) {
            const errorJson = await response.json();
            NotificationManager.error('Error!', JSON.stringify(errorJson.message), 6000);
            console.log("Autheresult invalid! - " + JSON.stringify(errorJson))
            dispatch(_onSetPasswordFailure({
                status: response.status,
                message: errorJson.code,
            }));
            return;
        }
        dispatch(_onSetPasswordSuccess())
        NotificationManager.success('Success!', 'Password set!', 3000);

    } catch (error) {
        console.error(JSON.stringify(error))
        NotificationManager.error('Error!', JSON.stringify(error), 3000);
        dispatch(_onSetPasswordFailure({
            status: 500,
        }));
    }
};



export const addAdmin = (data, accountId) => async (dispatch: TDispatch) => {
    try {
        const {email, firstName, lastName, password} = data

        dispatch(_onAddAdminRequest());
        const response = await fetch(auth0AddAdmin, {
            method: 'POST',
            headers: getHeaders(localStorage.getItem('access_token')),
            body: JSON.stringify({
                accountId,
                email,
                firstName,
                lastName,
                password
            }),
        });
        if (response.status >= 400) {
            const errorJson = await response.json();
            NotificationManager.error('Error!', JSON.stringify(errorJson.message), 6000);
            console.log("Autheresult invalid! - " + JSON.stringify(errorJson))
            dispatch(_onAddAdminFailure({
                status: response.status,
                message: errorJson.code,
            }));
            return;
        }
        await getAdminUsersRunner(accountId, dispatch)
        NotificationManager.success('Success!', 'User added!', 3000);
        _onAddAdminSuccess()

    } catch (error) {
        console.error(JSON.stringify(error))
        NotificationManager.error('Error!', JSON.stringify(error), 3000);
        dispatch(_onAddAdminFailure({
            status: 500,
        }));
    }
};

export const removeAdmin = (email, accountId) => async (dispatch: TDispatch) => {
    try {
        dispatch(_onAddAdminRequest());
        const response = await fetch(auth0RemoveAdmin, {
            method: 'POST',
            headers: getHeaders(localStorage.getItem('access_token')),
            body: JSON.stringify({
                accountId,
                email
            }),
        });
        if (response.status >= 400) {
            const errorJson = await response.json();
            console.log("Autheresult invalid! - " + JSON.stringify(errorJson))
            NotificationManager.error('Error!', errorJson.message, 3000);
            dispatch(_onAddAdminFailure({
                status: response.status,
                message: errorJson.code,
            }));
            return;
        }
        NotificationManager.success('Success!', 'User removed!', 3000);
        await getAdminUsersRunner(accountId, dispatch)
        _onAddAdminSuccess()

    } catch (error) {
        console.error(JSON.stringify(error))
        NotificationManager.error('Error!', JSON.stringify(error), 3000);
        dispatch(_onAddAdminFailure({
            status: 500,
        }));
    }
};


export const getAdminUsers = (accountId: string) => async (dispatch: TDispatch) => {
    await getAdminUsersRunner(accountId, dispatch)
}

const getAdminUsersRunner = async (accountId: string, dispatch: TDispatch) => {
    try {
        dispatch(_onGetAdminUsersRequest());

        const response = await fetch(`${auth0Stat}?accountId=${accountId}&operation=admins`, {
            method: 'get',
            headers: getHeaders(),
        });
        const resultJson = await response.json();
        console.log(`Admin users for ${accountId}: ${JSON.stringify(resultJson)}`)
        if (response.status >= 400) {
            const errorJson = resultJson;
            dispatch(_onGetAdminUsersFailure({
                status: response.status,
                id: errorJson.error,
                message: errorJson.error_description,
            }));
            return;
        }
        dispatch(_onGetAdminUsersSuccess(resultJson, accountId));
    } catch (error) {
        dispatch(_onAddAdminFailure({
            status: 500,
        }));
    }
};
