/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';

const Container = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-top: 1.6em;
`;

const BenefitsProfile = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;

const Title = styled.h2`
  text-align:center;
  color white;
  margin:0;
`

type TProps = {
  children?: any;
}

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    const { children, account } = this.props;
    return (
      <Container >
        <Wrapper>
          <BenefitsProfile>
          </BenefitsProfile>
          <ButtonsContainer>
            <Title>Our leisure homes</Title>
          </ButtonsContainer>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
