/**
 * @flow
 */

import styled from 'styled-components';

const LinkStyle = styled.div`
    text-decoration: none;
    //text-transform: uppercase;
    padding: 0.7em 1em;
    margin-bottom: 0.5em;
    color: #929292;
    background: '#F41A4D';
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #fff;
    &>* {
        text-decoration: none;
        color: #fff;
        border-bottom: 2px solid #F41A4D;
        }
    }
  `;

export default LinkStyle;
