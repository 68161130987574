/**
 * @flow
 */

import React, { Component } from 'react';
import styled from "styled-components";

const stepWrapper = {
  display: "block"
};

const InnerBox = styled.div `
  padding-top: 5px;
  padding-bottom: 5px;
`;

let Content = styled.div `
  
`;

class SupportQASection extends Component {
  mapSteps(props) {
    if(!(props && props.steps)) return null
    const steps = Object.values(props.steps);
    const listSteps = steps.filter(s => s !== undefined).map((step, i) =>
      <div key={i + '-steps'}>
        <span style={stepWrapper}>{step.stepText}</span>
        {this.mapChildSteps(step)}
      </div>
    );
    return (
      <div>{listSteps}</div>
    );
  }
  mapChildSteps(props) {
    if(!props.childSteps) return null;
    const childSteps = Object.values(props.childSteps);
    const listChildSteps = childSteps.filter(s => s !== undefined).map((step, i) =>
      <li key={i + '-childsteps'}>{step}</li>
    );
    return (
      <ol>
        {listChildSteps}
      </ol>
    );
  }
  render() {
    return (
        <InnerBox>
          <Content>
            <h2>{!(this.props.content.title) ? null : this.props.content.title}</h2>
            {!(this.props.content) ? null : this.mapSteps(this.props.content)}
          </Content>
        </InnerBox>
    );
  }
}

export default SupportQASection;
