/**
 * @flow
 */

 import React from 'react';
 import styled from 'styled-components';

 type TMenuProps = {
   open: boolean;
 }

 const MenuItemContainer = styled.div`
   width: 30px;
   margin-right: 10px;
   height: 30px;
   position: relative;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
   `;

 const MenuItem = styled.span`
   display: block;
   position: absolute;
   height: 4px;
   width: 100%;
   background: #fff;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   &:nth-child(1){
     top: 3px;
     }
   `;

 const MenuItem2 = styled.span`
   display: block;
   position: absolute;
   height: 4px;
   width: 100%;
   background: #fff;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   top: 11px;
   `;

 const MenuItem3 = styled.span`
   display: block;
   position: absolute;
   height: 4px;
   width: 100%;
   background: #fff;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   top: 11px;
   `;

 const MenuItem4 = styled.span`
   display: block;
   position: absolute;
   height: 4px;
   width: 100%;
   background: #fff;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .25s ease-in-out;
   -moz-transition: .25s ease-in-out;
   -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   top: 19px;
   `;

 const MenuIcon = ({ open } : TMenuProps) => {
   return (
     <MenuItemContainer className={open ? 'open' : ''} id="nav-icon3">
       <MenuItem>&#160;</MenuItem>
       <MenuItem2>&#160;</MenuItem2>
       <MenuItem3>&#160;</MenuItem3>
       <MenuItem4>&#160;</MenuItem4>
     </MenuItemContainer>
   );
 };

 export default MenuIcon;
