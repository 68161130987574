/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import FirebaseContainer from "../../../../common/firebase/FirebaseContainer";
import {Box, Flex} from "reflexbox";
import {SaveButton} from "../../../components/Buttons";
import Wrapper from "../../../components/Typografy/Wrapper";
import theme from "../../../theme";
import { buildUserDataFull} from "../../../../common/config/firebase_config/endpoints";
import XLSX from "xlsx";
import FileSaver from "file-saver";


type TProps = {

};

const StatsCard = styled.div`
  background-color: ${theme.userProfile.cardBackground}; 
  margin-bottom: 1em;
`;

const StatsInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const StatsTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const toXLSX = (data) => {
    if (data) {

        const dataRows = data.map(row => [row.email, row.firstName, row.lastName, row.signupAccount])

        const mergedRows = [["Email", "First Name", "Last Name", "Account"]].concat(dataRows)

        const worksheet = XLSX.utils.aoa_to_sheet(mergedRows);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "EmailExport");
        const wbout = XLSX.write(new_workbook, {type: 'binary', bookType: "xlsx"});


        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), "export_emails.xlsx");


    }

}

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

class RetrieveEmail extends Component<void, TProps, void> {

    _getQueries = () => {
        return ([{
            endpoint: buildUserDataFull(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAllEmailLoadSuccess,
        },
        ]);
    };

  render() {
    return (
        <Wrapper>
            <FirebaseContainer queries={this._getQueries()}>
                <StatsCard key={"benefitReports"}>
                    <Flex wrap>
                        <Box w={[1]}>
                            <StatsInfoContainer>
                                <StatsTitle>Download all registered emails</StatsTitle>
                                {this.props.emailData && console.log(this.props.emailData)}
                                <SaveButton onClick={() => toXLSX(this.props.emailData)}>Download</SaveButton>
                            </StatsInfoContainer>
                        </Box>
                    </Flex>
                </StatsCard>
            </FirebaseContainer>
        </Wrapper>
    );
  }
}


export default RetrieveEmail;

