/**
 * @flow
 */
import styled from 'styled-components';

const ShortLine = styled.hr`
  color: #F41A4D;
  width: 3em;
  flex: 1;
  border-style: solid;
  border-width: 2px;
  `;

export default ShortLine;
