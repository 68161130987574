import React, {Component} from 'react';
import {Field, getFormValues} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import Iframe from 'react-iframe'
import {SketchPicker} from 'react-color';

import Modal from '../../../../components/Modal'
import windowDimensions from 'react-window-dimensions';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';

import {CardTitle, Card, Container} from '../../../../components/Elements'

import styled from "styled-components";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import connect from "react-redux/es/connect/connect";
import {updateAccountThemeExperimental} from "../../../../../common/accounts/actions";
//import {NotificationManager} from "react-notifications";

type TProps = {
    field: string;
    baseUrl: string;
    formValues: any;
    label: string;
    width: number;
    height: number;
    showUrl: string;
    writeUrl: string;
}
const AccountImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;

const HiddenBox = styled.div`
    visibility:hidden;
  
`;

const base = 'https://passwordless-test-1.web.app'
const getUrlMapping = (page, account, platform, language, experimental) => {
    return `${base}/${account}/${page}/`
}

const SketchField = (props) => {
    return (<SketchPicker
        color={props.input.value}
        onChangeComplete={props.input.onChange}
    />)
}

class ThemeBuilder extends Component<void, TProps, TState> {
    state: TState = {
        edit: false,
        experimental: {
            logoHeight: 80,
            darkTheme: false,
            colors: {accentColor: '#F41A4D'}
        },
        original: {}
    };

    componentDidMount() {
        const account = this.props.account
        if (!account) return

        const experimental = ((this.props.account || {}).theme || {}).overrides
        if (experimental) {
            this.setState({...this.state, experimental, original: experimental})
            this.props.updateAccountThemeExperimental(this.props.acccountId, experimental)
        }
    }

    renderThemeFrame(page, scale, experimental) {
        return (
            <Box w={[1, 6 / 12]} px={2} style={{height: 1334 * scale, width: 750 * scale}}>
                <Iframe allowFullScreen={false} height={1334 * scale} width={750 * scale}
                        url={getUrlMapping(page, this.props.accountId, 'ios', 'en', experimental)}/>
            </Box>

        )
    }

    updateColor = (newValue, previousValue, name) => {
        let experimental = this.state.experimental
        experimental.colors = experimental.colors || {}
        experimental.colors.accentColor = newValue.hex
        this.updateStateAndExperimental(experimental)
    }
    updateLogoHeight = (event, newValue, previousValue, name) => {
        let experimental = this.state.experimental
        experimental.logoHeight = Number(event.currentTarget.value)
        this.updateStateAndExperimental(experimental)
    }
    updateDarkTheme = (newValue, previousValue, name) => {
        let experimental = this.state.experimental
        experimental.darkTheme = newValue
        this.updateStateAndExperimental(experimental)
    }

    updateStateAndExperimental = (experimental) => {
        this.setState({...this.state, experimental})
        this.props.updateAccountThemeExperimental(this.props.accountId, experimental)
    }

    onSave = () => {
        console.log('onSave')
        this.props.input.onChange(this.state.experimental)
        this.props.forceUpdate()
        this.setState({...this.state, edit: false})

    }

    onCancel = () => {
        console.log('onSave')
        this.setState({...this.state, experimental: this.state.original, edit: false})
    }



    render() {
        const {field, width, height} = this.props


       // const field = 'theme.overrides'

        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Adjust theme
                    </CardTitle>
                </Box>
                {this.renderThemeFrame('Benefits', 0.45)}
                {this.renderThemeFrame('Profile', 0.45)}
                <Box w={[1]}>
                    <Flex justify="flex-end">
                        <SaveButton type="button" onClick={(e) => { this.setState({...this.state, edit: true}); e.stopPropagation() }}> Update </SaveButton>
                    </Flex>
                </Box>
                <Modal header={`Adjust Theme`}
                       onSaveClick={() => this.onSave()}
                       onCancelClick={() => this.onCancel()}
                       saveButtonText={"Save"}
                       cancelButtonText={"Cancel"}
                       isOpen={this.state.edit}>

                    <Flex>
                        <Box w={1 / 4}>
                            <CustomFieldInput
                                name={`${field}.logoHeight`}
                                type="number"
                                userCard
                                label="Logo scale"
                                input={{ onChange: this.updateLogoHeight, value: this.state.experimental.logoHeight}}
                            />
                            <ToggleSwitch
                                name={`${field}.darkTheme`}
                                component={ToggleSwitch}
                                label="Use dark theme"
                                type="checkbox"
                                input={{onChange:this.updateDarkTheme, checked:this.state.experimental.darkTheme }}

                            />
                            <SketchField
                                name={`${field}.colors.accentColor`}
                                type="number"
                                userCard
                                label="Accent Color"
                                input={{onChange:this.updateColor, value: (this.state.experimental.colors || {}).accentColor}}
                            />

                        </Box>

                        {this.renderThemeFrame('Benefits', 0.55, true)}
                        {this.renderThemeFrame('Profile', 0.55, true)}

                    </Flex>
                </Modal>
            </Card>
        );
    };


}


const ThemeBuilderContainer = connect(
    null,
    (dispatch) => ({
        updateAccountThemeExperimental: (accountId, theme) => dispatch(updateAccountThemeExperimental(accountId, theme)),
    })
)(ThemeBuilder);

export default windowDimensions()(ThemeBuilderContainer);
