/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {SaveButton} from '../Buttons';
import {Card, CardTitle} from '../Elements'

import Dropzone from '../Dropzone';
import styled from 'styled-components';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';


import styles from '../../assets/form.css'
import {NotificationManager} from "react-notifications";
import {uploadAnyImage} from "../../../common/benefits/actions/uploadImage";


const DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;
const CancelImageUpdateButton = styled(SaveButton)`
  margin-top:40px;
`;

const AccountImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;

type TProps = {
    uploadUrl: string;
    showUrl: string;
    headline: string;
    minWidth: number;
    minHeight: number;
    aspectRatio: number

}

type TState = {}
const _URL = window.URL || window.webkitURL;

class ImageUploader extends Component<void, TProps, TState> {

    state: TState = {
        file: null,
        edit: false,
    };

    _onDrop = (acceptedFiles) => {
        const [file] = acceptedFiles;

        const setter = this.setState
        let img = new Image();

        const onloader = function (setter) {
            if (this.width >= setter.props.minWidth && this.height >= setter.props.minHeight) {
                setter.setState({file});
            }
            else {
                console.log("IMAGE TOO SMALL!")
                NotificationManager.error('Error', `Image too small (is ${this.width}x${this.height}, min ${setter.props.minWidth}x${setter.props.minHeight})`, 6000);
            }
        };

        const onloaderBound = onloader.bind(img, this)
        img.onload = onloaderBound;


        img.onerror = function () {
            console.log("IMAGE ERROR!")
            NotificationManager.error('Error', 'Invalid image', 3000);
        };

        img.src = _URL.createObjectURL(file);
    };

    _onImageUpload = () => {

        this.refs.cropper.getCroppedCanvas().toBlob(blob => {
            uploadAnyImage(this.props.uploadUrl, blob, url => {
                    if (this.props.input.onChange) {
                        this.props.input.onChange(url);
                    }

                    this.setState({file: null, picture: url, edit: false});
                    NotificationManager.success('Success', 'Image uploaded!', 3000);
                },
                errorCb => {
                    NotificationManager.error('Error', 'Error uploading image', 3000)
                });
        });
    };


    _crop = () => {

        const data = this.refs.cropper.getData();

        if (data.width < this.props.minWidth || data.height < this.props.minHeight) {
            data.width = this.props.minWidth;
            data.height = this.props.minHeight;
            this.refs.cropper.setData(data);
        }
    }

    renderShow(picture, headline) {

        return (
            <div>
                <Box w={[1]}>
                    <CardTitle>
                        {headline}
                    </CardTitle>
                </Box>
                <Box w={[1, 6 / 12]} px={2}>
                    <AccountImageBorder>
                        <img src={picture} alt="preview" style={{maxWidth: '100%'}}/>
                    </AccountImageBorder>
                </Box>
                <Flex justify="flex-end">
                    <SaveButton name="toggleEdit" type="button" onClick={() => this.setState({edit:true})}> Update image </SaveButton>
                </Flex>
            </div>
        )

    }


    renderEdit(headline) {
        return (
            <div>
	            {headline ?
		            <Box w={[1]}>
			            <CardTitle>
				            New {headline}
			            </CardTitle>
		            </Box> :
		            null
				}
                <Box w={[1]} px={2}>
                    {
                        this.state.file ?
                            <div>
                                <Cropper
                                    ref='cropper'
                                    src={this.state.file.preview}
                                    scalable={false}
                                    zoomable={false}
                                    style={{
	                                    width: 780,
	                                    height: 780,
                                    }}
                                    x={0}
                                    y={0}
                                    crop={this._crop.bind(this)}
                                    aspectRatio={this.props.aspectRatio}
                                    guides={false}
                                />
                                <SaveButton onClick={this._onImageUpload.bind(this)} disabled={!this.state.file} style={{
                                    marginTop: '20px'
                                }}> Save image </SaveButton>
                            </div>
                            :
                            <Dropzone onDrop={this._onDrop} multiple={false}>
                                <DropzonePlaceholder>
                                    Drop file or click to select
                                </DropzonePlaceholder>
                            </Dropzone>

                    }
                </Box>
                {this.state.edit &&
                <Box w={[1]}>
                <Flex justify="flex-end">
                    <CancelImageUpdateButton name="toggleEdit" type="button" onClick={() => this.setState({...this.state, edit:false})}> Cancel image update</CancelImageUpdateButton>
                </Flex></Box>}
            </div>)
    }

    render() {
        const {headline} = this.props

        const hasValue = (this.props.input && this.props.input.value && this.props.input.value.length > 0)

        let picture = hasValue ? this.props.input.value : this.props.showUrl;

        if (this.state.picture) {
            picture = this.state.picture;
        }

        const showEdit = this.state.edit || (!hasValue)

        return (
                <Card>
                        <Box w={[1]}>
                        {showEdit ? this.renderEdit(headline) : this.renderShow(picture, headline) }
                        </Box>
                </Card>
        );
    }
}

export default ImageUploader

