/**
 * @flow
 */
 
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/index';
import CoveredProIcon from '../../../../components/Typografy/CoveredProIcon';

const BenefitIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  color: ${theme.icon.subHeaderIcon};
`;

const BenefitIcon = () => (
  <BenefitIconStyle>&#xe801;</BenefitIconStyle>
);

export default BenefitIcon;
