/**
 * @flow
 */

import type {TProfileData} from '../../../types/models/TAuth';
import type {TAuth0Profile} from '../../../types/api/TAuth';

const namespace = 'http://coveredpro.com/';

export const toProfile = (auth0Profile): TProfileData => {
    let profile = {
        //   accountId: auth0Profile.accountId,
        email: auth0Profile[namespace + 'user'].email,
        userId: auth0Profile[namespace + 'user'].user_id,
    };
    const userMetadata = auth0Profile[namespace + 'user_metadata'];
    if (userMetadata) {
        profile = {
            ...profile,
            firstName: userMetadata.firstName,
            lastName: userMetadata.lastName,
            phone: userMetadata.phone,
            homeAddress: userMetadata.homeAddress,
            postcode: userMetadata.postcode,
            postplace: userMetadata.postplace,
            country: userMetadata.country,
        };
    }
    const appMetadata = auth0Profile[namespace + 'app_metadata'];
    if (appMetadata) {
        profile = {
            ...profile,
            accountId: appMetadata.accountId,
            accounts: appMetadata.accounts,
            role:appMetadata.role
        };
    }

    return profile;
};


export const toAuth0Profile = (profile: TProfileData): TAuth0Profile => {
  return {
    accountId: profile.accountId,
    email: profile.email,
    user_id: profile.userId,
    user_metadata: {
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      phone: profile.phone || '',
      homeAddress: profile.homeAddress || '',
      postcode: profile.postcode || '',
      postplace: profile.postplace || '',
      country: profile.country || '',
    },
    app_metadata: {
      accountId: profile.accountId || '',
    },
  };
};
