/**
* @flow
*/
import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../components/Typografy/Wrapper';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import { mx } from '../../utils';

import LongLine from './components/LongLine';
import List from './components/List/List';
import H2 from '../../components/Typografy/H2';
import PromoImageWrapper from './components/PromoImageWrapper';
import Section from './components/Section';

import bgDigital from '../../assets/images/bg_digital.png';
import phoneBenefit from "../../assets/images/phone_benefit.png";

import phoneHome from "../../assets/images/phone_promo_home.png"
const Container = styled.div`
  background-color: rgb(25, 25, 25);
`;

const DigitalWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 4em;
  `;

const TopWrapper = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: center;
  gap: 4em;
  flex-wrap: wrap;
  `;

const DescriptionWrapper = styled.div`
  margin-top: 1em;
  ${mx('lg')} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 3em 0 3em 0;
    width: calc(100% - 600px);
    padding-top: 0;
    }
  `;

const BoldText = styled.div`
  color: #fff;
  font-weight: bold;
  `;

const DigitalText = styled(H2)`
  ${mx('lg')} {
    font-size: 1.5em;
    }
  `;

const ListItem = styled.li`
  margin-bottom: 10px;
  `;

const ListText = styled.span`
  color: #fff;
  `;

const TopLine = styled(LongLine)`
  ${mx('lg')} {
    margin-top: 5em;
    }
  `;

const BottomLine = styled(LongLine)`
  margin-bottom: 2em;
  ${mx('lg')} {
    margin-bottom: 5em;
    }
  `;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  `;

const PromoPhone = styled(PromoImageWrapper)`
  position: relative;
`;

class DigitalPlatformSection extends Component {
  render() {
    return (
      <Section>
        <Container>
          <DigitalWrapper>
            <TopWrapper>
              <DescriptionWrapper>
                <DigitalText>
                  <BoldText> &lt;&lt; State of the art &gt;&gt;  digital platform, </BoldText>
                  som ikke krever noen form for integrasjon med andre systemer
                </DigitalText>
                <List>
                  <ListItem>
                    <ListText>
                      Totalt fristilt fra andre IT-systemer og prosesser
                    </ListText>
                  </ListItem>
                  <ListItem>
                    <ListText>
                      Økt tilgjengelighet
                    </ListText>
                  </ListItem>
                  <ListItem>
                    <ListText>
                      Økt bruk
                    </ListText>
                  </ListItem>
                  <ListItem>
                    <ListText>
                      Nye muligheter for flere og bedre ansattrabatter
                    </ListText>
                  </ListItem>
                  <ListItem>
                    <ListText>
                      App mer din egen logo og design, dedikert til formålet
                    </ListText>
                  </ListItem>
                </List>
              </DescriptionWrapper>
              <img
                  src={phoneHome}
                  style={{
                    objectFit: "contain",
                    paddingLeft: 20,
                    height: 600,
                    width: "100%",
                    maxWidth: 500,
                  }}
                  alt="promo_phone_home"
              />
            </TopWrapper>
          </DigitalWrapper>
        </Container>
      </Section>
    );
  }
}

export default DigitalPlatformSection;
