/**
 * @flow
 */


import { connect } from 'react-redux';
import MessageDetails from './MessageDetails';
import {
	archiveMessage, onMessageArchivesLoadSuccess,
	onMessageDraftsLoadSuccess,
	publishMessageDraft, restoreArchivedMessage
} from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';

const MessageArchiveDetailsContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.archiveData  || [],
			isDraft: false,
			isArchive: true,
		};
	},
	(dispatch) => ({
		onMessageArchivesLoadSuccess: (data) => dispatch(onMessageArchivesLoadSuccess(data)),
		onMessageDraftsLoadSuccess: (data) => dispatch(onMessageDraftsLoadSuccess(data)),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId, true)),
		restoreArchivedMessage: (message) => dispatch(restoreArchivedMessage(message)),
		publishMessageDraft: (message, accountId) => dispatch(publishMessageDraft(message, accountId)),
	}),
)(MessageDetails);

export default MessageArchiveDetailsContainer;

