/**
 * @flow
 */

import React, { Component } from 'react';
import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'

class TermsPageNo extends Component<void, void, void> {

    render() {
        return (
            <div>
                <StickyMenu />
                <MessageSection
                    headlineText={'Brukervilkår'}
                    full={
                        <div className="text_holder">
                            <p>
                                Vennligst les følgende vilkår og betingelser nøye før du bruker en av CoveredPro’s apper ("appen"). Ved å bruke appen godtar du å overholde og være bundet av disse vilkårene og betingelsene. Hvis du ikke er enig i disse vilkårene og betingelsene, vennligst ikke bruk appen.
                            </p>

                            <h2>1. Aksept av vilkår</h2>
                            <p>
                                Ved å registrere deg og bruke appen, bekrefter du at du har lest, forstått og akseptert disse vilkårene og betingelsene. Disse vilkårene og betingelsene kan oppdateres eller endres av CoveredPro når som helst uten varsel. Din fortsatte bruk av appen innebærer at du godtar de reviderte vilkårene og betingelsene.
                            </p>

                            <h2>2. Bruker registrering</h2>
                            <p>2.1. For å få tilgang til fordelene og rabattkodene som er tilgjengelige gjennom appen, må du fullføre registreringsprosessen og oppgi nøyaktig, fullstendig og oppdatert informasjon.</p>
                            <p>2.2. Du er ansvarlig for å opprettholde konfidensialiteten til kontoinformasjonen din, inkludert e-posten din, og for all aktivitet som skjer på kontoen din.</p>

                            <h2>3. Berettigelse</h2>
                            <p>3.1. Appen er beregnet på ansatte/medlemmer av registrerte selskaper/organisasjoner som er kvalifisert til å få tilgang til fordelene og rabattkodene som tilbys av deres arbeidsgiver, medlemsorganisasjon, CoveredPro og/eller eksterne leverandører.</p>
                            <p>3.2. Brukere må bekrefte sin ansettelses-/medlemsstatus hos et registrert selskap/organisasjon gjennom Appens utpekte prosess.</p>

                            <h2>4. Fordeler og rabattkoder</h2>
                            <p>4.1. Ved å bruke appen vil registrerte brukere få tilgang til en liste over fordeler og rabattkoder gitt av deres arbeidsgiver/organisasjon. Disse fordelene og rabattkodene kan endres, og tilgjengeligheten deres bestemmes av den respektive arbeidsgiveren/organisasjonen.</p>
                            <p>4.2. Appen er ikke ansvarlig for nøyaktigheten, tilgjengeligheten eller kvaliteten på fordeler og rabattkoder som er tilgjengelige i appen. Brukere oppfordres til å kontakte sin arbeidsgiver, organisasjon eller leverandøren for eventuelle spørsmål eller avvik.</p>

                            <h2>5. Bruk av appen</h2>
                            <p>5.1. Du godtar å bruke appen i samsvar med alle gjeldende lover, regler og forskrifter.</p>
                            <p>5.2. Du samtykker i å ikke delta i noen aktiviteter som kan skade, forstyrre eller forstyrre appens funksjonalitet, sikkerhet eller integritet.</p>
                            <p>5.3. Appen kan samle inn og lagre anonymiserte brukerdata med det formål å tilby og forbedre tjenestene. Vennligst se vår personvernerklæring for mer informasjon.</p>

                            <h2>6. Oppsigelse av konto</h2>
                            <p>6.1. Appen forbeholder seg retten til å avslutte eller suspendere kontoen din etter eget skjønn, med eller uten varsel, hvis du bryter disse vilkårene og betingelsene.</p>
                            <p>6.2. Din konto vil også kunne slettes dersom du ikke lenger er ansatt eller medlem av en registrert bedrift/organisasjon som gir tilgang til appens fordeler og rabattkoder.</p>
                            <p>6.3. Du kan også avslutte kontoen din når som helst ved å kontakte vår kundestøtte.</p>

                            <h2>7. Ansvarsbegrensning</h2>
                            <p>7.1. Appen leveres «som den er» og «som tilgjengelig». Vi gir ingen garantier eller representasjoner angående nøyaktigheten, påliteligheten eller tilgjengeligheten til appen eller fordelene og rabattkodene som tilbys.</p>
                            <p>7.2. Appen skal ikke holdes ansvarlig for noen direkte eller indirekte skader, inkludert men ikke begrenset til tap av data, tap av fortjeneste eller andre tap som følge av din bruk av appen.</p>

                            <h2>8. Endringer i brukervilkår</h2>
                            <p>
                                Appen forbeholder seg retten til å endre disse vilkårene og betingelsene når som helst. Eventuelle endringer trer i kraft umiddelbart etter at de oppdaterte vilkårene og betingelsene er lagt ut på appen. Det er ditt ansvar å gjennomgå disse vilkårene og betingelsene med jevne mellomrom.
                            </p>

                            <h2>9. Kontaktinformasjon</h2>
                            <p>
                                Hvis du har spørsmål, kommentarer eller bekymringer angående brukervilkårene, kan du gjerne kontakte oss. Vi verdsetter ditt privatliv og er her for å hjelpe deg.
                            </p>
                            <p>
                                <strong>Coveredpro AS<br />Org.nr. 918 084 495</strong>
                            </p>
                            <p>
                                <a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a>
                            </p>
                        </div>
                    }
                />
                <Footer />
            </div>
        );
    }
}

export default TermsPageNo;