/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import ResponsiveImage from '../../../../../components/Typografy/ResponsiveImage';
import Wrapper from '../../../../../components/Typografy/Wrapper';
import SmallText from '../../../../../components/Typografy/SmallText';
import { mx } from '../../../../../utils';

const FooterWrapper = styled.div` 
    display: none;
    ${mx('lg')} {
  background-color: #262626;
  height: 4vh;
    position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  color: #c7c7c7;
  }
`;

const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ImageContainer = styled.div`
   flex: 1;
   display: none;
   ${mx('lg')} {
    display: flex;
   }
`;

const Footer = () => (
  <FooterWrapper>
    <Wrapper>
      <FooterFlex>
        <ImageContainer>
          <ResponsiveImage
            src="footer-logo.png"
            width={109}
            height={33}
            alt=""
            containerStyle={'flex: 1'}
          />
        </ImageContainer>
        <SmallText>
            © {new Date().getFullYear()} CoveredPro. All Rights Reserved
        </SmallText>
      </FooterFlex>
    </Wrapper>
  </FooterWrapper>
);


export default Footer;
