/**
 * @flow
 */

import React from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import moment from 'moment'
import styled from 'styled-components';
import {Link as RouterLink} from 'react-router-dom';

import XLSX from 'xlsx';
import FileSaver from 'file-saver';

import theme from '../../../theme/index';
import type {TStatsApiData} from '../../../../common/types/api/TStats';
import {SaveButton} from "../../../components/Buttons";

const StatsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 1em;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;


const StatsInfoContainer = styled.div`
  padding: 1.5em 2.5em 1.5em 2.5em;
`;

const StatsTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const StatsText = styled.div`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 0.2em;
`;
const DateText = styled.div`
  font-size: 1.2em;
  color: #dcdcdc;
  display: flex;
  justify-content:flex-end;
  padding: 0.4em 0.4em 0.4em 0.4em;

`;

type TProps = {
    stats: TStatsApiData;
    accountId: number;
};

export const FormattedDate = ({dateString}) => {
    if (!dateString) return null

    const dateObj = moment(dateString)

    return dateObj.fromNow()

}

const ListItem = ({list}) => {
    if (list) {
        let result = []
        //list.sort((a, b)=>{return moment(a.created_at) - moment(b.created_at)})
        list.forEach((l, i) => {
                if (l.user_metadata) result.push(<Box key={i+"1"}
                    w={[1 / 3]}>{l.user_metadata.firstName} {l.user_metadata.lastName}</Box>)

                result.push(<Box key={i+"2"} w={[1 / 3]}>{l.email}</Box>)
                result.push(<Box key={i+"3"} w={[1 / 4]}><FormattedDate dateString={l.created_at}/></Box>)
            }
        )
        return result
    }

    return null
}


const toCSV = (list) => {
    if (list) {


        const dataRows = list.map(row => [row.user_metadata.firstName, row.user_metadata.lastName, row.email, row.created_at])

        const mergedRows = [["First name", "Last name", "Email", "Created at"]].concat(dataRows)

        const worksheet = XLSX.utils.aoa_to_sheet(mergedRows);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Unverified");
        const wbout = XLSX.write(new_workbook, {type: 'binary', bookType: "xlsx"});


        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), "Unverified_Users.xlsx");


    }

}

export const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}



const StatsInnerList = ({list}) => {
    if(!list) return null

    return <StatsText>
        <Flex wrap={true} justify="space-between">
            <ListItem key={Object.keys(list)} list={list}/>
        </Flex>
    </StatsText>;
}

const StatsItem = (props: TProps) => {
    let {stat, accountId, list} = props;
    if (list) {
        list.sort((a, b) => {
            return moment(a.created_at) - moment(b.created_at)
        })
    }


    return (
        <StatsCard>
            <Flex wrap>
                <Box w={[1]}>
                    <StatsInfoContainer>
                        <StatsTitle>{stat.title}: {stat.data} </StatsTitle>
                        <StatsInnerList list={list} />
                        {list && <SaveButton onClick={() => toCSV(list)}>Download list</SaveButton>}
                    </StatsInfoContainer>
                </Box>
            </Flex>
        </StatsCard>
    );
};

export default StatsItem;
