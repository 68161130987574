
import React, {Component} from 'react'
import {Box, Flex} from "reflexbox";
import {Card, CardTitle} from "../../../../components/Elements";
import {SaveButton} from "../../../../components/Buttons";
import IconBuilderComposition from "./IconBuilderComposition";
import ImageUploaderModal from "../../../../components/form/ImageUploaderModal";
import {
    buildAccountIcon,
    readAccountIcon,
    readAccountLogo
} from "../../../../../common/config/firebase_config/endpoints";
import styled from "styled-components";
import connect from "react-redux/es/connect/connect";
import type {TAppState} from "../../../../../common/TAppState";
import {onLanguagesLoadSuccess} from "../../../../../common/languages/actions";
import {
    getAppCodeStatus,
    onAccountsLoadSuccess,
    updateAccount,
    updateAppCodes, iconTextUpdated
} from "../../../../../common/accounts/actions";
import AccountEdit from "../AccountEdit";

const ImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;



class IconBuilder extends Component<void, TProps, TState> {
    state: TState = {
        picture: null,
        fields: {}
    };

    onChange(picture){
        if(this.setState) {
            this.setState({...this.state, picture})
        }
    }
    onChangeSupers(fields){
        if(this.setState) {
            this.setState({...this.state, fields: fields})
        }
    }

    render() {

        let picture =  readAccountIcon(this.props.accountId);

        if (this.state.picture) {
            picture = this.state.picture;
        }

        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Icon
                    </CardTitle>
                </Box>
                <Box w={[1, 6 / 12]} px={2}>
                    <ImageBorder>
                        <img src={picture} alt="preview" style={{maxWidth: '100%'}}/>
                    </ImageBorder>
                </Box>
                <Flex justify="flex-end">
                    <ImageUploaderModal
                        {...this.props}
                        uploadUrl={buildAccountIcon(this.props.accountId)}
                                        showUrl={this.props.account ? readAccountIcon(this.props.accountId) : null}
                                        headline={"Icon Image"} minWidth={1024} minHeight={1024} aspectRatio={1}
                                        name="iconUrl"
                                        onChange={this.onChange.bind(this)}>
                        <SaveButton name="toggleEdit" type="button"> Upload image </SaveButton>
                    </ImageUploaderModal>
                    <IconBuilderComposition
                        {...this.props}
                        writeUrl={buildAccountIcon(this.props.accountId)}
                        showUrl={this.props.account ? readAccountLogo(this.props.accountId) : null}
                        headline={"Icon Image"} minWidth={1024} minHeight={1024} aspectRatio={1}
                        name="iconComposition"
                        onUpdate={this.onChange.bind(this)}
                    />
                </Flex>


            </Card>
        )


    }

}

/*
<Field uploadUrl={buildAccountIcon(this.props.accountId)}
               showUrl={this.props.account ? readAccountIcon(this.props.accountId) : null}
               headline={"Icon Image"} minWidth={512} minHeight={512} aspectRatio={1} component={ImageUploader}
               name="iconUrl"/>
 */


export default IconBuilder
