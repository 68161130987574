/**
 * @flow
 */

import type { TBenefitResponse } from '../../types/api/TBenefit';



export const onBenefitsLoadSuccess = (payload: TBenefitResponse, metadata, config) => ({
    type: 'LOAD_BENEFITS_SUCCESS',
    payload,
    metadata,
    config
});

export const onBenefitStatsLoadSuccess = (payload) => ({
    type: 'LOAD_BENEFIT_STATS_SUCCESS',
    payload
})


export const onBenefitsReset = () => ({
    type: 'RESET_BENEFITS_SUCCESS',
});

export const onVendorVisibilityLoadSuccess = (payload) => ({
    type: 'LOAD_VENDOR_VISIBILITY_SUCCESS',
    payload
});

export const onBenefitOrderLoadSuccess = (payload) => {
    console.log("benefitsLoad.js", payload);
    return {
        type: 'LOAD_BENEFIT_ORDER_SUCCESS',
        payload
    }
}