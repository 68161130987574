import React from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import Select from "../../../../components/Select";


const options = (accounts, self) => {
    if(!accounts) return []

    return Object.keys(accounts).filter((acccountId) => { return (acccountId !== self)}).map((acccountId) => ({
        label: accounts[acccountId].name,
        value: acccountId
    }));
}


const renderForm = ({fields, accounts, accountId, meta: {touched, error}}) => {
    return (
        <div>
            {fields.map((step, index) =>
                <div key={index}>
                    <h4>Account #{index + 1}</h4>
                    <Flex
                        justify="space-between">
                        <Box w={[1, 7/12]} >
                            <div>
                                <Field
                                    name={`${step}.accountId`}
                                    component={Select}
                                    options={options(accounts, accountId)}
                                    label="Name"/>
                            </div>
                        </Box>
                        <Box w={[1, 2/12]} mx={2}>
                            <RemoveField onClick={() => fields.remove(index)} />
                        </Box>
                    </Flex>
                </div>
            )}
            <Flex justify="flex-end">
                <SaveButton name="addAccount" type="button" onClick={(event) => {
                    if (event) event.preventDefault(); fields.push({type: {}});}}> Add account </SaveButton>
            </Flex>
        </div>
    );
}

export default renderForm