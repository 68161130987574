import {firebaseDb} from '../../config/firebase_config/firebase';
import {
    buildNewCabin, buildCabinDraft, buildCabin
} from '../../config/firebase_config/endpoints';

import history from '../../utils/history'
import {NotificationManager} from 'react-notifications';

function updateCabinRequest() {
  return {
    type: 'UPDATE_CABIN_REQUEST',
  };
}

function updateCabinSuccess(key, data) {
  let action = {
      type: 'UPDATE_CABIN_SUCCESS',
  }

  if(key && data)
    action.payload = {
        key, data
    }
    return action
}


function updateCabinMoveSuccess() {
    return {
        type: 'UPDATE_CABIN_MOVE_SUCCESS',
    }

}

function updateCabinError(json) {
  return {
    type: 'UPDATE_CABIN_ERROR',
  };
}


export const updateCabin = (formValues, cabin, publish=false) => async (dispatch, getState) => {
  try {
    dispatch(updateCabinRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    let cabinKey = ''

    if (cabin) {
        cabinKey = cabin.cabinId;
    }
    else {
      const newCabinEndpoint = buildNewCabin(accountId);
        cabinKey = firebaseDb.ref().child(newCabinEndpoint).push().key;
    }

    let storableData = JSON.parse(JSON.stringify(formValues));

    updates[buildCabin(accountId, cabinKey)] = storableData;

    updates[buildCabinDraft(accountId, cabinKey)] = null;


    console.log("update cabin called")
    await firebaseDb.ref().update(updates);

    dispatch(updateCabinSuccess(cabinKey, storableData));
    if (!cabin) {
      history.replace('/admin/cabins/' + cabinKey)
      if (publish) NotificationManager.success('Success!', 'Cabin published!', 3000);
      else NotificationManager.success('Success!', 'Cabin saved!', 3000);
    }
    else{
      if (publish) history.replace('/admin/cabins/' + cabinKey);
      if (publish) NotificationManager.success('Success!', 'Cabin published!', 3000);
      else NotificationManager.success('Success!', 'Cabin saved!', 3000);
    }
  } catch (err) {
    console.log("update cabin ERROR: " + JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateCabinError());
  }
};

export const updateDraftCabin = (formValues, cabin, disable=false) => async (dispatch, getState) => {
  try {
    dispatch(updateCabinRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    let cabinKey = ''

    if (cabin) {
        cabinKey = cabin.cabinId;
    }
    else {
      const newCabinEndpoint = buildNewCabin(accountId);
        cabinKey = firebaseDb.ref().child(newCabinEndpoint).push().key;
    }

    let storableData = JSON.parse(JSON.stringify(formValues));


    updates[buildCabinDraft(accountId, cabinKey)] = storableData;


    updates[buildCabin(accountId, cabinKey)] = null;


    console.log("update cabinDraft called")
    await firebaseDb.ref().update(updates);

    dispatch(updateCabinSuccess(cabinKey, storableData));
    if (!cabin) {
      history.replace('/admin/cabins-draft/' + cabinKey)
      if (disable) NotificationManager.success('Success!', 'Cabin disabled!', 3000);
      else NotificationManager.success('Success!', 'Cabin draft saved!', 3000);
    }
    else{
      if (disable) history.replace('/admin/cabins-draft/' + cabinKey);
      if (disable) NotificationManager.success('Success!', 'Cabin disabled!', 3000);
      else NotificationManager.success('Success!', 'Cabin draft saved!', 3000);
    }

  } catch (err) {
    console.log("update cabin draft ERROR: " + JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateCabinError());
  }
};


// export const copyCabin = (sourceAccountId, targetAccountId, sourceBenefit) => async (dispatch) => {
//   try {
//     dispatch(updateCabinRequest());
//
//     let updates = {};
//
//     //const newBenefitEndpoint = buildBenefit(targetAccountId, sourceBenefit.benefitId);
//     //const targetBenefitKey = firebaseDb.ref().child(newBenefitEndpoint).push().key;
//     const targetBenefitKey = sourceBenefit.benefitId
//     const storableData = sourceBenefit
//
//
//
//     let categoryId = "1";
//     if (storableData && storableData.category) {
//       categoryId = storableData.category
//     }
//
//
//     updates[buildBenefitDraft(targetAccountId, targetBenefitKey)] = storableData;
// /*
//     updates[buildBenefitMaster(targetAccountId, targetBenefitKey)] = storableData;
//
//     updates[buildBenefit(targetAccountId, targetBenefitKey)] = storableData;
//     updates[buildBenefitWithCategory(targetAccountId, targetBenefitKey, categoryId)] = storableData;
//
//     updates[buildBenefitLocations(targetAccountId, targetBenefitKey)] = storableData.locations || null;
//   */
//     updates[buildBenefitCopyMessage(sourceAccountId, targetAccountId, targetBenefitKey)] = new Date().toJSON()
//
//     console.log("update benefit called")
//     await firebaseDb.ref().update(updates);
//
//     dispatch(updateCabinMoveSuccess());
//     NotificationManager.success('Success!', 'Benefit copied!', 3000);
//
//   } catch (err) {
//     console.log("update benefit ERROR: " + JSON.stringify(err))
//     NotificationManager.error('Error!', JSON.stringify(err), 3000);
//
//     dispatch(updateCabinError());
//   }
// };

//
// export const archiveCabin = (accountId, benefit) => async (dispatch, getState) => {
//   try {
//     dispatch(updateCabinRequest());
//     const store = getState();
//     //const accountId = store.auth.profile.data.accountId;
//     const categoryId = benefit.category;
//     let benefitKey = benefit.benefitId;
//
//
//     let updates = {};
//
//     updates[buildBenefitArchive(accountId, benefitKey)] = benefit;
//
//     updates[buildBenefitWithCategory(accountId, benefitKey, benefit.category)] = null;
//     updates[buildBenefitMaster(accountId, benefitKey)] = null;
//     updates[buildBenefit(accountId, benefitKey)] = null;
//     updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = null;
//     updates[buildBenefitLocations(accountId, benefitKey)] = null
//     updates[buildBenefitDraft(accountId, benefitKey)] = null
//
//     console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))
//
//     await firebaseDb.ref().update(updates);
//
//     dispatch(updateCabinMoveSuccess());
//     NotificationManager.success('Success!', 'Benefit archived!', 3000);
//     history.replace('/admin/benefits');
//
//   } catch (err) {
//     NotificationManager.error('Error!', JSON.stringify(err), 3000);
//     dispatch(updateCabinError(err));
//   }
// };
//
// export const unArchiveCabin = (accountId, benefit) => async (dispatch, getState) => {
//   try {
//     dispatch(updateCabinRequest());
//     const store = getState();
//     //const accountId = store.auth.profile.data.accountId;
//     const categoryId = benefit.category;
//     let benefitKey = benefit.benefitId;
//
//
//     let updates = {};
//
//     updates[buildBenefitDraft(accountId, benefitKey)] = benefit;
// /*
//     updates[buildBenefitWithCategory(accountId, benefitKey, benefit.category)] = benefit;
//     updates[buildBenefitMaster(accountId, benefitKey)] = benefit;
//     updates[buildBenefit(accountId, benefitKey)] = benefit;
//     updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = benefit;*/
//     /*
//     if (benefit.locations) {
//       updates[buildBenefitLocations(accountId, benefitKey)] = benefit.locations
//     }*/
//
//     updates[buildBenefitArchive(accountId, benefitKey)] = null;
//
//     console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))
//
//     await firebaseDb.ref().update(updates);
//
//     dispatch(updateCabinMoveSuccess());
//     NotificationManager.success('Success!', 'Benefit unarchived!', 3000);
//     history.replace('/admin/benefits-archive');
//
//   } catch (err) {
//     NotificationManager.error('Error!', JSON.stringify(err), 3000);
//     dispatch(updateCabinError(err));
//   }
// };
//
//
// function copyFbRecord(oldRef, newRef) {
//   return Promise((resolve, reject) => {
//     oldRef.once('value').then(snap => {
//       return newRef.set(snap.val());
//     }).then(() => {
//       console.log('Done!');
//       resolve();
//     }).catch(err => {
//       console.log(err.message);
//       reject();
//     });
//   });
// }
//
// function moveFbRecord(oldRef, newRef) {
//   return Promise((resolve, reject) => {
//     oldRef.once('value').then(snap => {
//       return newRef.set(snap.val());
//     }).then(() => {
//       return oldRef.set(null);
//     }).then(() => {
//       console.log('Done!');
//       resolve();
//     }).catch(err => {
//       console.log(err.message);
//       reject();
//     });
//   })
// }
