/**
 * @flow
 */

import React from 'react';
import { buildUserAdmins } from '../../../../common/config/firebase_config/endpoints';
import type {
  TUserAdminApiData,
  TUserAdminResponse,
} from '../../../../common/types/api/TUserAdmin';
import UserAdminItem from './UserAdminItem';

import Spinner from '../../../components/Spinner'

type TProps = {
  accountId: number;
  userAdmin: Array<TUserAdminApiData>;
  onUserAdminsLoadSuccess: (data: TUserAdminResponse) => void;
};

class UserAdminList extends React.Component<void, TProps, void> {


  _getItems = () => {
    if(!(this.props.userAdmin && this.props.userAdmin.admins_users)) return null

    return this.props.userAdmin.admins_users.map((userAdmin, i) => {
      console.log("listing " + JSON.stringify(userAdmin))
      return <UserAdminItem key={i} user={userAdmin} accountId={this.props.accountId} removeFromUserAdmin={this.props.removeFromUserAdmin} />;
    });
  };
  render() {
    return (
        <div>
          { this.props.userAdmin ? this._getItems() : <Spinner/> }
        </div>
    );
  }
}
export default UserAdminList;
