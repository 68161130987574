/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import FormInput from './FormInput';
import FormInputToolTip from './FormInputToolTip';

type TMeta = {
    touched: boolean;
    error: string;
};

type TInput = {
    onChange: (text: string) => void;
    value: string;
};

type TProps = {
    input: TInput;
    type: string;
    placeholder: string;
    disabled?: boolean;
    meta: TMeta;
    userCard: boolean;
    label?: string;
    value: string;
};

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 1em;
`;

const CustomFieldInput = (props: TProps) => {
    const {
        input,
        disabled,
        type,
        placeholder,
        meta,
        userCard,
        label,
        inject,
        dateRange
    } = props;

    if (inject && input && input.onChange) {
        input.onChange(inject)
    }

    return (
        <div style={{flex: 1}}>
	        {label ? <FormLabel>{label}</FormLabel> : null}
            <FormInput
                {...input}
                type={type}
                withError={meta && meta.touched && meta.error}
                placeholder={placeholder}
                disabled={disabled}
                userCard={userCard}
                {...dateRange}
            />
            {meta && meta.touched && (meta.error &&
                <FormInputToolTip>
                    {meta.error}
                </FormInputToolTip>
            )}
        </div>
    );
};

export default CustomFieldInput;
