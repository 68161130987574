import styled from "styled-components";

const Box = styled.div`
  margin-bottom: 2em;
  box-shadow: 0px 0px 13px 1px rgba(0,0,0,0.3);
  background-color: whitesmoke;
  border-radius: 20px;
  max-width: 400px;
  margin-left:auto;
  margin-right:auto;
`;

export default Box;
