
/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';

import theme from '../theme';
import H3 from './Typografy/H3';
import { SecondaryButton, PrimaryButton } from './Buttons';

const Overlay = styled.div`
  position: fixed;
  background-color: ${theme.modal.overlayColor};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index:1000
`;

const Content = styled.div`
  position: absolute;
  border-radius: 0.5em;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  overflow: auto;
  padding: 20px;
  margin-right: -50%;
  outline: none;
  transform: translate(-50%, -50%);
  border: 1px solid ${theme.modal.contentBackground};
  background-color: ${theme.modal.contentBackground};
  max-width:900vw;
  max-height:90vh;
`;

const ModalHeader = styled(H3)`
  //text-transform: uppercase;
  padding-bottom: 0.5em;
  color: ${theme.modal.contentFont};
  border-bottom: 1px solid ${theme.modal.line};
`;

const ModalBody = styled.div`
  border-bottom: 1px solid ${theme.modal.line};
`;

const ModalFooter = styled.div`
  padding-top: 0.5em;
  display: flex;
  justify-content: flex-end;

`;

type TProps = {
  header: string;
  children?: Element<any>;
  onSaveClick: Function;
  onCancelClick: Function;
  onOverlayClick: Function;
  saveButtonText: string;
  cancelButtonText: string;
  isOpen: boolean;
}

class Modal extends Component<void, TProps, void> {

  render() {
    if (!this.props.isOpen) return null;

    return (
      <Overlay onClick={this.props.onOverlayClick}>
        <Content onClick={e => e.stopPropagation()}>
          <ModalHeader>{this.props.header}</ModalHeader>
          <ModalBody>{this.props.children}</ModalBody>
          <ModalFooter>
            <PrimaryButton onClick={(e) => {this.props.onSaveClick(e); e.stopPropagation(); }}>
              {this.props.saveButtonText}
            </PrimaryButton>
              {this.props.cancelButtonText && <SecondaryButton onClick={(e) => {this.props.onCancelClick(e); e.stopPropagation(); }}>
              {this.props.cancelButtonText}
            </SecondaryButton>}
          </ModalFooter>
        </Content>
      </Overlay>
    );
  }
}

export default Modal;
