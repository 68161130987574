/**
 * @flow
 */

import React, { Component } from 'react';
import { polyfill } from 'smoothscroll-polyfill';


import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'


class PrivacyPage extends Component<void, void, void> {


    render() {
        polyfill();
        return (
            <div>
                <StickyMenu />
                <MessageSection
                    headlineText={'Personvernerklæring'}
                    full={
                        <div className="text_holder">
                            {/* <h1>Personvernerklæring</h1> */}
                            <p><strong>Sist oppdatert:</strong> 2. October 2023</p>

                            <h2>1. Introduksjon</h2>
                            <p>
                                Ta deg tid til å lese denne personvernerklæringen nøye for å forstå hvordan vi håndterer informasjonen din. Ved å gå inn på eller bruke vår nettside, eller mobilapplikasjoner, bekrefter du at du har lest, forstått og godtatt praksisene beskrevet i denne personvernerklæringen.
                            </p>
                            <p>
                                Vi kan oppdatere denne personvernerklæringen fra tid til annen for å gjenspeile endringer i vår praksis eller av andre operasjonelle, juridiske eller regulatoriske årsaker. Når vi gjør endringer, vil vi revidere "Sist oppdatert" øverst i denne policyen og varsle deg i samsvar med gjeldende lover. Din fortsatte bruk av tjenestene våre etter eventuelle endringer i denne personvernerklæringen betyr at du godtar disse endringene.
                            </p>
                            <p>
                                Hvis du har spørsmål eller bekymringer angående denne personvernerklæringen eller vår datapraksis, ikke nøl med å kontakte oss ved å bruke informasjonen gitt i "Kontakt oss"-delen på slutten av dette dokumentet.
                            </p>

                            <h2>2. Data vi samler inn</h2>
                            <p>
                                Når du bruker vår mobilapplikasjon, ønsker vi å forsikre deg om at vi ikke samler inn eller lagrer noen personlig informasjon. Alle data relatert til din bruk av mobilapplikasjonen lagres lokalt på din mobile enhet. Vi har ikke tilgang til eller lagrer noen data som kan identifisere deg personlig mens du bruker mobilapplikasjonen.
                            </p>
                            <p>
                                For brukerautentisering, samler vi midlertidig inn e-postadressen din. Denne e-postadressen brukes utelukkende for autentiseringsprosessen og lagres ikke permanent i systemet vårt. Når du har logget på, blir e-postadressen din slettet fra systemet vårt, noe som sikrer at personvernet ditt opprettholdes.
                            </p>
                            <p>
                                Når du bruker nettstedet vårt, samler vi inn og lagrer e-postadressen din. Denne informasjonen er nødvendig for å gi deg tilgang til visse funksjoner og funksjoner på nettstedet, for eksempel kontoadministrasjon og kommunikasjon.
                            </p>
                            <p>
                                I tillegg til dette samler vi inn anonymiserte applikasjonsbruksdata for analytiske formål. Disse dataene er anonymisert og samlet for å forstå hvordan nettstedet vårt og applikasjonen vår brukes og for å forbedre ytelsen. Denne informasjonen er ikke personlig identifiserbar og brukes utelukkende for å forbedre brukeropplevelsen.
                            </p>

                            <h2>3. Hvordan vi bruker dataen</h2>
                            <p>
                                Vi bruker informasjonen din utelukkende for å tilby og forbedre tjenestene våre. Dette inkluderer å administrere kontoen din, administrere fordeler og sende relevante varsler. Vi behandler data basert på juridisk grunnlag som kontraktsmessig nødvendighet, legitime interesser og samtykke når det kreves av loven. Dataene dine oppbevares så lenge det er nødvendig for de angitte formålene. Ditt personvern er vår prioritet, og vi deler ikke informasjonen din med tredjeparter.
                            </p>

                            <h2>4. Informasjonskapsler og sporingsteknologier</h2>
                            <h3>Informasjonskapsler</h3>
                            <p>
                                Informasjonskapsler er små tekstfiler som er lagret på enheten din som hjelper oss å forstå hvordan du samhandler med tjenestene våre. Vi bruker informasjonskapsler til ulike formål, inkludert:
                            </p>
                            <ul>
                                <li>Autentisering: Informasjonskapsler er avgjørende for brukerautentisering, og sikrer din sikkerhet når du logger på.</li>
                                <li>Preferanser: Vi bruker informasjonskapsler for å huske innstillingene og preferansene dine for å gi en mer personlig opplevelse.</li>
                            </ul>

                            <h3>Sporingsteknologier</h3>
                            <p>
                                Både i mobilapplikasjonen vår og på nettsiden vår bruker vi Google Analytics, en nettanalysetjeneste levert av Google, Inc. Google Analytics bruker informasjonskapsler og andre sporingsteknologier for å samle inn og analysere brukeratferd og interaksjoner. Dette hjelper oss å samle innsikt i hvordan tjenestene våre brukes, spore trender og gjøre datadrevne forbedringer.
                            </p>
                            <p>
                                Google Analytics kan samle inn ulike typer data, inkludert:
                            </p>
                            <ul>
                                <li>Bruksdata: Informasjon om dine interaksjoner med tjenestene våre, for eksempel sider vist, tid brukt på nettstedet og funksjoner som brukes.</li>
                                <li>Enhets- og nettleserinformasjon: Detaljer om enheten og nettleseren din, inkludert IP-adresse, operativsystem og skjermoppløsning.</li>
                                <li>Geolokaliseringsdata: Generell plasseringsinformasjon basert på din IP-adresse.</li>
                            </ul>
                            <p>
                                Vær oppmerksom på at informasjonen som samles inn av Google Analytics er aggregert og anonymisert, og den identifiserer deg ikke personlig. Vi bruker disse dataene utelukkende med det formål å forbedre tjenestene våre og gi deg en bedre opplevelse.
                            </p>
                            <p>
                                Hvis du foretrekker ikke å ha data samlet inn av Google Analytics, kan du melde deg av ved å installere Google Analytics Opt-out Browser Add-on som er tilgjengelig på Google Analytics-nettstedet.
                            </p>
                            <p>
                                Ved å bruke tjenestene våre samtykker du til bruken av informasjonskapsler og sporingsteknologier som beskrevet i denne delen.
                            </p>

                            <h2>5. Dine rettigheter</h2>
                            <p>
                                Som bruker i EU-regionen har du spesifikke rettigheter angående dine personopplysninger i henhold til General Data Protection Regulation (GDPR). Vi er forpliktet til å respektere og opprettholde disse rettighetene:
                            </p>
                            <ul>
                                <li><strong>Rett til tilgang:</strong> Du har rett til å be om innsyn i personopplysningene vi har om deg.</li>
                                <li><strong>Rett til retting:</strong> Du kan be om retting av unøyaktige eller ufullstendige personopplysninger vi har om deg.</li>
                                <li><strong>Rett til sletting (rett til å bli glemt):</strong> Under visse omstendigheter har du rett til å be om sletting av dine personopplysninger. Denne retten er ikke absolutt og kan avhenge av juridiske krav.</li>
                                <li><strong>Rett til å begrense behandling:</strong> Du kan be om begrensning av behandlingen av dine personopplysninger under visse betingelser, for eksempel mens vi undersøker nøyaktigheten av dataene dine.</li>
                                <li><strong>Rett til dataportabilitet:</strong> Du har rett til å motta dine personopplysninger i et strukturert, vanlig brukt og maskinlesbart format og til å be om overføring av dine data til en annen behandlingsansvarlig, der det er teknisk mulig.</li>
                                <li><strong>Rett til å protestere:</strong> Du kan protestere mot behandlingen av dine personopplysninger av grunner knyttet til din spesielle situasjon med mindre vi har legitime grunner for behandlingen som overstyrer dine interesser, rettigheter og friheter.</li>
                                <li><strong>Rett til å trekke tilbake samtykke:</strong> Der vi stoler på ditt samtykke som det juridiske grunnlaget for å behandle dataene dine, har du rett til å trekke tilbake samtykket når som helst.</li>
                                <li><strong>Automatisert beslutningstaking:</strong> Vi engasjerer oss ikke i automatiserte beslutningsprosesser som påvirker deg betydelig uten ditt samtykke.</li>
                            </ul>
                            <p>
                                I mobilapplikasjonen lagres alle personlige data lokalt. Hvis du ønsker å slette disse dataene, kan dette oppnås ved å gå til "Profil"-siden i mobilapplikasjonen og klikke på knappen for å slette dataene dine.
                            </p>
                            <p>
                                Hvis du ønsker å utøve noen av disse rettighetene eller har spørsmål angående dine rettigheter i henhold til GDPR, vennligst kontakt oss ved å bruke informasjonen gitt i "Kontakt oss"-delen nedenfor. Vi vil svare på forespørselen din innen tidsrammen som er foreskrevet av gjeldende databeskyttelseslover.
                            </p>

                            <h2>6. Sikkerhet</h2>
                            <p>
                                Vi prioriterer sikkerheten til dine data gjennom omfattende tiltak. Alle dataoverføringer mellom enheten din og serverne våre er kryptert med SSL-teknologi, noe som sikrer konfidensialiteten til informasjonen din. Vi håndhever strenge tilgangskontroller og opprettholder sikre sikkerhetskopier av dataene dine. Selv om vi er dedikert til å beskytte dataene dine, oppfordrer vi også din aktive deltakelse til å beskytte informasjonen din, og understreker viktigheten av å sikre passord og ansvarlig nettadferd. Du kan være trygg på at vi kontinuerlig forbedrer vår sikkerhetspraksis for å gi best mulig beskyttelse av dataene dine.
                            </p>
                            <p>
                                Din tillit til vår forpliktelse til datasikkerhet er avgjørende. Hvis du noen gang mistenker uautorisert tilgang eller har sikkerhetsproblemer, vennligst ta kontakt med oss umiddelbart ved å bruke kontaktinformasjonen som er oppgitt i "Kontakt oss"-delen.
                            </p>

                            <h2>7. Tredjeparts lenker</h2>
                            <p>
                                Vår tjeneste kan inkludere lenker til tredjeparts nettsteder eller tjenester for din bekvemmelighet og referanse. Vi ønsker imidlertid å gjøre det klart at vi ikke støtter, kontrollerer eller har ansvar for innholdet, personvernpraksisen eller sikkerheten til disse tredjepartsnettstedene eller -tjenestene.
                            </p>
                            <p>
                                Når du klikker på en tredjepartslenke og forlater tjenesten vår, bør du være klar over at personvernreglene våre ikke lenger gjelder. Dine interaksjoner med disse tredjeparts nettstedene eller tjenestene er underlagt deres respektive personvernregler og bruksvilkår. Vi oppfordrer deg til å gjennomgå personvernreglene til tredjeparts nettsteder eller tjenester du besøker for å forstå hvordan de samler inn, bruker og beskytter informasjonen din.
                            </p>
                            <p>
                                Selv om vi streber etter å gi lenker til anerkjente og pålitelige kilder, kan vi ikke holdes ansvarlige for handlinger, praksis eller innhold på tredjeparts nettsteder eller tjenester. Din bruk av slike lenker er på egen risiko.
                            </p>

                            <h2>8. Endringer i denne personvernerklæringen</h2>
                            <p>
                                Vi kan oppdatere denne personvernerklæringen med jevne mellomrom for å gjenspeile endringer i vår datapraksis, juridiske krav eller av driftsmessige årsaker. Når vi gjør endringer i denne policyen, vil vi revidere "Sist oppdatert" øverst i dette dokumentet for å indikere datoen for den siste revisjonen. Vi oppfordrer deg til å lese gjennom denne personvernerklæringen regelmessig for å holde deg informert om hvordan vi håndterer din personlige informasjon.
                            </p>
                            <p>
                                Hvis vi gjør vesentlige endringer som påvirker dine rettigheter eller måten vi håndterer dataene dine på, vil vi varsle om slike endringer gjennom fremtredende varsler på vår nettside, mobilapplikasjon eller på andre måter som kreves av gjeldende lover og forskrifter. Vi kan også sende deg en e-post eller annen kommunikasjon for å informere deg om viktige oppdateringer.
                            </p>
                            <p>
                                Ved å fortsette å bruke tjenestene våre etter eventuelle endringer i denne personvernerklæringen, erkjenner og samtykker du til den oppdatede praksisen beskrevet i den reviderte policyen. Hvis du ikke godtar endringene, kan du velge å slutte å bruke tjenestene våre eller utøve rettighetene dine som beskrevet i denne personvernerklæringen.
                            </p>

                            <h2>9. Kontakt oss</h2>
                            <p>
                                Hvis du har spørsmål, kommentarer eller bekymringer angående denne personvernerklæringen eller datapraksisen vår, kan du gjerne kontakte oss. Vi verdsetter ditt privatliv og er her for å hjelpe deg.
                            </p>
                            <p>
                                <strong>Coveredpro AS<br />Org.nr. 918 084 495</strong>
                            </p>
                            <p>
                                <a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a>
                            </p>
                        </div>
                    }
                />
                <Footer />
            </div>);
    }
}

export default PrivacyPage;
