/**
* @flow
*/
import React, { Component } from 'react';
import styled from 'styled-components';

import ContactContainer from './Contact/ContactContainer';
import Wrapper from '../../components/Typografy/Wrapper';

import Title from '../components/Typography/Title';
import ShortLine from './components/ShortLine';
import DescriptionWrapper from './components/DescriptionWrapper';
import Section from './components/Section';

import { mx } from '../../utils';

import bgShape from '../../assets/images/bg_shape.svg';

const Container = styled.div`
  min-height: 20em;
  padding: 0 0 2em;
  display: flex;
  align-items: center;
  background-color: rgb(243, 243, 243);
`;

class ContactSection extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Wrapper>
            <DescriptionWrapper>
              <Title>
                Ønsker du å vite mer?
              </Title>
            </DescriptionWrapper>
            <ShortLine />
            <ContactContainer />
          </Wrapper>
        </Container>
      </Section>
    );
  }
}

export default ContactSection;
