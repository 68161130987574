
import React from 'react'
import CanvasBuilder from '../../../../components/form/CanvasBuilder'

import {imageAccountSplashTemplate} from '../../../../../common/config/images/endpoints'
import {
    readAccountSplash,
    buildAccountSplash,
    readAccountLogo
} from '../../../../../common/config/firebase_config/endpoints'


const initialValues = {
    splash: {
            x:200,
            y:0,
            scale:90
        }
    }



const SplashBuilder = (props) => {

    return(

        <CanvasBuilder {...props}
                       field="splash"
                       baseUrl={imageAccountSplashTemplate()}
                       overlayUrl={readAccountLogo(props.accountId)}
                       formValues={props.accountFormValues}
                       label={"Splash"}
                       showUrl={readAccountSplash(props.accountId)}
                       writeUrl={buildAccountSplash(props.accountId)}
                       newInitialValues={initialValues}
                       baseScale={0.6} />
    )

}

export default SplashBuilder

/*
  height={1884}
                       width={959}
 */