/**
 * @flow
 */

/* eslint-disable import/prefer-default-export */

export const buildBenefits = (accountId: number) => `benefits-account/${accountId}/public/all`;
export const buildNewBenefit = (accountId: number) => `benefits-account/${accountId}/public/all`;
export const buildBenefitMaster = (accountId: number, benefitId:string) => `benefits-account/${accountId}/master/${benefitId}`;
export const buildBenefitArchive = (accountId: number, benefitId:string) => `benefits-account/${accountId}/archive/${benefitId}`;
export const buildBenefitsArchive = (accountId: number) => `benefits-account/${accountId}/archive`;
export const buildBenefitDraft = (accountId: number, benefitId:string) => `benefits-account/${accountId}/draft/${benefitId}`;
export const buildBenefitsDraft = (accountId: number) => `benefits-account/${accountId}/draft`;
export const buildBenefit = (accountId: number, benefitId:string) => `benefits-account/${accountId}/public/all/${benefitId}`;
export const buildBenefitWithCategory = (accountId: number, benefitId:string, categoryId:string) => `benefits-account/${accountId}/public/${categoryId}/${benefitId}`;
export const buildCard = (accountId: number) => `accounts-card/${accountId}`;
export const buildLocations = (accountId: number) => `locations-account/${accountId}`;
export const buildBenefitLocations = (accountId: number, benefitId:string) => `locations-account/${accountId}/${benefitId}`;
export const buildBenefitImage = (accountId: number, benefitId:string, fileName:string) => `benefit-images/${accountId}/${benefitId}/${fileName || 'main.png'}`;
export const buildCategory = (accountId: number, categoryId:string) => `accounts-config/${accountId}/categories/${categoryId}`;
export const buildCategoryArchive = (accountId: number, categoryId:string) => `archives/accounts-config/${accountId}/categories/${categoryId}`;
export const buildBenefitStats = (accountId: number) => `stats/${accountId}/benefit`;
export const buildHiddenVendorBenefits = (accountId) => `accounts/${accountId}/vendorBenefitsDisabled`;
export const buildHiddenVendorBenefit = (accountId, benefitId) => `accounts/${accountId}/vendorBenefitsDisabled/${benefitId}`;

export const buildBenefitOrders = (accountId) => `accounts/${accountId}/benefitOrder`;
export const buildBenefitOrder = (accountId, benefitId) => `accounts/${accountId}/benefitOrder/${benefitId}`;

export const buildCategories = (accountId) => `accounts-config/${accountId}/categories`;
export const buildCategoriesByAccount = (accountId) => `accounts/${accountId}/categories`;
export const buildCategoryImage = (accountId: number, categoryId, fileName:string) => `category-images/${accountId}/${categoryId}/${fileName}`;
export const buildTokenWriter = (accountId: number, token:string) => `/users/${accountId}/fcmTokens/${token}`;
export const buildTokens = (accountId: number) => `/users/${accountId}/fcmTokens`;
export const buildMessages = (accountId: number) => `messages/${accountId}`;

export const buildMessageDrafts = (accountId: number) => `message-drafts/${accountId}`;

export const buildMessage = (accountId: number, messageId: string) => `messages/${accountId}/${messageId}`;

export const buildMessageDraft = (accountId: number, messageId: string) => `message-drafts/${accountId}/${messageId}`;
export const buildMessageArchive = (accountId: number, messageId: string) => `archives/messages/${accountId}/${messageId}`;

export const buildMessageArchives = (accountId: number) => `archives/messages/${accountId}`;
export const buildNewMessage = (accountId: number) => `messages/${accountId}`;

export const buildNewMessageDraft = (accountId: number) => `message-drafts/${accountId}`;

export const buildWhitelist = (accountId: number, whitelistId: string) => `whitelist/${accountId}/${whitelistId}`;
export const buildWhitelistIndex = (whitelistId:string) => `control/whitelist/${whitelistId}`;
export const buildNewWhitelist = (accountId: number) => `whitelist/${accountId}`;
export const buildWhitelists = (accountId: number) => `whitelist/${accountId}`;


export const buildAccounts = () => 'accounts'
export const buildNewAccount = (accountId: number) => `accounts/${accountId}`;
export const buildAccount = (accountId: number) => `accounts/${accountId}`;
export const buildAccountTheme = (accountId: number) => `accounts-theme/${accountId}`;
export const buildAccountThemeExperimental = (accountId: number) => `accounts-theme-experimental/${accountId}`;
export const buildAccountCustomizeVendorBenefitCategory = (accountId: number, benefitId) => `accounts/${accountId}/vendorBenefitCustomCategories/${benefitId}`;
export const buildAccountArchive = (accountId: number) => `archives/accounts/${accountId}`;
export const buildAccountLogo = (accountId: number, fileName:string) => buildAccountImage(accountId, 'logo');
export const buildAccountSplash = (accountId: number, fileName:string) => buildAccountImage(accountId, 'splash');
export const buildAccountFeatureGraphic = (accountId: number, fileName:string) => buildAccountImage(accountId, 'featureGraphic');
export const buildAccountIcon = (accountId: number, fileName:string) => buildAccountImage(accountId, 'icon');
export const buildAccountMembershipTemplateFile = (accountId, type) => `reports/templates/accounts/${accountId}/template.${type? type: 'odt'}`;
export const buildAccountMembershipTemplate = (accountId) => `accounts/${accountId}/membershipVerificationTemplate`;
export const readAccountMembershipTemplateFile = (accountId, account)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${account.membershipVerificationTemplate ? account.membershipVerificationTemplate :  (buildAccountMembershipTemplateFile(accountId)).replace(/\//g,'%2F')}?alt=media&token=${mediaToken}`;


export const readAccountLogo = (accountId: number, fileName:string) => readAccountImage(accountId, 'logo');
export const readAccountSplash = (accountId: number, fileName:string) => readAccountImage(accountId, 'splash');
export const readAccountFeatureGraphic = (accountId: number, fileName:string) => readAccountImage(accountId, 'featureGraphic');
export const readAccountIcon = (accountId: number, fileName:string) => readAccountImage(accountId, 'icon');


export const buildAccountImage = (accountId: number, type: string, fileName:string) => `account-images/${accountId}/${type}/${fileName || 'main.png'}`;

let bucket = process.env.REACT_APP_ENV === 'development' ? "coveredpro-dev" : "coveredpro";
let mediaToken = process.env.REACT_APP_ENV === 'development' ? "b017dd9f-8d1d-4f2e-85d4-29437e0e73f0" : "5c453475-fefa-454e-a588-e306499bd83e";

export const readAccountImage = (accountId: string, type:string)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${(buildAccountImage(accountId, type)).replace(/\//g,'%2F')}?alt=media&token=${mediaToken}`;
//export const readAccountImage = (accountId: string, type:string)  => `https://firebasestorage.googleapis.com/v0/b/coveredpro/o/${(buildAccountImage(accountId, type)).replace(/\//g,'%2F')}?alt=media&token=5c453475-fefa-454e-a588-e306499bd83e`;

export const buildCategoryImageUpload = (accountId: string, index:number) => `account-categories/${accountId}/c_${index}.png`;
export const readCategoryImage = (accountId: string, index:number)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${(buildCategoryImageUpload(accountId, index)).replace(/\//g,'%2F')}?alt=media&token=${mediaToken}`;
// export const readCategoryImage = (accountId: string, index:number)  => `https://firebasestorage.googleapis.com/v0/b/coveredpro/o/${(buildCategoryImageUpload(accountId, index)).replace(/\//g,'%2F')}?alt=media&token=5c453475-fefa-454e-a588-e306499bd83e`;

export const buildMessageImage = (accountId: string, fileName: string) => `message-images/${accountId}/${fileName}`;

export const readMessageImage = (accountId: string, fileName: string) => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${(buildMessageImage(accountId, fileName)).replace(/\//g, '%2F')}?alt=media&token=${mediaToken}`

export const buildDomains = () => `control/account-domains/domains`;
export const buildDomain = (domain) => `control/account-domains/domains/${domain}`;
export const buildDomainAccount = (domain, account) => `control/account-domains/domains/${domain}/accounts/${account}`;


export const buildDivisions = (accountId: number) => `accounts-structure/divisions/${accountId}`;
export const buildDivision = (accountId: number, division:string) => `accounts-structure/divisions/${accountId}/${division}`;

export const buildBenefitCopyMessage = (fromAccountId, toAccountId, benefitId) => `migrations/${fromAccountId}/${toAccountId}/${benefitId}`;

export const buildGoogleServices = `config/services/google-services`;
export const buildLanguages = `config/locale/languages`;

export const buildAllAppcodes = () => `/config/appcodes`;
export const buildAppcodePointer = (accountId: number) => `config/appcodepointers/${accountId}`;
export const buildAllAppcodePointers = `config/appcodepointers`;
export const buildAppcodes = (accountId: number) => `config/appcodes/${accountId}`;
export const buildAppcodesUser = (email, accountId) => `config/appcodes/${accountId}/users/${email}`;
export const buildAppcodesFree = (accountId) => `config/appcodes/${accountId}/free`;
export const buildAppcodesUsed = (accountId) => `config/appcodes/${accountId}/used`;
export const buildAndroidAppCode = (accountId) => `config/appcodes/${accountId}/android`;
export const buildAppleNotEnterpriseCode = (accountId) => `config/appcodes/${accountId}/apple`;


export const buildContactRequest = (emailID: string) => `contact_requests/list/${emailID}`;

export const buildFaqContent = () => `config/faq`;
export const buildFaqContentGeneral = () => `config/generalFaq`;
export const buildEditableHelp = () => `config/help`;

export const buildUserDataIds = () => `user-data/ids`;
export const buildUserDataFull = () => `user-data/full`;
export const buildUserData = (userId) => `user-data/full/${userId}`;
export const buildUserAccountPresence = (userId, account) => `user-data/presence/${account}/${userId}`;
export const buildAccountPresence = (account) => `user-data/presence/${account}`;
export const buildAllPresence = () => `user-data/presence`;

export const buildOneTimeCodes = (userId) => `secured/access/one-time-code${userId ? `/${userId}` : ""}`;

export const buildBenefitReports = (accountId: number, userId) => `compliance/${accountId}/${userId}/all`;
export const buildBenefitReport = (accountId: number, userId, reportId) => `compliance/${accountId}/${userId}/all/${reportId}`;
export const buildNewBenefitReport = (accountId: number, userId) => `compliance/${accountId}/${userId}/all`;
export const buildAllBenefitReports = (accountId: number) => `compliance/${accountId}`;

export const buildNewCabin = (accountId: number) => `accounts-cabins/${accountId}/published`;
export const buildCabin = (accountId: number, cabinId) => `accounts-cabins/${accountId}/published/${cabinId}`;
export const buildCabins = (accountId: number) => `accounts-cabins/${accountId}/published`;
export const buildCabinDraft = (accountId: number, cabinId) => `accounts-cabins/${accountId}/draft/${cabinId}`;
export const buildCabinsDraft = (accountId: number) => `accounts-cabins/${accountId}/draft`;
export const buildCabinArchive = (accountId: number, cabinId) => `accounts-cabins/${accountId}/archive/${cabinId}`;
export const buildCabinsArchive = (accountId: number) => `accounts-cabins/${accountId}/archive`;

export const buildCabinImage = (accountId: number, cabinId:string) => `cabin-images/${accountId}/${cabinId}/`;

export const buildBookings = (accountId: number) => `accounts-cabins/${accountId}/bookings`;
export const buildCabinBooking = (accountId: number, cabinId, bookingId) => `accounts-cabins/${accountId}/bookings/${cabinId}/${bookingId}`;
export const buildCabinBookings = (accountId: number, cabinId) => `accounts-cabins/${accountId}/bookings/${cabinId}`;
export const buildNewCabinBooking = (accountId: number, cabinId) => `accounts-cabins/${accountId}/bookings/${cabinId}`;
export const buildCabinConfig = () => `config/cabins`;
export const buildUserBookings = (accountId: number, userEmail) => `accounts-cabins/${accountId}/userBookings/${userEmail}`;
export const buildUserBooking = (accountId: number, userEmail, bookingId) => `accounts-cabins/${accountId}/userBookings/${userEmail}/${bookingId}`;

export const buildAccountDeployConfigs = `config/deploy-management/config`;
export const buildAccountDeployJob = (state, action, platform, accountId) => `config/deploy-management/queue/${state}/${action}/${platform}/${accountId}`;
export const buildAccountsDeployStatus = `config/deploy-management/release`;

