/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';
import {Box, Flex,} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import WhitelistSaver from './components/WhitelistSaver';
import type {
  TWhitelistApiData,
  TWhitelistResponse,
} from '../../../../common/types/api/TWhitelist';

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;


type TProps = {
  accountId: string;
  initialValues: TWhitelistApiData;
  hasWhitelist: boolean;
}

type TState = {}

const WhitelistsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const WhitelistInfoContainer = styled.div`
  padding: 1.5em;
  padding-bottom: 0;
`;

const WhitelistTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;


class WhitelistForm extends Component<void, TProps, TState> {

  render() {

    return (
      <WhitelistsCard>
        <Flex wrap>
          <Box w={[1]}>
            <WhitelistInfoContainer>
              <form autoComplete="off">
	              <div
	                style={{
						width: "100%",
						display: "flex",
		                flexDirection: "row",
		                gap: "1em",
	                }}
	              >
	                  <Field
	                    name="email"
	                    component={CustomFieldInput}
	                    type="text"
	                    placeholder="Add a new email address..."
	                    userCard
	                  />
			              
		              <WhitelistSaver {...this.props}/>
	              </div>
              </form>
            </WhitelistInfoContainer>
          </Box>
        </Flex>
      </WhitelistsCard>
    );
  }
}

export default  connect(
  null, {change})
(reduxForm({
  form: 'WhitelistForm',
  field: ['email'],
})(WhitelistForm));
