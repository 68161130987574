/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import FormInputToolTip from './FormInputToolTip';

type TMeta = {
  touched: boolean;
  error: string;
};

type TInput = {
  onChange: (text: string) => void;
  value: string;
};

type TProps = {
  input: TInput;
  type: string;
  placeholder: string;
  disabled?: boolean;
  meta: TMeta;
  userCard: boolean;
  label?: string;
  value: string;
};

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 1em;
`;

const FormInput = styled.input`
  margin-bottom: 1.25em;
  ${props => props.userCard && 'line-height: 26px'};
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;

const CustomFieldInputCheckbox = (props: TProps) => {
  const {
    input,
    disabled,
    type,
    placeholder,
    meta,
    userCard,
    label,
    inject,

  } = props;

  if (inject && input && input.onChange) {
    input.onChange(inject)
  }

  return (
    <div>
      <FormInput
        {...input}
        type={type}
        withError={meta && meta.touched && meta.error}
        placeholder={placeholder}
        disabled={disabled}
        userCard={userCard}
      />
      <FormLabel>{label}</FormLabel>
      {meta && meta.touched && (meta.error &&
        <FormInputToolTip>
          {meta.error}
        </FormInputToolTip>
      )}
    </div>
  );
};

export default CustomFieldInputCheckbox;
