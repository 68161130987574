/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import SubHeader from './SubHeader';
import theme from '../../../theme/index';
import type {
  TBenefitApiData,
  TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import CabinList from './CabinList';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import Spinner from "../../../components/Spinner";
import {getAccounts, getUserBookings} from '../components/Retrievers'
import {Box, Flex} from "reflexbox";
import {getAllBookings} from "../components/Retrievers";
import CabinBookingList from "./CabinBookingList";

const BenefitsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;

const BenefitBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png')});
   margin-bottom: 2em;
`;


type TState = {
  selectedId: string;
};

type TProps = {
  accountId: number;
  benefits: TBenefitApiData;
  onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
};

class CabinPage extends React.Component<void, TProps, TState> {

  componentDidMount() {
    if (this.props.isDraft || this.props.isArchive) {
      this.props.onCabinsReset();
    }
  }

  state: TState = {
    selectedId: 'all',
  };


  render(){
      return( <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
          {this.props.accountsLoaded ? this.innerRender() : <Spinner/>}
      </FirebaseContainer>)
  }

  renderLists = () => {
      const {accountId, accounts, onUserBookingLoadSuccess, userBookingsLoaded, userEmail} = this.props;
      return (
          <CabinList
              accountId={accountId}
              accounts={accounts}
              cabins={this.props.cabins}
              onCabinsLoadSuccess={this.props.onCabinsLoadSuccess}
              isLoaded={this.props.isLoaded}
              isArchive={this.props.isArchive}
              isDraft={this.props.isDraft}
          />)
  }
  //     ) : (
  //         <Flex>
  //             <Box w={[1,1/2]} p={1} flex>
  //                 <CabinList
  //                     accountId={accountId}
  //                     accounts={accounts}
  //                     cabins={this.props.cabins}
  //                     onCabinsLoadSuccess={this.props.onCabinsLoadSuccess}
  //                     isLoaded={this.props.isLoaded}
  //                     isArchive={this.props.isArchive}
  //                     isDraft={this.props.isDraft}
  //                 />
  //             </Box>
  //             <Box w={[1,1/2]} p={1} flex>
  //                 <FirebaseContainer queries={getUserBookings(accounts, accountId, userEmail, this.props.onUserBookingLoadSuccess)}>
  //                     {userBookingsLoaded && <CabinBookingList {...this.props}/>}
  //                 </FirebaseContainer>
  //             </Box>
  //         </Flex>
  //     )
  // }

  innerRender() {
    const {accountId, accounts, onAllBookingLoadSuccess, allBookingsLoaded, isLoaded} = this.props;
    return (
      <BenefitsWrap>
        <BenefitBackground>
          <SubHeader isArchive={this.props.isArchive} isDraft={this.props.isDraft} account={this.props.accounts[this.props.accountId]}/>
        </BenefitBackground>
        <Wrapper>
            {this.renderLists()}
        </Wrapper>
      </BenefitsWrap>
    );
  }
}

export default CabinPage;
