/**
 * @flow
 */

import React, {Component} from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';
import theme from '../../../theme/index';

import ArrowIcon from '../../../components/ArrowIcon';
import BenefitCard from './BenefitCard';
import BenefitSection from './BenefitSection';
import AboutSection from './AboutSection';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {buildAccounts, buildBenefits, buildBenefitsArchive} from '../../../../common/config/firebase_config/endpoints';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import Spinner from "../../../components/Spinner";

import {getAccounts, getBenefits, getCategories} from '../components/Retrievers'
import MapSection from "./MapSection";

const Container = styled.div`
  background-color: ${theme.benefits.backgroundColor}
  padding-top: 1.6em;
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
   color: black;
   & * {
    color: black;
   }
`;

type TProps = {
    accountId: number;
    benefits: TBenefitApiData;
    onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
    params: Object;
    saveRating: Function,
    isDraft: boolean,
    isArchive: boolean,
    updateBenefit: (data, benefit, boolean) => void;
    updateDraftBenefit: (data, benefit, boolean) => void
};
const getSteps = (stepsSource) => {
    const steps = [];
    for (const key in stepsSource) {
        if (stepsSource[key]) {
            steps.push({
                text: stepsSource[key].text,
                type: stepsSource[key].type,
                id: key,
                url: stepsSource[key].url,
            });
        }
    }
    return steps;
};

class BenefitDetails extends Component<void, TProps, void> {

    componentDidMount = () => {
        window.scrollTo(0,0);
    }

    _getItem = () => {
        const id = this.props.match.params.id;
        const benefit = find(this.props.benefits || [], b => b.benefitId === id);
        if (!benefit) return (<div></div>);

        const steps = getSteps(benefit.steps);


        return (
            <div>
                <Container>
                    <Wrapper>
                        <BackLink to={"/app/benefits"}>
                            <ArrowIcon/>
                            tilbake
                        </BackLink>
                        <BenefitCard benefit={benefit}  {...this.props} />
                    </Wrapper>
                </Container>
                <AboutSection benefit={benefit} theme={this.props.theme} />
                <BenefitSection steps={steps} theme={this.props.theme} />
                <MapSection benefits={this.props.benefitsList} locations={this.props.locations} accountId={this.props.accountId} />
            </div>
        );
    }


    renderInner() {
        const {accounts, accountId, onBenefitsLoadSuccess, onCategoriesLoadDefaultSuccess, onCategoriesLoadSuccess, isArchive, isDraft} = this.props
        return (
            <FirebaseContainer queries={[...getBenefits(accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft), ...getCategories(accounts, accountId, onCategoriesLoadDefaultSuccess, onCategoriesLoadSuccess)]}>
                {this.props.benefits ? this._getItem() : null}
            </FirebaseContainer>
        );
    }

    render() {
        return (
            <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                {this.props.accountsLoaded ? this.renderInner() : <Spinner/>}
            </FirebaseContainer>
        );
    }
}


export default BenefitDetails;

