/**
 * @flow
 */
 
import React from 'react';
import styled from 'styled-components';
import theme from '../theme/index';
import CoveredProIcon from './Typografy/CoveredProIcon';

const ArrowIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  font-size: 1em;
  color: ${theme.icon.arrowIcon};
`;

const ArrowIcon = () => (
  <ArrowIconStyle>&#xe800;</ArrowIconStyle>
);

export default ArrowIcon;

