/**
 * @flow
 */


import {connect} from 'react-redux';
import CabinPage from './CabinPage';
import type {TAppState} from '../../../../common/TAppState';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {benefitDataTemplate, onBenefitsReset} from "../../../../common/benefits/actions";
import {onCabinsLoadSuccess, onCabinsReset} from "../../../../common/cabins/actions";
import {onAllBookingLoadSuccess} from "../../../../common/cabins/actions/bookings";

const CabinContainer = connect(
    (state: TAppState) => {
        return {
            accountId: state.auth.profile.data.accountId,
            accountsLoaded: state.accounts.isLoaded,
            accounts: state.accounts.data,
            cabins: state.cabins.data,
            isLoaded: state.cabins.isLoaded,
            isArchive: true,
            allBookings: state.cabins.allBookings,
            allBookingsLoaded: state.cabins.allBookingsLoaded,
        };
    },
    (dispatch) => ({
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        onCabinsLoadSuccess: (data, metadata, account) => dispatch(onCabinsLoadSuccess(data, metadata, account)),
        onCabinsReset: () => dispatch(onCabinsReset()),
        onAllBookingLoadSuccess: (payload , metadata, config) => dispatch(onAllBookingLoadSuccess(payload, metadata, config)),
    }),
)(CabinPage);
export default CabinContainer;
