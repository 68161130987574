/* eslint import/prefer-default-export: 0 */

import AsyncStorage from '../utils/AsyncStorage';

import * as keys from '../storageKeys';

const _onRestoreStateRequest = () => ({
  type: 'RESTORE_STATE_REQUEST',
});

const _onRestoreStateFailure = () => ({
  type: 'RESTORE_STATE_FAILURE',
});

const _onRestoreStateSuccess = (restoredState) => ({
  type: 'RESTORE_STATE_SUCCESS',
  payload: restoredState,
});

export const restoreState = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(_onRestoreStateRequest());
  
  try {
    // Auth
    const auth0Token = await AsyncStorage.getItem(keys.KEY_AUTH0_TOKEN);
    const firebaseToken = await AsyncStorage.getItem(keys.KEY_FIREBASE_TOKEN);
  
    // Profile
    const asProfile = await AsyncStorage.getItem(keys.KEY_PROFILE);
    const profile = asProfile ? JSON.parse(asProfile) : null;
    
    // Rehydrate state
    dispatch(_onRestoreStateSuccess({
      ...state,
      auth: {
        ...state.auth,
        signIn: {
          ...state.auth.signIn,
          auth0Token: auth0Token || state.auth.signIn.auth0Token,
          firebaseToken: firebaseToken || state.auth.signIn.firebaseToken,
        },
        profile: {
          ...state.auth.profile,
          data: profile || state.auth.profile.data,
        },
      },
    }));
  } catch (err) {
    dispatch(_onRestoreStateFailure());
    Promise.reject(err);
  }
};
