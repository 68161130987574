/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import {Box, Flex} from "reflexbox";
import Wrapper from "../../../components/Typografy/Wrapper";
import theme from "../../../theme";
import ManageHelpPageForm from "./ManageHelpPageForm";


type TProps = {

};

const Card = styled.div`
  background-color: ${theme.userProfile.cardBackground}; 
  margin-bottom: 1em;
`;

const InfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const Title = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;


class ManageHelpPage extends Component<void, TProps, void> {

    componentDidMount() {
        this.props.loadHelp();
    }

    render() {
        return (
            <Wrapper>
                <Card>
                    <Flex wrap>
                        <Box w={[1]}>
                            <InfoContainer>
                                <Title>Edit help page</Title>
                                {this.props.loaded && <ManageHelpPageForm initialValues={this.props.helpData || null} {...this.props} />}
                            </InfoContainer>
                        </Box>
                    </Flex>
                </Card>
            </Wrapper>
        );
    }
}


export default ManageHelpPage;

