/**
 * @flow
 */
import styled from 'styled-components';
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import { mx } from '../../../utils';


const EmailInput = styled(CustomFieldInput)`
width: 100%;
${mx('lg')} {
  width: 21vw;
}
border: none;
background: none;
border-bottom: 2px solid #F41A4D;
margin-bottom: 1.25em;
padding-bottom: 0.5em;
padding-left: 0.6em;
font-size: 1.15em;
line-height: 1.35;
font-family: 'Lato', sans-serif;
box-sizing: border-box;
color: #171717;
&:focus{
  outline: none;
  background: none;
  border-bottom: solid 2px #F41A4D;
  color: #171717;
  }
&:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #171717 !important;
}
&::-webkit-input-placeholder {
  color: #171717;
};
&:-moz-placeholder {
   color:    #171717;
   opacity:  1;
}
&::-moz-placeholder {
   color:    #171717;
   opacity:  1;
}
`;

export default EmailInput;
