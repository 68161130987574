import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';
import Modal from "../../../../components/Modal";
import {Card, CardTitle} from "../../../../components/Elements";
import Select from "../../../../components/Select";
import styled from "styled-components";
import Text from "../../../../components/Typografy/Text";

const BlackText = styled(Text)`
  color: darkgray;
  margin-bottom:20px;
`;


const options = (selectedLanguages, languages, self) => {
    if (!selectedLanguages || Object.keys(selectedLanguages).length === 0 || !languages || Object.keys(languages).length === 0) return []

    return Object.keys(selectedLanguages).filter((language) => selectedLanguages[language])
        .map((language) => {
            return ({
                label: languages[language].description,
                value: language
            })
        });
}


class SelectedLanguages extends Component<void, TProps, TState> {
    state: TState = {
        edit: false,
    };


    render() {
        const {accountFormValues, languages} = this.props

        const selectedLanguages = accountFormValues ? accountFormValues.languages : {}

        return (<Card wrap={true}>
            <Box w={[1]}>
                <CardTitle>
                    Language config
                </CardTitle>
            </Box>
            <Box w={[1]}>
                <Field
                    name="primaryLanguage"
                    component={Select}
                    options={options(selectedLanguages, languages)}
                    label="Primary language"/>
            </Box>
            {this.renderInner(languages, selectedLanguages)}
        </Card>)
    }


    renderInner(languages, selectedLanguages) {

        return (
            <Box w={[1]}>
                <Box>
                    <BlackText>Supported
                        languages: {options(selectedLanguages, languages).map(l => l.label).join(', ')}</BlackText></Box>
                <Box>
                    <SaveButton type="button" onClick={() => {
                        this.setState({edit: true})
                    }}> Add languages </SaveButton>
                </Box>
                <Modal header={`Select languages to support`}
                       onSaveClick={() => this.setState({edit: false})}
                       onCancelClick={() => this.setState({edit: false})}
                       saveButtonText={"Done"}
                       isOpen={this.state.edit}>

                    <Flex wrap={true}
                          justify="space-between">
                        {Object.keys(languages).map((languageKey, index) =>
                            <Box w={[1, 7 / 12]} key={languageKey}>
                                <div>
                                    <Field
                                        name={`languages.${languageKey}`}
                                        component={CustomFieldInput}
                                        type="checkbox"
                                        userCard
                                        label={languages[languageKey].description}/>
                                </div>
                            </Box>
                        )}
                    </Flex>
                </Modal>


            </Box>
        );
    }
}

export default SelectedLanguages
