/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import CabinForm from './CabinForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"
import Spinner from '../../../components/Spinner'
import {
    getCategories,
    getAccounts,
    getBenefits,
    getCabins,
    getBookings,
    getCabinConfig
} from "../components/Retrievers";

type TProps = {
    profile: TProfileData;
}

class CabinEdit extends Component<void, TProps, void> {

    _getItem = () => {
        const id = this.props.match.params.id;
        return find(this.props.cabins || [], c => c.cabinId === id)
    }

    _getForm = (cabin, isDraft) => {
        let initials = cabin || {}
        return (
            <CabinForm hasCabin={cabin || false} cabin={cabin} isDraft={isDraft}
                         initialValues={initials} enableReinitialize={true} {...this.props}/>
        );
    };

    _innerRender = (hasCabin, isDraft) => {
        if (hasCabin || isDraft) {
            if (this.props.onCabinsLoadSuccess) {
                const cabin = this.props.cabins ? this._getItem() : null;
                if (cabin) {
                    return (
                        <FirebaseContainer
                            queries={getBookings(this.props.accounts, this.props.accountId, cabin.cabinId, this.props.onBookingsLoadSuccess)}>
                            {this._getForm(cabin, isDraft)}
                        </FirebaseContainer>
                    )
                } else {
                    return (
                        this._getForm(cabin,isDraft)
                    )
                }
            }
            else {
                return <Spinner/>
            }
        }
        else {
            return this._getForm()
        }


    }

    renderInner() {
        const cabinId = this.props.match.params.id;
        const hasCabin = !!this.props.match.params.id;
        let isDraft = this.props.match.path.endsWith("draft");

        if (!hasCabin) isDraft = true;


        return (
            <div>
                <SubHeader isDraft={isDraft} hasCabin={hasCabin} cabinId={cabinId}/>
                <FirebaseContainer queries={getCabins(this.props.accounts, this.props.accountId, this.props.onCabinsLoadSuccess, false, isDraft)}>
                    <div>
                        {this.props.isLoaded  && this._innerRender(hasCabin, isDraft)}
                    </div>
                </FirebaseContainer>
            </div>
        );
    }

    render() {
        return (
                <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                    <FirebaseContainer queries={getCabinConfig(this.props.onCabinConfigSuccess)}>
                        {this.props.accountsLoaded && this.props.configLoaded && this.renderInner()}
                    </FirebaseContainer>
                </FirebaseContainer>
        );
    }
}

export default CabinEdit;
