/**
 * @flow
 */


import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import UserAdminPage from './UserAdminPage';
import {getAdminUsers, addAdmin, removeAdmin} from '../../../../common/auth/admin/actions';
import type { TAppState } from '../../../../common/TAppState';

const UserAdminContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     userAdmin: state.userAdmin.data[state.auth.profile.data.accountId],
     processing: state.userAdmin.processing,
     userAdminFormValues: getFormValues('UserAdminForm')(state)
   };
 },
 (dispatch) => ({
   getAdminUsers: (data) => dispatch(getAdminUsers(data)),
     addAdmin: (data, accountId) => dispatch(addAdmin(data, accountId)),
     removeAdmin: (data, accountId) => dispatch(removeAdmin(data, accountId)),
 }),
)(UserAdminPage);
export default UserAdminContainer;
