/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../theme/index';
import {mx} from "../../utils";

const RegisterListsCard = styled.div`
  border-radius: 40px;
  overflow: hidden;
  background-color: ${theme.userProfile.cardBackground};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  transition: all .2s ease-in-out;
  
  &:hover {
	transform: scale(0.98);
  }
  
  &:active {
    transform: scale(0.96);
  }
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  padding-right: 1.5em;
`;

const RegisterListInfoContainer = styled.div`
`;

const RegisterListInfoBox = styled(Box)`
  margin-top:auto;
  margin-bottom:auto;
  margin-block-start: auto;
    margin-block-end: auto;
`;

const RegisterListTitle = styled.div`
  font-size: 2em;
  color: black;
`;

const RegisterListImage = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
  object-fit: contain;
  display: block;
  aspect-ratio: 1;
`;


const RegisterListItem = (props) => {
  const {  account } = props;

  return (
    <LinkToDetails to={`/register/${account.customerUrl}`}>
      <RegisterListsCard>
        <Flex wrap>
          <Box w={[4/12]}>
            <ImageContainer>
              <RegisterListImage src={account.iconUrl || `https://firebasestorage.googleapis.com/v0/b/coveredpro/o/account-images%2F${account.id}%2Ficon%2Fmain.png?alt=media&token=80c63bcf-4e8b-485b-b468-30d969b837d2`} alt="" />
            </ImageContainer>
          </Box>
          <RegisterListInfoBox w={[8/12]}>
            <RegisterListInfoContainer>
              <RegisterListTitle> {account?.companyNameOnCard}</RegisterListTitle>
            </RegisterListInfoContainer>
          </RegisterListInfoBox>

        </Flex>
      </RegisterListsCard>
    </LinkToDetails>
  );
};

export default RegisterListItem;
