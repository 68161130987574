/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import ProfileIcon from './components/ProfileIcon';
import theme from '../../theme';
import AdminMenu from './components/AdminMenu'

const ClientInfo = styled.span`
   font-size: 1em;
   // color: ${theme.nav.color};
`;

const ImageContainer = styled.div`
   flex: 1;
   min-width: 2.5em;
`;

const InfoContainer = styled(Box)`
  flex: 1;
`;

class UserInfo extends React.Component {
  render() {
    const { profile, history } = this.props;
    return (
      <Flex wrap align="center" justify="space-between">
        <AdminMenu {...this.props}>
        <InfoContainer >
          <ImageContainer>
            <ProfileIcon theme={this.props.theme} />
          </ImageContainer>
        </InfoContainer>
        </AdminMenu>
        <InfoContainer >
          <ClientInfo>
            { profile.email }
          </ClientInfo>
        </InfoContainer>
      </Flex>
    );
  }
}

export default UserInfo;
