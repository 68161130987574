/**
 * @flow
 */
import deepmerge from 'deepmerge'

export const colors: TColors = {
    black: '#000000',
    accentColor: '#F41A4D',
    white: '#FFFFFF',
    drawerItemNotSelected: '#727272',
};

const logoHeightDefault = 80
const lightTheme = {
    "mode": "light",
    "authPage": {
        "backgroundColor": "#e8e8e8",
        "backgroundImageOpacity": 0.2,
        "textColor": "#373737",
        "textInput": {
            "backgroundColor": "#eeeeee",
            "borderColor": "#c1c1c1",
            "opacity": 0.8,
            "placeholderTextColor": "#282828",
            "textColor": "#2b2b2b"
        }
    },
    "userCard": {
        "backgroundColor": "#b2b2b0",
        "secondaryTextColor": "#FFFFFF"
    }
}


const mergeColors = (newColors) => {
    return deepmerge(colors, newColors)
}


export const buildTheme = (settings) => {
    const col = mergeColors((settings.colors || {}))

    console.log('buildTheme', settings)
    console.log('col', col)

    const logoHeight = settings.logoHeight || logoHeightDefault

    const baseTheme = Theme(col, logoHeight)
    const overrides = settings.overrides || {}

    console.log('overrides', overrides)
    const fullOverrides = settings.darkTheme ? {...overrides, mode: "dark"} : deepmerge(lightTheme, overrides)

    console.log('fullOverrides', fullOverrides)

    return deepmerge(baseTheme, fullOverrides)
}


export const Theme = (colors, logoHeight) => ({
    backgroundColor: '#DBDBDB', // Whole app but the auth flow pages (some screens override this)
    textColor: '#171717', // Whole app but the auth flow pages
    accentColor: colors.accentColor,
    navigationBar: { // Whole app
        backgroundColor: '#212121',
        titleColor: colors.white,
        iconColor: colors.accentColor,
    },
    // Styles for whole app but auth flow pages
    card: {
        backgroundColor: colors.white,
        textColor: colors.black,
        shadowColor: colors.black,
    },
    bgShapeBackgroundColor: '#141414', // Drawer background color when using bg-shape.png
    authPage: { // Splash, Start, SignIn, SignUp, Forgot password
        backgroundColor: '#141414',
        backgroundImageOpacity: 0.13,
        textColor: colors.white,
        logo: {
            height: logoHeight,
            width: '100%',
            marginTop: 110,
            marginLeft: 0,
            marginBottom: 10,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            resizeMode: 'contain',

        },
        primaryButton: {
            backgroundColor: colors.accentColor,
            borderColor: 'transparent',
        },
        secondaryButton: {
            backgroundColor: 'transparent',
            borderColor: colors.accentColor,
        },
        primaryBottomButton: {
            backgroundColor: colors.accentColor,
            borderColor: 'transparent',
        },
        secondaryBottomButton: {
            backgroundColor: '#1d1d1d',
            textColor: colors.accentColor,
            borderColor: '#40403f',
        },
        textInput: {
            backgroundColor: '#1d1d1d',
            borderColor: '#40403f',
            textColor: colors.white,
            placeholderTextColor: '#929292',
        },
        startBottom: {
            backgroundColor: '#1d1d1d',
            borderColor: '#40403f',
            opacity: 0.7,
        },
    },
    loginPage: {
        logo: {
            height: logoHeight,
            width: '100%',
            marginLeft: 0,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: 10,
            marginBottom: 10,
            marginVertical: 0,
            resizeMode: 'contain',
        }
    },
    benefitList: {
        backgroundColor: colors.white,
        dividerColor: '#dbdbdb',
    },
    benefitDetails: {
        backgroundColor: '#141414',
        textColor: colors.white,
        accentColor: colors.accentColor,
        stepIcon: {
            backgroundColor: colors.accentColor,
            textColor: colors.white
        },
        stepText: {
            textColor: colors.black
        },
        howTo: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
        longDescription: {
            backgroundColor: colors.white,
            textColor: colors.black
        }
    },
    rating: {
        fullColor: '#ffba00',
        emptyColor: '#cecece',
    },
    userCard: {
        backgroundColor: '#40403f',
        primaryTextColor: colors.white,
        secondaryTextColor: colors.accentColor
    },
    drawer: {
        dividerColor: '#1f1f1f',
        iconColor: colors.drawerItemNotSelected,
        iconSelectedColor: colors.accentColor,
        textColor: colors.drawerItemNotSelected,
        textSelectedColor: colors.white,
    },
    notifications: {
        headline: colors.black,
        description: colors.black,
        closeIconColor: colors.white,
        closeIconBackground: '#929292'
    },
    tabBar: {
        notSelectedColor: colors.drawerItemNotSelected,
        selectedColor: colors.accentColor,
    },
    defaultIconColor: {
        iconColor: colors.accentColor
    },
    bubble: {
        borderColor: '#141414',
        backgroundColor: '#141414',
        textColor: colors.accentColor
    },
    profile: {
        title: '#929292'
    },
    switch: {
        activeColor: colors.accentColor
    }
});




