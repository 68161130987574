/**
 * @flow
 */
import styled from 'styled-components';

const LongLine = styled.hr`
  justify-content: center;
  margin-top: 2em;
  color: #fff;
  flex: 1;
  width: 100%;
  `;

export default LongLine;
