/**
 * @flow
 */
import styled from 'styled-components';

const Question = styled.img`
  max-width:250px;
  max-height:100px;
  margin-left: auto;
  margin-right: auto;
  display:block;
  border-radius: 18px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 20%) 0px 2px 3px;
  `;

export default Question;
