/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import BenefitForm from './BenefitForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
  buildBenefits,
  buildBenefitsDraft,
  buildCategories,
  buildLanguages
} from '../../../../common/config/firebase_config/endpoints';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"
import Spinner from '../../../components/Spinner'
import {getCategories, getAccounts, getBenefits} from "../components/Retrievers";

type TProps = {
    profile: TProfileData;
}

class BenefitEdit extends Component<void, TProps, void> {
    componentDidMount() {
        let isNew = this.props.match.path.endsWith("new");
        if (isNew) {
            this.props.updateDraftBenefit({}, null, false, true);
        }
    }
    _getQueries = (isDraft) => {

        let queries;

        if (isDraft) {
          queries = [
            {
              endpoint: buildBenefitsDraft(this.props.accountId),
              method: 'on',
              eventType: 'value',
              callback: this.props.onBenefitsLoadSuccess,
              metadata: this.props.accountId,
              config: this.props.accounts[this.props.accountId]
            },
            {
              endpoint: buildLanguages,
              method: 'on',
              eventType: 'value',
              callback: this.props.onLanguagesLoadSuccess,
            }
          ];
        }
        else {
          queries = [
            {
              endpoint: buildBenefits(this.props.accountId),
              method: 'on',
              eventType: 'value',
              callback: this.props.onBenefitsLoadSuccess,
              metadata: this.props.accountId,
              config: this.props.accounts[this.props.accountId]
            },
            {
              endpoint: buildLanguages,
              method: 'on',
              eventType: 'value',
              callback: this.props.onLanguagesLoadSuccess,
            }
          ];
        }

        return queries.concat(getCategories(this.props.accounts, this.props.accountId, this.props.onCategoriesLoadSuccess))
    };

    _getItem = () => {
        const id = this.props.match.params.id;
        return find(this.props.benefits || [], b => b.benefitId === id)
    }

    _getForm = (benefit, isDraft) => {
        let initials = benefit || {}
        return (
            <BenefitForm
                updateBenefit={this.props.updateBenefit}
                benefitFormValues={this.props.benefitFormValues}
                updateDraftBenefit={this.props.updateDraftBenefit}
                languages={this.props.languages}
                hasBenefit={benefit || false}
                benefit={benefit}
                accountId={this.props.accountId}
                accounts={this.props.accounts}
                accountsList={this.props.accountsList}
                profile={this.props.profile}
                categories={this.props.categories}
                initialValues={initials}
                enableReinitialize={true}
                isDraft={isDraft}
            />
        );
    };

    _innerRender = (hasBenefit, isDraft) => {
        if (hasBenefit || isDraft) {
            if (this.props.onBenefitsLoadSuccess) {
                const benefit = this.props.benefits ? this._getItem() : null
                return this._getForm(benefit, isDraft)
            }
            else {
                return <Spinner/>
            }
        }
        else {
            return this._getForm()
        }


    }

    renderInner() {
        const hasBenefit = this.props.match.params.id ? true : false;
        let isDraft = this.props.match.path.endsWith("draft");

        if (!hasBenefit) isDraft = true;

        return (
            <div>
                <SubHeader isDraft={isDraft} hasBenefit={hasBenefit}/>
                <FirebaseContainer queries={getBenefits(this.props.accounts, this.props.accountId, this.props.onBenefitsLoadSuccess, false, isDraft, this.props.onLanguagesLoadSuccess)}>
                    <div>
                        {this._innerRender(hasBenefit, isDraft)}
                    </div>
                </FirebaseContainer>
            </div>
        );
    }

    render() {
        return (
                <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                        {this.props.accountsLoaded && this.renderInner()}
                </FirebaseContainer>
        );
    }
}

export default BenefitEdit;
