/**
 * @flow
 */

const breakpoints = {
  xsm: '(min-width: 300px)',
  xxsm: '(min-width: 400px)',
  sm: '(min-width: 500px)',
  md: '(min-width: 700px)',
  lg: '(min-width: 900px)',
  xl: '(min-width: 1280px)',
};

const ms = size => `@media screen and ${size}`;
const mx = (size: string) => () => ms(breakpoints[size]);

export {
  breakpoints,
  mx,
};
