/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildWhitelists } from '../../../../common/config/firebase_config/endpoints';
import type {
  TWhitelistApiData,
  TWhitelistResponse,
} from '../../../../common/types/api/TWhitelist';
import WhitelistItem from './WhitelistItem';

import Spinner from '../../../components/Spinner'

type TProps = {
  accountId: number;
  whitelist: Array<TWhitelistApiData>;
  onWhitelistsLoadSuccess: (data: TWhitelistResponse) => void;
};

class WhitelistList extends React.Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildWhitelists(this.props.accountId),
      method: 'on',
      eventType: 'value',
      callback: this.props.onWhitelistsLoadSuccess,
    },
    ]);
  };
  _getItems = () => {
    return this.props.whitelist.map((whitelist, i) => {
      console.log("listing " + JSON.stringify(whitelist))
      return <WhitelistItem key={i} whitelist={whitelist} accountId={this.props.accountId} removeFromWhitelist={this.props.removeFromWhitelist} />;
    });
  };
  render() {
    return (
      <FirebaseContainer queries={this._getQueries()}>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          { this.props.whitelist ? this._getItems() : <Spinner/> }
        </div>
      </FirebaseContainer>
    );
  }
}
export default WhitelistList;
