/**
 * @flow
 */

import styled from 'styled-components';
import theme from '../theme';
import BaseButton from './Typografy/BaseButton'
import Link from './Typografy/Link'

export const Button = styled.button`
  //text-transform: uppercase;
    padding: 0.5em 1em;
    border-radius: 1.5em;
    margin-bottom: 1em;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    ${props => props.disabled && 'background: #d68a9c;'}
    &:hover {
      background-color: ${theme.buttons.hover};
      border: 2px solid ${theme.buttons.hover};
      color: ${theme.buttons.primary};
    }
`;


export const HrefButton = styled.a`
    text-transform: uppercase;
    padding: 0.5em 1em;
    border-radius: 1.5em;
    margin-bottom: 1em;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    text-decoration:none;
    ${props => props.disabled && 'background: #d68a9c;'}
      background-color: ${theme.buttons.hover};
      border: 2px solid ${theme.buttons.hover};
      color: ${theme.buttons.primary};
    &:hover {
      background-color: ${theme.buttons.hover};
      border: 2px solid ${theme.buttons.hover};
      color: ${theme.buttons.primary};
    }
`;




export const SecondaryButton = styled(Button)`
  color: ${theme.buttons.defaultColor};
  border: 2px solid ${theme.buttons.defaultColor};
  margin-left: 0.5em;
`;

export const PrimaryButton = styled(Button)`
  color: ${theme.buttons.primary};
  border: 2px solid ${theme.buttons.defaultColor};
  background-color: ${theme.buttons.defaultColor};
`;

export const CancelButton = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  background-color: transparent;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

export const SaveButton = styled(BaseButton)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${props => props.disabled ? 'lightgray' : theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    cursor:pointer;
  }
`;

export const PublishButton = styled(BaseButton)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    cursor:pointer
  }
`;


export const DraftButton = styled(BaseButton)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: #4286f4;
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    cursor:pointer
  }
`;

