/**
 * @flow
 */


import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import {
  applyMiddleware,
  compose,
  combineReducers,
  createStore as ReduxCreateStore,
} from 'redux';

import commonReducer from '../common/commonReducer';

const rootReducer = combineReducers({
  form: formReducer,
  ...commonReducer, // Common app logic between native & web
});

const store = (() => {
  const middleware = applyMiddleware(thunk);

  const createStoreWithMiddleware = compose(middleware);
  return createStoreWithMiddleware(ReduxCreateStore)(rootReducer);
})();

export default store;
