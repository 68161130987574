/**
 * @flow
 */

import React, {Component} from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import { Box, Flex } from 'reflexbox';
import {reduxForm, Field, submit} from "redux-form";
import { connect } from 'react-redux';
import CustomFieldDate from '../CabinDetails/components/CustomFieldDate';
import theme from "../../../theme";
import {SaveButton} from "../../../components/Buttons";
import {confirmAlert} from "react-confirm-alert";
import {
    addDaysToDate,
    enDate,
    getNumDays,
    isWeekendInRange,
    todayHtmlFormat
} from "../../../../common/utils/formatDate";
import CustomFieldInput from "../../../components/Typografy/CustomFieldInput";
import {Container} from "../../../components/Elements";
import {mx} from "../../../utils";
import H3 from "../../../components/Typografy/H3";
import SmallText from "../../../components/Typografy/SmallText";

const FromToWrapper = styled(Flex)`
    border: 1px solid #aaa;
    border-radius:10px;
`;

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;

const BookingWrapper = styled.div`
  padding-bottom: 2em;
  `;

const Details = styled.div`
  background-color:white;
  margin: 0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);

 ${mx('lg')} {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 1em;
 }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.backgroundColor : '#222222'};
  color: white;
  padding: 1em;
  `;


const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  //text-transform: uppercase;
  `;

const Text = styled(SmallText)`
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  padding:1em;
  `;


const DescriptionPart = styled.div`
  display:flex;
  flex-direction: column;
  ${mx('lg')} {
    display: flex;
    width: 100%;
  }
  `;

const required = value => value ? undefined : 'Required'

class CustomBooking extends Component {

    handleSubmit = (data) => {
        const { bookingFormValues, cabin } = this.props;
        console.log("Submitting with data", data);

        let toSubmit = {
            start_date: bookingFormValues.dateFrom,
            end_date: bookingFormValues.dateTo,
            status: 2,
            priceOnBooking: bookingFormValues.price,
            user_email: bookingFormValues.user_email,
            user_name: bookingFormValues.user_name,
        };
        this.props.requestBooking(toSubmit, cabin.cabinId);
    }

    confirmBooking = () => {
        confirmAlert({
            title: 'Confirm booking request',
            message: 'Are you sure you want to book?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: this.props.handleSubmit(this.handleSubmit)
                },
                {
                    label: 'Cancel'
                }
            ]
        })
    };

    render() {
        const {cabin, bookingFormValues} = this.props;
        console.log("BOOKING VALS", this.props.bookingFormValues);
        console.log("PROPS", this.props)

        if(!cabin)
        {
            return(<div></div>)
        }
        return (
            <Details>
                <DescriptionPart>
                    <Header>
                        <Title>
                            Manual Booking
                        </Title>
                    </Header>
                    <Text>
                        <BookingWrapper>
                            <form>
                                <Wrapper>
                                    <Flex>
                                        <Box w={1/2}>
                                            <FormLabel>Check-in</FormLabel>
                                        </Box>
                                        <Box w={1/2}>
                                            <FormLabel>Check-out</FormLabel>
                                        </Box>
                                    </Flex>
                                    <FromToWrapper>
                                        <Box w={1/2}>
                                            <Field
                                                component={CustomFieldDate}
                                                type={'date'}
                                                name={'dateFrom'}
                                                label={'Check-in'}
                                                dateRange={{
                                                    max: bookingFormValues && bookingFormValues.dateTo ? bookingFormValues.dateTo : null,
                                                    min: todayHtmlFormat(),
                                                }}
                                                validate={required}
                                            />
                                        </Box>
                                        <Box w={1/2}>
                                            <Field
                                                component={CustomFieldDate}
                                                type={'date'}
                                                name={'dateTo'}
                                                label={'Check-out'}
                                                borderLeft={true}
                                                dateRange={{
                                                    min: bookingFormValues && bookingFormValues.dateFrom ? bookingFormValues.dateFrom : todayHtmlFormat(),
                                                }}
                                                validate={required}
                                            />
                                        </Box>
                                    </FromToWrapper>
                                    <Field
                                        component={CustomFieldInput}
                                        name={'price'}
                                        label={'Price'}
                                        validate={required}
                                    />
                                    <Field
                                        component={CustomFieldInput}
                                        name={'user_name'}
                                        label={'Booker\'s name'}
                                        validate={required}
                                    />
                                    <Field
                                        component={CustomFieldInput}
                                        name={'user_email'}
                                        label={'Booker\'s email'}
                                        validate={required}
                                    />
                                    <SaveButton disabled={this.props.pristine || this.props.invalid} type={'button'} style={{width: '100%', marginTop: 15}} onClick={() => this.confirmBooking()}>Add Booking</SaveButton>
                                </Wrapper>
                            </form>
                        </BookingWrapper>
                    </Text>
                </DescriptionPart>
            </Details>
        );
    }
}

CustomBooking = reduxForm({
    form: 'CabinBookingForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(CustomBooking);

export default connect()(CustomBooking);
