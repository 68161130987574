/**
 * @flow
 */

import React, { Component } from 'react';
import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'

class TermsPageSv extends Component<void, void, void> {

    render() {
        return (
            <div>
                <StickyMenu />
                <MessageSection
                    headlineText={'Användarvillkor'}
                    full={
                        <div className="text_holder">
                            <p>
                                Läs följande villkor noggrant innan du använder någon av CoveredPros appar ("appen"). Genom att använda appen godkänner du att följa och vara bunden av dessa villkor. Om du inte godkänner dessa villkor, vänligen använd inte appen.
                            </p>

                            <h2>1. Acceptans av villkor</h2>
                            <p>
                                Genom att registrera dig och använda appen bekräftar du att du har läst, förstått och accepterat dessa villkor. Dessa villkor kan uppdateras eller ändras av CoveredPro när som helst utan förvarning. Din fortsatta användning av appen innebär att du accepterar de reviderade villkoren.
                            </p>

                            <h2>2. Användarregistrering</h2>
                            <p>2.1. För att få tillgång till förmånerna och rabattkoderna som finns tillgängliga genom appen, måste du slutföra registreringsprocessen och tillhandahålla korrekt, fullständig och uppdaterad information.</p>
                            <p>2.2. Du är ansvarig för att upprätthålla konfidentialiteten för ditt kontoinformation, inklusive din e-post, och för all aktivitet som sker på ditt konto.</p>

                            <h2>3. Behörighet</h2>
                            <p>3.1. Appen är avsedd för anställda/medlemmar av registrerade företag/organisationer som är kvalificerade att få tillgång till förmåner och rabattkoder som erbjuds av deras arbetsgivare, medlemsorganisation, CoveredPro och/eller externa leverantörer.</p>
                            <p>3.2. Användare måste bekräfta sin anställnings-/medlemsstatus i ett registrerat företag/organisation genom Appens utsedda process.</p>

                            <h2>4. Förmåner och rabattkoder</h2>
                            <p>4.1. Genom att använda appen får registrerade användare tillgång till en lista över förmåner och rabattkoder som ges av deras arbetsgivare/organisation. Dessa förmåner och rabattkoder kan ändras, och deras tillgänglighet bestäms av den respektive arbetsgivaren/organisationen.</p>
                            <p>4.2. Appen ansvarar inte för noggrannheten, tillgängligheten eller kvaliteten på förmåner och rabattkoder som finns tillgängliga i appen. Användare uppmanas att kontakta sin arbetsgivare, organisation eller leverantör för eventuella frågor eller avvikelser.</p>

                            <h2>5. Användning av appen</h2>
                            <p>5.1. Du godkänner att använda appen i enlighet med alla tillämpliga lagar, regler och förordningar.</p>
                            <p>5.2. Du samtycker till att inte delta i några aktiviteter som kan skada, störa eller störa appens funktionalitet, säkerhet eller integritet.</p>
                            <p>5.3. Appen kan samla in och lagra anonymiserad användardata i syfte att tillhandahålla och förbättra tjänsterna. Se vår sekretesspolicy för mer information.</p>

                            <h2>6. Avslutning av konto</h2>
                            <p>6.1. Appen förbehåller sig rätten att avsluta eller suspendera ditt konto efter eget gottfinnande, med eller utan meddelande, om du bryter mot dessa villkor.</p>
                            <p>6.2. Ditt konto kan också raderas om du inte längre är anställd eller medlem av ett registrerat företag/organisation som ger tillgång till appens förmåner och rabattkoder.</p>
                            <p>6.3. Du kan också avsluta ditt konto när som helst genom att kontakta vår kundsupport.</p>

                            <h2>7. Begränsning av ansvar</h2>
                            <p>7.1. Appen tillhandahålls "som den är" och "som tillgänglig". Vi ger inga garantier eller representationer angående appens noggrannhet, tillförlitlighet eller tillgänglighet eller de förmåner och rabattkoder som erbjuds.</p>
                            <p>7.2. Appen ska inte hållas ansvarig för några direkta eller indirekta skador, inklusive men inte begränsat till förlust av data, förlorad vinst eller andra förluster som följd av din användning av appen.</p>

                            <h2>8. Ändringar i användarvillkor</h2>
                            <p>
                                Appen förbehåller sig rätten att ändra dessa villkor när som helst. Eventuella ändringar träder i kraft omedelbart efter att de uppdaterade villkoren har lagts ut på appen. Det är ditt ansvar att regelbundet granska dessa villkor.
                            </p>

                            <h2>9. Kontaktinformation</h2>
                            <p>
                                Om du har några frågor, kommentarer eller bekymmer angående användarvillkoren, tveka inte att kontakta oss. Vi värdesätter din integritet och är här för att hjälpa dig.
                            </p>
                            <p>
                                <strong>Coveredpro AS<br />Org.nr. 918 084 495</strong>
                            </p>
                            <p>
                                <a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a>
                            </p>
                        </div>
                    }
                />
                <Footer />
            </div>
        );
    }
}

export default TermsPageSv;
