import React, {Component} from 'react';
import {Field, getFormValues} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import ReactTooltip from 'react-tooltip'
import {FaLanguage as LanguageIcon} from 'react-icons/fa'
import Modal from '../Modal'

import CustomFieldInput from "../Typografy/CustomFieldInput";
import Button from '../Typografy/BaseButton';
import connect from "react-redux/es/connect/connect";
import type {TAppState} from "../../../common/TAppState";
import styled from "styled-components";
import theme from "../../theme";

export const Wrapper = styled(Flex)`
  width:70vw;
  
`;

export const TranslateButton = styled(Button)`
  color: ${theme.buttons.defaultColor};
  border: 2px solid ${theme.buttons.defaultColor};
  margin-left: 0.5em;
  padding: 0.5em 0.5em;
  border-radius: 50%;
`;

const matchIndexRef = /\[([0-9]+)\]/g

const parseField = (field, formValues, parentField) => {
    if(!field) return (formValues || {})[parentField]

    const pathArray = field.split('.')
    let pointInPath = formValues
    pathArray.forEach(pathElement => {
        if(pathElement.includes('['))
        {
            const matched = matchIndexRef.exec(pathElement)
            const base = pathElement.replace(matchIndexRef, '')
            pointInPath = pointInPath[base][Number(matched[1])]
        }
        else{
            pointInPath = pointInPath[pathElement]
        }
    })

        return (pointInPath || {})[parentField]
}

class Translate extends Component<void, TProps, TState> {
    state: TState = {
        editTranslate: false,
    };

    noTranslate(){
        console.log('Translate not available')
    }

    render(){
        const props = this.props
        const {accounts, accountId, formValues, languages, accountsLoaded, parentField, field, label, rows} = this.props
        const active = (accountsLoaded && Object.keys(languages).length > 0) && accounts && accountId && formValues && Object.keys(formValues).length > 0

        const fieldValue = active ? parseField(field, formValues, parentField) : null

        const account = active ? accounts[accountId] : {}

        const basePath = field ? field + '.' : ''



        return(
        <Flex wrap={true}>
            <Box w={[10 / 12]}>
                <Field {...props} onChange={(event, value) => {
                    if(this.props.formChange){
                    console.log("Changing language field! - " + value)
                    this.props.formChange(`${basePath}i18n.${parentField}.${account.primaryLanguage}`, value)}

                }} /></Box>
            {this.renderTranslate(account, basePath, parentField, fieldValue)}
        </Flex>)
    }

    renderTranslate(account, basePath, parentField, fieldValue) {


        const props = this.props

        //let formValues = null
       // const formValueKey = Object.keys(this.props).find(key => {key.toLowerCase().endsWith("formValues")})
        //formValues = formValueKey ? this.props[formValueKey] : null

        const {accounts, accountId, formValues, languages, accountsLoaded, field, label, rows} = this.props
        const active = (accountsLoaded && Object.keys(languages).length > 0) && accounts && accountId && formValues && Object.keys(formValues).length > 0


       // console.log("Translate:" + JSON.stringify(props))

        return (
            <div>
                <TranslateButton data-tip data-for='translate-tooltip' type="button" onClick={() => {
                    active ? this.setState({editTranslate: true}) : this.noTranslate()
                }}> <LanguageIcon/> </TranslateButton>
                <ReactTooltip id='translate-tooltip'>
                    <span>Translate</span>
                </ReactTooltip>
                <Modal header={`Translate ${label}`}
                       onSaveClick={() => this.setState({editTranslate: false})}
                       onCancelClick={() => this.setState({editTranslate: false})}
                       saveButtonText={"Save"}
                       cancelButtonText={"Cancel"}
                       isOpen={this.state.editTranslate}>

                    <Wrapper wrap={true}>
                        {Object.keys(account.languages || {}).map((languageKey) => {
                            if (languageKey === account.primaryLanguage) {
                                return (
                                    <Box w={[1]} key={languageKey}>
                                        <Field
                                            name={`${basePath}i18n.${parentField}.${languageKey}`}
                                            component={props.component || CustomFieldInput}
                                            rows={rows}
                                            type="text"
                                            userCard
                                            inject={fieldValue}
                                            placeholder="Translation"
                                            label={languages[languageKey].description} disabled={true}/>
                                    </Box>)
                            }
                            return (
                                <Box w={[1]} key={languageKey}>
                                    <Field
                                        name={`${basePath}i18n.${parentField}.${languageKey}`}
                                        component={props.component || CustomFieldInput}
                                        rows={rows}
                                        type="text"
                                        userCard
                                        placeholder="Translation"
                                        label={languages[languageKey].description}/>
                                </Box>)
                        })}
                    </Wrapper>
                </Modal>
            </div>
        );
    };


}



const TranslateContainer = connect(
    (state: TAppState, dispatch) => {
        return {
            languages: state.languages.data,
            accounts: state.accounts.data ,
            accountsLoaded: state.accounts.isLoaded
        };
    },
null
)(Translate);

export default TranslateContainer;



