/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme';
import type { TAccountApiData } from '../../../common/types/api/TAccount';
import Link from '../../../components/Typografy/Link';
import BaseButton from '../../../components/Typografy/BaseButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const AccountsCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;

const ImageContainer = styled.div`
  padding-right: 1.5em;
  overflow: hidden;
  margin: 0.5vw;
`;

const AccountImage = styled.img`
  width: 12vw;
`;


const AccountInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const AccountTitle = styled.h2`
  font-size: 1.5em;
`;

const AccountText = styled.p`
  font-size: 1em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid ${theme.categories.categoryColor};
`;

const RatingTitle = styled.span`
  font-size: 1em;
`;

const RatingContainer = styled.div`
  padding-top: 2.5em;
  text-align: center;
`;
const LinkContainer = styled.div`
  text-align: right;
  margin-bottom: 2em;
  padding-right: 2.5em;
`;


const StarsContainer = styled.div`
  padding: 0.25em;
  border: 1px solid ${theme.card.cardBorders};
  border-radius: 3em;
  width: auto;
  display: flex;
  justify-content: space-around;
  max-width: 8em;
  margin: 1em auto;
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;

const EditAccountButton = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const ArchiveAccountButton = styled(BaseButton)`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;



type TProps = {
  account: TAccountApiData;
  onArchive: Function;
  accountId:number;
};

const archive = (props: TProps) => {
  confirmAlert({
    title: 'Confirm to archive',                        // Title dialog
    message: 'Are you sure you want to archive this account?',
      buttons: [{
          label: 'Confirm',
          onClick: () => props.onArchive(props.accountId, props.account)
      },
          {
              label: 'Cancel'
          }
      ]
  })
};


const AccountCard = (props: TProps) => {
  const { account, onArchive, accountId } = props;


  return (
    <AccountsCard>
      <Flex wrap>
        <Box w={[1, 7/12]}>
          <AccountInfoContainer>
            <AccountTitle>{account.name}</AccountTitle>
            <AccountText>
             Id: {account.id}
            </AccountText>
          </AccountInfoContainer>
          <LinkContainer>
            <EditAccountButton to={'/admin/accounts/' + account.accountId + '/edit'}>
              Edit
            </EditAccountButton>
            <ArchiveAccountButton onClick={() => archive(props)}>
              Archive
            </ArchiveAccountButton>
            <EditAccountButton to={'/admin/accounts/' + account.accountId + '/deploy'}>
              Deploy
            </EditAccountButton>
          </LinkContainer>
        </Box>
        <Box w={[1, 2/12]}>
        </Box>
      </Flex>
    </AccountsCard>
  );
};

export default AccountCard;
