/**
 * @flow
 */


import {connect} from 'react-redux';
import BenefitDetails from './BenefitDetails';
import {onBenefitsLoadSuccess} from '../../../../common/benefits/actions/index';
import type {TAppState} from '../../../../common/TAppState';
import {
    archiveBenefit,
    changeVendorBenefitVisibility,
    copyBenefit
} from '../../../../common/benefits/actions/editBenefit';
import {onAccountsLoadSuccess, setCustomCategory} from "../../../../common/accounts/actions";
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions";
import {benefitDataTemplate, updateBenefit} from "../../../../common/benefits/actions";
import {getFormValues} from "redux-form";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";
import {onVendorVisibilityLoadSuccess} from "../../../../common/benefits/actions/benefitsLoad";

const BenefitDetailsContainer = connect(
  (state: TAppState) => {
    return {
      languages: state.languages.data,
      accountId: state.auth.profile.data.accountId,
      benefits: state.benefits.data.all || [],
      accounts: state.accounts.data,
      accountsLoaded: state.accounts.isLoaded,
      profile: state.auth.profile.data,
      categories: state.categories.list,
      isDraft: true,
      benefitFormValues: getFormValues('BenefitForm')(state),
        hiddenBenefits: state.benefits.hiddenBenefits.list,
    };
  },
  (dispatch) => ({
      onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
    onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
    onArchive: (accountId, benefit) => dispatch(archiveBenefit(accountId, benefit)),
    onCopy: (sourceAccountId, targetAccountId, benefit) => dispatch(copyBenefit(sourceAccountId, targetAccountId, benefit)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onSetCustomCategory: (accountId, targetCategoryId, benefit) => dispatch(setCustomCategory(accountId, targetCategoryId, benefit)),
    onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
    onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
    updateBenefit: (data, benefit, publish) => dispatch(updateBenefit(data, benefit, publish)),
      onVendorVisibilityLoadSuccess: (payload) => dispatch(onVendorVisibilityLoadSuccess(payload)),
      changeVendorBenefitVisibility: (benefitId, status) => dispatch(changeVendorBenefitVisibility(benefitId, status)),
  }),
)(BenefitDetails);

export default BenefitDetailsContainer;

