import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CollapsibleWrapper from './Typography/CollapsibleWrapper';
import Box from './Typography/Box';
import styled from "styled-components";

const stepWrapper = {
  fontStyle: "italic",
  display: "block",
  textAlign: "center"
};

const InnerBox = styled.div `
`;

class QASection extends Component {

  mapSteps(props) {
    console.log("Mapping steps")
    if(!(props && props.steps)) return null
    const steps = Object.values(props.steps);
    console.log("Mapping steps2")
    const listSteps = steps.filter(s => s !== undefined).map((step, i) =>
      <div key={i + '-steps'}>
        <span style={stepWrapper}>{step.stepText}</span>
        {this.mapChildSteps(step)}
      </div>
    );
    return (
      <div>{listSteps}</div>
    );
  }
  mapChildSteps(props) {
      if(!props.childSteps) return null;
    const childSteps = Object.values(props.childSteps);
    const { accountData } = this.props;
    console.log("Mapping childsteps")
    const listChildSteps = childSteps.filter(s => s !== undefined).map((step, i) =>
      <li key={i + '-childsteps'}>{step.replace("[appName]", accountData.appName).replace("[companyName]", accountData.companyName)}</li>
    );
    return (
      <ol>
        {listChildSteps}
      </ol>
    );
  }
  render() {
    return (
      <Box>
        <InnerBox>
          <CollapsibleWrapper btnColor={this.props.btnColor} title={!(this.props.content && this.props.content.title) ? "Loading..." : this.props.content.title} content={this.mapSteps(this.props.content)}/>
        </InnerBox>
      </Box>
    );
  }
}

QASection.propTypes = {
  content: PropTypes.object.isRequired,
}

export default QASection;
