/**
 * @flow
 */
import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

type TProps = {
  bg?: boolean;
}

const Link = ({ bg, ...props }: TProps) => {
  const StyledLink = styled(RouterLink)`
    text-decoration: none;
    //text-transform: uppercase;
    padding: 0.7em 1em;
    margin-bottom: 0.5em;
    color: #929292;
    background: ${bg ? '#F41A4D' : 'none'};
    &:hover {
      text-decoration: none;
      color: #fff;
    &>* {
        text-decoration: none;
        color: #fff;
        border-bottom: 2px solid #F41A4D;
        }
    }
  `;
  return <StyledLink {...props} />;
};

export default Link;
