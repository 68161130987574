/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import styled from 'styled-components';
import type {TContactState} from '../../../../common/contact//TContactState';
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import LoginButton from '../../../components/Typografy/LoginButton';
import EmailInput from './EmailInput';

type TContactProps = {
  handleSubmit: Function;
  registerContactMeDB: (data: string) => void;
  contact: TContactState;
}

const SubmitWrapper = styled.div`
  margin-top: 3em;
`;

const SubmitButton = styled(LoginButton)`
  font-weight: bold;
  transition: all .2s;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    opacity: 0.6;
    outline: none;
  }
  `;


class ContactForm extends Component<void, TContactProps, void> {

  _handleSubmit = (data) => {
    this.props.registerContactMeDB(data);
  };

  renderForm = () => (
    <SubmitWrapper>
      <form onSubmit={this.props.handleSubmit(this._handleSubmit)}>
        <div>
          <Field
            name="email"
            component={CustomFieldInput}
            type="email"
            placeholder="Din e-post"
          />
        </div>
        <SubmitButton
          disabled={this.props.contact.isLoading}
          type="submit"
        >
          Kontakt oss
        </SubmitButton>
      </form>
    </SubmitWrapper>
  );

  renderSent = () => (
    <div>
      Takk for din henvendelse! Du hører fra oss snart.
    </div>
  );


  render() {
    if (this.props.contact.sent) {
      return this.renderSent();
    }
    else {
      return this.renderForm();
    }
  }
}

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Fyll inn epostadresse!';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Ugyldig epostaddresse';
  }
  return errors;
};

export default reduxForm({
  form: 'contact',
  validate,
  field: ['email'],
})(ContactForm);
