/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, change, formValueSelector} from 'redux-form';
import {
  Flex,
  Box,
} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import SetPasswordRunner from './components/SetPasswordRunnder';


import type {
  TUserAdminApiData,
  TUserAdminResponse,
} from '../../../../common/types/api/TUserAdmin';

import styles from '../UserAdminEdit/UserAdminForm.css'

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;


type TProps = {
  accountId: string;
  initialValues: TUserAdminApiData;
  hasUserAdmin: boolean;
}

type TState = {}

const UserAdminsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const UserAdminInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const UserAdminTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;


class UserAdminForm extends Component<void, TProps, TState> {

  render() {

    return (
      <UserAdminsCard>
        <Flex wrap>
          <Box w={[1]}>
            <UserAdminInfoContainer>
                <UserAdminTitle>Enter email and password of a user to set password for.</UserAdminTitle>
              <form autoComplete="off">
                <div>
                  <Field
                    name="email"
                    component={CustomFieldInput}
                    type="text"
                    placeholder="Email address..."
                    userCard
                  />
                  <Field
                      name="password"
                      component={CustomFieldInput}
                      type="text"
                      placeholder="Password"
                      userCard
                  />
                </div>
              </form>
            </UserAdminInfoContainer>
          </Box>
          <Box w={[1]}>
            <SetPasswordRunner {...this.props}/>
          </Box>
        </Flex>
      </UserAdminsCard>
    );
  }
}

export default  connect(
  null, {change})
(reduxForm({
  form: 'SetPasswordForm',
  field: ['email', 'password'],
})(UserAdminForm));
