/**
 * @flow
 */


import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import WhitelistPage from './WhitelistPage';
import { onWhitelistsLoadSuccess, updateWhitelist, removeFromWhitelist } from '../../../../common/whitelist/actions';
import type { TAppState } from '../../../../common/TAppState';

const WhitelistContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     whitelist: state.whitelist.list,
     whitelistFormValues: getFormValues('WhitelistForm')(state)
   };
 },
 (dispatch) => ({
   onWhitelistsLoadSuccess: (data) => dispatch(onWhitelistsLoadSuccess(data)),
   updateWhitelist: (data, whitelist) => dispatch(updateWhitelist(data, whitelist)),
   removeFromWhitelist: (data) => dispatch(removeFromWhitelist(data)),
 }),
)(WhitelistPage);
export default WhitelistContainer;
