
import React from 'react';
import {FaTimesCircle} from 'react-icons/fa';

import styles from './../assets/form.css'
const RemoveField = (props) =>
{
    return (<FaTimesCircle size="50" color="#ececec" className={styles.faTimesCircle} {...props} />)
}

export default  RemoveField
