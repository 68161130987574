/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../../components/Typografy/Wrapper';
import BaseButton from '../../../../components/Typografy/BaseButton';
import Link from '../../../../components/Typografy/Link';
import { mx } from '../../../../utils';
import theme from '../../../../theme/index';

const CancelButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  background-color: transparent;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const DropButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 10px;
  box-shadow: none;
  padding: 10px 20px;
  background: ${theme.subHeader.buttonSecondColor};
  border: none;
  transition: all .2s ease-in-out;
  &:active {
    outline: none;
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
    cursor: pointer;
    opacity: 0.6;
  }
`;




const FooterFlex = styled.div`
  margin-left: auto;
`;
const ButtonsContainer = styled.div`
   flex: 1;
   display: flex;
`;

const Dropper = (props) => {
  return(
      <FooterFlex>
          <DropButton onClick={() => props.removeFromWhitelist(props.whitelist)}> Drop</DropButton>
      </FooterFlex>
)};


export default Dropper;
