import React from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import Select from "../../../../components/Select";
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import CustomFieldTextarea from "../../../../components/Typografy/CustomFieldTextarea";
import stepTypes from '../../BenefitsList/data/stepTypes';
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import LegacyTranslate from "../../../../components/form/LegacyTranslate";

const stepOptions = stepTypes.map(step => ({
    label: step.name,
    value: step.id
}));


const hasThreeColumns = (fields, index) => (fields.get(index).type === 'link' || fields.get(index).type === 'phone')

const renderSteps = ({fields, meta: {touched, error}, formValues, accountId, accountsList}) => {

    const dynamicLinkOptions = accountsList.filter(item => {
        if (item.vendors && item.vendors.length) {
            let vendorFiltered = item.vendors.filter((vendor) => {
                return vendor.type === accountId;
            });
            return vendorFiltered.length > 0;
        }
        return false;
    });

    let _stepOptions = stepOptions.map(item => item);
    if (dynamicLinkOptions && dynamicLinkOptions.length) {
        _stepOptions.push({
            value: 'dynamicLink',
            label: 'Dynamic Links',
        });
    }

    return (
        <div>
            {fields.map((step, index) =>
                <div key={index}>
                    <h4>Step #{index + 1}</h4>
                    <Flex
                        justify="space-between">
                        <Box w={[1, 2/12]}  >
                            <Field
                                name={`${step}.type`}
                                component={Select}
                                label="Step type"
                                options={_stepOptions}/>
                        </Box>
                        <Box w={hasThreeColumns(fields, index) ? [1, 7/12] : [1, 9/12]} >
                            <div>
                                <Field
                                    name={`${step}.text`}
                                    component={CustomFieldTextarea}
                                    rows={3}
                                    type="text"
                                    userCard
                                    placeholder="Step content"
                                    label="Content"/>
                            </div>
                        </Box>
                        <Box w={[1, 1/12]} >
                            <LegacyTranslate component={CustomFieldTextarea} rows={3} formValues={formValues} accountId={accountId}
                                       parentField="text" field={step} label="Content" />
                        </Box>
                        {
                            fields.get(index).type === 'link' &&
                            <Box w={[1, 4/12]} mx={2}>
                                <div>
                                    <Field
                                        name={`${step}.url`}
                                        component={CustomFieldInput}
                                        type="text"
                                        userCard
                                        placeholder="Step link"
                                        label="Link"/>
                                </div>
                            </Box>
                        }
                        {
                            fields.get(index).type === 'phone' &&
                            <Box w={[1, 3/12]} mx={2} >
                                <div>
                                    <Field
                                        name={`${step}.url`}
                                        component={CustomFieldInput}
                                        type="text"
                                        userCard
                                        placeholder="Step phone number"
                                        label="Phone number"/>
                                </div>
                            </Box>
                        }
                        <Box w={[1, 1/12]} mx={2}>
                            <RemoveField onClick={() => fields.remove(index)} />
                        </Box>
                    </Flex>
                    {
                        fields.get(index).type === 'dynamicLink' &&
                        <Box w={[1, 4/12]} mx={2}>

                                { dynamicLinkOptions.map((dlItem, dlIndex) => {return (
                                    <div key={dlIndex}>
                                        <Field
                                            name={`${step}.urls._${dlItem.accountId}`}
                                            component={CustomFieldInput}
                                            type="text"
                                            userCard
                                            placeholder={dlItem.accountName}
                                            label={`Link - ${dlItem.accountName}`}/>
                                    </div>)})}
                                    <div>
                                        <Field
                                            name={`${step}.url`}
                                            component={CustomFieldInput}
                                            type="text"
                                            userCard
                                            placeholder="Default fallback link"
                                            label="Default fallback link"/>
                                    </div>
                        </Box>
                    }
                </div>
            )}
            <Flex justify="flex-end">
                <SaveButton name="addStep" id="addStep" type="button" onClick={(event) => {
                    if (event) event.preventDefault(); fields.push({text: ''});}}> Add step </SaveButton>
            </Flex>
        </div>
    );
}

export default renderSteps
