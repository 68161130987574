/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {SaveButton} from '../../../../components/Buttons';
import {Card, CardTitle} from '../../../../components/Elements'

import Dropzone from '../../../../components/Dropzone';
import styled from 'styled-components';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';


import {NotificationManager} from "react-notifications";
import {uploadAnyImage} from "../../../../../common/cabins/actions/uploadImage";
import Modal from '../../../../components/Modal'
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {Field} from "redux-form";


const DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;
const CancelImageUpdateButton = styled(SaveButton)`
  margin-top:40px;
`;

const AccountImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;

type TProps = {
    uploadUrl: string;
    showUrl: string;
    headline: string;
    minWidth: number;
    minHeight: number;
    aspectRatio: number

}

type TState = {}
const _URL = window.URL || window.webkitURL;

class ImageUploader extends Component<void, TProps, TState> {

    state: TState = {
        file: null,
        edit: false,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            id: nextProps.formValues.images ? Object.keys(nextProps.formValues.images).length : 0
        })
    }

    _onDrop = (acceptedFiles) => {
        const [file] = acceptedFiles;

        const setter = this.setState
        let img = new Image();

        const onloader = function (setter) {
            if (this.width >= setter.props.minWidth && this.height >= setter.props.minHeight) {
                setter.setState({file});
            }
            else {
                console.log("IMAGE TOO SMALL!")
                NotificationManager.error('Error', `Image too small (is ${this.width}x${this.height}, min ${setter.props.minWidth}x${setter.props.minHeight})`, 6000);
            }
        };

        const onloaderBound = onloader.bind(img, this)
        img.onload = onloaderBound;


        img.onerror = function () {
            console.log("IMAGE ERROR!")
            NotificationManager.error('Error', 'Invalid image', 3000);
        };

        img.src = _URL.createObjectURL(file);
    };

    _onImageUpload = () => {

        this.refs.cropperz.getCroppedCanvas().toBlob(blob => {
            uploadAnyImage(this.props.uploadUrl, blob, url => {
                    if (this.props.input.onChange) {
                        this.props.input.onChange(url);
                    }

                    this.setState({file: null, picture: url, edit: false});
                    NotificationManager.success('Success', 'Image uploaded!', 3000);
                },
                errorCb => {
                    NotificationManager.error('Error', 'Error uploading image', 3000)
                });
        });
    };


    _crop = () => {

        const data = this.refs.cropperz.getData();

        if (data.width < this.props.minWidth || data.height < this.props.minHeight) {
            data.width = this.props.minWidth;
            data.height = this.props.minHeight;
            this.refs.cropperz.setData(data);
        }
    }

    renderShow(picture, headline) {
        return (
            <div>
                <SaveButton onClick={(e) => this.onClickShow(e)}>
                    Add image
                </SaveButton>
            </div>
        )

    }

    noImage(p) {
        p.target.src = 'https://firebasestorage.googleapis.com/v0/b/my-coveredpro.appspot.com/o/click-to-edit.png?alt=media&token=6927c3df-82d6-48cd-b0b2-0ddd301fece3';
    }


    renderEdit(headline) {
        return (
            <Modal header={headline}
                   onSaveClick={(e) => this.onSave(e)}
                   onCancelClick={() => this.setState({edit: false})}
                   saveButtonText={"Save"}
                   cancelButtonText={"Cancel"}
                   isOpen={this.state.edit}>
                <Box w={[1]}>
                    {
                        this.state.file ?
                            <Cropper
                                ref='cropperz'
                                src={this.state.file.preview}
                                scalable={false}
                                zoomable={false}
                                x={0}
                                y={0}
                                width={780}
                                height={780}
                                crop={this._crop}
                                aspectRatio={this.props.aspectRatio}
                                guides={false}
                                style={{maxWidth: 780, maxHeight: 780}}
                            />
                            :
                            <Dropzone onDrop={this._onDrop} multiple={false}>
                                <DropzonePlaceholder>
                                    Drop file or click to select
                                </DropzonePlaceholder>
                            </Dropzone>

                    }
                </Box>
            </Modal>)
    }

    onBlob = (blob) => {
        uploadAnyImage(this.props.uploadUrl + this.state.file.name, blob, this.onUrl,
            errorCb => {
                NotificationManager.error('Error', 'Error uploading image', 3000)
            });
    }

    onUrl = (url) => {
        this.setState({file: null, picture: url, edit: false});
        const toSend = {
            path: url,
            id: this.state.id
        }

        this.props.pushvalues(toSend);

        // if(this.props.onChange){
        //     this.props.onChange(toSend)
        // }
        // else if(this.props.input && this.props.input.onChange){
        //     this.props.input.onChange(toSend)
        // }
        NotificationManager.success('Success', 'Image uploaded!', 3000);
    }


    onSave = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.refs.cropperz.getCroppedCanvas().toBlob((blob) => {
            this.onBlob(blob)
        });
    }

    onClickShow = (event) => {
        event.preventDefault()
        this.setState({...this.state, edit: true})
    }

    render() {
        const {headline, buttonText} = this.props

        const {
            input: { value, onChange }
        } = this.props;

        const hasValue = (this.props.input && this.props.input.value && this.props.input.value.length > 0)

        let picture = hasValue ? this.props.input.value : this.props.showUrl;

        if (this.state.picture) {
            picture = this.state.picture;
        }

        return (
                    <Box w={[1]}>
                        {this.renderShow(picture, headline)}
                        {this.renderEdit(buttonText)}
                    </Box>
        );
    }
}

export default ImageUploader

