import React from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';


import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton, HrefButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import LocationSearchInput from "./LocationInput";




const renderLocations = ({fields, meta: {touched, error}, formValues}) => {
    const locations = ((formValues || {} ).locations) || []

    return (
        <div>
            {fields.map((location, index) =>
                <div key={index}>
                    <h4>Location #{index + 1}</h4>
                    <Flex
                        justify="space-between">
                        <Box w={[1, 5/12]} mx={2}>
                            <Field
                                name={`${location}.heading`}
                                component={CustomFieldInput}
                                type="text"
                                userCard
                                placeholder="Heading"
                                label="Heading"/>
                        </Box>
                        <Box w={[1, 5/12]} mx={2}>
                            <Field
                                name={`${location}`}
                                component={LocationSearchInput}
                                type="text"
                                userCard
                                placeholder="Address"
                                label="Address search"/>
                        </Box>

                        <Box w={[1, 2/12]} mx={2} >
                            <RemoveField onClick={() => fields.remove(index)}/>
                        </Box>
                    </Flex>
                    <Flex
                        justify="flex-start">
                        <Box w={[1, 3/12]} mx={2}>
                            <Field
                                name={`${location}.latitude`}
                                component={CustomFieldInput}
                                type="text"
                                userCard
                                placeholder="Latitude"
                                label="Latitude"
                                disabled/>
                        </Box>
                        <Box w={[1, 3/12]} mx={2}>
                            <Field
                                name={`${location}.longitude`}
                                component={CustomFieldInput}
                                type="text"
                                userCard
                                placeholder="Longitude"
                                label="Longitude"
                                disabled/>
                        </Box>
                        <Box w={[1, 3/12]} mx={2}>
                            <HrefButton target="_blank" href={`http://maps.google.com//maps/place/${(locations[index] || {}).latitude},${ (locations[index] || {}).longitude}/@${(locations[index] || {}).latitude},${ (locations[index] || {}).longitude},18z/data=!3m1!1e3`}  >Preview Location</HrefButton>
                        </Box>
                    </Flex>
                </div>
            )}
            <Flex justify="flex-end">
                <SaveButton name="addLocation" id="addLocation" type="button" onClick={(event) => {
                    if (event) event.preventDefault();
                    fields.push({
                        text: ''
                    });
                }}> Add location </SaveButton>
            </Flex>
        </div>
    );
}


export default renderLocations
