/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../components/Typografy/Wrapper';
import theme from '../../theme/index';
import type {
  TMessageApiData,
  TMessageResponse,
} from '../../../common/types/api/TMessage';
import SubHeader from './SubHeader';
import Map from './Map';
import {getBenefits} from './components/Retrievers'
import FirebaseContainer from "../../../common/firebase/FirebaseContainer";
import {getAccounts} from "./components/Retrievers";
import type {TBenefitApiData, TBenefitResponse} from "../../../common/types/api/TBenefit";
import Spinner from "../../components/Spinner";
import GoogleMapReact from "google-map-react";


const MessagesWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const MessageBackground = styled.div`
   background-image: url(${require('../../assets/images/top-background.png')});
`;

const MapWrapper = styled.div`
  height: 80vh;
  width:100%;
`;


type TProps = {
  accountId: number;
  messages: TMessageApiData;
  onMessagesLoadSuccess: (data: TMessageResponse) => void;
  benefits: Array<TBenefitApiData>;
  onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
};

class MapPage extends React.Component<void, TProps, TState> {

  innerRender() {
    const {accounts, accountId, onBenefitsLoadSuccess} = this.props;
    if (!this.props.accountId) {
      return (
        <div>
          <h1>LASTER KONTO...</h1>
        </div>)
    }
    return (
      <FirebaseContainer queries={getBenefits(accounts, accountId, onBenefitsLoadSuccess)}>
        {this.props.benefits && this.props.locations ?
            <MapWrapper>
                <Map benefits={this.props.benefits} locations={this.props.locations} accountId={this.props.accountId} />
            </MapWrapper> : this.props.isLoaded ? <h3>No benefits found!</h3> :
          <Spinner/>}
      </FirebaseContainer>
    );
  }

  render() {
    console.log("isLoaded: " + this.props.isLoaded);
    console.log("Locations: ");
    console.log(this.props.locations);
    return (
      <MessagesWrap>
        <MessageBackground>
          <SubHeader theme={this.props.theme} />
        </MessageBackground>
        <Wrapper>
          <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
            {this.props.accountId && this.innerRender()}
          </FirebaseContainer>
        </Wrapper>
      </MessagesWrap>
    );
  }
}

export default MapPage;
