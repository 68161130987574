import styled from 'styled-components';

export const VippsButton = styled.button`
text-transform: uppercase;
padding: 0.5em 1em;
border-radius: 1.5em;
border:none;
margin-bottom: 1em;
background-color: #ff5b24;
color: white;
font-family: 'Lato', sans-serif;
&:hover {
  background-color: #ff5b24;
  color: white;
}
`;

export const VippsWrapper = styled.div`
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content:center;
    margin-top:20px;
`;
