/**
 * @flow
 */

 import styled from 'styled-components';

 import bgShape from '../../assets/images/bg_shape.svg';

 const LoginWrapper = styled.div`
 //background-image: url(${bgShape});
 background-color: #141414;
 //background-size: 150em;
 //background-position: left;
 height: 100vh;
 padding-top: 3em;
`;


 export default LoginWrapper;
