/**
 * @flow
 */


import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import DomainsPage from './DomainsPage';
import { onDomainsLoadSuccess, addAccountToDomain, removeAccountFromDomain } from '../../../../common/domains/actions';
import type { TAppState } from '../../../../common/TAppState';

const DomainsContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     domain: state.domains.list,
     domainFormValues: getFormValues('DomainForm')(state)
   };
 },
 (dispatch) => ({
   onDomainsLoadSuccess: (data) => dispatch(onDomainsLoadSuccess(data)),
   addAccountToDomain: (data, history) => dispatch(addAccountToDomain(data, history)),
   removeAccountFromDomain: (data, history) => dispatch(removeAccountFromDomain(data, history)),
 }),
)(DomainsPage);
export default DomainsContainer;
