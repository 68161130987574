/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, reset, change, formValueSelector} from 'redux-form';
import {
  Flex,
  Box,
} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';
import Wrapper from '../../../components/Typografy/Wrapper';
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import CustomFieldTextarea from '../../../components/Typografy/CustomFieldTextarea';
import DomainSaver from './components/DomainSaver';


import type {
  TDomainApiData,
  TDomainResponse,
} from '../../../../common/types/api/TDomain';

import styles from './DomainForm.css'

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;


type TProps = {
  accountId: string;
  initialValues: TDomainApiData;
  hasDomain: boolean;
}

type TState = {}

const DomainsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const DomainInfoContainer = styled.div`
  padding: 1.5em;
  padding-bottom: 0;
`;

const DomainTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;


class DomainForm extends Component<void, TProps, TState> {

  render() {

    return (
      <DomainsCard>
        <Flex wrap>
          <Box w={[1]}>
            <DomainInfoContainer>
              <form autoComplete="off">
	              <div
		              style={{
			              width: "100%",
			              display: "flex",
			              flexDirection: "row",
			              gap: "1em",
		              }}
	              >
	                  <Field
	                    name="domain"
	                    component={CustomFieldInput}
	                    type="text"
	                    placeholder="Add a new domain..."
	                    userCard
	                  />
		              
		              <DomainSaver {...this.props}/>
                </div>
              </form>
            </DomainInfoContainer>
          </Box>
        </Flex>
      </DomainsCard>
    );
  }
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset('DomainForm'));


export default  connect(
  null, {change})
(reduxForm({
  form: 'DomainForm',
  field: ['domain'],
  onSubmitSuccess: afterSubmit,
})(DomainForm));
