/**
 * @flow
 */
import styled from 'styled-components';

const SubTitle = styled.h2`
  color: #171717;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  `;

export default SubTitle;
