import React from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';


const renderVendors = ({fields, meta: {touched, error}}) => {
    return (
        <div>
            {fields.map((step, index) =>
                <div key={index}>
                    <h4>Vendor #{index + 1}</h4>
                    <Flex
                        justify="space-between">
                        <Box w={[1, 7/12]} >
                            <div>
                                <Field
                                    name={`${step}.name`}
                                    component={CustomFieldInput}
                                    type="text"
                                    userCard
                                    placeholder="Vendor name"
                                    label="Name"/>
                            </div>
                        </Box>
                        <Box w={[1, 2/12]} mx={2}>
                            <RemoveField onClick={() => fields.remove(index)} />
                        </Box>
                    </Flex>
                </div>
            )}
            <Flex justify="flex-end">
                <SaveButton name="addCardVEndor"  type="button" onClick={(event) => {
                    if (event) event.preventDefault(); fields.push({name: ''});}}> Add vendor </SaveButton>
            </Flex>
        </div>
    );
}

export default renderVendors