/**
 * @flow
 */
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import CabinEdit from './CabinEdit';
import type { TAppState } from '../../../../common/TAppState';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {benefitDataTemplate} from "../../../../common/benefits/actions";
import {onCabinsLoadSuccess, updateCabin, updateDraftCabin} from "../../../../common/cabins/actions";
import {approveBooking, onBookingLoadSuccess} from "../../../../common/cabins/actions/bookings";
import {onCabinConfigSuccess} from "../../../../common/cabins/actions/cabinsLoad";

const CabinEditContainer = connect(
  (state: TAppState, dispatch) => {
    return {
        accountId: state.auth.profile.data.accountId,
        accountsLoaded: state.accounts.isLoaded,
        accounts: state.accounts.data,
        cabins: state.cabins.data,
        isLoaded: state.cabins.isLoaded,
        cabinFormValues: getFormValues('CabinForm')(state),
        profile: state.auth.profile.data,
        bookings: state.cabins.bookings,
        config: state.cabins.config,
        configLoaded: state.cabins.configLoaded,
    };
  },
  (dispatch) => ({
      onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
      onCabinsLoadSuccess: (data, metadata, account) => dispatch(onCabinsLoadSuccess(data, metadata, account)),
      onBookingsLoadSuccess: (data, metadata, account) => dispatch(onBookingLoadSuccess(data, metadata, account)),
      updateDraftCabin: (data, cabin) => dispatch(updateDraftCabin(data, cabin)),
      updateCabin: (data, cabin, publish) => dispatch(updateCabin(data,cabin,publish)),
      approveBooking: (cabinId, bookingId, status) => dispatch(approveBooking(cabinId, bookingId, status)),
      onCabinConfigSuccess: (data) => dispatch(onCabinConfigSuccess(data)),
  })
)(CabinEdit);

export default CabinEditContainer;
