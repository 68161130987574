/**
 * @flow
 */

export type TStep = {
  id: string;
  name: string;
}

const stepTypes: Array<TStep> = [
  {
    id: 'text',
    name: 'Text',
  },
  {
    id: 'link',
    name: 'Link',
  },
  {
    id: 'phone',
    name: 'Phone',
  }
];

export default stepTypes;
