import React from 'react';
import {Field, FieldArray} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from "../../../../components/Buttons";
import styled from 'styled-components';
import Select from "../../../../components/Select";

const ClickToAddBtn = styled.div`
    cursor: pointer;
    padding:10px;
    color: rgb(10,10,180);
`;

const ClickToRemoveBtn = styled.div`
    cursor: pointer;
    padding:10px;
    width:20px;
`;

const DateBasedPricingWrapper = styled.div`
    margin-top:10px;
    margin-bottom:10px;
    border: 1px solid #ddd;
    border-radius:10px;
`;

const options = (current, index) => {

    let options = [
        {
            label: 'Price per day',
            value: 'Price per day'
        },
        {
            label: 'Price per weekend',
            value: 'Price per weekend'
        },
        {
            label: 'Price per week',
            value: 'Price per week'
        },
    ];

    for (let i = 0; i < current.length; i++) {
        if (i === index) continue;
        if (current[i] && current[i].priceSpan === 'Price per day') options.splice(options.findIndex(i => i.label === 'Price per day'),1);
        if (current[i] && current[i].priceSpan === 'Price per week') options.splice(options.findIndex(i => i.label === 'Price per week'),1);
        if (current[i] && current[i].priceSpan === 'Price per weekend') options.splice(options.findIndex(i => i.label === 'Price per weekend'),1);
    }

    return options;
}

const DateBasedPricing = ({fields, meta: {touched, error}, formValues, buttonText, arrayname, validator}) => {

    return (
        <div>


            {fields.map((item, index) => {
                let itemVals = formValues[arrayname][index];
                return (
                    <DateBasedPricingWrapper key={index}>
                        <ClickToRemoveBtn onClick={() => fields.remove(index)} >X</ClickToRemoveBtn>
                        <Flex py={1} px={1}>
                            <Box w={[1, 1]} pr={1}>
                                <Field
                                    name={`${item}.title`}
                                    component={CustomFieldInput}
                                    type="text"
                                    userCard
                                    label="Title"
                                    placeholder="Ex. Summer"
                                />
                            </Box>
                        </Flex>
                        <Flex py={1} px={1}>
                            <Box w={[1, 1 / 2]} pr={1}>
                                <Field
                                    name={`${item}.dateFrom`}
                                    component={CustomFieldInput}
                                    type="date"
                                    userCard
                                    label="Date From"
                                    validate={validator}
                                    dateRange={{
                                        max: itemVals && itemVals.dateTo ? itemVals.dateTo : null
                                    }}
                                />
                            </Box>

                            <Box w={[1, 1 / 2]} pl={1}>
                                <Field
                                    name={`${item}.dateTo`}
                                    component={CustomFieldInput}
                                    type="date"
                                    userCard
                                    label="Date To"
                                    validate={validator}
                                    dateRange={{
                                        min: itemVals && itemVals.dateFrom ? itemVals.dateFrom : null
                                    }}
                                />
                            </Box>
                        </Flex>
                        <FieldArray formValues={formValues[arrayname][index]} name={item+`.pricing`} arrayname={`pricing`} component={PriceSpan} />
                    </DateBasedPricingWrapper>
                )
            })}
            <SaveButton type={'button'} onClick={() => fields.push()}>Click to add a new period</SaveButton>
        </div>
    );
}


const PriceSpan = ({fields, meta: {touched, error}, formValues, buttonText, arrayname}) => {
    return (
        <div>
            {fields.map((item, index) => {
                return(
                    <div key={index}>
                        <Flex key={index} px={1}>
                            <Box w={[1, 2 / 4]} pr={1}>
                                <Field
                                    name={`${item}.priceSpan`}
                                    component={Select}
                                    options={options(formValues[arrayname], index)}
                                    placeholder='Select price range'
                                />
                            </Box>
                            <Box w={[1, 1 / 4]} pl={1}>
                                <Field
                                    name={`${item}.price`}
                                    component={CustomFieldInput}
                                    type="text"
                                    placeholder='In NOK'
                                    userCard
                                />
                            </Box>
                            <Box w={[1, 1 / 4]} pl={1}>
                                <ClickToRemoveBtn onClick={() => fields.remove(index)}>X</ClickToRemoveBtn>
                            </Box>
                        </Flex>
                    </div>
                )
            })}
            {fields.length < 3 && <ClickToAddBtn onClick={() => fields.push()}>Click to add pricing</ClickToAddBtn>}
        </div>
    )
}

export default DateBasedPricing
