import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import RemoveField from '../../../../components/RemoveField';
import {SaveButton} from "../../../../components/Buttons";
import styled from 'styled-components';
import FormInput from "../../../../components/Typografy/FormInput";

const ItemWrapper = styled.div`
    cursor: pointer;
    padding:5px;
    display: inline-block;
    margin: 5px;
    border: 1px solid gray;
    background-color:#eee;
    &:hover {
        background-color: ${props => props.positive ? 'rgba(20,200,20,0.2)' : 'rgba(200,20,20,0.2)'};
    }
`;

class ValueArray extends Component {

    constructor(props) {
        super(props);

        this.state = {
            temp: '',
        }

    }

    get suggestions() {

        const excludeExisting = (suggestion) => {
            if (this.props.formValues && this.props.formValues[this.props.arrayname]) {
                for (let i = 0; i < this.props.formValues[this.props.arrayname].length; i++) {
                    let field = this.props.formValues[this.props.arrayname][i];
                    if (field === suggestion) {
                        return false;
                    }
                }
            }
            return true;
        }

        return this.props.suggestions.filter(excludeExisting);
    }

    render() {
        const {fields, meta: {touched, error}, formValues, buttonText, arrayname} = this.props;
        return (
            <div>
                {fields.map((item, index) => {
                    return (
                        <ItemWrapper key={index} onClick={() => fields.remove(index)}>
                            {formValues && arrayname && formValues[arrayname][index]}
                        </ItemWrapper>
                    )
                })}
                <div>
                    <Flex>
                        <Box w={[1, 6 / 12]}>
                            <FormInput
                                value={this.state.temp}
                                onChange={(e) => this.setState({temp: e.target.value})}
                                style={{marginTop:20, height:30}}
                            />
                        </Box>
                        <Box w={[1, 6 / 12]} mt={2}>
                            <SaveButton type={'button'} onClick={(e) => {
                                fields.push(this.state.temp);
                                this.setState({
                                    temp: '',
                                })
                            }}>
                                {buttonText}
                            </SaveButton>
                        </Box>
                    </Flex>
                </div>
                <div>
                    Click item below to add
                </div>
                {this.suggestions.map((s, i) => {
                    return (
                        <ItemWrapper positive={true} key={i} onClick={() => fields.push(s)}>
                            {s}
                        </ItemWrapper>
                    )
                })}
            </div>
        );
    }
}


export default ValueArray
