/**
 * @flow
 */

import styled from 'styled-components';

const FormInputToolTip = styled.div`
  background: #454545;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  bottom: 0.5em;
  width: 100%;
  padding: 0.25em 0 0.25em 0.6em;
  text-align: left;
  color: #fff;
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 8%;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #454545;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

export default FormInputToolTip;
