
/**
 * @flow
 */
import styled from 'styled-components';
import { mx } from '../../utils';

const Wrapper = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  ${mx('lg')} {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;
export default Wrapper;
