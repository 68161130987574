/**
 * @flow
 */

import React, {Component} from 'react';
import {polyfill} from 'smoothscroll-polyfill';


import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'


class PrivacyPage extends Component<void, void, void> {


  render() {
    polyfill();
    return (
      <div>
        <StickyMenu />
        <MessageSection
          headlineText={'Policy för cookies och integritet'}
          full={
              <div className="text_holder">
                  <p><strong>Cookie och sekretesspolicy</strong></p>
                  <p><strong>Introduktion</strong><br/>
                      När du besöker vår webbplats samlas information om dig, som används för teknisk funktionalitet.
                      för användning av kontaktformulär. Trafikmätning, så vi kan se hur många som besöker vår webbplats
                      och vilka sidor som besöks. Om du inte vill samla information ska du ta bort dina cookies (<a
                      href="https://pcforalla.idg.se/2.1054/1.682569/cookies-browser">se instruktioner</a>) och avstå från vidare
                      användning av webbplatsen. Nedan har vi utarbetat vilken information som samlas in, deras syfte
                      och vilka tredje parter som har tillgång till dem.</p>
                  <p><strong> </strong></p>
                  <p><strong>Cookies</strong><br/>
                      Webbplatsen använder "cookies", som är en textfil som lagras på din dator, mobil eller mobil. På
                      samma sätt för att erkänna det, kom ihåg inställningar och utföra statistik. Det finns inga
                      personliga uppgifter lagrade i våra cookies och kan inte innehålla skadlig kod som. virus. Det är
                      möjligt att radera eller blockera cookies. Se instruktionerna: <a
                      href="https://pcforalla.idg.se/2.1054/1.682569/cookies-browser">Hantera cookies</a></p>
                  <p>Webbplatsen innehåller cookies från tredje part. Vi använder Google Analytics för att analysera hur
                      alla användare använder webbplatsen. Google samlade användningsinformation (trafikdata och
                      IP-adress), skickades till och sparades på Googles servrar. Vi beställer Google Analytics för att
                      bara använda en del av IP-adressen och inte hela adressen för geoplazing.</p>
                  <p><strong>Webbplatsen innehåller följande cookies<br/>
                      funktionalitet:<br/>
                  </strong>
                  Analytics<br/>
                      _utmv. Förfallodatum: 2 år<br/>
                      Den här cookien från Google används för att övervaka trafiken på vår webbplats och spåra vilken
                      kategori besökare du är.</p>
                  <p>_utmz. Utgångstid: 180 dagar<br/>
                      Den här cookien från Google används för att övervaka trafiken på vår webbplats och spåra var
                      besökaren kommer från (sökmotor, sökord, länk)</p>
                  <p>_utma. Förfallodatum: 2 år<br/>
                      Den här cookien från Google används för att övervaka trafiken på vår webbplats och spåra antalet
                      besök av varje besökare, tiden för det första besöket, det tidigare besöket och det aktuella
                      besöket.</p>
                  <p>_utmb. Utgångstid: Släckt efter utgången av sessionen<br/>
                      Den här kakan används för att spåra hur lång tid besökaren spenderar på en webbplacering: när ett
                      besök börjar och när det slutar. Om du observerar cookieändringar (till exempel med hjälp av en
                      brandkaka), kommer du att se regelbundna ändringar.</p>
                  <p><strong>Lagring av användar- och händelsedata<br/>
                  </strong>Lagringsperioden för data som är associerad med cookies, användar-id eller annonserings-ID är
                      så låg som möjligt av Google, i 14 månader. Användar-ID återställs efter varje ny aktivitet. Data
                      som är associerade med användarnamnet raderas automatiskt efter lagringsperioden.</p>
                  <p>Vi laddar inte upp data som gör det möjligt för Google att personligen identifiera en person (t.ex.
                      vissa namn, e-postadresser eller liknande data) eller data som permanent identifierar en viss
                      enhet (t.ex. En unik identifierare om en sådan identifierare kan inte återställas). Du kan välja
                      bort cookies från Google Analytics här: <a
                          href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a></p>
                  <p>Om du väljer att anmäla dig till vårt nyhetsbrev, skicka den e-postadress du skickar till oss till
                      Mailgun, som ger oss e-postmarknadsföringstjänster. Vi anser att Mailgun är en dataprocessor
                      från tredje part.</p>
                  <p>När du registrerar dig för nyhetsbrevet anger du din e-postadress och ditt namn. Vi använder endast
                      e-postadressen för att skicka nyhetsbrevet till dig och namnet för att personifiera innehållet.
                      Din e-postadress kommer att förbli i vår egen - och i Mailguns databasen tills du specifikt vill
                      ta bort från listan. Du kan göra detta genom att prenumerera med hjälp av unsubscribe länkar som
                      ingår i våra nyhetsbrev eller genom att begära borttagning via e-post.</p>
                  <p>Vi avslöjar inte din information till 3. man.</p>
                  <p><strong> </strong></p>
                  <p><strong>individuell information</strong></p>
                  <p><strong>Generelt</strong><br/>
                      Personlig information är någon form av information som till viss del kan tillskrivas dig. När vi
                      använder vår webbplats samlar vi in ​​och bearbetar en mängd sådana uppgifter. Detta händer till
                      exempel. av alm. Få tillgång till innehåll om du anmäler dig till vårt nyhetsbrev, registrera dig som användare, annars använda
                      tjänster eller göra inköp via webbplatsen.</p>
                  <p>Vi samlar och bearbetar vanligtvis följande typer av information: Sidorna du klickar på (intressen)
                      och, i den mån du uttryckligen samtycker till det och till och med anger informationen, behandlas:
                      Namn, telefonnummer, e-postadress, adress och betalningsinformation. Detta kommer normalt att vara
                      kopplat till skapandet av inloggning eller inköp.</p>
                  <p>Vi avslöjar inte din information till 3. man.</p>
                  <p><strong>Säkerhet</strong><br/>
                      Vi har vidtagit tekniska och organisatoriska åtgärder mot att din information är oavsiktligt eller
                      olagligt avslöjad, förlorad, förlorad eller kompromisslös, missbrukad eller på annat sätt
                      behandlad i strid med lagarna.</p>
                  <p><strong>Ändamål</strong><br/>
                      Informationen används för att identifiera dig som användare och visa vilka annonser som är mest
                      sannolikt relevanta för dig, för att registrera dina inköp och betalningar och för att
                      tillhandahålla de tjänster du har begärt, till exempel. att skicka ett nyhetsbrev. Dessutom
                      använder vi informationen för att optimera våra tjänster och innehåll.</p>
                  <p><strong>Lagringsperiod</strong><br/>
                      Informationen hålls för den lag som tillåts och vi tar bort dem när de inte längre är nödvändiga.
                      Perioden beror på informationens art och bakgrunden för lagring. Det är därför inte möjligt att
                      ange en allmän tidsram för när informationen raderas.</p>
                  <p><strong>Upplysande av information</strong><br/>
                      Data om din användning av webbplatsen, vilka annonser du får och om någon, klick, geografiskt läge
                      etc. uppges till tredje part i den mån denna information är känd. Du kan se vilka tredje parter
                      som finns i avsnittet "Cookies" ovan. Informationen används för att rikta annonsering.</p>
                  <p>Dessutom använder vi ett antal tredje parter för att lagra och bearbeta data. Dessa handlar endast
                      om information på vår vägnar och får inte användas för egna ändamål.</p>
                  <p><strong>Skydd av personuppgifter</strong><br/>
                      Enligt personuppgiftslagen måste dina personuppgifter hållas säkra och konfidentiella. Vi lagrar
                      din personliga information om begränsade datorer i kontrollerade lokaler och våra
                      säkerhetsåtgärder övervakas kontinuerligt för att avgöra om vår användarinformation hanteras
                      ordentligt och med vederbörlig hänsyn till dina rättigheter som användare. Vi kan dock inte
                      garantera 100% -säkerhet för dataöverföringar via Internet. Det innebär att det kan finnas risk
                      för att andra obehöriga personer får tillgång till information när data skickas och lagras
                      elektroniskt. Därmed avslöjar du din personliga information efter eget gottfinnande.</p>
                  <p>Personliga data raderas eller anonymiseras fortlöpande, eftersom syftet med vilket de samlades in
                      avslutas. Personlig information lagras inte mer än 12 månader efter användning.</p>
                  <p>Den snabba utvecklingen av Internet innebär att förändringar i vår behandling av personuppgifter
                      kan bli nödvändiga. Vi förbehåller oss därför rätten att uppdatera och ändra denna
                      sekretesspolicy. Vid betydande förändringar kommer vi att meddela dig i form av ett synligt
                      meddelande på våra webbplatser.</p>
                  <p><strong>Insikter och klagomål<br/>
                  </strong>I den utsträckning personuppgifter om dig behandlas har du rätt att avslöja vilken personlig
                      information som kan hänföras till dig enligt personuppgiftslagen. Om det verkar som om
                      informationen eller data som behandlas om dig är felaktig eller vilseledande, har du rätt att
                      kräva att dessa korrigeras, raderas eller blockeras. Du har rätt att veta vilken personlig
                      information vi behandlar om dig. Du kan också invända mot användningen av information när som
                      helst. Du kan också återkalla ditt samtycke till att behandla information om dig. Om informationen
                      som behandlas om dig är inkorrekt har du rätt att rätta eller ta bort.</p>
                  <p>Förfrågan kan göras till: <a href="mailto:info@coveredpro.com">info@coveredpro.com</a>. Om du skulle vilja
                      klaga på vår behandling av din personliga information, har du också möjlighet att kontakta <a
                          href="https://www.datainspektionen.se/">Datainspektionen</a>.</p>
              </div>
          }
        />
        <Footer />
      </div>);
  }
}

export default PrivacyPage;
