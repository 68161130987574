/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TMessageApiData } from '../../../../common/types/api/TMessage';
import type {TBenefitReportApiData} from "../../../../common/types/api/TBenefitReport";
import Link from '../../../components/Typografy/Link';

const MessagesCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;



const MessageInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const MessageTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const MessageText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 0.2em;
`;
const DateText = styled.div`
  font-size: 1.2em;
  color: #dcdcdc;
  display: flex;
  justify-content:flex-end;
  padding: 0.4em 0.4em 0.4em 0.4em;

`;

const BenefitReportEditButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  background-color:${theme.subHeader.buttonSecondColor};
  &:hover {
    text-decoration: none;
    background-color:white;
    color: ${theme.subHeader.buttonSecondColor};
  }
`;

type TProps = {
  report: TBenefitReportApiData;
  accountId: number;
};

const BenefitReportItem = (props: TProps) => {
  const { report, accountId } = props;

  return (
      <MessagesCard>
        <Flex wrap>
          <Box w={[1]}>
            <MessageInfoContainer>
              <MessageTitle>{report.date} - {report.fromBenefit.label} </MessageTitle>
              <MessageText>{report.serviceDescription} </MessageText>
              <MessageText>Original value: {report.originalValue} </MessageText>
              <MessageText>Discount %: {report.discountPercent} </MessageText>
              <MessageText>Discount NOK: {report.discountValue} </MessageText>
              <MessageText>Extra note: {report.comment} </MessageText>
              <BenefitReportEditButton to={`/admin/benefit-report/`+report.reportId+`/edit`}>Edit report</BenefitReportEditButton>
            </MessageInfoContainer>
          </Box>
        </Flex>
      </MessagesCard>
  );
};

export default BenefitReportItem;
