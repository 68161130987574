/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';

import styled from 'styled-components';

import 'cropperjs/dist/cropper.css';
import {FieldArray} from "redux-form";
import DateBasedPricing from "./DateBasedPricing";
import PeriodBasedPricing from "./PeriodBasedPricing";

const Title = styled.h2`
    margin:0;
    margin-bottom:10px;
    padding:0;
    font-size: 18px;
`;

const InfoBox = styled.p`
    
`;

class AdvancedPricing extends Component {

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.cabinFormValues.advancedPricing !== nextProps.cabinFormValues.advancedPricing;
    //
    // }


    validateDatePredefined = (value, inputFormValues) => {


        let formValues = inputFormValues.advancedPricing;

        let error = "";

        if (!value || value === "") return error;

        let item = 'periodbased';

        let checkDate = new Date(value);

        for (let i = 0; i < formValues[item].length; i++) {
            // if (item[i] === arrayname[inputIndex]) continue;
            if (!formValues[item][i] || !formValues[item][i].dateFrom || !formValues[item][i].dateTo) continue;
            if (formValues[item][i].dateFrom === "" || formValues[item][i].dateTo === "") continue;
            let from = new Date(formValues[item][i].dateFrom);
            let to = new Date(formValues[item][i].dateTo);
            if ((checkDate < to && checkDate > from)) {
                let conf = formValues[item][i].title ? ": " + formValues[item][i].title : "";
                console.log("Item", item);
                error = 'Date is in conflict' + conf;
                break;
            }
        }

        return error;

    }

    validateDatePeriod = (value, inputFormValues) => {


        let formValues = inputFormValues.advancedPricing;

        let error = "";

        let item = 'datebased';

        if (!value || value === "") return error;

        let checkDate = new Date(value);

        for (let i = 0; i < formValues[item].length; i++) {
            // if (item[i] === arrayname[inputIndex]) continue;
            if (!formValues[item][i] || !formValues[item][i].dateFrom || !formValues[item][i].dateTo) continue;
            if (formValues[item][i].dateFrom === "" || formValues[item][i].dateTo === "") continue;
            let from = new Date(formValues[item][i].dateFrom);
            let to = new Date(formValues[item][i].dateTo);
            if ((checkDate < to && checkDate > from)) {
                let conf = ": Nr." + parseInt(i+1) + " in Date Based Pricing";
                console.log("Item", item);
                error = 'Date is in conflict' + conf;
                break;
            }
        }
        return error;
    }


    render() {


        return (
            <div>
                <Flex>
                    <Box w={1}>
                        <Title>How to use advanced pricing</Title>
                        <InfoBox>
                            There are two options to set pricing.
                            First one is meant for a longer range, for instance: seasons.
                            To set this, simply click “Click to add a new period” in the left column below.
                            Firstly, you set the range (from and to dates).
                            After that, you can add pricing for per day, per week and per weekend.
                            The other option is predefined periods, for instance: holiday weekends.
                            This is set by clicking “Click to add a new period” in the right hand column.
                            Set the title, for instance: Easter weekend”, and then set the range and pricing.
                            The pricing is fixed, as this is meant for single weeks, weekends and such.
                        </InfoBox>
                        <InfoBox>
                            Beware of date collisions.
                            But also note that a date in Predefined Periods can override Date Based Periods.
                            Predefined Periods will always be prioritised.
                            Then Date Based Periods, and lastly default pricing.
                        </InfoBox>
                    </Box>
                </Flex>
                <Flex>
                    <Box w={[1, 1/2]} pr={1}>
                        <Title>Calendar based pricing</Title>
                        <FieldArray validator={this.validateDatePeriod} formValues={this.props.cabinFormValues[this.props.arrayname]} name={this.props.arrayname+`.datebased`} arrayname={`datebased`} component={DateBasedPricing} />
                    </Box>
                    <Box w={[1, 1/2]} pl={1}>
                        <Title>Predefined period pricing</Title>
                        <FieldArray validator={this.validateDatePredefined} formValues={this.props.cabinFormValues[this.props.arrayname]} name={this.props.arrayname+`.periodbased`} arrayname={`periodbased`} component={PeriodBasedPricing} />
                    </Box>
                </Flex>
            </div>
        );
    }
}

export default AdvancedPricing

