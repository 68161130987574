/**
 * @flow
 */

import type { TAction } from './types/TAction';
import type { THandler } from './types/THandler';

export default function createReducer<T>(initialState: T, handlers: THandler<T>) {
  return function reducer(state: T = initialState, action: TAction<*>) {
    return typeof handlers[action.type] === 'function'
      ? handlers[action.type](state, action)
      : state;
  };
}
