// SelectInput.js
import React from 'react';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import '../../../../components/SelectInput.css';
import styled from 'styled-components';
import theme from '../../../../theme';

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;

const CustomFieldSelectPeriod = (props) => {
    return (
        <div>
            <FormLabel>{props.label}</FormLabel>
            <Select
                {...props}
                value={props.input.value}
                onChange={(option) => props.input.onChange(option)}
                onBlur={() => props.input.onBlur(props.input.value)}
                options={props.options}
            />
            {props.meta.touched && props.meta.error &&
                <div>
                    Error: {props.meta.error}
                </div>
            }
        </div>
    );
};

const StyledCustomFieldSelectPeriod = styled(CustomFieldSelectPeriod)`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  padding-left: 0.6em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  &:focus{
    outline: none;
    background: none;
    border-bottom: ${props => props.userCard ?
    `1px solid ${`${theme.input.textColorUserCard}`}` :
    `1px solid ${`${theme.input.textColor}`}`}
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;

export default StyledCustomFieldSelectPeriod;
