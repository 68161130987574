/**
 * @flow
 */

/* eslint quote-props: 0 */

const getHeaders = (token?: string) => {
  const headers = {
    'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json',
  };
  return token ?
    { ...headers, 'Authorization': `Bearer ${token}` } :
    headers;
};

export default getHeaders;
