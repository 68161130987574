/**
 * @flow
 */

import styled from 'styled-components';

const CoveredProIcon = styled.i`
  font-family: "CoveredProIcons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  min-width: 1em;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default CoveredProIcon;
