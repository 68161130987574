/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TMessageApiData } from '../../../../common/types/api/TMessage';

const MessagesCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;



const MessageInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const MessageTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const MessageText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 0.2em;
`;
const DateText = styled.div`
  font-size: 1.2em;
  color: #dcdcdc;
  display: flex;
  justify-content:flex-end;
  padding: 0.4em 0.4em 0.4em 0.4em;

`;

type TProps = {
  message: TMessageApiData;
  accountId: number;
};

const MessageItem = (props: TProps) => {
  const { message, accountId } = props;

  return (
      <MessagesCard>
        <Flex wrap>
          <Box w={[1]}>
            <MessageInfoContainer>
              <MessageTitle>{message.text} / {message.headline} </MessageTitle>
              <MessageText>{message.description} </MessageText>
            </MessageInfoContainer>
          </Box>
          {message.date &&
          <Box w={[1]}>
              <DateText>Delivered: {message.date.hours}:{message.date.minutes}, {message.date.day}.{message.date.month}.{message.date.year}</DateText>
          </Box>}
        </Flex>
      </MessagesCard>
  );
};

export default MessageItem;
