/**
 * @flow
 */

import React from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../../../theme/index';
import Arrow from '../../../../../assets/images/arrow.png';

const ImageCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
  display:block;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  margin: 0.5vw;
  margin-left:auto;
  margin-right:auto;
  // width:60%;
`;

const CabinImage = styled.img`
  width: 100%;
  max-width:100px;
  cursor:pointer;
  box-sizing:border-box;
  padding:5px;
  transition: padding .2s;
  &: hover {
    padding:0;
    opacity: .9;
  }
`;

const CarouselBtn = styled.img`
    width: 100%;
    max-width:100px;
    cursor:pointer;
    box-sizing:border-box;
    transition: padding .2s;
    &: hover {
        padding:0;
        opacity: .9;
    }
`;

const CarouselBtnRight = styled(CarouselBtn)`
    transform: scaleX(-1);
`;

const CabinImageItem = (props) => <Box p={1} style={props.outerStyle}><CabinImage {...props} /></Box>

const ImageCarousel = (props) => {
    const {cabin, activeImageId, setNewActiveImage, setNewOffset, offset, limit} = props;

    return (
        <ImageCard>
            <Flex wrap>
                <Box w={[1, 1]}>
                    <ImageContainer>
                        <Flex justify='center'>
                            <Box p={1} align='center' justify='space-around'>
                                <CarouselBtn src={Arrow} alt="" onClick={() => setNewOffset(offset-1)}/>
                            </Box>
                            {cabin.images.map((image, id) => {

                                if (id >= offset && id < offset+limit) {
                                    return id === activeImageId ? (
                                            <CabinImageItem key={id} src={image.path} alt="" style={{padding: 0}}/>) :
                                        (<CabinImageItem onClick={() => setNewActiveImage(id)} key={id} src={image.path} alt=""/>);
                                } else {
                                    return <CabinImageItem outerStyle={{display:'none'}} key={id} src={image.path} alt=""/>
                                }
                            })}
                            <Box p={1}>
                                <CarouselBtnRight src={Arrow} alt="" onClick={() => setNewOffset(offset+1)}/>
                            </Box>
                        </Flex>
                    </ImageContainer>
                </Box>
            </Flex>
        </ImageCard>
    );
};

export default ImageCarousel;
