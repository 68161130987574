/**
 * @flow
 */

import React, {
  Component,
} from 'react';

import SubHeader from './SubHeader';
import BenefitReportForm from './BenefitReportForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
  buildBenefitReports, buildBenefits,
  buildBenefitsDraft,
  buildLanguages
} from '../../../../common/config/firebase_config/endpoints';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"
import {getAccounts, getBenefits, getCategories} from "../../Benefits/components/Retrievers";
import Spinner from "../../../components/Spinner";

type TProps = {
  profile: TProfileData;
}

class BenefitReportEdit extends Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildBenefitReports(this.props.accountId, this.props.userId),
      method: 'on',
      eventType: 'value',
      callback: this.props.onBenefitReportsLoadSuccess,
    }]);
  };

  _getBenefits = () => {

    let queries;

    queries = [
      {
        endpoint: buildBenefits(this.props.accountId),
        method: 'on',
        eventType: 'value',
        callback: this.props.onBenefitsLoadSuccess,
        metadata: this.props.accountId,
        config: this.props.accounts[this.props.accountId]
      },
      {
        endpoint: buildLanguages,
        method: 'on',
        eventType: 'value',
        callback: this.props.onLanguagesLoadSuccess,
      }
    ];

    return queries;

  };


  _getItem = () => {
    const id = this.props.match.params.id;
    return find(this.props.benefitReports || [], b => b.reportId === id)
  }

  _getForm = (benefitReport) => {
    let initials = benefitReport || {};
    return (
      <BenefitReportForm
        updateBenefitReport={this.props.updateBenefitReport}
        reportFormValues={this.props.reportFormValues}
        hasReport={benefitReport || false}
        report={benefitReport}
        accountId={this.props.accountId}
        history={this.props.history}
        initialValues={initials}
        userId={this.props.userId}
        benefits={this.props.benefits}
      />
    );
  };

  render() {
    const {profile} = this.props;

    console.log("props");
    console.log(this.props.location.fromBenefitData);



    // console.log(this.props);

    let benefitReport = this.props.benefitReports ? this._getItem() : null

    if (this.props.location.fromBenefitData) {
      const fromBenefitData = this.props.location.fromBenefitData;
      const today = new Date();
      const formattedDate = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
      benefitReport = {
        fromBenefit: {
          label: fromBenefitData.heading + " - " + fromBenefitData.provider,
          value: fromBenefitData.benefitId
        },
        date: formattedDate
      }
    }

    const hasReport = benefitReport ? true : false

    return (
      <div>
        <SubHeader hasReport={hasReport}/>
        <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
          <FirebaseContainer queries={this._getQueries()}>
            {this.props.accountsLoaded && <FirebaseContainer queries={getBenefits(this.props.accounts, this.props.accountId, this.props.onBenefitsLoadSuccess, false, false, this.props.onLanguagesLoadSuccess)}>
              <div>
                {this.props.benefitReports && this.props.benefitsLoaded ? this._getForm(benefitReport) : <Spinner/>}
              </div>
            </FirebaseContainer>}
          </FirebaseContainer>
        </FirebaseContainer>
      </div>
    );
  }
}

export default BenefitReportEdit;
