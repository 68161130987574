import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from "../../../../components/Buttons";
import styled from 'styled-components';
import CabinImageUploader from "./CabinImageUploader";
import {buildCabinImage} from "../../../../../common/config/firebase_config/endpoints";
import {SortableElement, SortableContainer, arrayMove, SortableHandle} from "react-sortable-hoc";
import ToggleSwitch from "../../../../components/ToggleSwitch";

const ImageItemWrapper = styled.div`
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    background-color: ${props => props.listId === 0 ? '#fafae0' : '#fafafa'};
    padding:5px;
    margin-top:10px;
    margin-bottom:10px;
`;

const ImageWrapper = styled.div`
    border:1px solid black;
    width:100%;
`;

const Image = styled.img`
    width:100%;
`;

const RemoveImgLink = styled.div`
    text-decoration:none;
    font-size:17px;
    color: #000;
    cursor: pointer;
`;

const DragWrapper = styled.div`
    cursor:pointer;
    font-size:60px;
`;

const DragHandle = SortableHandle(() => <DragWrapper>≡</DragWrapper>);

const SortableItem = SortableElement(({id, value, fields}) => (
    <ImageItemWrapper key={id} listId={id}>
        <Flex>
            <Box w={[1, 1/8]} align={'center'}>
                <DragHandle />
            </Box>
            <Box w={[1, 1/6]}>
                <ImageWrapper>
                    <Image src={value && value.path} alt={'test'}/>
                </ImageWrapper>
            </Box>
            <Box w={[1, 5/6]} px={1}>
                <Flex w={1} py={1} >
                    <Box>
                    </Box>
                    <Box ml='auto'>
                        <RemoveImgLink onClick={() => fields.remove(id)}>
                            ✖
                        </RemoveImgLink>
                    </Box>
                </Flex>
                <Flex w={1} pt={1}>
                    <Box w={[1, 1/3]}>
                        <Field
                            name={`images.${id}.isWinter`}
                            component={ToggleSwitch}
                            label="Winter"
                            type="checkbox"
                        />
                    </Box>
                    <Box w={[1, 1/3]}>
                        <Field
                            name={`images.${id}.isSummer`}
                            component={ToggleSwitch}
                            label="Summer"
                            type="checkbox"
                        />
                    </Box>
                    <Box w={[1, 1/3]}>
                        <Field
                            name={`images.${id}.isCommon`}
                            component={ToggleSwitch}
                            label="Inside"
                            type="checkbox"
                        />
                    </Box>
                </Flex>
            </Box>
        </Flex>
    </ImageItemWrapper>
));

const SortableList = SortableContainer(({items, fields}) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} id={index} fields={fields} index={index} value={value} />
            ))}
        </div>
    );
});

class SortableImages extends Component {

    onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) return;

        // Flyttes bakover
        if (newIndex > oldIndex) {
            for (let i = oldIndex; i < newIndex; i++) {
                this.props.fields.swap(i, i+1);
            }
        }
        if (newIndex < oldIndex) {
            for (let i = oldIndex; i > newIndex; i--) {
                this.props.fields.swap(i, i-1);
            }
        }
    };

    componentDidMount() {
        this.setState({
            items: this.props.formValues.images
        })
    }

    render() {
        return (
            <div>
                {this.props.formValues.images && <SortableList useDragHandle items={this.props.formValues.images} {...this.props} onSortEnd={this.onSortEnd} />}
                <Field
                    key={"uploadBtn"}
                    name="images"
                    pushvalues={(values) => this.props.fields.push(values)}
                    formValues={this.props.formValues}
                    component={CabinImageUploader}
                    uploadUrl={buildCabinImage(this.props.accountId, this.props.formValues.cabinId)}
                    showUrl={null}
                    headline={"test"}
                    minWidth={500}
                    minHeight={500}
                    aspectRatio={null}/>
            </div>
        )

    }
}

export default SortableImages
