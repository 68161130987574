/**
 * @flow
 */
import {firebaseDb} from '../config/firebase_config/firebase';
import {
  buildDomain,
  buildDomainAccount,
} from '../config/firebase_config/endpoints';

import history from '../utils/history'
import {NotificationManager} from 'react-notifications';

export const onDomainsLoadSuccess = payload => ({
  type: 'LOAD_DOMAINS_SUCCESS',
  payload,
});


function updateDomainRequest() {
  return {
    type: 'UPDATE_DOMAIN_REQUEST',
  };
}

function updateDomainSuccess() {
  return {
    type: 'UPDATE_DOMAIN_SUCCESS',
  };
}

function updateDomainError(json) {
  return {
    type: 'UPDATE_DOMAIN_ERROR',
  };
}

/* export const addAccountToDomain = (formValues) => async (dispatch, getState) => {
  try {
    dispatch(updateDomainRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};
    let storableData = JSON.parse(JSON.stringify(formValues));

    let domainKey = ''

    if (storableData) {
      domainKey = storableData.replace(/\./g, '|');
    }

    updates[buildDomainAccount(domainKey, accountId)] = true;

    console.log("update domain called")
    await firebaseDb.ref().update(updates);

    dispatch(updateDomainSuccess());
    NotificationManager.success('Success!', 'Account linked to domain!', 3000);
    history.push('/admin/domains')

  } catch (err) {
    console.log("update domain ERROR: "+ JSON.stringify(err))

    dispatch(updateDomainError());
  }
}; */

export const addAccountToDomain = (formValues) => async (dispatch, getState) => {
  try {
    dispatch(updateDomainRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let storableData = JSON.parse(JSON.stringify(formValues));
    let domainKey = ''

    if (storableData) {
      domainKey = storableData.replace(/\./g, '|');
    }

    const domainPath = buildDomain(domainKey);
    const domainExists = await checkDomainExists(domainPath);

    if (!domainExists) {
      let updates = {};
      updates[buildDomainAccount(domainKey, accountId)] = true;

      console.log("update domain called");
      await firebaseDb.ref().update(updates);

      dispatch(updateDomainSuccess());
      NotificationManager.success('Success!', 'Account linked to domain!', 3000);
      history.push('/admin/domains');
    } else {
      console.log("Domain already has a whitelist entry");
      NotificationManager.error('Ta kontakt for mer info: feedback@coveredpro.com', 'Dette domenet har allerede blitt lagt til på en annen konto!', 8000);
    }

  } catch (err) {
    console.log("update domain ERROR: " + JSON.stringify(err));
    console.log(err);

    dispatch(updateDomainError());
  }
};

// Helper function to check if the domain already exists in the database
const checkDomainExists = async (domainPath) => {
  if (domainPath.endsWith("coveredpro|com")) return false;
  const snapshot = await firebaseDb.ref(domainPath).once('value');
  return snapshot.exists();
};

export const removeAccountFromDomain = (domain) => async (dispatch, getState) => {
  try {
    dispatch(updateDomainRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    updates[buildDomainAccount(domain, accountId)] = null;

    await firebaseDb.ref().update(updates);

    dispatch(updateDomainSuccess());
    NotificationManager.success('Success!', 'Domain unlinked from account!', 3000);
    history.replace('/admin/domains');

  } catch (err) {
    dispatch(updateDomainError());
  }
};
