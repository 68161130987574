/**
 * @flow
 */

import styled from 'styled-components';

const LoginButton = styled.button`
  //text-transform: uppercase;
    padding: 0.8em 1em;
    color: #fff;
    border-radius: 1.5em;
    margin-bottom: 1em;
    background: #f41a4d;
    border: none;
    width: 100%;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    ${props => props.disabled && 'background: #d68a9c;'}
`;

export default LoginButton;
