/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
  buildBenefits,
} from '../../../../common/config/firebase_config/endpoints';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import BenefitItem from './BenefitItem';
import Spinner from '../../../components/Spinner'
import styled from 'styled-components';

import {getBenefits} from '../components/Retrievers'
import {
    Flex,
    Box,
} from 'reflexbox';

type TProps = {
    accountId: number;
    benefits: Array<TBenefitApiData>;
    onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
    isArchive: boolean;
    isDraft: boolean;
};

const ListWrapper = styled.div`
    width:100%;
`;

class BenefitList extends React.Component<void, TProps, void> {
    _getQueries = () => {
        let queries = [{
            endpoint: buildBenefits(this.props.accountId),
            method: 'on',
            eventType: 'value',
            callback: this.props.onBenefitsLoadSuccess,
            metadata: this.props.accountId,
            config: this.props.accounts
        },
        ]

        if (this.props.isDraft || this.props.isArchive) return queries;

        const account = this.props.accounts[this.props.accountId]
        if (account.vendors && account.vendors.length > 0 ) {
            account.vendors.forEach((vendor) => {
                if (vendor && vendor.type && vendor.type.value) {
                    queries.push({
                        endpoint: buildBenefits(vendor.type.value),
                        method: 'on',
                        eventType: 'value',
                        callback: this.props.onBenefitsLoadSuccess,
                        metadata: vendor.type.value,
                        config: this.props.accounts
                    })
                }
            })
        }


        return queries;
    };
    _getItems = () => {
        return this.props.benefits.map((benefit, i) => {
            return (
                    <Box w={1/5} p={1} column key={i}>
                        <BenefitItem key={i} benefit={benefit} accountId={this.props.accountId} accounts={this.props.accounts} isArchive={this.props.isArchive} isDraft={this.props.isDraft}/>
                    </Box>
            );
        });
    };

    render() {
        if (!this.props.accountId) {
            return (
                <div>
                    <h1>LASTER KONTO...</h1>
                </div>)
        }
        const {accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft} = this.props
        return (
            <FirebaseContainer queries={getBenefits(accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft)}>
                <ListWrapper>

                    <Flex
                        justify='center'
                        wrap
                        w={1}
                        mt={2}
                        px={3}>
                    {this.props.benefits ? this._getItems() : this.props.isLoaded ? <h3>No benefits found!</h3> :
                        <Spinner/>}
                    </Flex>
                </ListWrapper>
            </FirebaseContainer>
        );
    }
}

export default BenefitList;
