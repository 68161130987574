/**
 * @flow
 */
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import BenefitEdit from './BenefitEdit';
import type { TAppState } from '../../../../common/TAppState';
import { onBenefitsLoadSuccess, updateBenefit, updateDraftBenefit } from '../../../../common/benefits/actions/index';
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions/index";
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {benefitDataTemplate} from "../../../../common/benefits/actions";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";

const BenefitEditContainer = connect(
  (state: TAppState, dispatch) => {
    return {
        languages: state.languages.data,
      accountId: state.auth.profile.data.accountId,
      benefits: state.benefits.data.all || [] ,
      benefitsLoaded:  state.benefits.isLoaded,
      categories: state.categories.list,
        accountsLoaded: state.accounts.isLoaded,
        accounts: state.accounts.data,
        accountsList: state.accounts.list,
      benefitFormValues: getFormValues('BenefitForm')(state),
        profile: state.auth.profile.data,
    };
  },
  (dispatch) => ({
      onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
      onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
      onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
      onCategoriesLoadSuccess: (data, metadata, account) => dispatch(onCategoriesLoadSuccess(data, metadata, account)),
    updateBenefit: (data, benefit) => dispatch(updateBenefit(data, benefit)),
    updateDraftBenefit: (data, benefit, disabled=false, newBenefit=false) => dispatch(updateDraftBenefit(data, benefit, disabled, newBenefit)),
  })
)(BenefitEdit);

export default BenefitEditContainer;
