/**
 * @flow
 */

import React, {
    Component,
} from 'react';
import StickyMenu from './components/StickyMenu'
import MessageSection from '../VerifyAppleToken/components/MessageSection'
import Footer from './components/Footer'
import parseSearch from "../../../common/utils/parseSearch";
import styled from "styled-components";
import Spinner from "../../components/Spinner";

let parsed = "";
let headline = "Henter din nedlastningslenke";
let subHeadline = "Vennligst vent";
let subHeadLine2 = "";

type TProps = {
    getAppCodeAndroid: (data) => void;
    accountId: number
}

const DownloadLink = styled.a`
  color: #ffffff;
  font-size: 1em;
  margin: auto auto 0.5em 0.5em;
  border-radius: 50px;
  text-decoration:none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f41a4d;
  display:block;
  
  &:hover, &:focus, &:active {
    opacity:0.8;
  }
`;

class VerifyAndroid extends Component<void, TProps, void> {

    constructor(props) {
        super(props);

        this.state = {
            cpb: false
        }
    }

    componentDidMount = () => {
        parsed = parseSearch();
        if ( parsed.accountId === undefined ) {
            subHeadline = "En feil oppstod: Ugyldig lenke";
            subHeadLine2 = "Prøv lenken en gang til, hvis samme melding vises, kontakt: hjelp@coveredpro.com";
        }
        else {
            if (parsed.commonApp) {
                console.log("common")
                this.setState({
                    cpb:true
                });
                this.props.getAppCodeAndroidCommon();
            }
            else {
                this.props.getAppCodeAndroid(parsed.accountId);
            }
        }
    };

    renderContent = () => {
        return (
            <div>
                <StickyMenu/>
                <MessageSection
                    headlineText={headline}
                    subheadlineText={subHeadline}
                    subheadline2Text={subHeadLine2}
                />
                <Footer/>
            </div>
        )
    };

    buildButton = (param) => {
        return (
            <div>
              <DownloadLink href={param.link}>
                Last ned
              </DownloadLink>
              <em style={{fontSize: 15}}>
                  Dette steget må gjøres fra din android mobil.
              </em>
            </div>
        )

    };

    render() {

        const {token} = this.props;

        if (token && token.error) {
            headline = "Error";
            subHeadline = "En feil oppstod";
            subHeadLine2 = "Prøv lenken en gang til, hvis samme melding vises, kontakt: hjelp@coveredpro.com";
        }
        if (this.state.cpb) {
            headline = "Klikk 'Last ned' å laste ned appen"
            subHeadline = "";
            subHeadLine2 = this.buildButton(token);
        }
        else if (token && token.link) {
            headline = "Din lenke er klar, klikk under for å laste ned appen"
            subHeadline = "";
            /*subHeadLine2 = token.link;*/
            subHeadLine2 = this.buildButton(token);
        }

        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }
}

export default VerifyAndroid;
