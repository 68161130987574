/**
 * @flow
 */

import styled from 'styled-components';
import { mx } from '../../utils';

const FormWrapper = styled.div`
  padding-left: 2.5em;
  padding-right: 2.5em;
  margin: 0 auto;
  padding-top: 3em;
  text-align: center;
  ${mx('lg')} {
   max-width: 24em;
   padding-top: 3em;
   padding-left: 0;
   padding-right: 0;
  }
`;

export default FormWrapper;
