
const enMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

export const enDate = (input, excludeYear = false) => {
    let date = new Date(input);

    return date.getDate() + '. ' + enMonths[date.getMonth()] + (excludeYear ? '' : ' ' + date.getFullYear());

}

export const todayHtmlFormat = () => {
    let d = new Date();
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = '' + d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
}

export const enDateFromTo = (dateFrom, dateTo, excludeYear = false) => {
    let fromString = enDate(dateFrom, excludeYear);
    let toString = enDate(dateTo, excludeYear)
    return fromString + ' to ' + toString;

}

export const getNumDays = (dateFrom, dateTo) => {
    let dateFromObj = new Date(dateFrom);
    let dateToObj = new Date(dateTo);

    let msBetween = dateToObj.getTime() - dateFromObj.getTime();
    return (msBetween / (1000*60*60*24)) + 1;
}

export const addDaysToDate = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const isWeekendInRange = (dateFrom, dateTo) => {
    let hasFri, hasSat, hasSun = false;

    let msBetween = dateTo.getTime() - dateFrom.getTime();
    let numDays = (msBetween / (1000*60*60*24)) + 1;
    for (let i = 0; i < numDays; i++) {
        let checkDate = addDaysToDate(dateFrom,i);
        let checkDay = checkDate.getDay();
        if (checkDay === 5) hasFri = true;
        if (checkDay === 6) hasSat = true;
        if (checkDay === 0) hasSun = true;
    }
    return hasFri && hasSat && hasSun;

}
