/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import BenefitReportList from './BenefitReportList';
import SubHeader from './SubHeader';
import type {
  TBenefitReportApiData,
  TBenefitReportResponse
} from "../../../../common/types/api/TBenefitReport";


const MessagesWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const MessageBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png').default});
   margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  benefitReports: TBenefitReportApiData;
  onBenefitReportsLoadSuccess: (data: TBenefitReportResponse) => void;
};

class BenefitReportPage extends React.Component<void, TProps, TState> {
  render() {
    return (
      <MessagesWrap>
        <MessageBackground>
          <SubHeader />
        </MessageBackground>
        <Wrapper>
          <BenefitReportList
            accountId={this.props.accountId}
            benefitReports={this.props.benefitReports}
            onBenefitReportsLoadSuccess={this.props.onBenefitReportsLoadSuccess}
            userId={this.props.userId}
          />
        </Wrapper>
      </MessagesWrap>
    );
  }
}

export default BenefitReportPage;
