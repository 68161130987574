/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import FileUploader from './components/FileUploader'
import Wrapper from '../../../components/Typografy/Wrapper';

import {
    Flex,
    Box,
} from 'reflexbox';

import {Container} from '../../../components/Elements'


type TProps = {
    profile: TProfileData;
}

class UpdateGoogleServices extends Component<void, TProps, void> {

    render() {
        return (
            <div>
                <SubHeader/>
                <Container>
                    <Wrapper>
                        <Flex wrap>
                            <FileUploader update={this.props.updateGoogleServices}/>
                        </Flex>
                    </Wrapper>
                </Container>
            </div>
        );
    }
}

export default UpdateGoogleServices;
