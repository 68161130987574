/**
 * @flow
 */

import React, {Component} from 'react';
import {polyfill} from 'smoothscroll-polyfill';
import styled from 'styled-components';


import StickyMenu from './components/StickyMenu';
import MessageSection from './components/MessageSection';
import Footer from '../Verify/VerifyConfirmation/components/Footer';
import FirebaseContainer from "../../../common/firebase/FirebaseContainer";
import {buildFaqContentGeneral} from "../../../common/config/firebase_config/endpoints";
import SupportQASection from "./components/SupportQASection";
import Spinner from "../../components/Spinner";

const texts = {
    headline: 'Hjelp',
}


const ContentWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

type TProps = {
  faq: [],
  onLoadFaqSuccess: (data) => void;
};

class SupportPage extends Component<void, TProps, void> {

    componentDidMount() {
        this.props.loadHelp();
    }

    renderContent = () => {
      return(
          <ContentWrapper dangerouslySetInnerHTML={{__html: this.props.helpData.data}}>

            {/*<SupportQASection*/}
              {/*key={this.props.faq[1] ? this.props.faq[1].title : "1"}*/}
              {/*content={this.props.faq[1] ? this.props.faq[1] : "null"}*/}
            {/*/>*/}
            {/*<iframe src="https://player.vimeo.com/video/296009560?title=0&byline=0&portrait=0" width="320" height="570"*/}
                    {/*frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>*/}
            {/*<SupportQASection*/}
              {/*key={this.props.faq[0] ? this.props.faq[0].title : "2"}*/}
              {/*content={this.props.faq[0] ? this.props.faq[0] : "null"}*/}
            {/*/>*/}
            {/*<div style={{padding: "56.25% 0 0 0", position: "relative"}}>*/}
              {/*<iframe src="https://player.vimeo.com/video/291860736?byline=0&portrait=0"*/}
                      {/*style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", }} frameBorder={"0"}*/}
                      {/*webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>*/}
            {/*</div>*/}
            {/*<script src={"https://player.vimeo.com/api/player.js"}></script>*/}
          </ContentWrapper>
      )
    };

    render() {
        polyfill();
        return (
            <div>
                <StickyMenu />
                {this.props.loaded ? <MessageSection
                    headlineText={texts.headline}
                    full={this.renderContent()}
                /> : <Spinner/>}
                <Footer/>
            </div>);
    }
}

export default SupportPage;
