/**
 * @flow
 */

import createReducer from '../createReducer';
import getHeaders from "../config/auth/headers";
import auth0data from "../config/auth/auth0";
import {firebaseDb} from "../config/firebase_config";
import {buildUserData} from "../config/firebase_config/endpoints";


const initialState = {
  data: {},
  list: [],
  tokens: {},
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_BENEFIT_REPORTS_SUCCESS: (state, action) => {
        console.log('LOAD_BENEFIT_REPORTS_SUCCESS')
        const data = action.payload
        const reports = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const report = {...data[key], reportId: key}
                reports[key] = report
                list.push(report);
            });

        }
        list.reverse()
        return {...state, error: null, isLoaded: true, data: reports, list: list, tokens: state.tokens};
    },
    LOAD_ALL_BENEFIT_REPORTS_SUCCESS: (state, action) => {
        console.log('LOAD_ALL_BENEFIT_REPORTS_SUCCESS')
        const data = action.payload
        const reports = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
				if (data[key]?.all) {
	                Object.keys(data[key].all).forEach((reportKey) => {
	                    const report = {...data[key].all[reportKey], reportId: reportKey}
	                    reports[reportKey] = report
	                    list.push(report);
	                });
				}
            });

        }
        list.reverse()
        return {...state, error: null, isLoaded: true, allData: reports, allList: list, tokens: state.tokens};
    }
}


export default createReducer(initialState, handlers);
