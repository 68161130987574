/**
 * @flow
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from 'reflexbox';

import SignUpFormEmployee from './SignUpFormEmployee';
import SignUpFormNotEmployee from './SignUpFormNotEmployee';

import type { TSignUpState, TSignInState } from '../../../common/auth/TAuthState';
import type { TSignUpData } from '../../../common/types/models/TAuth';

const ToggleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  text-decoration: none;
  //text-transform: uppercase;
  overflow: hidden;
  color: #929292;
  border-radius: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  border:2px solid #929292;
`;

const TabBox = (props) => {
  const { isSelected, isFieldDisabled, ...rest } = props; // eslint-disable-line
  return <Box{...rest} />;
};

const UserTypeBox = styled(TabBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border-right: 2px solid #929292;
  opacity: 0.2;
  ${props => props.isSelected && 'color: white; background-color: #929292;'}
  ${props => !props.isFieldDisabled && `
    opacity: 1;
    &:hover {
      text-decoration: none;
      color: white;
      background-color: #929292;
      cursor: pointer;
    }`
}
  &:last-of-type {
    border-right: 0px;
  }
`;

const FormContainer = styled.div`
   border-radius: 1.5em solid #929292;
   color: #929292;
`;

const userRoles = {
  employee: 'employee',
  company: 'company',
  vendor: 'vendor',
};

type TState = {
  userRole: string;
}

type TProps = {
  signUp: TSignUpState,
  signIn: TSignInState;
  signUpAccount: (data: TSignUpData) => void;
}

class UserToggle extends Component<void, TProps, TState> {

  state: TState = {
    userRole: userRoles.employee,
    userDisabled: true,
  };

  toggleEmployee = () => {
    this.setState({
      userRole: userRoles.employee,
    });
  }

  toggleCompany = () => {
    this.setState({
      userRole: userRoles.company,
    });
  }

  toggleVendor = () => {
    this.setState({
      userRole: userRoles.vendor,
    });
  }

  render() {
    return (
      <FormContainer>
        <ToggleWrapper col={12}>
          <UserTypeBox
            isSelected={this.state.userRole === userRoles.employee}
            onClick={this.toggleEmployee}
            col={4}
          >EMPLOYEE</UserTypeBox>
          <UserTypeBox
            isSelected={this.state.userRole === userRoles.company}
            onClick={!this.state.userDisabled && this.toggleCompany}
            col={4}
            isFieldDisabled
          >COMPANY</UserTypeBox>
          <UserTypeBox
            isSelected={this.state.userRole === userRoles.vendor}
            onClick={!this.state.userDisabled && this.toggleVendor}
            col={4}
            isFieldDisabled
          >VENDOR</UserTypeBox>
        </ToggleWrapper>
        {this.state.userRole === userRoles.employee &&
          <SignUpFormEmployee
            signIn={this.props.signIn}
            signUp={this.props.signUp}
            signUpAccount={this.props.signUpAccount}
          />
        }
        {this.state.userRole === userRoles.company &&
          <SignUpFormNotEmployee
            signIn={this.props.signIn}
            signUp={this.props.signUp}
            signUpAccount={this.props.signUpAccount}
          />
        }
        {this.state.userRole === userRoles.vendor &&
          <SignUpFormNotEmployee
            signIn={this.props.signIn}
            signUp={this.props.signUp}
            signUpAccount={this.props.signUpAccount}
          />
        }
      </FormContainer>
    );
  }
}


export default UserToggle;
