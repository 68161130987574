import getHeaders from "../auth/headers";
import {functionsConfig} from './config'

const delegate = async (accessToken, profile) => {

  console.log('Running DELEGATION!')
  const sender = {
    method: 'POST',
    headers: getHeaders(accessToken),
    body: JSON.stringify({
      data: profile.userId
    }),
  }
  console.log("..with: " + JSON.stringify(sender))
  return fetch(functionsConfig.delegationUrl, sender);


}
export default delegate
