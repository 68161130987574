/**
 * @flow
 */

import React from 'react';
import StatsItem from './StatsItem';

import Spinner from '../../../components/Spinner'
import styled from 'styled-components';

const ListWrapper = styled.div`
  padding: 30px;
  background-color:#cacaca;
  margin-bottom: 25px;
  border-radius: 20px;
  margin-left:15px;
  margin-right:15px;
`;


class AllUnverifiedList extends React.Component<void, TProps, void> {

  _getItems = (unverified) => {

    console.log(unverified);

    let items = []

    if (unverified.allunverified && unverified.allunverified_users.length > 0) items.push(<StatsItem
      key={'unverified-' + this.props.accountId} stat={{data: unverified.allunverified, title: 'Unverified users'}}
      list={unverified.allunverified_users} accountId={this.props.accountId}/>)

    return items

  };

  render() {
    return (
      <ListWrapper>
        {this.props.unverified ? this._getItems(this.props.unverified) :
          <Spinner/>}
      </ListWrapper>
    );
  }
}

export default AllUnverifiedList;
