/**
 * @flow
 */


import { connect } from 'react-redux';
import BenefitReportPage from './BenefitReportPage';
import type { TAppState } from '../../../../common/TAppState';
import {onBenefitReportsLoadSuccess} from "../../../../common/benefitreports/actions";

const BenefitReportContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     benefitReports: state.benefitReports.list,
     userId: state.auth.profile.data.userId
   };
 },
 (dispatch) => ({
   onBenefitReportsLoadSuccess: (data) => dispatch(onBenefitReportsLoadSuccess(data)),
 }),
)(BenefitReportPage);
export default BenefitReportContainer;
