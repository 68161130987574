/**
 * @flow
 */

import createReducer from '../createReducer';

import initialValues from './initialValues'

const initialState = {
    data: {},
    list: [],
    error: null,
    isLoaded: false,
    token: {
        loading: false,
        error: null,
        link: null
    },
    tokens: {loading: false, data: null},
    toggler: {}
};


const mergeInitiaValues = (account) => {
    //loop objects in initialvalues.
    Object.keys(initialValues).forEach(key => {
        if(!account[key]){
            account[key] = initialValues[key]
        }

    })

    return account
}


const handlers = {
    LOAD_ACCOUNTS_SUCCESS: (state, action) => {
        console.log('LOAD_ACCOUNTS_SUCCESS')
        const data = action.payload
        const accounts = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                let account = {
                    ...data[key], 
                    accountId: key,
                    includedAccountsPrepared: data[key].includedAccounts ? Object.keys(data[key].includedAccounts).map(x => ({accountId: x})) : []
                }
                accounts[key] = mergeInitiaValues(account)
                list.push(account);
            });

        }

        return {...state, error: null, isLoaded: true, data: accounts, list: list, tokens: state.tokens};
    },
    APPLE_TOKEN_REQUEST: (state, action) => {
        return {...state, token: {loading: true, error: null}};
    },
    APPLE_TOKEN_SUCCESS: (state, action) => {
        console.log('APPLE_TOKEN_SUCCESS')
        const data = action.payload

        if (data) {

            if (data.result === 'success') {
                const token = data.message

                let link = token.link
                if(typeof link !== 'string' && link['link']){
                    link = link.link
                }

                return {...state, token: {loading: token.loading, error: token.error, link: link}};

            }
            else {
                return {...state, token: {loading: false, error: data.message, link: null}};

            }

        }

        return state;
    },
    APPLE_TOKEN_FAILED: (state, action) => {
        console.log('APPLE_TOKEN_FAILED')
        const data = action.payload
        return {...state, token: {loading: false, error: data, link: null}};
    },


    APPLE_ALL_TOKENS_REQUEST: (state, action) => {
        return {...state, tokens: {loading: true, error: null}};
    },
    APPLE_ALL_TOKENS_SUCCESS: (state, action) => {
        console.log('APPLE_ALL_TOKENS_SUCCESS')
        const data = action.payload

        if (data) {

            let summary = state.tokens.data || {}

            if (!data.accountId) {
                summary = data.data
            }
            else {
                summary[data.accountId] = data.data
            }
            return {...state, tokens: {loading: false, data: summary}};
        }

        return state;
    },
    APPLE_ALL_TOKENS_FAILED: (state, action) => {
        console.log('APPLE_ALL_TOKENS_FAILED')
        return {...state, tokens: {loading: false, data: state.tokens.data}};
    },


    ANDROID_TOKEN_SUCCESS: (state, action) => {
        console.log('ANDROID_TOKEN_SUCCESS')
        const data = action.payload

        if (data) {

            return {...state, token: {loading: false, error: null, link: data}};

        }

        return state;
    },
    ANDROID_TOKEN_FAIL: (state, action) => {
        console.log('APPLE_TOKEN_FAILED')
        const data = action.payload
        return {...state, token: {loading: false, error: data, link: null}};
    },
    TOGGLER_UPDATED: (state, action) => {
        console.log('TOGGLER_UPDATED')
        const data = action.payload
        return {...state, toggler: {...state.toggler, [data.key]:data.value}};
    },

}


export default createReducer(initialState, handlers);
