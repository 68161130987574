/**
 * @flow
 */

let firebaseConfig = {};

if (process.env.REACT_APP_ENV === 'development') {
    // Dev config
    firebaseConfig = {
      apiKey: "AIzaSyBlmWtkSyW7TPYSqA-OP-zvbGfHQaSDoz8",
      authDomain: "coveredpro-dev.firebaseapp.com",
      databaseURL: "https://coveredpro-dev.firebaseio.com",
      projectId: "coveredpro-dev",
      storageBucket: "coveredpro-dev",
      messagingSenderId: "115088359213"
    };
} else {
    // Prod config
    firebaseConfig = {
      apiKey: 'AIzaSyAIBruVaxyqe4a2nvbI3z8B9ZM3T95awus',
      authDomain: 'my-coveredpro.firebaseapp.com',
      databaseURL: 'https://my-coveredpro.firebaseio.com',
      projectId: "my-coveredpro",
      storageBucket: 'coveredpro',
      messagingSenderId: '699517782130',
    };
}

export const functionsConfig = process.env.REACT_APP_ENV === 'development' ? {
  delegationUrl: 'https://us-central1-coveredpro-dev.cloudfunctions.net/delegate',
    appleTokenUrl: 'https://us-central1-coveredpro-dev.cloudfunctions.net/appleToken'
} : {
  delegationUrl: 'https://us-central1-my-coveredpro.cloudfunctions.net/delegate',
    appleTokenUrl: 'https://us-central1-my-coveredpro.cloudfunctions.net/appleToken'
};


export default firebaseConfig;
