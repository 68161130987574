/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import FormInputToolTip from '../../../../components/Typografy/FormInputToolTip';

type TMeta = {
    touched: boolean;
    error: string;
};

type TInput = {
    onChange: (text: string) => void;
    value: string;
};

type TProps = {
    input: TInput;
    type: string;
    placeholder: string;
    disabled?: boolean;
    meta: TMeta;
    userCard: boolean;
    label?: string;
    value: string;
};



const FormInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  padding: 0.5em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  ${props => props.borderLeft && 'border-left: 1px solid #aaa'}
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  ${props => props.userCard && 'line-height: 26px'};
  &:focus{
    outline: none;
    background: none;
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;

const CustomFieldDate = (props: TProps) => {
    const {
        input,
        disabled,
        type,
        placeholder,
        meta,
        userCard,
        label,
        inject,
        dateRange,
        borderLeft
    } = props;

    if (inject && input && input.onChange) {
        input.onChange(inject)
    }

    return (
        <div>
            <FormInput
                {...input}
                type={type}
                withError={meta && meta.touched && meta.error}
                placeholder={placeholder}
                disabled={disabled}
                userCard={userCard}
                borderLeft={borderLeft}
                {...dateRange}
            />
            {meta && meta.touched && (meta.error &&
                <FormInputToolTip>
                    {meta.error}
                </FormInputToolTip>
            )}
        </div>
    );
};

export default CustomFieldDate;
