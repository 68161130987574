/**
 * @flow
 */

import React, {
  Component,
} from 'react';
import StickyMenu from './components/StickyMenu'
import MessageSection from './components/MessageSection'
import Footer from './components/Footer'
import parseSearch from "../../../../common/utils/parseSearch";



class VerifyConfirmation extends Component<void, TProps, void> {


  render() {
      const parsed = parseSearch()


    const successTexts = {
      headline: 'Velkommen til CoveredPro!',
      subheadline: 'Din konto er nå verifisert, og du kan nå logge inn.',
      subheadline2: '- med vennlig hilsen, CoveredPro-teamet.',

    }
    const alreadyVerifiedTexts = {
      headline: 'Allerede verifisert!',
      subheadline: 'Din konto er allerede verifisert - det er bare å logge inn.',
      subheadline2: '- med vennlig hilsen, CoveredPro-teamet.',

    }
    const errorTexts = {
      headline: 'Det skjedde en feil!',
      subheadline: 'Denne verifiseringslenken er ugyldig. Har du ventet for lenge med å aktivere?',
      subheadline2: '- med vennlig hilsen, CoveredPro-teamet.',

    }


    let texts = {}

    if(parsed.success === 'true')
    {
      texts = successTexts;
    }
    else if(parsed.message.indexOf('already') >= 0 && parsed.message.indexOf('verified') >= 0)
    {
      texts = alreadyVerifiedTexts;
    }
    else{
      texts = errorTexts;
    }

    return (
      <div>
        <StickyMenu />
        <MessageSection
          headlineText={texts.headline}
          subheadlineText={texts.subheadline}
          subheadline2Text={texts.subheadline2}
        />
        <Footer />
      </div>
    );
  }
}

export default VerifyConfirmation;
