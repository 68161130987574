/**
 * @flow
 */

 // Eslint has problems with destructuring
 /* eslint react/no-unused-prop-types: 0 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import FormTextarea from './FormTextarea';
import FormInputToolTip from './FormInputToolTip';

type TMeta = {
  touched: boolean;
  error: string;
};

type TInput = {
  onChange: (text: string) => void;
  value: string;
};

type TProps = {
  input: TInput;
  type: string;
  placeholder: string;
  disabled?: boolean;
  meta: TMeta;
  userCard: boolean;
  label?: string;
  value: string;
};

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;

const CustomFieldInput = (props: TProps) => {
  const {
    input,
    disabled,
    type,
    placeholder,
    meta,
    userCard,
    label,
    rows = 3,
      inject
  } = props;


    if (inject && input && input.onChange) {
        input.onChange(inject)
    }

  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <FormTextarea
        {...input}
        type={type}
        withError={meta.touched && meta.error}
        placeholder={placeholder}
        disabled={disabled}
        userCard={userCard}
        rows={rows}
      />
      {meta.touched && (meta.error &&
      <FormInputToolTip>
        {meta.error}
      </FormInputToolTip>
    )}
    </div>
  );
};

export default CustomFieldInput;
