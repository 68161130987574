/**
 * @flow
 */

import styled from 'styled-components';

const H2 = styled.h2`
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
`;

export default H2;
