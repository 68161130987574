/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import VerifyAppleToken from './VerifyAppleToken'
import {
    getAppCode, getAppCodeAppleCommon, getAppCodeAppleNotEnterprise,
} from "../../../common/accounts/actions";

const VerifyAppleTokenContainer = connect(
  (state: TAppState) => ({
    token: state.accounts.token

  }),
  (dispatch) => ({
    getAppCode: (accountId: number) => dispatch(getAppCode(accountId)),
    getAppCodeAppleNotEnterprise: (accountId: number) => dispatch(getAppCodeAppleNotEnterprise(accountId)),
    getAppCodeAppleCommon: () => dispatch(getAppCodeAppleCommon())
  }),
)(VerifyAppleToken);


export default VerifyAppleTokenContainer;
