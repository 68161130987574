/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import styled from "styled-components";
import theme from "../../../../theme";

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;
const FormInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  padding-left: 0.6em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  ${props => props.userCard && 'line-height: 26px'};
  &:focus{
    outline: none;
    background: none;
    border-bottom: ${props => props.userCard ?
    `1px solid ${`${theme.input.textColorUserCard}`}` :
    `1px solid ${`${theme.input.textColor}`}`}
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;


const StyledSelectInput = styled.div`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  padding-left: 0.6em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  &:focus{
    outline: none;
    background: none;
    border-bottom: ${props => props.userCard ?
    `1px solid ${`${theme.input.textColorUserCard}`}` :
    `1px solid ${`${theme.input.textColor}`}`}
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;

const DropdownWrapper = styled.div`
    z-index:1000;
    margin-bottom:25px;
`;

const DropdownItemDiv = styled.div`
    border-bottom:1px solid #dddddd;
    padding: 5px;
`;



class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '' };
    }

    componentDidMount() {
        this.setState({...this.state, ...(this.props.input.value)})
    }



    updateState = (update) => {

        this.setState({...this.state, ...update });

        if (this.props.onChange) {
            this.props.onChange(this.state)
        }
        if (this.props.input.onChange) {
            this.props.input.onChange(this.state)
        }
    }

    handleChange = address => {
        this.updateState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => { console.log('Success', latLng)
                this.updateState({address, latitude: latLng.lat, longitude:latLng.lng })
            })
            .catch(error => console.error('Error', error));
    };

    render() {



        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <FormLabel>Address</FormLabel>
                        <FormInput
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                            })}
                        />
                        <DropdownWrapper>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <DropdownItemDiv
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </DropdownItemDiv>
                                );
                            })}
                        </DropdownWrapper>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default LocationSearchInput;
