import React, { Component } from 'react'
import styled from 'styled-components'
import Spinner from '../../components/Spinner'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    max-width: 600px;
`

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    margin: none;
`

const CopyButton = styled.p`
    color: #007bff;
    border: none;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;

    transition: all .1s;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
        background-color: #f3f3f3;
    }
`

const phoneRegex = /^\d{8}$/;
const ISSValidCodes = [
    "2435",
    "8354",
    "1243",
    "8521",
    "7432",
    "1543",
    "6583",
    "5132",
    "9823",
    "3285"
]

export default class ISSRegister extends Component {
    constructor(props) {
        super(props)

        this.state = {
            phone: "",
            isEmployee: false,
            loading: false,
            code: null,
        }
    }

    onSubmit(e) {
        e.preventDefault()
        this.setState({ loading: true });

        const validPhone = phoneRegex.test(this.state.phone);
        if (!validPhone) {
            alert("Telefonnummeret er ikke gyldig");
            this.setState({ loading: false });
            return;
        }

        if (!this.state.isEmployee) {
            alert("Du må være ansatt for å kunne registrere deg");
            this.setState({ loading: false });
            return;
        }

        const code = ISSValidCodes[Math.floor(Math.random() * ISSValidCodes.length)];
        setTimeout(() => {
            this.setState({ code, loading: false });
        }, 300 + Math.random() * 1000);
    }

    render() {
        if (this.state.code) {
            return (
                <Container>
                    <Card>
                        <div style={{
                            display: "flex",
                            gap: "20px",
                        }}>
                            <img
                                style={{ height: "80px", objectFit: "contain" }}
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Integrated_Service_Solutions_%28logo%29.svg/1200px-Integrated_Service_Solutions_%28logo%29.svg.png"
                            />

                            <p style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                            }}>Registrering fullført!</p>
                        </div>



                        <p style={{
                            fontSize: "16px",
                        }}>Du har nå fått en bruker og kode slik at du kan komme deg inn i appen.</p>

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <p style={{
                                fontSize: "16px",
                            }}>Bruker</p>
                            <CopyButton
                                onClick={() => {
                                    navigator.clipboard.writeText(`${this.state.phone}@iss-bruker.no`);
                                }}
                            >
                                Kopier
                            </CopyButton>
                        </div>
                        <Input
                            type="text"
                            value={`${this.state.phone}@iss-bruker.no`}
                            disabled
                        />

                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <p style={{
                                fontSize: "16px",
                            }}>Kode</p>
                            <CopyButton
                                onClick={() => {
                                    navigator.clipboard.writeText(this.state.code);
                                }}
                            >
                                Kopier
                            </CopyButton>
                        </div>
                        <Input
                            type="text"
                            value={this.state.code}
                            disabled
                        />
                    </Card>
                </Container>
            )
        }

        return (
            <Container>
                <Card>
                    <div style={{
                        display: "flex",
                        gap: "20px",
                    }}>
                        <img
                            style={{ height: "80px", objectFit: "contain" }}
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Integrated_Service_Solutions_%28logo%29.svg/1200px-Integrated_Service_Solutions_%28logo%29.svg.png"
                        />

                        <p style={{
                            fontSize: "28px",
                            fontWeight: "bold",
                        }}>Registrering til ansattfordeler</p>
                    </div>

                    <p style={{
                        fontSize: "16px",
                    }}>Fyll ut skjemaet under for få en bruker og kode slik at du kan komme deg inn i appen.</p>

                    <form onSubmit={this.onSubmit.bind(this)} style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                    }}>
                        <label>Telefonnummer</label>
                        <Input
                            type="text"
                            name="telefonnummer"
                            placeholder="Telefonnummer"
                            onChange={(e) => this.setState({ phone: e.target.value })}
                        />

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}>
                            <input
                                type="checkbox"
                                name="terms"
                                id="terms"
                                onChange={(e) => this.setState({ isEmployee: e.target.checked })}
                            />
                            <label htmlFor="terms">Jeg er ansatt i ISS</label>
                        </div>

                        {this.state.loading && <Spinner />}

                        <button style={{
                            padding: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            marginTop: "10px",
                        }}>
                            Fortsett
                        </button>
                    </form>
                </Card>
            </Container>
        )
    }
}
