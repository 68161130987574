/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../../theme/index';
import Link from "../../../../components/Typografy/Link";
import { confirmAlert } from "react-confirm-alert";
import BaseButton from "../../../../components/Typografy/BaseButton";
import { hasSuperAdminAccess } from "../../../../../common/auth";



const MenuLink = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.1em 0.1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 0.3em;
  width: 100%;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;


const MenuClick = styled.div`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.1em 0.1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 0.3em;
  width: 100%;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const MenuWrap = styled.div`
  margin-bottom: 0.2em;
  margin-right: 0.2em;
  width: 100%;

`;

const CopyButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;


const getText = (accountId, accounts) => {
  if (accounts && accounts[accountId]) {
    return `${accounts[accountId].name} (${accountId})`
  }
  return accountId
}

const copy = (props: TProps, targetAccountId) => {
  confirmAlert({
    title: 'Confirm to copy',
    message: 'Are you sure you want to copy this benefit?',
    buttons: [{
      label: 'Confirm',
      onClick: () => props.onCopy(props.accountId, targetAccountId, props.benefit)
    },
    {
      label: 'Cancel'
    }
    ]
  })
};


const MenuItems = ({ props, open, history }) => {
  const accounts = hasSuperAdminAccess(props.profile) ? props.accounts : props.profile.accounts
  return (
    <div className={open ? 'open' : ''} id="nav-icon3">
      {Object.keys(accounts).filter(id => id !== props.accountId).map((targetAccountId) =>
        <MenuWrap key={targetAccountId + '-wrap'}><MenuClick key={targetAccountId} onClick={() => copy(props, targetAccountId)}>{getText(targetAccountId, props.accounts)}</MenuClick></MenuWrap>)}
    </div>
  );
};



const Container = styled(Flex)`
  z-index: 4;
  position: relative;
`;


const Wrapper = styled(Flex)`
`;

const MenuContainer = styled(Box)`
  display: ${props => props.open ? 'flex' : 'none'};
  position: absolute;
  background: ${theme.nav.menuContainer};
  padding: 1.2em;
  top: 3.25em;
  left: -2em;
  right: 0em;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  width: 200px;
  max-height: 400px;
  overflow-y: scroll;


`;


type TProps = {
  bg?: boolean;
  children?: any;
  logo?: any;
}

type TState = {
  open: boolean;

}


class CopyMenu extends React.Component<void, TProps, TState> {

  state: TState = {
    open: false,
  };

  toggleMenu = () => {
    console.log("Copymenu toggled")
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidUpdate(prevProps: TProps, prevState: TState) {
    if (prevState.open && !this.state.open) {
      document.body.style = 'overflow: auto';
    } else if (!prevState.open && this.state.open) {
      // document.body.style = 'overflow: hidden';
    }
  }

  componentWillUnmount() {
    // If we navigate out of this view and the menu is open, make sure to restore the overflow
    if (this.state.open) {
      document.body.style = 'overflow: auto';
    }
  }

  render() {
    const { open } = this.state;
    const { profile, accounts } = this.props;

    if (!(profile && profile.accounts && accounts)) return null;

    return (
      <Wrapper>
        <Container wrap align="center" justify="space-between">
          <MenuContainer open={open} >
            <MenuItems props={this.props} open={open} />
          </MenuContainer>
        </Container>
        <CopyButton onClick={this.toggleMenu} open={open} >
          Copy
        </CopyButton>
      </Wrapper>
    );
  }
}
export default CopyMenu;
