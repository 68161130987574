/**
 * @flow
 */

import React, {
  Component,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import FormWrapper from '../../components/Typografy/FormWrapper';
import ResetWrapper from './ResetWrapper';
import ResetForm from './ResetForm';
import type { TSignInState } from '../../../common/auth/TAuthState';
import type { TSignInData } from '../../../common/types/models/TAuth';
import Spinner from '../../components/Spinner'
import {NotificationContainer} from 'react-notifications';

const Link = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 1em 1.5em;
  color: #fff;
  border-radius: 1.25em;
  margin-bottom: 1em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

const ImageContainer = styled.div`
   margin: 0 auto;
   margin-bottom: 2.5em;
`;

const FormContainer = styled.div`
   margin-top: 1.5em;
`;

const ForgotPasswordWrapper = styled.div`
   text-align: left;
`;
const FormLink = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  color: #f41a4d;
  border-radius: 1.25em;
  margin-bottom: 1em;
   font-size: 2.25em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

const FormTitle = styled.span`
   font-size: 1.25em;
   font-weight: bold;
   color: #fff;
`;

const SmallTitle = styled.span`
   font-size: 0.9em;
   font-weight: bold;
   color: #fff;
`;

const SignUpLinkWrapper = styled.div`
   margin-top: 2.75em;
`;

type TRouter = {
  push: (data: string) => void;
}

type TProps = {
  signIn: TSignInState;
  signInAccount: (data: TSignInData) => void;
  history: TRouter;
}

class ResetPassword extends Component<void, TProps, void> {

  componentDidMount(){
    if (window.location.hash && window.location.hash.length > 0)
    {
        this.props.handleAuthentication(this.props.history)
    }
  }

/*  componentWillReceiveProps(nextProps: TProps) {
    if (!this.props.signIn.auth0Token && nextProps.signIn.auth0Token) {
      this.props.history.push('/');
    }
  }
*/
  render() {
      if (window.location.hash && window.location.hash.length > 0)
      {
        return <ResetWrapper>
          <Spinner/>
        </ResetWrapper>
      }

      const isReset = this.props.reset  && this.props.reset.isReset

    return (
      <ResetWrapper>
        <NotificationContainer/>
        <Link to="/login">&#60; login</Link>
        <FormWrapper>
          <ImageContainer>
            <ResponsiveImage
              src="form_logo.png"
              width={160}
              height={49}
              containerStyle="margin: 0 auto"
              alt=""
            />
          </ImageContainer>
          {isReset ?
            <ForgotPasswordWrapper>
              <FormLink to="/login"> Back to login</FormLink>
            </ForgotPasswordWrapper>:<div>
              <FormTitle> Reset Password </FormTitle>
              <FormContainer>
            <ResetForm
              resetPasswordAccount={this.props.resetPasswordAccount}
              isReset={isReset}
              history={this.props.history}
            />
            </FormContainer></div>}
        </FormWrapper>
      </ResetWrapper>
    );
  }
}

export default ResetPassword;
