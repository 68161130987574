/**
 * @flow
 */

import React from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import Link from '../../../components/Typografy/Link';
import BaseButton from '../../../components/Typografy/BaseButton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Switch from 'react-toggle-switch'

const BenefitsCard = styled.div`
  border-radius: 20px;
  color: ${theme.benefits.textColor};
`;

const LinkContainer = styled.div`
  text-align: right;
  // padding-right: 2.5em;
`;




const EditBenefitButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const ArchiveBenefitButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const ToggleLabel = styled.span`
  padding-left:10px;
  padding-right:10px;
  vertical-align:super;
  font-size: 20px;
`;

const ToggleWrapper = styled.div`
  text-align:right;
`;

type TProps = {
    cabin: TBenefitApiData;
    onArchive: Function;
    accountId:number;
};

const archive = (props: TProps) => {
    confirmAlert({
        title: 'Confirm to archive',
        message: 'Are you sure you want to archive this cabin?',
        buttons: [{
            label: 'Confirm',
            onClick: () => props.onArchive(props.accountId, props.benefit)
        },
            {
                label: 'Cancel'
            }
        ]
    })
};


const unArchive = (props: TProps) => {
    confirmAlert({
        title: 'Confirm to unarchive',
        message: 'Are you sure you want to unarchive this cabin?',
        buttons: [{
            label: 'Confirm',
            onClick: () => props.onArchive(props.accountId, props.benefit)
        },
            {
                label: 'Cancel'
            }
        ]
    })
};

const CabinAdminControl = (props: TProps) => {
    const { cabin, profile, accountId, isArchive, accounts, isDraft, updateCabin, updateDraftCabin } = props;

    const account = accounts[accountId];

    return (

        <BenefitsCard>
            <Flex wrap>
                <Box w={[1,6/12]}>
                    {!isDraft && !isArchive && <ToggleWrapper><Switch onClick={() => updateDraftCabin(cabin, cabin, true)} on={!isDraft} /><ToggleLabel>Cabin is published</ToggleLabel></ToggleWrapper>}
                    {isDraft && !isArchive && <ToggleWrapper><Switch onClick={() => updateCabin(cabin, cabin, true)} on={!isDraft} /><ToggleLabel>Cabin is not published</ToggleLabel></ToggleWrapper>}
                </Box>
                <Box w={[1, 6/12]}>
                    <LinkContainer>
                        <Flex>
                            {!isDraft && !isArchive && <EditBenefitButton to={'/admin/cabins/' + cabin.cabinId + '/edit'}>
                                Edit
                            </EditBenefitButton>}
                            {isDraft && !isArchive && <EditBenefitButton to={'/admin/cabins/' + cabin.cabinId + '/edit-draft'}>
                                Edit
                            </EditBenefitButton>}
                            {/*{!isDraft && !isArchive && <EditBenefitButton to={'/admin/cabins/' + cabin.cabinId + '/admin'}>*/}
                                {/*Administrate*/}
                            {/*</EditBenefitButton>}*/}
                            {(isArchive ?
                                <ArchiveBenefitButton onClick={() => unArchive(props)}>
                                    Unarchive
                                </ArchiveBenefitButton>:
                                <ArchiveBenefitButton onClick={() => archive(props)}>
                                    Archive
                                </ArchiveBenefitButton>)}
                        </Flex>
                    </LinkContainer>
                </Box>
            </Flex>
        </BenefitsCard>
    );
};

export default CabinAdminControl;
