/**
 * @flow
 */

import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import FormWrapper from '../../components/Typografy/FormWrapper';
import SignUpWrapper from './SignUpWrapper';
import UserToggle from './UserToggle';

import type { TSignUpState, TSignInState } from '../../../common/auth/TAuthState';
import type { TSignUpData } from '../../../common/types/models/TAuth';

const Link = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 1em 1.5em;
  color: #fff;
  border-radius: 1.25em;
  margin-bottom: 1em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

const ImageContainer = styled.div`
   margin: 0 auto;
   margin-bottom: 2.5em;
`;

const FormLink = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  color: #f41a4d;
  border-radius: 1.25em;
  margin-bottom: 1em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

const FormTitle = styled.span`
   font-size: 1.25em;
   font-weight: bold;
   color: #fff;
`;

const SmallTitle = styled.span`
   font-size: 0.9em;
   font-weight: bold;
   color: #fff;
`;

const SignInLinkWrapper = styled.div`
   margin-top: 2.75em;
`;

type TRouter = {
  push: (data: string) => void;
}

type TProps = {
  signUp: TSignUpState,
  signIn: TSignInState;
  signUpAccount: (data: TSignUpData) => void;
  history: TRouter;
}


class SignUp extends Component<void, TProps, void> {

  componentWillReceiveProps(nextProps: TProps) {
    if (!this.props.signIn.auth0Token && nextProps.signIn.auth0Token) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <SignUpWrapper>
        <Link to="/">&#60; Main page</Link>
        <FormWrapper>
          <ImageContainer>
            <ResponsiveImage
              src="form_logo.png"
              width={160}
              height={49}
              containerStyle="margin: 0 auto"
              alt=""
            />
          </ImageContainer>
          <FormTitle> Sign up </FormTitle>
          <UserToggle
            signIn={this.props.signIn}
            signUp={this.props.signUp}
            signUpAccount={this.props.signUpAccount}
          />
          <SignInLinkWrapper>
            <SmallTitle>Already have an account? </SmallTitle>
            <FormLink to="/login"> sign in</FormLink>
          </SignInLinkWrapper>
        </FormWrapper>
      </SignUpWrapper>
    );
  }
}

export default SignUp;
