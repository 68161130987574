/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import type { TSignInData } from '../../../common/types/models/TAuth';
import type { TSignInState } from '../../../common/auth/TAuthState';
import CustomFieldInput from '../../components/Typografy/CustomFieldInput';
import LoginButton from '../../components/Typografy/LoginButton';
import {resetPasswordAccount} from "../../../common/auth/resetPassword/actions";

type TLoginProps = {
  handleSubmit: Function;
  signInAccount: (data: TSignInData) => void;
  signIn: TSignInState;
}

class ResetForm extends Component<void, TLoginProps, void> {

  _handleSubmit = (data) => {
    this.props.resetPasswordAccount(data);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this._handleSubmit)}>
        <div>
          <Field
            name="email"
            component={CustomFieldInput}
            type="email"
            placeholder="Email"
          />
        </div>
        <LoginButton
          disabled={this.props.reset.isLoading}
          type="submit"
        >
          Reset
        </LoginButton>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Sorry, we don\'t recognize this email';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 5) {
    errors.password = 'Sorry, your password must contain at least 5 characters';
  }
  return errors;
};

export default reduxForm({
  form: 'resetPassword',
  validate,
  field: ['email'],
})(ResetForm);
