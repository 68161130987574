/**
 * @flow
 */
import {firebaseDb} from '../config/firebase_config/firebase';
import {
    buildAccountArchive,
    buildAccount, buildAccountTheme,
    buildAccountCustomizeVendorBenefitCategory,
    buildGoogleServices
} from '../config/firebase_config/endpoints';

import {NotificationManager} from 'react-notifications';
import history from '../utils/history'

import {buildTheme} from '../theme/native/base'

export const onLanguagesLoadSuccess = payload => ({
    type: 'LOAD_LANGUAGES_SUCCESS',
    payload,
});
