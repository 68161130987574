/**
 * @flow
 */

import React, {Component} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';
import ArrowIcon from './components/ArrowIcon';
import MessageCard from './MessageCard';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
	buildMessageArchives,
	buildMessageDrafts,
	buildMessages
} from '../../../../common/config/firebase_config/endpoints';
import type {
	TMessageApiData,
	TMessageResponse,
} from '../../../../common/types/api/TMessage';

const Container = styled.div`
  //background-image: url(${require('../../../assets/images/top-background.png').default});
	background-color: #141414; 
  padding-top: 1.6em;
`;

const BackLink = styled(Link)`
  padding: 2em 0 2em 0;
`;

type
TProps = {
	accountId: number;
	messages: TMessageApiData;
	onMessageDraftsLoadSuccess: (data: TMessageResponse) => void;
	archiveMessage: any;
	publishMessageDraft: any;
	params: Object;
	isDraft? : boolean;
	isArchive? : boolean;
};

class MessageDetails extends Component<void, TProps, void> {
	
	_getQueries = () => {
		return ([{
			endpoint: this.props.isArchive ?
				buildMessageArchives(this.props.accountId) :
				this.props.isDraft ?
					buildMessageDrafts(this.props.accountId) :
					buildMessages(this.props.accountId),
			method: 'on',
			eventType: 'value',
			callback: this.props.isArchive ?
				this.props.onMessageArchivesLoadSuccess :
				this.props.isDraft ?
					this.props.onMessageDraftsLoadSuccess :
					this.props.onMessagesLoadSuccess,
			metadata: this.props.accountId,
		},
		]);
	};
	_getItem = () => {
		const id = this.props.match.params.id;
		const message = _.find(this.props.messages || [], b => b.messageId === id);
		if (!message) return (<div></div>);
		
		
		return (
			<Container>
				<Wrapper>
					<BackLink to={`/admin/messages${this.props.isArchive ? "/archived" : this.props.isDraft ? "/drafts" : ""}`}>
						<ArrowIcon/>
						Messages
					</BackLink>
					<MessageCard
						isArchive={this.props.isArchive}
						isDraft={this.props.isDraft}
						message={message}
						onEdit={() => this.props.history.push(`/admin/messages/${this.props.isArchive ? "archive/" : this.props.isDraft ? "drafts/" : ""}${message.messageId}/edit`)}
						accountId={this.props.accountId}
						archiveMessage={this.props.archiveMessage}
						restoreArchivedMessage={this.props.restoreArchivedMessage}
						publishMessageDraft={this.props.publishMessageDraft}
						account={this.props.account}
						accounts={this.props.accounts}
					/>
				</Wrapper>
			</Container>
		);
	}
	
	render() {
		return (
			<div>
				<FirebaseContainer queries={this._getQueries()}>
					{this.props.messages ? this._getItem() : null}
				</FirebaseContainer>
			</div>
		);
	}
}


export default MessageDetails;

