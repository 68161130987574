/**
 * @flow
 */

import createReducer from '../createReducer';

const initialState = {
  data: {},
  error: null,
  isLoading: false,
};

const handlers = {
  LOAD_HELP_DATA_SUCCESS: (state, action) => {
    const data = action.payload;

    return {
      ...state,
      error: null,
      isLoading: true,
      data: data,
    };
  },
  UPDATE_HELP_DATA_SUCCESS: (state, action) => {
    const data = action.payload;

    return {
        ...state,
        data:data,
    }
  }
};

export default createReducer(initialState, handlers);
