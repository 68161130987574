/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../../common/TAppState';
import ContactForm from './ContactForm';
import { registerContactMeDB } from '../../../../common/contact/actions';

const ContactContainer = connect(
  (state: TAppState) => ({ contact: state.contact }),
  (dispatch) => ({
    registerContactMeDB: (data) => dispatch(registerContactMeDB(data)),
  }),
)(ContactForm);

export default ContactContainer;
