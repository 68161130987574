/**
 * @flow
 */


import { connect } from 'react-redux';
import BenefitDetails from './BenefitDetails';
import { onBenefitsLoadSuccess } from '../../../../common/benefits/actions/index';
import type { TAppState } from '../../../../common/TAppState';
import { saveRating } from '../../../actions';
import {changeVendorBenefitVisibility, unArchiveBenefit} from '../../../../common/benefits/actions/editBenefit';
import {onAccountsLoadSuccess, setCustomCategory} from "../../../../common/accounts/actions";
import {benefitDataTemplate} from "../../../../common/benefits/actions";
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";
import {onVendorVisibilityLoadSuccess} from "../../../../common/benefits/actions/benefitsLoad";

const BenefitDetailsContainer = connect(
 (state: TAppState) => {
   return {
     languages: state.languages.data,
     accountId: state.auth.profile.data.accountId,
     benefits: state.benefits.data.all || [],
       accounts: state.accounts.data,
       profile: state.auth.profile.data,
       isArchive: true,
     accountsLoaded: state.accounts.isLoaded,
     categories: state.categories.list,
       hiddenBenefits: state.benefits.hiddenBenefits.list,
   };
 },
 (dispatch) => ({
   onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
   onBenefitsLoadSuccess: (data) => dispatch(onBenefitsLoadSuccess(data)),
   onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
   onArchive: (accountId, benefit) => dispatch(unArchiveBenefit(accountId, benefit)),
   onSetCustomCategory: (accountId, targetCategoryId, benefit) => dispatch(setCustomCategory(accountId, targetCategoryId, benefit)),
   onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
   onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
     onVendorVisibilityLoadSuccess: (payload) => dispatch(onVendorVisibilityLoadSuccess(payload)),
     changeVendorBenefitVisibility: (benefitId, status) => dispatch(changeVendorBenefitVisibility(benefitId, status)),
 }),
)(BenefitDetails);

export default BenefitDetailsContainer;

