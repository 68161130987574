/**
 * @flow
 */

/* eslint import/prefer-default-export: 0 */

import type { TDispatch } from '../../types/TDispatch';

import type {
  TAuthError,
} from '../TAuthState';

import getHeaders from '../../config/auth/headers';
import {
  resetPasswordBody,
} from '../config';
import auth0Config from '../../config/auth/auth0';
import {NotificationManager} from 'react-notifications';


const _onResetPasswordSuccess = (auth0Token: string, firebaseToken: string) => ({
  type: 'AUTH0_RESET_PASSWORD_SUCCESS',
});

const _onResetPasswordRequest = () => ({
  type: 'AUTH0_RESET_PASSWORD_REQUEST',
});

const _onResetPasswordFailure = (error: TAuthError) => ({
  type: 'AUTH0_RESET_PASSWORD_FAILURE',
  payload: error,
});

export const resetPasswordAccount = (data) => async (dispatch: TDispatch) => {
  try {
    const email = data.email

    dispatch(_onResetPasswordRequest());
    const response = await fetch(`https://${auth0Config.domain}/dbconnections/change_password`, {
      method: 'POST',
      headers: getHeaders(),
      body: resetPasswordBody({email}),
    });
    if (response.status >= 400) {
      const errorJson = await response.json();
      dispatch(_onResetPasswordFailure({
        status: response.status,
        message: errorJson.error,
      }));
      NotificationManager.error('Error!', "Could not reset password. Check the email address.", 5000);
    } else {
      dispatch(_onResetPasswordSuccess());
      NotificationManager.success('Success!', 'Password reset! Check your inbox', 5000);

    }
  } catch (error) {
    dispatch(_onResetPasswordFailure({
      status: 500,
    }));
  }
};