/**
 * @flow
 */


import {connect} from 'react-redux';
import BenefitDetails from './BenefitDetails';
import {onBenefitsLoadSuccess} from '../../../../common/benefits/actions/index';
import type {TAppState} from '../../../../common/TAppState';
import {
    archiveBenefit,
    changeVendorBenefitVisibility,
    copyBenefit
} from '../../../../common/benefits/actions/editBenefit';
import {onAccountsLoadSuccess, setCustomCategory} from "../../../../common/accounts/actions";
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions";
import {benefitDataTemplate, updateDraftBenefit} from "../../../../common/benefits/actions";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";
import {onBenefitOrderLoadSuccess, onVendorVisibilityLoadSuccess} from "../../../../common/benefits/actions/benefitsLoad";
import { changeBenefitOrder } from '../../../../common/benefits/actions/editBenefit';

const BenefitDetailsContainer = connect(
    (state: TAppState) => {
        return {
            languages: state.languages.data,
            accountId: state.auth.profile.data.accountId,
            benefits: state.benefits.data.all || [],
            accounts: state.accounts.data,
            accountsLoaded: state.accounts.isLoaded,
            profile: state.auth.profile.data,
            categories: state.categories.list,
            hiddenBenefits: state.benefits.hiddenBenefits.list,
            benefitOrder: state.benefits.benefitOrder,
        };
    },
    (dispatch) => ({
        onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
        onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
        onArchive: (accountId, benefit) => dispatch(archiveBenefit(accountId, benefit)),
        onCopy: (sourceAccountId, targetAccountId, benefit) => dispatch(copyBenefit(sourceAccountId, targetAccountId, benefit)),
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        onSetCustomCategory: (accountId, targetCategoryId, benefit) => dispatch(setCustomCategory(accountId, targetCategoryId, benefit)),
        onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
        onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
        updateDraftBenefit: (data, benefit, disable) => dispatch(updateDraftBenefit(data, benefit, disable)),
        onVendorVisibilityLoadSuccess: (payload) => dispatch(onVendorVisibilityLoadSuccess(payload)),
        changeVendorBenefitVisibility: (benefitId, status) => dispatch(changeVendorBenefitVisibility(benefitId, status)),
        onBenefitOrderLoadSuccess: (payload) => dispatch(onBenefitOrderLoadSuccess(payload)),
        changeBenefitOrder: (benefitId, order) => dispatch(changeBenefitOrder(benefitId, order)),
    }),
)(BenefitDetails);

export default BenefitDetailsContainer;

