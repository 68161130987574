/**
 * @flow
 */

import React, {Component,} from 'react';
import LoginWrapper from './LoginWrapper';
import Spinner from '../../components/Spinner'
import {signInAccount} from "../../../common/auth/signIn/actions";
import {hasAccount} from '../../../common/auth'
import type { TSignInState } from '../../../common/auth/TAuthState';
import type { TSignInData } from '../../../common/types/models/TAuth';


type TRouter = {
  push: (data: string) => void;
}

type TProps = {
  signIn: TSignInState;
  signInAccount: (data: TSignInData) => void;
  history: TRouter;
}

class Login extends Component<void, TProps, void> {

  componentDidMount(){
    const {isRestored, profile} = this.props

    if (window.location.hash && window.location.hash.length > 0)
    {
        console.log('Login page: found hash, sending to handleAuthentication: ' + JSON.stringify(this.props.history))
        this.props.handleAuthentication(this.props.history)
    }
     else if(isRestored && hasAccount(profile)) {
          console.log('login page: isRestored && hasAccount(profile). redirecting to benefits')
          this.props.history.push('/admin/benefits');
      }
     else if(isRestored && !hasAccount(profile)) {
          console.log('login page: isRestored && !hasAccount(profile). redirecting to login')
          signInAccount()
      }
  }
/*
  componentDidUpdate(prevProps: TProps) {
      const {isRestored, profile} = this.props

      if (!prevProps.signIn.firebaseToken && this.props.signIn.firebaseToken && this.props.signIn.firebaseToken.length > 0) {
          console.log('login page: !this.props.signIn.firebaseToken && nextProps.signIn.firebaseToken && nextProps.signIn.firebaseToken.length > 0. redirecting to benefits')
      this.props.history.push('/admin/benefits');
    }

      if(isRestored && !hasAccount(profile)) {
          console.log('login page: componentWillReceiveProps: isRestored && !hasAccount(profile). redirecting to login')
          signInAccount()
      }


  }
*/
  render() {

        return <LoginWrapper>
          <Spinner/>
        </LoginWrapper>
  }
}

export default Login;
