/**
 * @flow
 */

import styled from 'styled-components';

const Title = styled.h1`
  font-size: 1.8em;
  text-align: center;
  color: palevioletred;
`;

export default Title;

