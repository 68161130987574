/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TAccountApiData } from '../../../../common/types/api/TAccount';

const AccountsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;



const AccountInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const AccountTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const AccountText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 1.5em;
  //border-bottom: 1px solid ${theme.categories.categoryColor};
`;

const AccountImage = styled.img`
  width: 10em;
  height: 6em;
  object-fit: contain;
  margin-left: 2em;
`

type TProps = {
  account: TAccountApiData;
  accountId: number;
};

const AccountItem = (props: TProps) => {
  const { account, accountId } = props;

  return (
    <LinkToDetails to={`/admin/accounts/${account.accountId}`}>
      <AccountsCard>
        <Flex wrap>
          <Box w={[1]} style={{
            display: "flex",
            fledDirection: "row",
            alignItems: "center",
          }}>
            <AccountImage src={account.logoUrl} alt={"Account logo"} />

            <AccountInfoContainer>
              <AccountTitle> {account.name} </AccountTitle>
              <AccountText>
                Id: {account.id}
              </AccountText>
            </AccountInfoContainer>
          </Box>
        </Flex>
      </AccountsCard>
    </LinkToDetails>
  );
};

export default AccountItem;
