/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import VerifyAndroid from './VerifyAndroid'
import {
  getAppCodeAndroid,
  getAppCodeAndroidCommon
} from "../../../common/accounts/actions";

const VerifyAndroidContainer = connect(
  (state: TAppState) => ({
    token: state.accounts.token

  }),
  (dispatch) => ({
    getAppCodeAndroid: (accountId: number) => dispatch(getAppCodeAndroid(accountId)),
    getAppCodeAndroidCommon: () => dispatch(getAppCodeAndroidCommon()),
  }),
)(VerifyAndroid);


export default VerifyAndroidContainer;
