/**
 * @flow
 */

import React from 'react';
import StatsItem from './StatsItem';

import Spinner from '../../../components/Spinner'
import CodeList from "./CodeList";


class StatsList extends React.Component {
	
	_getItems = (stat, tokens) => {
		
		let items = []
		
		
		/* if (stat.total) items.push(
			<StatsItem
				key={'total-' + this.props.accountId}
				stat={{data: stat.total, title: 'Onboarded users'}}
				accountId={this.props.accountId}
			/>
		) */
		
		if (stat.unusedCodes) {
			items.push(
				<CodeList
					key={"unused"}
					stat={{title: "Unused one time codes", data: stat.unusedCodes?.length}}
					list={stat.unusedCodes}
					accountId={this.props.accountId}
					createOneTimeCode={this.props.createOneTimeCode}
					editOneTimeCode={this.props.editOneTimeCode}
					deleteOneTimeCode={this.props.deleteOneTimeCode}
				/>
			)
		}
		
		/* if (stat.unverified && stat.unverified_users.length > 0)
			items.push(
				<StatsItem
					key={'unverified-' + this.props.accountId}
					stat={{data: stat.unverified, title: 'Unverified users'}}
					list={stat.unverified_users}
					accountId={this.props.accountId}
				/>
			) */
		
		if (tokens && tokens.free)
			items.push(
				<StatsItem
					key={'tokens-' + this.props.accountId}
					stat={{data: tokens.free, title: 'Available Apple tokens'}}
					accountId={this.props.accountId}
				/>
			)
		
		return items
		
	};
	
	render() {
		return (
			<div>
				{this.props.stats[this.props.accountId] ? this._getItems(this.props.stats[this.props.accountId], this.props.tokens && this.props.tokens.data && this.props.tokens.data[this.props.accountId]) :
					<Spinner/>}
			</div>
		);
	}
}

export default StatsList;
