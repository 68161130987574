/**
 * @flow
 */


import {connect} from 'react-redux';
import MessageDetails from './MessageDetails';
import {archiveMessage, onMessagesLoadSuccess, publishMessageDraft} from '../../../../common/messages/actions';
import type {TAppState} from '../../../../common/TAppState';

const MessageDraftDetailsContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.data || [],
			isDraft: false,
			isArchive: false,
		};
	},
	(dispatch) => ({
		onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId)),
		publishMessageDraft: (message, accountId, notification) => dispatch(publishMessageDraft(message, accountId, notification)),
	}),
)(MessageDetails);

export default MessageDraftDetailsContainer;

