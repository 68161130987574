/**
 * @flow
 */


import {connect} from 'react-redux';
import StatsPage from './StatsPage';
import {getAppCodeStatus, onAccountsLoadSuccess} from '../../../../common/accounts/actions';
import {
	createOneTimeCode, deleteOneTimeCode,
	editOneTimeCode,
	getAllStats,
	getStats,
	updateDataForAllUsers
} from '../../../../common/stats/actions';
import type {TAppState} from '../../../../common/TAppState';
import {onAllBenefitReportsLoadSuccess} from "../../../../common/benefitreports/actions";

const StatsContainer = connect(
    (state: TAppState) => {
        return {
            accountId: state.auth.profile.data.accountId,
            stats: state.stats.data,
            tokens: state.accounts.tokens,
            benefitReports: state.benefitReports.allList,
            accounts: state.accounts.data,
            accountsLoaded: state.accounts.isLoaded,
        };
    },
    (dispatch) => ({
        getStats: (data) => dispatch(getAllStats(data)),
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        getAppCodeStatus: (data) => dispatch(getAppCodeStatus(data)),
        updateDataForAllUsers: () => dispatch(updateDataForAllUsers()),
        onAllBenefitReportsLoadSuccess: (data) => dispatch(onAllBenefitReportsLoadSuccess(data)),
		createOneTimeCode: (email, code, createdAt, accountId) => dispatch(createOneTimeCode(email, code, createdAt, accountId)),
	    editOneTimeCode: (id, email, code, createdAt, accountId) => dispatch(editOneTimeCode(id, email, code, createdAt, accountId)),
	    deleteOneTimeCode: (codeId, accountId) => dispatch(deleteOneTimeCode(codeId, accountId)),
    }),
)(StatsPage);
export default StatsContainer;
