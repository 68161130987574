/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import Wrapper from '../../../components/Typografy/Wrapper';

import {
    Flex,
} from 'reflexbox';

import {Container} from '../../../components/Elements'
import {buildAccounts, buildAccountDeployConfigs} from "../../../../common/config/firebase_config/endpoints";
import AccountDeployForm from "./AccountDeployForm";
import FirebaseContainer from "../../../../common/firebase/FirebaseContainer";


type TProps = {
    profile: TProfileData;
}

class AccountDeployManagement extends Component<void, TProps, void> {
    _getQueries = () => {
        return ([{
            endpoint: buildAccounts(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAccountsLoadSuccess,
        },
            {
                endpoint: buildAccountDeployConfigs,
                method: 'on',
                eventType: 'value',
                callback: this.props.onAccountDeployConfigsSuccess,
            },
        ]);
    };


    render() {
        const id = this.props.match.params.id;


        return (
            <div>
                <SubHeader accountId={id}/>
                <Container>
                    <Wrapper>
                        <FirebaseContainer queries={this._getQueries()}>
                            {this.getDiv(id)}
                        </FirebaseContainer>
                    </Wrapper>
                </Container>
            </div>);
    }

    getDiv(id) {
        if(!(this.props.accounts && Object.keys(this.props.accounts).length > 0)) return null
        if(!(this.props.config && Object.keys(this.props.config).length > 0)) return null

        return <div>
            {this.props.accounts && this.props.config && <AccountDeployForm {...this.props} accountId={id}/>}
        </div>;
    }
}

export default AccountDeployManagement;
