/**
 * @flow
 */

import React, {
  Component,
} from 'react';

import SubHeader from './SubHeader';
import AboutClient from './AboutClient';
import type { TProfileData } from '../../../common/types/models/TAuth';

type TProps = {
  profile: TProfileData;
}

class Profile extends Component<void, TProps, void> {
  render() {
    const { profile } = this.props;
    return (
      <div>
        <SubHeader />
        <AboutClient profile={profile} />
      </div>
    );
  }
}

export default Profile;
