/**
 * @flow
 */


import { connect } from 'react-redux';
import MessagePage from './MessagePage';
import {
	onMessagesLoadSuccess,
	archiveMessage,
	publishMessageDraft,
	onMessagesReset, onMessageDraftsLoadSuccess, onMessageArchivesLoadSuccess, restoreArchivedMessage
} from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';

const MessageArchiveContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.archiveList,
			isDraft: false,
			isArchive: true,
		};
	},
	(dispatch) => ({
		onMessageArchivesLoadSuccess: (data) => dispatch(onMessageArchivesLoadSuccess(data)),
		onMessagesReset: () => dispatch(onMessagesReset()),
		restoreArchivedMessage: (message) => dispatch(restoreArchivedMessage(message)),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId)),
		publishMessageDraft: (message, accountId) => dispatch(publishMessageDraft(message, accountId)),
	}),
)(MessagePage);
export default MessageArchiveContainer;
