/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
  buildBenefitsDraft,
  buildBenefits,
  buildBenefitsArchive
} from '../../../../common/config/firebase_config/endpoints';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import CabinItem from './CabinItem';
import Spinner from '../../../components/Spinner'

import {getBenefits, getCabins} from '../components/Retrievers'
import {onCabinsLoadSuccess} from "../../../../common/cabins/actions";

type TProps = {
    accountId: number;
    cabins: Array<TBenefitApiData>;
    onCabinsLoadSuccess: (data: TBenefitResponse) => void;
    isArchive: boolean;
    isDraft: boolean;
};

class CabinList extends React.Component<void, TProps, void> {

    _getItems = () => {
        return Object.keys(this.props.cabins).map((cabin, i) => {
            return <CabinItem key={i} cabin={this.props.cabins[cabin]} accountId={this.props.accountId} accounts={this.props.accounts}
                              isArchive={this.props.isArchive} isDraft={this.props.isDraft}/>;
        });
        return null;
    };

    render() {
        if (!this.props.accountId) {
            return (
                <div>
                    <h1>LASTER KONTO...</h1>
                </div>)
        }
        const {accounts, accountId, onCabinsLoadSuccess, isArchive, isDraft} = this.props
        return (
            <FirebaseContainer queries={getCabins(accounts, accountId, onCabinsLoadSuccess, isArchive, isDraft)}>
                <div>
                    {Object.keys(this.props.cabins).length ? this._getItems() : this.props.isLoaded ? <h3>No cabins found!</h3> :
                        <Spinner/>}
                </div>
            </FirebaseContainer>
        );
    }
}

export default CabinList;
