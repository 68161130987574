/**
 * @flow
 */

import { connect } from 'react-redux';

import Admin from './Admin';
import type { TAppState } from '../../../common/TAppState';

import { onAccountsLoadSuccess } from '../../../common/accounts/actions'
import {onThemeLoadSuccess} from "../../../common/theme/web/actions";

const AdminContainer = connect(
  (state: TAppState) => ({
    isRestored: state.restoreState.isRestored,
    profile: state.auth.profile.data,
    accounts: state.accounts.data,
    theme: state.theme.data,
    accountsLoaded: state.accounts.isLoaded,
    accountId: state.auth.profile.data.accountId
  }),
  (dispatch) => ({
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onThemeLoadSuccess: (data) => dispatch(onThemeLoadSuccess(data))
  }),
)(Admin);

export default AdminContainer;
