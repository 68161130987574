/**
 * @flow
 */

import styled from 'styled-components';

const List = styled.ul`
 font-size: 1.1em;
 color: #F41A4D;
 padding-left: 1em;
 text-align: left;
 line-height: 1.5;
 `;

export default List;
