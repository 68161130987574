/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
    data: {},
    deploys: {},
    deploysList: [],
    error: null,
    isLoaded: false,
};


const handlers = {
    LOAD_CONFIGS_SUCCESS: (state, action) => {
        console.log('LOAD_CONFIGS_SUCCESS')
        const data = action.payload
        if (data) {
            return {...state, error: null, isLoaded: true, data: data};

        }
        return state;
    },
    LOAD_DEPLOYS_SUCCESS: (state, action) => {
        console.log('LOAD_DEPLOYS_SUCCESS')
        const data = action.payload

        let deploys = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                let deploy = {...data[key], accountId: key}
                deploys[key] = deploy
                list.push(deploys);
            });

        }

        if (data) {
            return {...state, deploys: data};

        }
        return state;
    },


}


export default createReducer(initialState, handlers);
