/**
 * @flow
 */

import type { TSignInState } from '../TAuthState';
import createReducer from '../../createReducer';
import type { THandler } from '../../types/THandler';

const initialState: TSignInState = {
  error: null,
  isLoading: false,
  auth0Token: '',
  firebaseToken: '',
};

const handlers: THandler<TSignInState> = {
  AUTH0_SIGN_IN_REQUEST: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  AUTH0_SIGN_IN_FAILURE: (state, action) => {
    console.log("CALLED AUTH0_SIGN_IN_FAILURE")
    return { ...state, error: action.payload, isLoading: false  };
  },
  AUTH0_SIGN_IN_SUCCESS: (state, action) => {
    const tokens = action.payload;
    return {
      ...state,
      auth0Token: tokens.auth0Token,
      firebaseToken: tokens.firebaseToken,
      isLoading: false,
      error:null
  };
  },
  AUTH0_LOGOUT_SUCCESS: () => {
    return initialState;
  },
  RESTORE_STATE_SUCCESS: (state, action) => {
    return action.payload.auth.signIn;
  },
};

export default createReducer(initialState, handlers);
