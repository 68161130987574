import {firebaseStorage, firebaseDb} from '../config/firebase_config/firebase';
import {buildAccountMembershipTemplate, buildAccountMembershipTemplateFile} from '../config/firebase_config/endpoints';

const storageRef = firebaseStorage.ref();



const uploadTemplateString = (fileString, accountId) => {
    const destination = buildAccountMembershipTemplateFile(accountId)
    const uploadTask = storageRef.child(destination).put(fileString);

    return new Promise((resolve, reject) => { uploadTask.on('state_changed', (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
    }, function (error) {
        reject(error)
    }, function () {

        let updates = {};
        updates[buildAccountMembershipTemplate(accountId)] = destination;
        firebaseDb.ref().update(updates);

        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            resolve(downloadURL);
        });
    });
    });
};


export const getTemplateUrl = async (accountId) => {
    const destination = buildAccountMembershipTemplateFile(accountId)
    const downloadURL = await storageRef.child(destination).getDownloadURL()
    console.log('File available at', downloadURL);
    return downloadURL;

};


export default uploadTemplateString;
