/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme/index';
import CoveredProIcon from '../../../components/Typografy/CoveredProIcon';

const MessageIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  color: ${props => props.theme.accentColor ? props.theme.accentColor : theme.icon.subHeaderIcon};
`;

const MessageIcon = ({theme}) => (
  <MessageIconStyle theme={theme}>&#xe801;</MessageIconStyle>
);

export default MessageIcon;
