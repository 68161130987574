/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field,} from 'redux-form';
import {
  Flex,
  Box,
} from 'reflexbox';

import Wrapper from '../../../components/Typografy/Wrapper';

import {Card, CardTitle, Container} from '../../../components/Elements'


import type {
  TBenefitApiData,
} from '../../../../common/types/api/TBenefit';
import Calendar from "./components/Calendar";
import CustomCheckBox from "../../../components/Typografy/CustomCheckBox";
import PendingBookings from "./components/PendingBookings";
import styled from "styled-components";
import {mx} from "../../../utils";
import H3 from "../../../components/Typografy/H3";
import SmallText from "../../../components/Typografy/SmallText";
import theme from "../../../theme";
import CustomBooking from "./CustomBooking";
import find from "lodash/find";
import UpcomingBookings from "./components/UpcomingBookings";


const Details = styled.div`
  background-color:white;
  margin: 0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);

 ${mx('lg')} {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 1em;
 }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.backgroundColor : '#222222'};
  color: white;
  padding: 1em;
  `;


const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  //text-transform: uppercase;
  `;

const Text = styled(SmallText)`
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  padding:1em;
  `;


const DescriptionPart = styled.div`
  display:flex;
  flex-direction: column;
  ${mx('lg')} {
    display: flex;
    width: 100%;
  }
  `;


type TProps = {
  accountId: string;
  initialValues: TBenefitApiData;
  hasCabin: boolean;
  isDraft: boolean;
  cabin: {
      cabinId: string;
  };
}

type TState = {}


class CabinAdminForm extends Component<void, TProps, TState> {

  render() {

      const id = this.props.match.params.id;
      const cabin = find(this.props.cabins || [], b => b.cabinId === id);
    const { cabinFormValues } = this.props;
    return (
        <div>
            <Flex>
              <Box w={[1, 1/2]}>
                  <Details>
                      <DescriptionPart>
                          <Header theme={theme}>
                              <Title>
                                  Pending Bookings
                              </Title>
                          </Header>
                          <Text>
                              {this.props.bookings && <PendingBookings cabinId={this.props.cabinId} {...this.props}/>}
                          </Text>
                      </DescriptionPart>
                  </Details>
              </Box>
              <Box w={[1, 1/2]} pl={1}>
                  <Details>
                      <DescriptionPart>
                          <Header>
                              <Title>
                                  Upcoming bookings
                              </Title>
                          </Header>
                          <Text>
                              {this.props.bookings && <UpcomingBookings cabinId={this.props.cabinId} {...this.props}/>}
                          </Text>
                      </DescriptionPart>
                  </Details>
              </Box>
            </Flex>
            <Flex>
              <Box w={[1]}>
                  <Details>
                      <DescriptionPart>
                          <Header>
                              <Title>
                                  Calendar
                              </Title>
                          </Header>
                          <Text>
                              <h3>Filter</h3>
                              <Field
                                  name="calendarFilter.bookingsPending"
                                  component={CustomCheckBox}
                                  type="checkbox"
                                  userCard
                                  label="Bookings (Pending)"
                                  elementId="bookingsPending"
                              />
                              <Field
                                  name="calendarFilter.bookingsBooked"
                                  component={CustomCheckBox}
                                  type="checkbox"
                                  userCard
                                  label="Bookings (Booked)"
                                  elementId="bookingsBooked"
                              />
                              <Field
                                  name="calendarFilter.bookingsDeclined"
                                  component={CustomCheckBox}
                                  type="checkbox"
                                  userCard
                                  label="Bookings (Declined)"
                                  elementId="bookingsDeclined"
                              />
                              <Field
                                  name="calendarFilter.datebased"
                                  component={CustomCheckBox}
                                  type="checkbox"
                                  userCard
                                  label="Calendar based pricing range"
                                  elementId="datebased"
                              />
                              <Field
                                  name="calendarFilter.periodbased"
                                  component={CustomCheckBox}
                                  type="checkbox"
                                  userCard
                                  label="Period based pricing range"
                                  elementId="periodbased"
                              />
                          </Text>
                          <Text>
                              <Calendar cabin={this.props.cabins[this.props.cabinId]} filter={this.props.cabinFormValues} bookings={this.props.bookings} />
                          </Text>
                      </DescriptionPart>
                  </Details>
              </Box>
            </Flex>
        </div>
    );
  }
}

CabinAdminForm = reduxForm({
    form: 'CabinAdminForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(CabinAdminForm);



export default  connect(null)(CabinAdminForm);
