import React, {Component} from 'react';
import {polyfill} from 'smoothscroll-polyfill';
import {QRCodeSVG} from 'qrcode.react';
import StickyMenu from './components/StickyMenu';
import Footer from '../../components/Footer';
import Title from './components/Typography/Title';
import Wrapper from '../../components/Typografy/Wrapper';
import LongLine from './components/Typography/LongLine';
import LogoImage from './components/Typography/LogoImage';
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import {mx} from "../../utils";

import {BrowserView, isAndroid, isIOS} from "react-device-detect";
import {firebaseDb} from "../../../common/config/firebase_config";
import {buildFaqContent, readAccountIcon} from "../../../common/config/firebase_config/endpoints";
import FirebaseContainer from "../../../common/firebase/FirebaseContainer";
import QASection from "./components/QASection";
import DownloadLink from "./components/AppLink";

import appleDownload from "../../assets/images/apple-download.png";
import androidDownload from '../../assets/images/android-download.png';
import bgShapeMini from '../../assets/images/bg-shape-mini.png';
import bgShapeSmall from '../../assets/images/bg-shape-small.png';
import bgShapeMedium from '../../assets/images/bg-shape-medium.png';
import H3 from "../../components/Typografy/H3";

let howToLogin = {
  title: "Hvordan komme igang",
  steps: {
    1: {
      stepText: "Instruksjoner",
      childSteps: {
        1: `Åpne denne siden med din telefon`,
        2: `For å få [appName] til din telefon må du først laste ned appen "Tradebroker Benefits" til din telefon via knappen over - "Download on the AppStore"`,
        4: `Klikk videre "Åpne i AppStore"`,
        5: 'Klikk for å installere appen',
        6: 'Tradebroker-appen laster nå ned!',
        7: 'Registrer med din bedrifts-epost (husk at eposter med kode fra oss kan havne i søppel-mappen)'
      }
    }
  }
};

let howToLoginAndroid = {
  title: "Hvordan komme igang",
  steps: {
    1: {
      stepText: "Instruksjoner",
      childSteps: {
        1: `Åpne denne siden med din telefon`,
        2: 'For å få [appName] til din telefon må du først laste ned appen "Tradebroker Benefits" til din telefon via lenken over',
        3: 'Klikk på "Get it on Google Play"',
        4: 'Du vil så bli sendt til Google Play Store',
        5: 'Last derfra ned appen på vanlig måte',
        6: 'Du skal nå ha Tradebroker-appen!',
        7: 'Registrer med din bedrifts-epost (husk at eposter med kode fra oss kan havne i søppel-mappen)'
      }
    }
  }
};

const QRWrapper = styled.div`
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

const QRCode = ({appName}) => {
  return <QRWrapper
    style={{
		padding: "25px",
	    borderRadius: "20px",
	    backgroundColor: "#f8f8f8",
	    aspectRatio: 1,
	    height: "100%",
    }}
  >
	  <QRCodeSVG
	      value={`https://coveredpro.com/register/${appName}`}
	      size={256}
	      bgColor={"#f8f8f8"}
	      fgColor={"#000000"}
	      level={"H"}
	      includeMargin={false}
	  />
  </QRWrapper>
}

const UnknownPlatform = ({children}) => {
  if (((!isAndroid) && (!isIOS))) return children

  return null
}

const IOSView = ({children}) => {
  if (isIOS) return children

  return null
}

const AndroidView = ({children}) => {
  if (isAndroid) return children

  return null
}

const DownloadApple = styled.div`
  background: url(${appleDownload});
  width: 200px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
  margin: auto;
`;

const DownloadAndroid = styled.div`
  background: url(${androidDownload});
  width: 200px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
  margin: auto;
`;


const DownloadSection = styled.div`
  max-width:400;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:100px;
  margin-top: 50px;
`

const mainWrapper = {
  paddingTop: '100px',
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.78);
`;

const AboveButtons = styled.div`
  width:80%;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
`;

const contentWrapperStyle = {
  maxWidth: 400,
  marginLeft: "auto",
  marginRight: "auto"
};


let companyName = "null";
let appName = "null";
let logo = "null";
let accountId = 0;
let enterprise = false;
let commonApp = false;



class FAQPage extends Component {

  constructor() {
    super();

    this.state = {
      appName: appName,
      companyName: companyName,
      logo: logo,
      accountId: accountId,
      enterprise: enterprise,
      commonApp: commonApp,
      color: '',
      suffix: '',
      isMembershipAccount: false,
      isKundeklubb: false,
    }
  }


  componentDidMount() {
    // Takes parameter. This needs to be checked with Firebase
    const param = this.props.match.params.appName;
    this._getAccountData(param);

  }
  _getQueries = () => {
    return ([{
      endpoint: buildFaqContent(),
      method: 'on',
      eventType: 'value',
      callback: this.props.onLoadFaqSuccess,
    },
    ]);
  };
  _getAccountData = (param) => {
    firebaseDb.ref().child('accounts').orderByChild('customerUrl').equalTo(param).once('value', snapshot => {
      snapshot.forEach((childSnapshot) => {
        companyName = childSnapshot.child('customerName').val();
        appName = childSnapshot.child('accountName').val();
        logo = childSnapshot.child('logoUrl').val();
        accountId = childSnapshot.child('accountId').val();
        enterprise = childSnapshot.child('enterpriseDistribution').val();
        commonApp = childSnapshot.child('commonAppDistribution').val();
        let icon = readAccountIcon(childSnapshot.child('id').val());
        let color = childSnapshot.child('theme').child('overrides').child('colors').child('accentColor').val();
        let isMembershipAccount = false;
	      const isKundeklubb = accountId === "10153";
        let hasEntitlementsResolution = childSnapshot.child('entitlementsResolution').val();
        if (hasEntitlementsResolution) {
          let isApluss = childSnapshot.child('entitlementsResolution').child('value').val() === "apluss";
          if (isApluss) isMembershipAccount = true;
        }

        this.setState(() => {
          return {
            appName: appName,
            companyName: companyName,
            logo: icon,
            accountId: accountId,
            enterprise: enterprise,
            commonApp: commonApp,
            color: color,
            suffix: commonApp ? '&commonApp=true' : !enterprise ? '&notEnterprise=true' : '',
            isMembershipAccount: isMembershipAccount,
	          isKundeklubb,
          }
        });
      })
    });
  };


  onMobileContent = () => {
    if(!(isAndroid || isIOS)) return null


    let data = isIOS ? howToLogin : howToLoginAndroid
    console.log("Rendering; " + JSON.stringify(data))
    return (
        <FirebaseContainer queries={this._getQueries()}>
          <div style={contentWrapperStyle}>
            <QASection
                btnColor={this.state.color}
                key={data.title}
                content={data}
                accountData={{appName: this.state.appName, companyName: this.state.companyName}}
            />
          </div>
        </FirebaseContainer>)
  };

  render() {

    if (this.state.appName === "null") {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    polyfill();
    return (
      <PageWrapper>
        <StickyMenu bgColor={this.state.color} />
        <Wrapper style={mainWrapper}>
          <Title style={{fontSize: "3em", marginBottom: "10px"}}>
            Velkommen til {this.state.appName}
          </Title>
          <div style={{ margin: "auto", width: 300 }}>
            <LogoImage src={this.state.logo} alt={"logo"} />
          </div>
          <LongLine style={{marginTop: "20px"}} color={this.state.color} />
          <DownloadSection>
<div>
              <h2 style={{ textAlign: 'center' }}>
	              {this.state.appName} er {this.state.companyName} sin løsning for å få en oversikt over dine fordeler.
              <IOSView>
              <span> Her laster du ned appen til din mobil.</span>
              {this.state.isKundeklubb ?
	              <H3><br /><br />OBS: For å bruke Tradebroker Kundeklubb må man <a href={"https://coveredpro.com/tradebroker-kundeklubb"}>registrere seg her.</a><br /><br /></H3> :
	              null
              }
              </IOSView>
              <AndroidView>
              <span> Her laster du ned appen til din mobil.</span>
              {this.state.isKundeklubb ?
	              <H3><br /><br />OBS: For å bruke Tradebroker Kundeklubb må man <a href={"https://coveredpro.com/tradebroker-kundeklubb"}>registrere seg her.</a><br /><br /></H3> :
	              null
              }
              </AndroidView>
                <BrowserView>
                Denne siden må åpnes på mobiltelefonen din.
                <br />
                <br />
                Skann QR-koden nedenfor med kameraet på mobiltelefonen din og følg lenken videre. På nettsiden som åpner seg finner du videre informasjon om nedlastning fra enten App Store eller Google Play, avhengig av din mobiltelefon.
                <br />
                <br />
                <br />
                {this.state.isKundeklubb ?
	                <H3><br /><br />OBS: For å bruke Tradebroker Kundeklubb må man <a href={"https://coveredpro.com/tradebroker-kundeklubb"}>registrere seg her.</a><br /><br /></H3> :
	                null
				}
				<div style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
                    <QRCode appName={this.props.match.params.appName} />
				</div>
                <br />
                <br />
                </BrowserView>
              </h2>
            </div>
            <BrowserView >
              <AboveButtons>
              </AboveButtons>
            </BrowserView>
            <IOSView>
              <h3 style={{textAlign: 'center'}}>
                Trykk på logoen under for å laste ned {this.state.appName}
              </h3>
              <DownloadLink isApple={true} data={this.state}>
                <DownloadApple></DownloadApple>
              </DownloadLink>
              <br />
              <br />
              OBS: Du må registrere deg med din jobb-epost. Under registreringen vil du motta en kode. Sjekk søppelmappen din hvis du ikke finner den.

            </IOSView>
            <AndroidView>
              <h3 style={{textAlign: 'center'}}>
                Trykk på logoen under for å laste ned {this.state.appName}
              </h3>
              <DownloadLink isApple={false} data={this.state}>
                <DownloadAndroid></DownloadAndroid>
              </DownloadLink>
              <br />
              <br />
              OBS: Du må registrere deg med din jobb-epost. Under registreringen vil du motta en kode. Sjekk søppelmappen din hvis du ikke finner den.

            </AndroidView>
          </DownloadSection>
          <br/>
        </Wrapper>
        <Footer />
      </PageWrapper>
    );
  }
}

export default FAQPage;
