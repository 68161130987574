
/**
 * @flow
 */


const onCategoriesLoadMessage = (payload, accountId, isDefault) => ({
  type: 'LOAD_CATEGORIES_SUCCESS',
  payload,
  isDefault,
  accountId
});



export const onCategoriesLoadSuccess = (payload, isDefault) => async (dispatch, getState) => {
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    dispatch(onCategoriesLoadMessage(payload, accountId, isDefault));
  }


