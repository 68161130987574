/**
 * @flow
 */

import React, { Component } from 'react';
import moment from 'moment'
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme';
import { mx } from '../../../utils';
import {Card, CardTitle} from '../../../components/Elements'

const AccountsContainer = styled.div`
  background-image: url(${require('../../../assets/images/bg_benefits_details.png').default});
  min-height: 20em;
  background-position: right;
  ${mx('lg')} {
    background-position: bottom;
  }
  `;

const Title = styled.h3`
  color: ${theme.benefits.questionTitle};
  font-weight: bold;
  font-size: 1.0em;
  display: flex;
  justify-content: center;
  padding-top: 0em;
  margin-top:0em
  `;

const Line = styled.div`
  margin-top: 0.4em;
  `;

const Content = styled(Flex)`
  margin-top: 2em;
  `;




const DeployBox = styled(Box)`
  height: auto;
  border-radius: 3px;
  padding: 0.32em;
  background-color: ${theme.benefits.textColor};
  margin-bottom: 0.1em;
  box-sizing: border-box;
  display: flex;
  max-width: 35vw;
  margin-left:auto;
  margin-right:auto;
  border-style: solid;
  border-width: 1px;
  ${mx('lg')} {
    margin-bottom: 0.2em;
    max-width: 55vw;
  }
  `;

/*
 &:last-of-type {
 margin-right: 0;
 }

 */

const Text = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  `;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.nav.link};
  padding: 7px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin-right: 30px;
  ${mx('lg')} {
    margin-right: 30px;
  }
  `;

const CircleNumber = styled.h1`
  display: flex;
  align-items: center
  color: ${theme.benefits.textColor};
  font-size: 1.5em;
  `;

const Link = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 600;
  color: ${theme.nav.link};
  cursor: pointer;
  `;

type TProps = {
  steps: Array<Object>,
};



const DeployLine = ({data}) => {
return(
    <DeployBox w={[1]}>
      Version: {data.version}<br/>
      Branch: {data.branch}<br/>
      Date: {moment(data.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
    </DeployBox>
)

}

const DeploySection = (props) => {

  if(!props.deploy) return null

  const deploy = props.deploy

  const hasAndroid = deploy && deploy.deploy && deploy.deploy.android
  const hasIos = deploy && deploy.deploy && deploy.deploy.ios
  const hasPublished = deploy && deploy.publish && deploy.publish.undefined

  const published = hasPublished ? Object.keys(deploy.publish.undefined).map(key => deploy.publish.undefined[key]) : []
  const androidDeploys = hasAndroid ? Object.keys(deploy.deploy.android).map(key => deploy.deploy.android[key]) : []
  const iosDeploys = hasIos ? Object.keys(deploy.deploy.ios).map(key => deploy.deploy.ios[key]) : []

  return(
      <Card wrap={true}>
        <Box w={[1]}>
          <CardTitle>
            Release info
          </CardTitle>
        </Box>
        {hasPublished && <Title>Publish events</Title>}
        {published.map(p => <DeployLine key={p.timestamp} data={p}/>)}
        {hasPublished && <Line/>}
        {hasAndroid && <Title>Android deploys</Title>}
        {androidDeploys.map(p => <DeployLine key={p.timestamp} data={p}/>)}
        {hasAndroid && <Line/>}
        {hasIos && <Title>Ios deploys</Title>}
        {iosDeploys.map(p => <DeployLine key={p.timestamp} data={p}/>)}

      </Card>
  )
}


class AccountSection extends Component<void, TProps, void> {
  render() {
    return (
        <DeploySection {...this.props} />
    );
  }
}


export default AccountSection;

