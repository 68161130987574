import {firebaseDb} from '../../config/firebase_config/firebase';
import {
  buildCategory,
  buildCategoryArchive,
} from '../../config/firebase_config/endpoints';

import history from '../../utils/history'
import {NotificationManager} from 'react-notifications';


function updateCategoryRequest() {
  return {
    type: 'UPDATE_CATEGORY_REQUEST',
  };
}

function updateCategorySuccess() {
  return {
    type: 'UPDATE_CATEGORY_SUCCESS',
  };
}

function updateCategoryError(json) {
  return {
    type: 'UPDATE_CATEGORY_ERROR',
  };
}




export const updateCategory = (formValues, category) => async (dispatch, getState) => {
  try {
    dispatch(updateCategoryRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let categoryId = (store.categories && store.categories.data ? Object.keys(store.categories.data).length + 1: 0) + ''

    if(formValues.categoryId)
    {
      categoryId = formValues.categoryId
    }
    else if(category && category.category)
    {
      categoryId = category.category
    }

    let updates = {};


    let storableData = JSON.parse(JSON.stringify(formValues));
    storableData.categoryId = categoryId;

    updates[buildCategory(accountId, categoryId)] = storableData;

    console.log("update category called")
    await firebaseDb.ref().update(updates);

    dispatch(updateCategorySuccess());
    NotificationManager.success('Success!', 'Category saved!', 3000);
    if (!category) {
      history.replace('/admin/setup/categories/' + categoryId + "/edit");
    }
  } catch (err) {
    console.log("update category ERROR: "+ JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateCategoryError());
  }
};


export const archiveCategory = (accountId, category) => async (dispatch, getState) => {
  try {
    dispatch(updateCategoryRequest());
    const store = getState();
    //const accountId = store.auth.profile.data.accountId;
    const categoryId = category.category;
    let categoryKey = category.categoryId;


    let updates = {};

    updates[buildCategoryArchive(accountId, categoryKey)] = category;

    updates[buildCategory(accountId, categoryKey)] = null;

    console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))

    await firebaseDb.ref().update(updates);

    dispatch(updateCategorySuccess());
    NotificationManager.success('Success!', 'Category archived!', 3000);
    history.replace('/admin/categories')

  } catch (err) {
    NotificationManager.error('Error!', JSON.stringify(err), 3000);
    dispatch(updateCategoryError(err));
  }
};

export const unArchiveCategory = (accountId, category) => async (dispatch, getState) => {
  try {
    dispatch(updateCategoryRequest());
    const store = getState();
    //const accountId = store.auth.profile.data.accountId;
    const categoryId = category.category;
    let categoryKey = category.categoryId;


    let updates = {};


    updates[buildCategory(accountId, categoryKey)] = category;

    updates[buildCategoryArchive(accountId, categoryKey)] = null;

    console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))

    await firebaseDb.ref().update(updates);

    dispatch(updateCategorySuccess());
    NotificationManager.success('Success!', 'Category unarchived!', 3000);
    history.replace('/admin/categories-archive');

  } catch (err) {
    NotificationManager.error('Error!', JSON.stringify(err), 3000);
    dispatch(updateCategoryError(err));
  }
};
