/**
 * @flow
 */

import React from 'react';
import LinkStyleIcon from './LinkStyleIcon';

const CallIcon = () => {
  return (
    <LinkStyleIcon>&#xe80D; </LinkStyleIcon>
  );
};

export default CallIcon;

