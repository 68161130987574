import React, { Component } from  'react';
import { Flex, Box } from 'reflexbox';
import {CardTitle} from '../../../components/Elements'
import styled from "styled-components";
import {enDateFromTo} from "../../../../common/utils/formatDate";
import {SaveButton} from "../../../components/Buttons";

const Wrapper = styled.div`
  border-left:1px solid gray; 
  padding:15px;
  width:100%;
`;

const DetailWrapper = styled.div`
    margin-top:10px;
`;

const DetailLabel = styled.div`
    font-size:.8em;
`;

const DetailValue = styled.div`
    font-size:1.1em;
`;

const BookingWrapper = styled.div`
    background-color: #fff;
    border-radius:5px;
    padding:15px;
    margin-bottom:10px;
`;

class CabinBookingList extends Component {

    buildItem = (bookingId) => {
        const { allBookings, cabins } = this.props;
        console.log("CABINS", cabins);
        console.log("CABIN OF BOOKING CABIN ID", cabins[allBookings[bookingId].cabinId]);
        return (
            <BookingWrapper key={bookingId}>
                <Flex>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                Person Name
                            </DetailLabel>
                            <DetailValue>
                                {allBookings[bookingId].user_name}
                            </DetailValue>
                        </DetailWrapper>
                        <DetailWrapper>
                            <DetailLabel>
                                Person Email
                            </DetailLabel>
                            <DetailValue>
                                {allBookings[bookingId].user_email}
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                Cabin
                            </DetailLabel>
                            <DetailValue>
                                {cabins && cabins[allBookings[bookingId].cabinId] && cabins[allBookings[bookingId].cabinId].title}
                            </DetailValue>
                            <DetailLabel>
                                Period
                            </DetailLabel>
                            <DetailValue>
                                {enDateFromTo(allBookings[bookingId].start_date, allBookings[bookingId].end_date, true)}
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                </Flex>
                <SaveButton type={'button'} style={{width: '100%', marginTop: 15, marginBottom: 0}} onClick={() => this.props.history.push(`/admin/cabins/${allBookings[bookingId].cabinId}/admin`)}>VIEW BOOKING</SaveButton>
            </BookingWrapper>
        )
    }

    render() {
        const { allBookings } = this.props;

        console.log("==ALL BOOKINGS==", allBookings);

        return(
            <Wrapper>
                <CardTitle>
                    Pending Bookings
                </CardTitle>
                {Object.keys(allBookings).length && Object.keys(allBookings).map((key) => {
                        console.log("HERERERE");
                    if (allBookings[key].status === 1) {
                        return this.buildItem(key);
                    }
                })}
            </Wrapper>
        )
    }
}

export default CabinBookingList;
