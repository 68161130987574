/**
 * @flow
 */


import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import SetPasswordPage from './SetPasswordPage';
import {setPassword} from '../../../../common/auth/admin/actions';
import type { TAppState } from '../../../../common/TAppState';

const Container = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     setPasswordFormValues: getFormValues('SetPasswordForm')(state),
       processing: state.userAdmin.setPasswordProcessing,

   };
 },
 (dispatch) => ({
     setPassword: (data, accountId) => dispatch(setPassword(data, accountId)),
 }),
)(SetPasswordPage);
export default Container;
