/**
 * @flow
 */
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import CabinAdmin from './CabinAdmin';
import type { TAppState } from '../../../../common/TAppState';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {onCabinsLoadSuccess, updateCabin, updateDraftCabin} from "../../../../common/cabins/actions";
import {approveBooking, onBookingLoadSuccess, requestBooking} from "../../../../common/cabins/actions/bookings";

const CabinAdminContainer = connect(
  (state: TAppState, dispatch) => {
    return {
        accountId: state.auth.profile.data.accountId,
        accountsLoaded: state.accounts.isLoaded,
        accounts: state.accounts.data,
        cabins: state.cabins.data,
        isLoaded: state.cabins.isLoaded,
        cabinFormValues: getFormValues('CabinAdminForm')(state),
        profile: state.auth.profile.data,
        bookings: state.cabins.bookings,
        bookingFormValues: getFormValues('CabinBookingForm')(state),
    };
  },
  (dispatch) => ({
      onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
      onCabinsLoadSuccess: (data, metadata, account) => dispatch(onCabinsLoadSuccess(data, metadata, account)),
      onBookingsLoadSuccess: (data, metadata, account) => dispatch(onBookingLoadSuccess(data, metadata, account)),
      updateDraftCabin: (data, cabin) => dispatch(updateDraftCabin(data, cabin)),
      updateCabin: (data, cabin, publish) => dispatch(updateCabin(data,cabin,publish)),
      approveBooking: (cabinId, bookingId, status) => dispatch(approveBooking(cabinId, bookingId, status)),
      requestBooking: (data, cabinId) => dispatch(requestBooking(data, cabinId)),
  })
)(CabinAdmin);

export default CabinAdminContainer;
