/**
 * @flow
 */

import { connect } from 'react-redux';

import LandingPage from './LandingPage';
import {handleAuthentication} from "../../common/auth/signIn/actions";

const LandingPageContainer = connect(
  null,
    (dispatch) => ({
        handleAuthentication: (history) => dispatch(handleAuthentication(history)),
    }),
)(LandingPage);

export default LandingPageContainer;
