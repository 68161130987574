/**
 * @flow
 */

import createReducer from '../createReducer';
import type { TRestoreState } from './TRestoreState';
import type { THandler } from '../types/THandler';

const initialState: TRestoreState = {
  error: null,
  isRestored: false,
};

const handlers: THandler<TRestoreState> = {
  RESTORE_STATE_REQUEST: () => {
    return initialState;
  },
  RESTORE_STATE_FAILURE: (state, action) => {
    return { ...state, error: action.payload };
  },
  RESTORE_STATE_SUCCESS: (state) => {
    console.log("RESTORING STATE")
      console.log(state)
    return { ...state, isRestored: true };
  },
};

export default createReducer(initialState, handlers);
