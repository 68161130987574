import styled from "styled-components";
import theme from '../theme';
import {
    Flex,
    Box,
} from 'reflexbox';

export const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;

export const Card = styled(Flex)`
  margin-bottom: 2em;
  padding: 1.7em 1.8em 3em 1.8em;
  box-shadow: 0px 0px 13px 1px rgba(0,0,0,0.3);
  background: ${theme.userProfile.cardBackground};
  border-radius: 20px;
`;

export const CardTitle = styled.h2`
  font-size: 1.25em;
  padding-bottom: 1em;
  //text-transform: uppercase;
  border-bottom: 1px solid  ${theme.userProfile.cardBorder};
`;
