import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme';
import Link from "../../../components/Typografy/Link";
import {firebaseAuth} from "../../../../common/config/firebase_config/firebase";
import AsyncStorage from "../../../../common/utils/AsyncStorage";
import {auth0Logout} from "../../../../common/config/auth/auth0";
import {hasAdminAccessToAccount, hasMultiple, hasSuperAdminAccess} from "../../../../common/auth";



const MenuLink = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.1em 0.1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 0.3em;
  width: 100%;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;


const MenuClick = styled.div`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.1em 0.1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 0.3em;
  width: 100%;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const MenuWrap = styled.div`
  margin-bottom: 0.2em;
  margin-right: 0.2em;
  width: 100%;

`;



export const logoutAccount = async (history) => {
  try {
    await firebaseAuth.signOut();
    await AsyncStorage.clear();
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('@CoveredPro:PROFILE');

      window.location.href = auth0Logout

      //history.push('/');
  } catch (error) {
    alert(error)

  }
};

const MenuItems = ({ open, history, toggleMenu, profile }) => {
  return (
    <div className={open ? 'open' : ''} id="nav-icon3">
      <MenuWrap><MenuClick onClick={() => logoutAccount(history)} >Logout</MenuClick></MenuWrap>
        { (hasMultiple(profile) || hasAdminAccessToAccount(profile.selectedAccount)) && <MenuWrap><MenuLink onClick={() => toggleMenu()} to="/selectAccount">Switch accounts</MenuLink></MenuWrap> }
    </div>
  );
};



const Container = styled(Flex)`
  z-index: 4;
  position: relative;
`;


const MenuContainer = styled(Box)`
  display: ${props => props.open ? 'flex' : 'none'};
  position: absolute;
  background: ${theme.nav.menuContainer};
  padding: 1.2em;
  top: 3.25em;
  left: -2em;
  right: 0em;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
       width: 200px;


`;




class AdminMenu extends React.Component {

  state = {
    open: false,
  };

  toggleMenu = () => {
    console.log("Adminmenu toggled")
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open && !this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    } else if (!prevState.open && this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: hidden';
    }
  }

  componentWillUnmount() {
    // If we navigate out of this view and the menu is open, make sure to restore the overflow
    if (this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    }
  }

  render() {
    const { open } = this.state;
    const { children } = this.props;
    return (
      <div>
        <Container wrap align="center" justify="space-between">
          <MenuContainer open={open} >
            <MenuItems toggleMenu={this.toggleMenu} history={this.props.history} profile={this.props.profile} />
          </MenuContainer>
        </Container>
        <div onClick={this.toggleMenu} open={open} >
          {children}
        </div>
      </div>
    );
  }
}
export default AdminMenu;
