/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import logInPhone from '../../../assets/images/phone_login.png';
import employeeCardPhone from '../../../assets/images/phone_profile.png';
import mapPhone from '../../../assets/images/phone_messages.png';
import ratingPhone from '../../../assets/images/phone_home.png';

import test from "../../../assets/images/phone_test.png";

const images = {
  logInPhone: { src: logInPhone, width: 200, height: 397 },
  employeeCardPhone: { src: employeeCardPhone, width: 197, height: 399 },
  mapPhone: { src: mapPhone, width: 198, height: 401 },
  ratingPhone: { src: ratingPhone, width: 198, height: 400 },
};

type Props = {
  name: string,
};

const Image = styled.img`
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  width: 250px;
  overflow: hidden;
  `;

const PromoPhone = ({ name }: Props) => {
  const img = images[name];
  return (
    <Container>
      <Image
        src={img.src}
        width={img.width}
        height={img.height}
      />
    </Container>

  );
};

export default PromoPhone;
