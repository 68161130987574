import {firebaseStorage} from '../../config/firebase_config/firebase';
import {buildBenefitImage} from '../../config/firebase_config/endpoints';

const storageRef = firebaseStorage.ref();

const uploadImage = (accountId, benefitId, file, callback) => {
  const uploadTask = storageRef.child(buildBenefitImage(accountId, benefitId, file.name)).put(file);

  uploadTask.on('state_changed', (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
  }, function(error) {
    // Handle unsuccessful uploads
  }, function() {
    const downloadURL = uploadTask.snapshot.downloadURL;
    callback(downloadURL);
  });
};


export const uploadAnyImage = (url, fileString, callback, errorCallback) => {
    const uploadTask = storageRef.child(url).put(fileString);

    uploadTask.on('state_changed', (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
    }, function(error) {
        errorCallback()
    }, function() {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log('File available at', downloadURL);
            callback(downloadURL);
        });
    });
};



const uploadImageString = (accountId, benefitId, fileString, callback, errorCallback) => {
  const uploadTask = storageRef.child(buildBenefitImage(accountId, benefitId, 'main.png')).put(fileString);

  uploadTask.on('state_changed', (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
  }, function(error) {
    errorCallback()
  }, function() {

      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL);
          callback(downloadURL);
      });
  });
};


export default uploadImageString;
