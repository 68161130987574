/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TWhitelistApiData,
  TWhitelistResponse,
} from '../../../../common/types/api/TWhitelist';
import WhitelistList from './WhitelistList';
import WhitelistEdit from '../WhitelistEdit';
import SubHeader from './SubHeader';


const WhitelistsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const WhitelistBackground = styled.div`
  background-color: rgb(20, 20, 20);
  margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  whitelists: TWhitelistApiData;
  onWhitelistsLoadSuccess: (data: TWhitelistResponse) => void;
};

class WhitelistPage extends React.Component<void, TProps, TState> {
  render() {
    return (
      <WhitelistsWrap>
        <WhitelistBackground>
          <SubHeader />
        </WhitelistBackground>
        <Wrapper>
          <WhitelistEdit
            accountId={this.props.accountId}
            updateWhitelist={this.props.updateWhitelist}
            whitelistFormValues={this.props.whitelistFormValues}
            whitelist={this.props.whitelist}
          ></WhitelistEdit>
        </Wrapper>
        <Wrapper>

          <WhitelistList
            accountId={this.props.accountId}
            whitelist={this.props.whitelist}
            onWhitelistsLoadSuccess={this.props.onWhitelistsLoadSuccess}
            removeFromWhitelist={this.props.removeFromWhitelist}
          />
        </Wrapper>

      </WhitelistsWrap>
    );
  }
}

export default WhitelistPage;
