import React from 'react';
import Loader from 'react-loader-spinner'
import styled from "styled-components";


const SpinOuter = styled.div`
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SpinInner = styled.div`
  margin: auto;
`

const Spinner = ({width, height}) => <SpinOuter><SpinInner><Loader type="Circles" color="#f41a4d"  height={(height || 200)} width={(width || 200)}/></SpinInner></SpinOuter>
export default Spinner