/**
 * @flow
 */
import { connect } from 'react-redux';

import UpdateGoogleServices from './UpdateGoogleServices';
import type { TAppState } from '../../../../common/TAppState';
import { onGoogleServicesLoadSuccess, updateGoogleServices } from '../../../../common/googleservices/actions';

const UpdateGoogleServicesContainer = connect(
  (state: TAppState, dispatch) => {
    return {
    };
  },
  (dispatch) => ({
      onGoogleServicesLoadSuccess: (data) => dispatch(onGoogleServicesLoadSuccess(data)),
      updateGoogleServices: (data, account) => dispatch(updateGoogleServices(data, account)),
  })
)(UpdateGoogleServices);

export default UpdateGoogleServicesContainer;
