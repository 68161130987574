/**
 * @flow
 */

import React, {Component} from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import H3 from '../../../components/Typografy/H3';
import SmallText from '../../../components/Typografy/SmallText';
import Modal from '../../../components/Modal';
import {mx} from '../../../utils';
import type {TBenefitApiData} from '../../../../common/types/api/TBenefit';

const Details = styled.div`
  background-color:white;
  margin: 0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);

 ${mx('lg')} {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 1em;
 }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.backgroundColor : '#222222'};
  color: white;
  padding: 1em;
  `;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  `;

const Text = styled(SmallText)`
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  width: 80%;
  height: auto;
  padding:1em;
  `;

const BenefitDescriptionHeadline = styled.div`
  font-size: 1.4em;
  font-weight: bold;
`;

const BenefitAbout = styled.div`
  background-color: ${theme.benefits.backgroundColor}
  padding-bottom: 2em;
  `;

const DescriptionPart = styled.div`
  display:flex;
  flex-direction: column;
  ${mx('lg')} {
    display: flex;
    width: 100%;
  }
  `;

type TProps = {
  benefit: TBenefitApiData;
}

type TState = {
  isModalOpen: boolean;
  rating: number;
  ratingSelected?: boolean;
};

class AboutSection extends Component<void, TProps, TState> {

  state = {
    isModalOpen: false,
    rating: 0,
    ratingSelected: false,
  };

  render() {
    const {benefit, theme} = this.props;

    if(!benefit.description_long)
    {
      return(<div></div>)
    }

    console.log("THEMEE", theme);

    return (
      <BenefitAbout>
        <Wrapper>
          <Details>
            <DescriptionPart>
              <Header theme={theme}>
                <Title> Detaljer </Title>
              </Header>
              <Text>
                {benefit.description_long.split('\n').map((item, key) => {
                  if(item.startsWith('##')){
                    return <span key={key}><BenefitDescriptionHeadline>{item.substr(2)}</BenefitDescriptionHeadline><br/></span>
                  }
                  return <span key={key}>{item}<br/></span>
                })
                }
              </Text>
            </DescriptionPart>

          </Details>
        </Wrapper>
      </BenefitAbout>
    );
  }
}

export default AboutSection;
