import {
    buildAccounts, buildBookings, buildCabinBooking, buildCabinBookings, buildCabinConfig,
    buildCabins,
    buildCabinsArchive,
    buildCabinsDraft,
    buildCategories,
    buildCategoriesByAccount, buildUserBookings
} from "../../../../common/config/firebase_config/endpoints";
import base64 from 'base-64';

export const getAccounts = (onAccountsLoadSuccess) => {
    return ([{
        endpoint: buildAccounts(),
        method: 'on',
        eventType: 'value',
        callback: onAccountsLoadSuccess,
    }
    ]);
};

export const getCategories = (accounts, accountId, onCategoriesLoadSuccess) => {

    const accountSettings = accounts[accountId]


    if (accountSettings.categories) {
        console.log(`Categories are completely custom. `)
        return ([{
            endpoint: buildCategoriesByAccount(accountId),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: accountId
        }])
    }
    else if (accountSettings.cloneCategories) {
        const cloneAccountId = accountSettings.cloneCategories
        console.log(`Categories will be cloned from account ${cloneAccountId}.`)
        return ([{
            endpoint: buildCategoriesByAccount(cloneAccountId),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: cloneAccountId
        }])
    }
    else if (accountSettings.defaultCategories || (!(accountSettings.categories || accountSettings.cloneCategories))) {
        console.log("Default categories will be used...")
        return ([{
            endpoint: buildCategories('default'),
            method: 'on',
            eventType: 'value',
            callback: onCategoriesLoadSuccess,
            metadata: 'default'
        }])

    }


};

export const getCabins = (accounts, accountId, onCabinsLoadSuccess, isArchive, isDraft) => {



    const account = accounts[accountId]
    return [{
        endpoint: isArchive ? buildCabinsArchive(accountId) : isDraft ? buildCabinsDraft(accountId) : buildCabins(accountId),
        method: 'on',
        eventType: 'value',
        callback: onCabinsLoadSuccess,
        metadata: accountId,
        config: account
    }];
};

export const getCabinConfig = (onCabinConfigSuccess) => {

    return [{
        endpoint: buildCabinConfig(),
        method: 'on',
        eventType: 'value',
        callback: onCabinConfigSuccess,
    }];
};

export const getUserBookings = (accounts, accountId, userEmail, onUserBookingLoadSuccess) => {
    const account = accounts[accountId]
    return [{
        endpoint: buildUserBookings(accountId, base64.encode(userEmail)),
        method: 'on',
        eventType: 'value',
        callback: onUserBookingLoadSuccess,
        metadata: accountId,
        config: account
    }];
};

export const getAllBookings = (accounts, accountId, onAllBookingsLoadSuccess) => {
    const account = accounts[accountId]
    return [{
        endpoint: buildBookings(accountId),
        method: 'on',
        eventType: 'value',
        callback: onAllBookingsLoadSuccess,
        metadata: accountId,
        config: account
    }];
};
