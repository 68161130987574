/**
 * @flow
 */

import {combineReducers} from 'redux';

import profileReducer from './auth/profile/reducer';
import signInReducer from './auth/signIn/reducer';
import signUpReducer from './auth/signUp/reducer';
import resetPasswordReducer from './auth/resetPassword/reducer';
import restoreStateReducer from './storage/reducer';
import benefitsReducer from './benefits/reducer';
import messagesReducer from './messages/reducer';
import locationsReducer from './locations/reducer';
import cardReducer from './card/reducer';
import contactReducer from './contact/reducer';
import whitelistReducer from './whitelist/reducer';
import accountReducer from './accounts/reducer';
import domainReducer from './domains/reducer';
import divisionsReducer from './divisions/reducer';
import categories from './categories/reducer';
import googleservices from './googleservices/reducer';
import languages from './languages/reducer';
import helpaccountReducer from './helpaccount/reducer';
import signupaccountReducer from './signupaccount/reducer';
import stats from './stats/reducer';
import userAdmin from './auth/admin/reducer';
import benefitReportReducer from './benefitreports/reducer';
import retrieveData from './retrievedata/reducer';
import deploy from './deploy/reducer';
import theme from './theme/web/reducer';
import cabinReducer from './cabins/reducer';
import helpReducer from './help/reducer';

const commonReducer = {
    auth: combineReducers({
        profile: profileReducer,
        signIn: signInReducer,
        signUp: signUpReducer,
        resetPassword: resetPasswordReducer,
    }),
    benefits: benefitsReducer,
    messages: messagesReducer,
    locations: locationsReducer,
    card: cardReducer,
    restoreState: restoreStateReducer,
    contact: contactReducer,
    whitelist: whitelistReducer,
    accounts: accountReducer,
    domains: domainReducer,
    divisions: divisionsReducer,
    categories,
    googleservices,
    languages,
    faq: helpaccountReducer,
    signupaccountReducer,
    stats,
    userAdmin,
    benefitReports: benefitReportReducer,
    retrievedata: retrieveData,
    theme: theme,
    cabins: cabinReducer,
    deploy,
    help: helpReducer,
};

export default commonReducer;
