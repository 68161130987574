/**
 * @flow
 */


import {connect} from 'react-redux';
import BenefitPage from './BenefitPage';
import {onBenefitsLoadSuccess} from '../../../../common/benefits/actions/index';
import type {TAppState} from '../../../../common/TAppState';
import {onCategoriesLoadSuccess} from "../../../../common/categories/actions/index";
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {benefitDataTemplate, onBenefitsReset, onBenefitStatsLoadSuccess} from "../../../../common/benefits/actions";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";
import {onVendorVisibilityLoadSuccess} from "../../../../common/benefits/actions/benefitsLoad";

const DraftBenefitContainer = connect(
  (state: TAppState) => {
    return {
      languages: state.languages.data,
      accountId: state.auth.profile.data.accountId,
      benefits: state.benefits.data,
      isLoaded: state.benefits.isLoaded,
      accountsLoaded: state.accounts.isLoaded,
      accounts: state.accounts.data,
      isDraft: true,
        benefitStats: state.benefits.benefitStats,
        hiddenBenefits: state.benefits.hiddenBenefits.list,
    };
  },
  (dispatch) => ({
    onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
    onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
    onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
    onBenefitsReset: () => dispatch(onBenefitsReset()),
      onBenefitStatsLoadSuccess: (data) => dispatch(onBenefitStatsLoadSuccess(data)),
      onVendorVisibilityLoadSuccess: (payload) => dispatch(onVendorVisibilityLoadSuccess(payload)),
  }),
)(BenefitPage);
export default DraftBenefitContainer;
