/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import SignUp from './SignUp';
import { signUpAccount } from '../../../common/auth/signUp/actions';

const SignUpContainer = connect(
  (state: TAppState) => ({
    signUp: state.auth.signUp,
    signIn: state.auth.signIn,
  }),
  (dispatch) => ({
    signUpAccount: (data) => dispatch(signUpAccount(data)),
  }),
)(SignUp);

export default SignUpContainer;
