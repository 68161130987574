/**
 * @flow
 */

import React, { Component } from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';
import ArrowIcon from './components/ArrowIcon';
import AccountCard from './AccountCard';
import AccountSection from './AccountSection';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildAccounts, buildAccountsDeployStatus } from '../../../../common/config/firebase_config/endpoints';
import type {
  TAccountApiData,
  TAccountResponse,
} from '../../../../common/types/api/TAccount';
import Spinner from "../../../components/Spinner";
import {Flex} from "reflexbox";

const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png').default});
  padding-top: 1.6em;
`;

const InnerContainer = styled.div`
  padding-top: 1.6em;
`;


const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;

type TProps = {
  accountId: number;
  accounts: TAccountApiData;
  onAccountsLoadSuccess: (data: TAccountResponse) => void;
  params: Object;
  saveRating: Function,
};
const getSteps = (stepsSource) => {
  const steps = [];
  for (const key in stepsSource) {
    if (stepsSource[key]) {
      steps.push({
        text: stepsSource[key].text,
        type: stepsSource[key].type,
        id: key,
      });
    }
  }
  return steps;
};

class AccountDetails extends Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildAccounts(),
      method: 'on',
      eventType: 'value',
      callback: this.props.onAccountsLoadSuccess,
    },
      {
        endpoint: buildAccountsDeployStatus,
        method: 'on',
        eventType: 'value',
        callback: this.props.onDeployStatusLoadSuccess,
      }


    ]);
  };
  _getItem = () => {
    const id = this.props.match.params.id;
    const account = find(this.props.accounts || [], b => b.accountId === id);
    if (!account) return (<div></div>);

    const deploy = (this.props.deploys || {})[id];


    return (
      <div>
        <Container>
          <Wrapper>
            <BackLink to="/admin/accounts">
              <ArrowIcon />
              Accounts
            </BackLink>
            <AccountCard onArchive={this.props.onArchive} account={account} accountId={this.props.accountId} />
          </Wrapper>
        </Container>
          <InnerContainer>
            <Wrapper>
                <AccountSection deploy={deploy} />
            </Wrapper>
          </InnerContainer>
      </div>
    );
  }

  render() {
    return (
      <div>
        <FirebaseContainer queries={this._getQueries()}>
          {this.props.accountsLoaded ? this._getItem() : <Spinner/>}
        </FirebaseContainer>
      </div>
    );
  }
}


export default AccountDetails;

