/**
 * @flow
 */

import React, {
    Component,
} from 'react';

import SubHeader from './SubHeader';
import AccountForm from './AccountForm';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {buildAccounts, buildLanguages} from '../../../../common/config/firebase_config/endpoints';
import type {TProfileData} from '../../../../common/types/models/TAuth';
import find from "lodash/find"
import Spinner from '../../../components/Spinner'
import {updateAppCodes} from "../../../../common/accounts/actions";

type TProps = {
    profile: TProfileData;
}

class AccountEdit extends Component<void, TProps, void> {

    componentDidMount(){
        this.props.getAppCodeStatus(this.props.match.params.id)
    }

    _getQueries = () => {
        return ([{
            endpoint: buildAccounts(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAccountsLoadSuccess,
        },
            {
                endpoint: buildLanguages,
                method: 'on',
                eventType: 'value',
                callback: this.props.onLanguagesLoadSuccess,
            },
        ]);
    };

    _getItem = () => {
        const id = this.props.match.params.id;
        return find(this.props.accounts || [], b => b.accountId === id)
    }

    _getForm = (account) => {
        let initials = account || { defaultCategories:true, featureGraphic: {x: 0, y: 0, scale: 100}, splash: {x: 0, y: 0, scale: 100} }
        return (
            <AccountForm {...this.props}
                         hasAccount={account || false}
                         account={account}
                         accountId={(account || {}).accountId}
                         initialValues={initials} enableReinitialize={true}/>
        );
    };

    _innerRender = (hasAccount) => {
        if (hasAccount) {
            if (this.props.accountsLoaded) {
                const account = this.props.accounts ? this._getItem() : null
                return this._getForm(account)
            }
            else {
                return <Spinner/>
            }
        }
        else {
            return this._getForm()
        }


    }

    render() {
        const hasAccount = this.props.match.params.id ? true : false
        return (
            <div>
                <SubHeader hasAccount={hasAccount}/>
                <FirebaseContainer queries={this._getQueries()}>
                    <div>
                        {this._innerRender(hasAccount)}
                    </div>
                </FirebaseContainer>
            </div>
        );
    }
}

export default AccountEdit;
