/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import theme from '../../../theme/index';
import { mx } from '../../../utils';

const BenefitsContainer = styled.div`
  //background-image: url(${require('../../../assets/images/bg_benefits_details.png').default});
  min-height: 20em;
  background-position: right;
  ${mx('lg')} {
    background-position: bottom;
  }
  `;

const Title = styled.h3`
  color: ${theme.benefits.questionTitle};
  font-weight: bold;
  font-size: 2.0em;
  display: flex;
  justify-content: center;
  padding-top: 1em;
  margin-top: 0;
  `;

const Line = styled.hr`
  color: ${theme.nav.link};
  width: 5%;
  flex: 1;
  border-style: solid;
  border-width: 2px;
  `;

const Content = styled(Flex)`
  margin-top: 2em;
  `;

const BenefitBox = styled(Box)`
  height: auto;
  border-radius: 20px;
  padding: 2em;
  background-color: ${theme.benefits.textColor};
  margin-bottom: 1em;
  box-sizing: border-box;
  display: flex;
  max-width: 35vw;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  ${mx('lg')} {
    margin-bottom: 2em;
    max-width: 55vw;
    box-shadow: 0px 5px 20px 1px ${theme.benefits.shadow};
  }
  `;

const Wrapper = styled.div`
  max-width: 25%;
  margin: 0 auto;
  padding-left: 5em;
  padding-right: 5em;
  ${mx('lg')} {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

/*
 &:last-of-type {
 margin-right: 0;
 }

 */

const Text = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  `;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.nav.link};
  padding: 7px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin-right: 30px;
  ${mx('lg')} {
    margin-right: 30px;
  }
  `;

const CircleNumber = styled.h1`
  display: flex;
  align-items: center
  color: ${theme.benefits.textColor};
  font-size: 1.5em;
  line-height:0.2;
  `;

const Link = styled.a`
  text-decoration: none;
  //text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 600;
  color: ${theme.nav.link};
  cursor: pointer;
  `;

type TProps = {
  steps: Array<Object>,
};

const Step = ({ text, type, url, number }) => {

  return (
    <BenefitBox p={3}>
      <Circle>
        <CircleNumber>{number + 1}</CircleNumber>
      </Circle>
      <Text>
        {type === 'link' ?
          <Link href={url}>{text}</Link> : <div>{text}</div>
        }
      </Text>
    </BenefitBox>
  );
};


class BenefitSection extends Component<void, TProps, void> {
  render() {
    const { steps } = this.props;
    return (
      <BenefitsContainer>
        <Title> How to get it</Title>
        <Line />
        <Wrapper>
          <Content align="center" justify="space-between" wrap>
            {steps.map((step, index) => <Step key={index} number={index} {...step}/>)}
          </Content>
        </Wrapper>
      </BenefitsContainer>
    );
  }
}


export default BenefitSection;

