

/**
 * @flow
 */


import { connect } from 'react-redux';
import { onAccountsLoadSuccess } from '../../../common/accounts/actions';
import RegisterListPage from './RegisterListPage';

const RegisterListContainer = connect(
    (state) => {
        return {
            accountId: state.auth.profile.data.accountId,
            accounts: state.accounts.list,
        };
    },
    (dispatch) => ({
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    }),
)(RegisterListPage);
export default RegisterListContainer;
