/**
 * @flow
 */


import { connect } from 'react-redux';
import CabinPage from './CabinPage';
import { onAccountsLoadSuccess  } from '../../../../common/accounts/actions';
import type { TAppState } from '../../../../common/TAppState';
import {onCabinsLoadSuccess} from "../../../../common/cabins/actions";
import {onAllBookingLoadSuccess, onUserBookingLoadSuccess} from "../../../../common/cabins/actions/bookings";

const CabinPageContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
       userEmail: state.auth.profile.data.email,
     accountsLoaded: state.accounts.isLoaded,
     accounts: state.accounts.data,
     cabins: state.cabins.data,
     isLoaded: state.cabins.isLoaded,
       userBookings: state.cabins.userBookings,
       userBookingsLoaded: state.cabins.userBookingsLoaded,
   };
 },
 (dispatch) => ({
     onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
     onCabinsLoadSuccess: (data, metadata, account) => dispatch(onCabinsLoadSuccess(data, metadata, account)),
     onAllBookingLoadSuccess: (payload , metadata, config) => dispatch(onAllBookingLoadSuccess(payload, metadata, config)),
     onUserBookingLoadSuccess: (payload, metadata, config) => dispatch(onUserBookingLoadSuccess(payload, metadata, config))
 }),
)(CabinPage);
export default CabinPageContainer;
