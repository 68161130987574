import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import Translate from '../../../../components/form/Translate';
import ImageUploaderModal from '../../../../components/form/ImageUploaderModal';

import {buildCategoryImageUpload, readCategoryImage} from '../../../../../common/config/firebase_config/endpoints'
import CustomCategories from "./CustomCategories";
import {Card, CardTitle} from "../../../../components/Elements";
import Vendors from "./Vendors";
import SingleValueDivisions from "./SingleValueDivisions";
import MultiValueDivisions from "./MultiValueDivisions";

class Divisions extends Component<void, TProps, TState> {

    render() {

        return (
            <div>
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Single value Divisions
                    </CardTitle>
                </Box>
                <Box w={[1]}>
                    <FieldArray name="divisions.singleValue" accountId={this.props.accountId} accounts={this.props.accounts} accountFormValues={this.props.accountFormValues} component={SingleValueDivisions}/>
                </Box>
            </Card>
                <Card wrap={true}>
                    <Box w={[1]}>
                        <CardTitle>
                            Multi value Divisions
                        </CardTitle>
                    </Box>
                    <Box w={[1]}>
                        <FieldArray name="divisions.multiValue" accountId={this.props.accountId} accounts={this.props.accounts} accountFormValues={this.props.accountFormValues} component={MultiValueDivisions}/>
                    </Box>
                </Card>
            </div>
        );
    }
}
export default Divisions
