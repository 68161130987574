/**
 * @flow
 */

import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import ResponsiveImage from '../../../../components/Typografy/ResponsiveImage';
import Wrapper from '../../../../components/Typografy/Wrapper';
import { mx } from '../../../../utils';


const Container = styled(Flex)`
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 4;
  position: relative;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;

  ${mx('lg')} {
    margin-bottom: 81px;
    box-shadow: 0px -3px 5px 4px rgba(0, 0, 0, 0.4);
  }
`;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  `;

const LogoWrapper = styled.div`
  flex: 1;
`;

type TProps = {
  bg?: boolean;
  bgColor: string;
}

type TState = {
  open: boolean;
}

class StickyMenu extends Component<void, TProps, TState> {


  render() {
    console.log("BGColor", this.props.bgColor);
    return (
      <div>
        <Container wrap align="center" justify="space-between" style={{ backgroundColor: this.props.bgColor}}>
          <HeaderWrapper>
            <LogoWrapper>
              <ResponsiveImage
                src="CoveredPro_logo.png"
                width={115}
                height={35}
                alt=""
              />
            </LogoWrapper>

          </HeaderWrapper>
        </Container>
      </div>
    );
  }
}
export default StickyMenu;
