/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';

const BenefitsCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;

const BenefitInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const BenefitTitle = styled.h2`
  font-size:50px;
  margin:0;
  text-align:center;
  color:black;
`;

const CabinCard = (props) => {
  const { cabin } = props;

  return (
    <BenefitsCard>
      <Flex wrap>
        <Box w={[1, 1]}>
          <BenefitInfoContainer>
            <BenefitTitle>{cabin.title}</BenefitTitle>
          </BenefitInfoContainer>
        </Box>
      </Flex>
    </BenefitsCard>
  );
};

export default CabinCard;
