/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from './Typografy/Wrapper';
import {SaveButton, CancelButton, DraftButton} from '../components/Buttons';
import { mx } from '../utils';



const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: #262626;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
`;

const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ButtonsContainer = styled.div`
   flex: 1;
   display: none;
   ${mx('lg')} {
    display: flex;
   }
`;

const Footer = ({to, pristine, onSave, actionText, cancelText, type}) => (
  <FooterWrapper>
    <Wrapper>
      <FooterFlex>
        <ButtonsContainer>
          {!pristine && <SaveButton type={type || 'button'} onClick={() => onSave()}>{actionText || 'Save'}</SaveButton> }
            {to && <CancelButton to={to}>{cancelText || 'Exit'} </CancelButton>}
        </ButtonsContainer>
      </FooterFlex>
    </Wrapper>
  </FooterWrapper>
);


export default Footer;
