/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {Card, CardTitle} from '../../../../components/Elements'

import Dropzone from '../../../../components/Dropzone';
import styled from 'styled-components';


import {NotificationManager} from "react-notifications";

import Text from '../../../../components/Typografy/Text'
import {readAccountMembershipTemplateFile} from "../../../../../common/config/firebase_config/endpoints";


const  DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;

const BlackText = styled(Text)`
  color: darkgray;
  margin-bottom:20px;
`;


const rABS = false // typeof FileReader !== "undefined" && (FileReader.prototype||{}).readAsBinaryString;


class AppcodeUploader extends Component {

    state: TState = {
        file: null,
        edit: false,
    };

    _onDrop = (acceptedFiles) => {
        console.log("onDrop with " + (rABS ? "readAsBinaryString" : "string"))
        const [file] = acceptedFiles;
        const setter = this
        const {update, accountId, account} = this.props
        const reader = new FileReader();
        reader.onload = () => {
            console.log("onDrop onLoad")
            let data = reader.result;
            if(!rABS) data = new Uint8Array(data);
            update(data, accountId, account.accountName)
        };
        reader.onabort = () => { NotificationManager.error('Error!', 'file reading was aborted', 3000); console.log('file reading was aborted'); }
        reader.onerror = () => { NotificationManager.error('Error!', 'file reading failed', 3000); console.log('file reading failed'); }

        if(rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);

    };



    render() {
        console.log("loaded membershiptemplateuploader")
        const {account} = this.props
        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Membership verification template
                    </CardTitle>
                </Box>
                {account.membershipVerificationTemplate && <Box w={[1]}>
                    <CardTitle>
                        <a href={readAccountMembershipTemplateFile(this.props.accountId, account)}>Download existing template</a>
                    </CardTitle>
                </Box>

                }
                <Box w={[1]} px={2}>
                            <Dropzone onDrop={this._onDrop} multiple={false}>
                                <DropzonePlaceholder>
                                    Drop the DOCX Template here or click to select from folder on your computer.
                                </DropzonePlaceholder>
                            </Dropzone>

                </Box>
            </Card>)
    }

}

export default AppcodeUploader

