/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/index';
import CoveredProIcon from '../../../../components/Typografy/CoveredProIcon';

const BenefitIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  color: ${props => props.theme.accentColor ? props.theme.accentColor : theme.icon.subHeaderIcon};
`;

const BenefitIcon = ({theme}) => (
  <BenefitIconStyle theme={theme}>&#xe801;</BenefitIconStyle>
);

export default BenefitIcon;
