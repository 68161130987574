/**
 * @flow
 */

import styled from 'styled-components';

const SmallText = styled.span`
  font-size: 1.1em;
`;

export default SmallText;
