/**
 * @flow
 */


import {connect} from 'react-redux';
import MessagePage from './MessagePage';
import {onMessagesLoadSuccess, archiveMessage, onMessagesReset} from '../../../../common/messages/actions';
import type {TAppState} from '../../../../common/TAppState';

const MessageContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.list,
			isDraft: false,
		};
	},
	(dispatch) => ({
		onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
		onMessagesReset: () => dispatch(onMessagesReset()),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId)),
	}),
)(MessagePage);
export default MessageContainer;
