/**
 * @flow
 */

import type {TDispatch} from "../types/TDispatch";
import {auth0Stat} from "../config/auth/auth0";
import getHeaders from "../config/auth/headers";
import {getRef} from "../firebase";
import {firebaseDb} from "../config/firebase_config";
import {
	buildAccountPresence, buildAllPresence, buildOneTimeCodes,
	buildUserAccountPresence,
	buildUserData,
	buildUserDataFull,
	buildUserDataIds
} from "../config/firebase_config/endpoints";

function getDomainFromEmail(email) {
	if (!email || !email.includes("@")) return email
	return email.split('@')[1];
}

const getUserIdFromEmail = (email) => {
	if (!email) return null
	const input = email.trim().toLowerCase()
	return require('crypto')
		.createHash('sha256')
		.update(input)
		.digest('hex');
}

const _onStatsSuccess = (stats, accountId) => ({
    type: 'STATS_SUCCESS',
    payload: {stats, accountId}
});

const _onStatsRequest = () => ({
    type: 'STATS_REQUEST',
});

const _onStatsFailure = (error: TStatsError) => ({
    type: 'STATS_FAILURE',
    payload: error,
});

const _onAllStatsSuccess = (stats) => ({
  type: 'ALL_STATS_SUCCESS',
  payload: {stats}
});

const _onAllStatsRequest = () => ({
  type: 'ALL_STATS_REQUEST',
});

const _onAllStatsFailure = (error: TStatsError) => ({
  type: 'ALL_STATS_FAILURE',
  payload: error,
});

const getNowAsObject = () => {
  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth()+1; //January is 0!
  var year = today.getFullYear();

  var minutes = today.getMinutes()
  var hours = today.getHours()
  var seconds = today.getSeconds()

  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds
  }

}

export const getAllStats = (accountId: string) => async(dispatch: TDispatch) => {
    getStats(accountId, dispatch)
    getStatsUnverified(accountId, dispatch)
	getUnusedOneTimeCodes(accountId, dispatch);
}

export const getStatsFromAll = () => async(dispatch: TDispatch) => {
  try {
    dispatch(_onAllStatsRequest());

    const response = await getRef(buildAllPresence())

    const tokens = response.val() || {}

    let totals = {};

    Object.keys(tokens).map((child) => {
      getStatsUnverified(child, dispatch);
      return totals[child] = Object.keys(tokens[child]).length;
    })

    getAllUnverified(10113, dispatch);

    dispatch(_onAllStatsSuccess(totals));
  } catch (error) {
    dispatch(_onAllStatsFailure({
      status: 500,
    }));
  }

}

export const getStats = async (accountId: string, dispatch)  => {
    try {
        dispatch(_onStatsRequest());

        const response = await getRef(buildAccountPresence(accountId))

        const tokens = response.val() || {}

        const count = Object.keys(tokens).length


        dispatch(_onStatsSuccess({total: count}, accountId));
    } catch (error) {
        dispatch(_onStatsFailure({
            status: 500,
        }));
    }
};

export const getUnusedOneTimeCodes = async (accountId: string, dispatch: IDispatch) => {
	try {
		dispatch(_onStatsRequest());
		
		const res = await firebaseDb.ref(buildOneTimeCodes())
			.orderByChild('accountId')
			.equalTo(accountId)
			.once('value');
		
		let codes = res.val();
		
		// If codes are not null, sort them by timestamp
		if (codes) {
			codes = Object.keys(codes)
				.map(key => ({
					codeId: key,
					...codes[key]
				}))
				.sort((a, b) => b.timestamp - a.timestamp);
		}
		
		dispatch(_onStatsSuccess({unusedCodes: codes}, accountId));
	} catch(e) {
		console.log("Unused codes stats error", e);
		
		dispatch(_onStatsFailure({
			status: 500,
		}));
	}
}

export const getStatsUnverified = async (accountId: string, dispatch: TDispatch) => {
    try {
        dispatch(_onStatsRequest());

        const response = await fetch(`${auth0Stat}?accountId=${accountId}&operation=unverified`, {
            method: 'get',
            headers: getHeaders(),
        });
        const resultJson = await response.json();
        if (response.status >= 400) {
            const errorJson = resultJson;
            dispatch(_onStatsFailure({
                status: response.status,
                id: errorJson.error,
                message: errorJson.error_description,
            }));
            return;
        }
        dispatch(_onStatsSuccess(resultJson, accountId));
    } catch (error) {
        dispatch(_onStatsFailure({
            status: 500,
        }));
    }
};

export const getAllUnverified = async (accountId: string, dispatch: TDispatch) => {
  try {
    dispatch(_onStatsRequest());

    const response = await fetch(`${auth0Stat}?accountId=${accountId}&operation=allunverified`, {
      method: 'get',
      headers: getHeaders(),
    });
    const resultJson = await response.json();
    if (response.status >= 400) {
      const errorJson = resultJson;
      dispatch(_onStatsFailure({
        status: response.status,
        id: errorJson.error,
        message: errorJson.error_description,
      }));
      return;
    }
    dispatch(_onStatsSuccess(resultJson, 'all'));
  } catch (error) {
    dispatch(_onStatsFailure({
      status: 500,
    }));
  }
};

export const createOneTimeCode = (code, email, createdAt, accountId) => async (dispatch: TDispatch) => {
	const userId = getUserIdFromEmail(email);
	const domain = getDomainFromEmail(email);
	
	try {
		dispatch(_onStatsRequest());
		
		let updates = {}
		updates[buildOneTimeCodes(userId)] = {code, domain, email, accountId, timestamp: createdAt.getTime()}
		
		await firebaseDb.ref().update(updates)
		
		getUnusedOneTimeCodes(accountId, dispatch);
		
		dispatch(_onStatsSuccess());
		
	} catch (e) {
		console.log('Error creating code:', e);
		
		dispatch(_onStatsFailure({
			status: 500,
		}));
	}
}

export const editOneTimeCode = (codeId, code, email, createdAt, accountId) => async (dispatch: TDispatch) => {
	const userId = getUserIdFromEmail(email);
	const domain = getDomainFromEmail(email);
	
	try {
		dispatch(_onStatsRequest());
		
		let updates = {}
		updates[buildOneTimeCodes(userId)] = {code, domain, email, accountId, timestamp: createdAt.getTime()}
		
		await firebaseDb.ref().update(updates)
		
		getUnusedOneTimeCodes(accountId, dispatch);
		
		dispatch(_onStatsSuccess());
		
	} catch (e) {
		console.log('Error creating code:', e);
		
		dispatch(_onStatsFailure({
			status: 500,
		}));
	}
}

export const deleteOneTimeCode = (codeId, accountId) => async (dispatch: TDispatch) => {
	try {
		dispatch(_onStatsRequest());
		
		let updates = {}
		updates[buildOneTimeCodes(codeId)] = null;
		
		await firebaseDb.ref().update(updates)
		
		getUnusedOneTimeCodes(accountId, dispatch);
		
		dispatch(_onStatsSuccess());
		
	} catch (e) {
		console.log('Error creating code:', e);
		
		dispatch(_onStatsFailure({
			status: 500,
		}));
	}
}

export const updateDataForAllUsers = () => async (dispatch: TDispatch) => {


    const allUserIdsSnap = await getRef(buildUserDataFull())

    const allUserIds = allUserIdsSnap.val()

    Object.keys(allUserIds).forEach(async (id) => {
       await updateDataForUser(allUserIds[id], dispatch)
    })

}

export const updateDataForUser = async (user, dispatch: TDispatch) => {


    let updates = {}

    user.accounts && Object.keys(user.accounts).forEach(account => {
        updates[buildUserAccountPresence(user.user_id, account)] = true
    })

    if(user.email.includes("@ringnes.no")){
        updates[buildUserAccountPresence(user.user_id, "111222333")] = true
    }

    await firebaseDb.ref().update(updates);

}

export const updateDataForUserLive = async (userId: string, dispatch: TDispatch) => {


    const response = await fetch(`https://coveredpro.eu.auth0.com/api/v2/users/${userId}`, {
        method: 'get',
        headers: getHeaders(),
    });
    const resultJson = await response.json();
    if (response.status >= 400) {
        console.error(resultJson)
        return;
    }

    let updates = {};
    updates[buildUserData(userId)] = resultJson;

    resultJson.accounts && Object.keys(resultJson.accounts).forEach(account => {
        updates[buildUserAccountPresence(userId, account)] = true
    })

    if(resultJson.accountId && (resultJson.email.indexOf("@ringnes.no") > -1)){
        updates[buildUserAccountPresence(userId, resultJson.accountId)] = true
    }


    await firebaseDb.ref().update(updates);

}
