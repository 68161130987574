import React from 'react';
import {Field} from 'redux-form';
import Select from "../../../../components/Select";


const options = (accounts, self) => {
    if(!accounts) return []

    return Object.keys(accounts).filter((acccountId) => {return (acccountId !== self) && (accounts[acccountId].categories)}).map((acccountId) => ({
        label: accounts[acccountId].name,
        value: acccountId
    }));
}


const renderCloneCategories = ({accounts, accountId}) => {
    return (
                                <Field
                                    name="cloneCategories"
                                    component={Select}
                                    options={options(accounts, accountId)}
                                    label="Account to clone from"/>
    );
}

export default renderCloneCategories