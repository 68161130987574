/**
 * @flow
 */

import styled from 'styled-components';

const H3 = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
`;

export default H3;
