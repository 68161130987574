import React from 'react';
import {
  Router,
  Route,
} from 'react-router-dom';
import history from '../common/utils/history'

import LandingPage from './LandingPage';
import Login from './Employee/Login';
import ResetPassword from './Employee/ResetPassword';
import SignUp from './Employee/SignUp';
import Admin from './Employee/Admin';
import VerifyService from './Employee/Verify/VerifyService';
import VerifyConfirmation from './Employee/Verify/VerifyConfirmation';
import SupportPage from './Employee/SupportPage';
import SelectAccountContainer from './Employee/SelectAccount/SelectAccountContainer';
import PrivacyPageNo from './Employee/PrivacyPage/PrivacyPageNo';
import PrivacyPageDk from './Employee/PrivacyPage/PrivacyPageDk';
import PrivacyPageSv from './Employee/PrivacyPage/PrivacyPageSv';
import PrivacyPageEn from './Employee/PrivacyPage/PrivacyPageEn';
import FAQPage from './Employee/FAQPage';
import VerifyAppleToken from './Employee/VerifyAppleToken';
import VerifyAndroid from "./Employee/VerifyAndroid";
import RegisterListContainer from "./Employee/Register/RegisterListContainer";
import RegisterListAPlussContainer from "./Employee/Register/RegisterListAPlussContainer";
import App from './AppView/Admin';
import TBLandingPage from './TBKundeklubb/LandingPage';
import SalesAgreement from './TBKundeklubb/SalesAgreement';
import TermsPageEn from "./Employee/PrivacyPage/TermsPageEn";
import TermsPageNo from "./Employee/PrivacyPage/TermsPageNo";
import TermsPageDk from "./Employee/PrivacyPage/TermsPageDk";
import TermsPageSv from "./Employee/PrivacyPage/TermsPageSv";
import ISSRegister from './Employee/ISSRegister/ISSRegister';

const routes = () => (
  <div>
    <Router history={history}>
      <div>
        <Route component={LandingPage} exact path="/" />
        <Route component={Login} path="/login" />
        <Route component={ResetPassword} path="/reset" />
        <Route component={SignUp} path="/signup" />
          <Route component={PrivacyPageNo} path="/privacy/no" />
          <Route component={PrivacyPageNo} path="/privacy/nb" />
          <Route component={PrivacyPageDk} path="/privacy/dk" />
          <Route component={PrivacyPageSv} path="/privacy/sv" />
          <Route component={PrivacyPageEn} path="/privacy/en" />
        <Route component={TermsPageNo} path={"/terms/no"}/>
        <Route component={TermsPageNo} path={"/terms/nb"}/>
        <Route component={TermsPageDk} path={"/terms/dk"}/>
        <Route component={TermsPageSv} path={"/terms/sv"}/>
        <Route component={TermsPageEn} path={"/terms/en"}/>
        <Route component={SupportPage} path="/help" />
        <Route component={RegisterListContainer} exact path="/register" />
        <Route component={RegisterListAPlussContainer} exact path="/register-apluss" />
        <Route component={FAQPage} path="/register/:appName" />
        <Route component={VerifyService} path="/services/confirmEmail" />
        <Route component={VerifyConfirmation} path="/services/verification/:id" />
        <Route component={VerifyAppleToken} path="/services/verifyAppleToken" />
        <Route component={VerifyAndroid} path="/services/verifyAndroid" />
        <Route component={SelectAccountContainer} path="/selectAccount" />
        <Route component={Admin} path="/admin" />
        <Route component={App} path="/app" />
        <Route component={TBLandingPage} path="/tradebroker-kundeklubb" exact />
        <Route component={SalesAgreement} path="/tradebroker-kundeklubb/sales-agreement" />
        <Route component={ISSRegister} path="/iss-register/34140e65-e673-4f4d" />
      </div>
    </Router>
  </div>
);

export default routes;
