/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../../components/Typografy/Wrapper';
import BaseButton from '../../../../components/Typografy/BaseButton';
import Link from '../../../../components/Typografy/Link';
import { mx } from '../../../../utils';
import theme from '../../../../theme/index';

const CancelButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  background-color: transparent;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const SaveButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;



const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #262626;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
`;

const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ButtonsContainer = styled.div`
   flex: 1;
   display: none;
   ${mx('lg')} {
    display: flex;
   }
`;

const Footer = ({messageId, pristine, onSave}) => (
  <FooterWrapper>
    <Wrapper>
      <FooterFlex>
        <ButtonsContainer>
          {!pristine && <SaveButton onClick={() => onSave()}> Save </SaveButton> }
          <CancelButton to={`/admin/messages/${messageId}`}> Exit </CancelButton>
        </ButtonsContainer>
      </FooterFlex>
    </Wrapper>
  </FooterWrapper>
);


export default Footer;
