'use strict'

import React from 'react'
import {SketchPicker} from 'react-color'
import styled from "styled-components";
import theme from "../../../../theme";


const Swatch = styled.div`
    padding: 5px;
                    background: #fff;
                    border-radius: 1px;
                    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
                    display: inline-block;
                    cursor: pointer;
`;

const Popover = styled.div`
                    position: absolute;
                    zIndex: 2;
`;

const Cover = styled.div`
                    position: absolute;
                    zIndex: 2;
`;

const FormInput = styled.div`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  padding-left: 0.6em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  ${props => props.userCard && 'line-height: 26px'};
  &:focus{
    outline: none;
    background: none;
    border-bottom: ${props => props.userCard ?
    `1px solid ${`${theme.input.textColorUserCard}`}` :
    `1px solid ${`${theme.input.textColor}`}`}
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;


const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;

class SketchExample extends React.Component {
    state = {
        displayColorPicker: false,
        color: "#000000",
    };

    componentDidMount() {
        this.setState({...this.state, color: (this.props.color || this.props.input.value)})
    }

    handleClick = () => {
        this.setState({...this.state, displayColorPicker: !this.state.displayColorPicker})
    };

    handleClose = () => {
        this.setState({...this.state, displayColorPicker: false})
    };

    handleChange = (color) => {
        this.setState({color: color.hex})
        if (this.props.onChange) {
            this.props.onChange(color.hex)
        }
        if (this.props.input.onChange) {
            this.props.input.onChange(color.hex)
        }
    };

    render() {
        const Color = styled.div`
                    width: 36px;
                    height: 14px;
                    border-radius: 2px;
                    background-color: ${ this.state.color};
`;


        return (
            <div>
                <FormInput>
                    <FormLabel>{this.props.label}</FormLabel>
                    <Swatch onClick={this.handleClick}>
                        <Color/>
                    </Swatch>
                    {this.state.displayColorPicker ? <Popover>
                        <Cover onClick={this.handleClose}/>
                        <SketchPicker color={this.state.color} onChange={this.handleChange}/>
                    </Popover> : null}
                </FormInput>
            </div>
        )
    }
}

export default SketchExample
