/**
 * @flow
 */

import React from 'react';
import { Provider } from 'react-redux';

import store from './store';
import PersistenceContainer from '../common/storage/PersistenceContainer';
import RootContainer from './routes';

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <PersistenceContainer>
          <RootContainer />
        </PersistenceContainer>
      </Provider>
    );
  }
}



export default App;
