import XLSX from 'xlsx';
import {NotificationManager} from 'react-notifications';
import {buildAppcodePointer, buildAppcodes} from "../config/firebase_config/endpoints";
import {getRef} from "../firebase";
import {firebaseDb} from "../config/firebase_config";

const rABS = typeof FileReader !== "undefined" && (FileReader.prototype||{}).readAsBinaryString;
const TEST = false

const to_json = (data) => {
    const workbook = XLSX.read(data, {type: rABS ? 'binary' : 'array'});

    let result = [];
    workbook.SheetNames.forEach(function (sheetName) {
        let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        if (roa.length) {
            roa.sheetName = sheetName
            result.push(roa)
        }
    });
    return result;
};


export const appcodeUpdater = async (data, accountId, accountName) => {

    try {
        const sheets = to_json(data)
        const appcodeSheet = sheets[0]
        const productCell = appcodeSheet[1]
        const nameString = productCell[Object.keys(productCell)[1]]
        const appPart = nameString.split(",")[0]

        if (appPart.trim() === accountName.trim()) {

            const arraySheet = appcodeSheet.map(row => Object.keys(row).map(cellKey => row[cellKey]))

            await updateAppCodesInner(arraySheet, accountId)

        }
        else {
            NotificationManager.error('Error!', `Wrong account, found ${appPart}, needed ${accountName}`, 6000);
            console.log('file reading was aborted');
            return
        }

    } catch (e) {
        NotificationManager.error('Error!', e, 3000);
    }


}


const updateAppCodesInner = async (sheet, accountId) => {
    console.log("Updating app codes")

    const data = sheet
    let codes = {}
    let codeFound = false
    data.forEach(line => {
        if (codeFound) {
            codes[line[0]] = {code: line[0], link: line[1]}
        } else {
            if (line.length >= 3 && line[0] === 'Code' && line[2] === 'Code Redemption Link') codeFound = true
        }
    })


    const appCodesPath = buildAppcodes(accountId)
    const appCodesPointersPath = buildAppcodePointer(accountId)

    const accountSnap = await getRef(appCodesPath)
    const accountData = accountSnap.val()

    const pointersSnap = await getRef(appCodesPointersPath)
    const pointersData = pointersSnap.val()


//    let updatedAllAccounts = {}

    let accountPointers = pointersData || {current: -1, free: 0}


    let merged = accountData || {}


    merged.free = merged.free || {}
    merged.used = merged.used || {}
    merged.freeIndex = merged.freeIndex || {}
    merged.indexed = merged.indexed || {}

    if (merged.free) {
        let indices = Object.keys(merged.freeIndex).map(key => {
            return (merged.freeIndex[key] || 0)
        })

        const maxIndex = indices.length > 0 ? Math.max(...indices) : -1
        console.log("Highest index found: " + maxIndex)
        let pointerIndex = maxIndex + 1

        Object.keys(codes).forEach(code => {
            if ((merged.free[code]) && !(merged.freeIndex && merged.freeIndex[code])) {
                merged.freeIndex[code] = pointerIndex
                pointerIndex++
            }
            if (!(merged.free[code] || merged.used[code])) {
                merged.free[code] = codes[code]
                merged.freeIndex[code] = pointerIndex
                pointerIndex++
            }
        })

        Object.keys(merged.free).forEach(code => {
            merged.indexed[merged.freeIndex[code]] = TEST ? `https://example.com/${merged.freeIndex[code]}` : merged.free[code]

        })

        let freeCount = Object.keys(merged.free).map(key => (merged.freeIndex[key] || 0)).filter(i => i > accountPointers.current).length

        accountPointers.free = freeCount
    }

    console.log("updating appcodes")
    let updates = {};
    updates[appCodesPath] = merged;
    updates[appCodesPointersPath] = accountPointers
    await firebaseDb.ref().update(updates);

    NotificationManager.success(`Success! \n Free:${accountPointers.free}\n Current:${accountPointers.current}`, `Tokens saved!`, 3000);

    return null
}
