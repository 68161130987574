/**
 * @flow
 */

import createReducer from '../../createReducer';


const initialState = {
  data: {},
  error: null,
  isLoaded: false,
  processing:false,
  setPasswordProcessing:false
};


const handlers = {
    AUTH0_GET_ADMIN_USERS_SUCCESS: (state, action) => {
        console.log('AUTH0_GET_ADMIN_USERS_SUCCESS')
        const data = action.payload
        const users = state.data || {};
        if (data) {
            const existing = users[data.accountId] || {}
            users[data.accountId] = {...existing, ...data.data }
        }
        return {...state, error: null, isLoaded: true, data: users, processing:false};
    },
    AUTH0_ADD_ADMIN_SUCCESS: (state, action) => { return {...state, processing:false}; },
    AUTH0_ADD_ADMIN_REQUEST: (state, action) => { return {...state, processing:true}; },
    AUTH0_ADD_ADMIN_FAILURE: (state, action) => { return {...state, processing:false}; },
    AUTH0_SET_PASSWORD_SUCCESS: (state, action) => { return {...state, setPasswordProcessing:false}; },
    AUTH0_SET_PASSWORD_REQUEST: (state, action) => { return {...state, setPasswordProcessing:true}; },
    AUTH0_SET_PASSWORD_FAILURE: (state, action) => { return {...state, setPasswordProcessing:false}; },

}


export default createReducer(initialState, handlers);
