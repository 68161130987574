/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import theme from '../../../theme/index';
import { mx } from '../../../utils';
import Wrapper from '../../../components/Typografy/Wrapper';
import H3 from "../../../components/Typografy/H3";

const BenefitsContainer = styled.div`
  background-color: ${theme.benefits.backgroundColor}
  min-height: 20em;
  background-position: right;
  ${mx('lg')} {
    background-position: bottom;
  }
  `;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  `;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #222222;
  color: white;
  padding: 1em;
  margin:0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  `;

const Content = styled(Flex)`
  margin: 0.5vW
  `;

const BenefitBox = styled(Box)`
  height: auto;
  padding: 2em;
  background-color: ${theme.benefits.textColor};
  margin-bottom: 1em;
  box-sizing: border-box;
  display: flex;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  ${mx('lg')} {
    margin-bottom: 1em;
  }
  `;


const Text = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  justify-content: space-around;
  `;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.accentColor : theme.nav.link};
  padding: 7px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin-right: 30px;
  ${mx('lg')} {
    margin-right: 30px;
  }
  `;

const CircleNumber = styled.h1`
  display: flex;
  align-items: center
  color: ${theme.benefits.textColor};
  font-size: 1.5em;
  line-height:0.2;
  `;

const Link = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 600;
  color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.accentColor : theme.nav.link};
  cursor: pointer;
  `;

type TProps = {
  steps: Array<Object>,
};

const Step = ({ text, type, url, number, theme }) => {

  console.log("url");
  console.log(url);

  return (
    <BenefitBox p={3}>
      <Circle theme={theme}>
        <CircleNumber>{number + 1}</CircleNumber>
      </Circle>
      <Text>
        {type === 'link' ?
          <Link theme={theme} href={url}>{text}</Link> : <div>{text}</div>
        }
      </Text>
    </BenefitBox>
  );
};


class BenefitSection extends Component<void, TProps, void> {
  render() {
    const { steps } = this.props;
    console.log("Steps");
    console.log(steps);
    return (
      <BenefitsContainer>
        <Wrapper>
            <Header>
                <Title>Hvordan benytte gode</Title>
            </Header>
        </Wrapper>
        <Wrapper>
          <Content align="center" justify="space-between" wrap>
            {steps.map((step, index) => <Step theme={this.props.theme} key={index} number={index} {...step}/>)}
          </Content>
        </Wrapper>
      </BenefitsContainer>
    );
  }
}


export default BenefitSection;

