/**
 * @flow
 */

import styled from 'styled-components';

const BaseButton = styled.button`
  font-family: 'Lato', sans-serif;
`;

export default BaseButton;
