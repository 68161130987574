/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TUserAdminApiData,
  TUserAdminResponse,
} from '../../../../common/types/api/TUserAdmin';
import SetPasswordForm from './SetPasswordForm';
import SubHeader from '../SubHeader';
import Spinner from "../../../components/Spinner";


const UserAdminsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const UserAdminBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png').default});
   margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  userAdmins: TUserAdminApiData;
  onUserAdminsLoadSuccess: (data: TUserAdminResponse) => void;
};

class UserAdminPage extends React.Component<void, TProps, TState> {


    render() {
    return (
      <UserAdminsWrap>
        <UserAdminBackground>
          <SubHeader isUserAdmin={false} />
        </UserAdminBackground>
        <Wrapper>
            {this.props.processing ? <Spinner />:
          <SetPasswordForm
            accountId={this.props.accountId}
            setPassword={this.props.setPassword}
            setPasswordFormValues={this.props.setPasswordFormValues}
          ></SetPasswordForm>}
        </Wrapper>


      </UserAdminsWrap>
    );
  }
}

export default UserAdminPage;
