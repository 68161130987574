/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from "../../../../theme";
import {enDate, enDateFromTo, noDate} from "../../../../../common/utils/formatDate";

const BookingWrapper = styled.div`
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
    background-color: #fafafa;
    padding:10px;
`;

const Button = styled.button`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: .5em;
  margin-top:.5em;
  box-shadow: none;
  border: none;
  width:100%;
  box-sizing:border-box;
  &:hover {
    text-decoration: none;
    cursor:pointer;
  }
`;

const ApproveButton = styled(Button)`
  background: #9090ff;
`;

const DeclineButton = styled(Button)`
  background: ${theme.buttons.defaultColor};
`;

const BookingDate = styled.div`
    text-align:center;
    font-size:15px;
    padding:5px;
`;

const UserEmail = styled.div`
    font-weight:bold;
    font-size:15px;
    text-align:center;
`;

const DetailWrapper = styled.div`
    margin-top:10px;
`;

const DetailLabel = styled.div`
    font-size:.8em;
`;

const DetailValue = styled.div`
    font-size:1.1em;
`;

// const BookingWrapper = styled.div`
//     background-color: #fff;
//     border-radius:5px;
//     padding:15px;
// `;

class PendingBookings extends Component {
    render() {

        const {bookings, cabinId, cabins} = this.props;

        return (
            <div>
                {Object.keys(bookings).map((key, i) => {
                    if (bookings[key].status === 1) {
                    // if (true) {
                        return (
                            <BookingWrapper key={key} id={key}>
                                <Flex>
                                    <Box w={1/2}>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Person Name
                                            </DetailLabel>
                                            <DetailValue>
                                                {bookings[key].user_name}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Person Email
                                            </DetailLabel>
                                            <DetailValue>
                                                {bookings[key].user_email}
                                            </DetailValue>
                                        </DetailWrapper>
                                    </Box>
                                    <Box w={1/2}>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Period
                                            </DetailLabel>
                                            <DetailValue>
                                                {enDateFromTo(bookings[key].start_date, bookings[key].end_date, true)}
                                            </DetailValue>
                                        </DetailWrapper>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box w={1/2}>
                                        <ApproveButton type={'button'} onClick={() => this.props.approveBooking(cabinId, key, 2)}>Approve</ApproveButton>
                                    </Box>
                                    <Box w={1/2}>
                                        <DeclineButton type={'button'} onClick={() => this.props.approveBooking(cabinId, key, 3)}>Decline</DeclineButton>
                                    </Box>
                                </Flex>
                            </BookingWrapper>
                        )
                    }
                })}
            </div>
        );
    }
}

export default PendingBookings

