/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import {
    Route,
} from 'react-router-dom';

import {Flex, Box} from 'reflexbox';
import {NotificationContainer} from 'react-notifications';
import {mx} from '../../utils';
import Header from './components/Header';
import Footer from '../../components/Footer';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import UserInfo from './UserInfo';
import AccountMenu from './AccountMenu';
import theme from '../../theme';
import Wrapper from '../../components/Typografy/Wrapper';
import type {TProfileData} from '../../../common/types/models/TAuth';
import {buildAccounts, buildAccountTheme} from "../../../common/config/firebase_config/endpoints";
import FirebaseContainer from '../../../common/firebase/FirebaseContainer';
import BenefitDetails from "../Benefits/BenefitDetails";
import BenefitsList from "../Benefits/BenefitsList";
import MessageList from "../Messages/MessageList";
import Profile from "../Profile";
import MessageDetails from "../Messages/MessageDetails";
import MapPage from '../MapPage';
import Spinner from '../../components/Spinner'

import {signInAccount} from '../../../common/auth/signIn/actions'
import {
    hasAccount,
    hasAdminAccessToAccount,
    hasSelectedAccount,
    accountName,
    isLegacy,
    getAccount, hasWebapp
} from '../../../common/auth'
import {imageAccountLogo} from "../../../common/config/images/endpoints";
import CabinList from "../CabinBooking/CabinList";
import CabinDetails from "../CabinBooking/CabinDetails";


type TProps = {
    profile: TProfileData;
    children?: Element<any>,
}

const Container = styled.div`
  background-color: white;
  // background-color: ${props => props.theme.navigationBar ? props.theme.navigationBar.backgroundColor : theme.nav.backgroundColor};
  background-image: url(${require('../../assets/images/bg_benefits_details.png')});
  background-position: bottom;
  padding: 5px 0;
  border-bottom: 1px solid #bbb;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
`;

const FlexContainer = styled(Flex)`
  flex: 1;
  max-width: none;
  ${mx('lg')} {
    max-width: 28em;
  }
`;

const Wrap = styled.div`
  padding-top: 0;
  ${mx('lg')} {
    padding-top: 2.5em;
    flex: 1;
    background-color: ${theme.benefits.backgroundColor};

  }
`;

const WrapperContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;


`;

const AccountName = styled.div`
  text-decoration: none;
  //text-transform: uppercase;
  padding-left: 1em ;
  // color: ${theme.subHeader.buttonTextColor};
  font-size: 24px;
    font-weight: 800;
`;


class Admin extends React.Component<void, TProps, void> {
    _getQueries = (accountId) => {
        return (
            [{
                endpoint: buildAccounts(),
                method: 'on',
                eventType: 'value',
                callback: this.props.onAccountsLoadSuccess,
            },
            {
                endpoint: buildAccountTheme(accountId),
                method: 'on',
                eventType: 'value',
                callback: this.props.onThemeLoadSuccess,
            }
        ])
    };

    componentDidMount() {
        const {profile, history, isRestored, accounts} = this.props

        if (!isRestored) return


        if (!hasAccount(profile)) {
            console.log("!hasAccount");
            signInAccount()
        }
        else if (!hasSelectedAccount(profile) || !hasWebapp(accounts[profile.selectedAccount])) {
            history.push("/selectAccount")
        }
    }

    componentWillReceiveProps(nextProps: TProps) {
        const {isRestored, profile} = nextProps

        if (isRestored && !hasAccount(profile)) {
            signInAccount()
        }


    }


    render() {
        const {profile} = this.props;
        if (!hasAccount(profile))
            return <Spinner/>

        return (
            <FirebaseContainer queries={this._getQueries(profile.selectedAccount)}>
                {this.props.theme && this.renderInner()}
            </FirebaseContainer>
        )
    }

    renderInner() {
        const {profile, accounts, history, match, location, theme, accountsLoaded, accountId} = this.props;

        if (accounts[profile.selectedAccount] && !hasWebapp(accounts[profile.selectedAccount])) {
            history.push("/selectAccount");
            return(<div />);
        }

        const logo = (
            <FlexContainer align="center" style={{marginRight: 10}}>
                <Box>
                    <img
                        src={imageAccountLogo(this.props.profile.selectedAccount)}
                        height={50}
                        alt=""
                    />
                </Box>
                <Box>
                    {(location.pathname.indexOf("/accounts") < 0) &&
                    <AccountName>{accountName(profile, accounts)}</AccountName>}
                </Box>
            </FlexContainer>
        );
        return (
            <WrapperContainer>
                <Container theme={theme}>
                    <Wrapper>
                        <Header logo={logo}>
                            {(!isLegacy(profile)) && accountsLoaded && <AccountMenu theme={theme} profile={profile} history={history} accountId={accountId} accounts={accounts}/>}
                            {(!isLegacy(profile)) && <UserInfo theme={this.props.theme} profile={profile} history={history}/>}
                        </Header>
                    </Wrapper>
                </Container>
                <Wrap>
                    <Route component={BenefitsList} exact path={`${match.url}/benefits`}/>
                    <Route component={BenefitsList} path={`${match.url}/benefits/category/:id`}/>
                    <Route component={BenefitDetails} exact path={`${match.url}/benefits/:id`}/>
                    <Route component={Profile} path={`${match.url}/profile`}/>
                    <Route component={MessageList} exact path={`${match.url}/messages`}/>
                    <Route component={MessageDetails} path={`${match.url}/messages/:id`}/>
                    <Route component={MapPage} path={`${match.url}/map`}/>

                    {accountsLoaded && accounts[accountId].customModules && accounts[accountId].customModules.cabinBooking &&
                    <div>
                        <Route component={CabinList} exact path={`${match.url}/cabins`} />
                        <Route component={CabinDetails} exact path={`${match.url}/cabins/:id`} />
                    </div>
                    }


                </Wrap>
                <Footer/>
                <NotificationContainer/>
            </WrapperContainer>

        );
    }
}

export default Admin;

/*
                    <Route component={BenefitAccess} path={`${match.url}/benefits_access`}/>
<Route component={DomainsList}  exact path={`${match.url}/setup/categories`} />
              <Route component={DivisionEdit} path={`${match.url}/setup/categories/:id/edit`} />
            <Route component={DivisionsList} exact  path={`${match.url}/divisions`} />
            <Route component={DivisionEdit}  exact path={`${match.url}/divisions/:id/edit`} />
 */
