/**
 * @flow
 */

// Eslint has problems with destructuring
/* eslint react/no-unused-prop-types: 0 */

import React, { Component } from 'react';

import {hasAccessToAccount, hasAdminAccessToAccount, hasSuperAdminAccess, hasWebapp} from '../../../common/auth'

import styled from 'styled-components';
import {firebaseAuth} from "../../../common/config/firebase_config/firebase";
import AsyncStorage from "../../../common/utils/AsyncStorage";
import {auth0Logout} from "../../../common/config/auth/auth0";
import FormInput from "../../components/Typografy/FormInput";
import theme from "../../theme";

const AccountButton = styled.div`
    //text-transform: uppercase;
    padding: 0.8em 1.2em;
    color: #fff;
    border-radius: 1.5em;
    background: #f41a4d;
    border: none;
    width: 100%;
    font-family: 'Lato', sans-serif;
  	font-size: 1.2em;
    cursor: pointer;
    ${props => props.disabled && 'background: #d68a9c;'}
`;

const AppButton = styled.div`
  //text-transform: uppercase;
  padding: 0.8em 1.2em;
  color: #fff;
  border-radius: 1.5em;
  font-size: 1.2em;
  background: #541af4;
  border: none;
  width: 100%;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  ${props => props.disabled && 'background: #d68a9c;'}
`;

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const SearchInput = styled.input`
  width: 100%;
  background: none;
  border: 1px solid #747474;
  margin-bottom: 1.25em;
  border-radius: 20px;
  padding: 0.5em 1em;
  font-size: 1.2em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  ${props => props.userCard && 'line-height: 1.25em'};
  &:focus{
    outline: none;
    background: none;
    border: ${props => props.userCard ?
	`1px solid ${`${theme.input.textColorUserCard}`}` :
	`1px solid ${`${theme.input.textColor}`}`}
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;


const getText = (accountId, accounts) => {
  if(accounts && accounts[accountId])
  {
    return `${accounts[accountId].name} (${accountId})`
  }
  return accountId
}

const filterAccounts = (accounts, profile) => {
  if(hasSuperAdminAccess(profile)) return accounts

    let result = {};
    Object.keys(accounts).map((accountId) => {
      if (hasAdminAccessToAccount(profile, accountId)) result[accountId] = accounts[accountId];
    })
    return result;
}

const filterAccountsNotAdmin = (accounts, profile) => {
    // if(hasSuperAdminAccess(profile)) return accounts

    let result = {};
    Object.keys(accounts).map((accountId) => {

        if (hasAccessToAccount(profile, accountId) && hasWebapp(accounts[accountId])) result[accountId] =  accounts[accountId];
    });

    return result;
}

class SelectAccountForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
        }
    }


    _handleSubmit = (data) => {
    this.props.updateSelectedAccountSuccess(data, this.props.history);
  };

  _handleSubmitApp = (data) => {
    this.props.updateSelectedAccountAppSuccess(data, this.props.history);
  };

  _handleLogout = async () => {
    try {
      await firebaseAuth.signOut();
      await AsyncStorage.clear();
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('@CoveredPro:PROFILE');

      window.location.href = auth0Logout

      //history.push('/');
    } catch (error) {
      alert(error)

    }
  };

  render() {

    const {accounts, profile} = this.props

    const filteredAccounts = filterAccounts(accounts, profile);
    const filteredAccountsNotAdmin = filterAccountsNotAdmin(accounts, profile);

    const accountsCount = Object.keys(filteredAccounts).length;
    const notAdminCount = Object.keys(filteredAccountsNotAdmin).length;

    if (accountsCount === 0 && notAdminCount === 0 ) {
      return <div>
        <span style={{color: 'white'}}>No access!</span>
        <AccountButton key={"Not found"} onClick={() => this._handleLogout()}>Logout</AccountButton>
      </div>
    } else if (accountsCount === 1 && notAdminCount === 0) {
      this._handleSubmit(Object.keys(filteredAccounts)[0]);
    } else if (accountsCount === 0 && notAdminCount === 1) {
      console.log("Filt", filteredAccountsNotAdmin);
      this._handleSubmitApp(Object.keys(filteredAccountsNotAdmin)[0]);
    }
    return (
        <div>
            <SearchInput
                type={'text'}
                placeholder={'Search for account'}
                onChange={(event) => this.setState({query: event.target.value})}
            />
            <h3 style={{color: 'white'}}>Admin Access</h3>
	        <AccountsWrapper>
	            {this.searchedAccounts(Object.keys(filteredAccounts), filteredAccounts).map((accountId) =>
	                filteredAccounts[accountId] && <AccountButton key={accountId} onClick={() => this._handleSubmit(accountId) }>{getText(accountId, accounts)}</AccountButton>)}
			</AccountsWrapper>

            <h3 style={{color: 'white'}}>User Access</h3>
	        <AccountsWrapper>
                {this.searchedAccounts(Object.keys(filteredAccountsNotAdmin), filteredAccountsNotAdmin).map((accountId) =>
                    filteredAccountsNotAdmin[accountId] && <AppButton key={accountId} onClick={() => this._handleSubmitApp(accountId) }>{getText(accountId, accounts)}</AppButton>)}
	        </AccountsWrapper>
      </div>
    );
  }

  searchedAccounts = (keys,accounts) => {

      const { query } = this.state;

      const filtered = key => {

          const searchText = query.toLowerCase();

          const searchProp = (prop) => {
              return accounts[key] && accounts[key][prop] && accounts[key][prop].toLowerCase().includes(searchText);
          }

          console.log("Account", accounts[key]);

          const inId = searchProp('accountId', searchText);
          const inAccountName = searchProp('accountName', searchText);
          const inCustomerName = searchProp('customerName', searchText);

          return inId || inAccountName || inCustomerName;
      }

      return keys.filter(filtered);
  }
}


export default SelectAccountForm;
