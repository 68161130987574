/**
 * @flow
 */

import React, {
    Component,
} from 'react';
import parseSearch from "../../../../common/utils/parseSearch";


type TProps = {
    ticket: string;
}


class VerifyService extends Component<void, TProps, void> {


    componentDidMount() {
        try {
            const parsed = parseSearch()
            const newUrl = decodeURIComponent(parsed.token)
            window.location = newUrl;

        }
        catch (e) {
            console.error(e)
        }
    }


    render() {
        console.log("reached render")
        return (<div/>

        );
    }
}

export default VerifyService;
