
import { connect } from 'react-redux';
import FAQPage from './FAQPage';
import { onLoadFaqSuccess } from '../../../common/helpaccount/actions';
import { resetAppCode} from "../../../common/accounts/actions";

const FAQContainer = connect(
  (state) => {
    return {
      faq: state.faq.list,
        token: state.accounts.token

    };
  },
  (dispatch) => ({
    onLoadFaqSuccess: (data) => dispatch(onLoadFaqSuccess(data)),
      resetAppCode: () => dispatch(resetAppCode()),//migrateAllAppCodes()),

  }),
)(FAQPage);
export default FAQContainer;
