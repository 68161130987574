/**
 * @flow
 */

 // Eslint has problems with destructuring
 /* eslint react/no-unused-prop-types: 0 */

import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import CustomFieldInput from '../../components/Typografy/CustomFieldInput';
import LoginButton from '../../components/Typografy/LoginButton';

import type { TSignUpData } from '../../../common/types/models/TAuth';
import type { TSignUpState, TSignInState } from '../../../common/auth/TAuthState';

type TSignUpProps = {
  handleSubmit: Function;
  signUpAccount: (data: TSignUpData) => void;
  signUp: TSignUpState,
  signIn: TSignInState;
}

class SignUpFormEmployee extends Component<void, TSignUpProps, void> {

  _onHandleSubmit = (data: TSignUpData) => {
    this.props.signUpAccount({
      email: data.email,
      password: data.password,
    });
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this._onHandleSubmit)}>
        <Field
          name="email"
          component={CustomFieldInput}
          type="email"
          placeholder="Email"
        />
        <Field
          name="name"
          component={CustomFieldInput}
          placeholder="Name"
          type="text"
          disabled
        />
        <Field
          name="password"
          component={CustomFieldInput}
          placeholder="Password"
          type="password"
        />
        <Field
          name="retypePassword"
          component={CustomFieldInput}
          placeholder="Retype password"
          type="password"
        />
        <LoginButton
          disabled={this.props.signUp.isLoading || this.props.signIn.isLoading}
          type="submit"
        > Sign Up
        </LoginButton>
      </form>
    );
  }
}

const validate = (values, disabled) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Sorry, we don\'t recognize this email';
  }
  if (!disabled && !values.name) {
    errors.name = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Sorry, your password must contain at least 8 characters';
  }
  if (!values.retypePassword) {
    errors.retypePassword = 'Required';
  } else if (values.password !== values.retypePassword) {
    errors.retypePassword = 'Password doesn\'t match';
  }
  return errors;
};


export default reduxForm({
  form: 'signUpEmployee',
  validate,
  field: ['email', 'name', 'password', 'retypePassword'],
})(SignUpFormEmployee);
