/**
 * @flow
 */


import { connect } from 'react-redux';
import ManageHelpPage from './ManageHelpPage';
import type { TAppState } from '../../../../common/TAppState';
import {loadHelp, updateHelp} from "../../../../common/help/actions";
import {getFormValues} from "redux-form";

const ManageHelpPageContainer = connect(
 (state: TAppState) => {
   return {
       helpData: state.help.data,
       loaded: state.help.isLoading,
       helpForm: getFormValues('HelpForm')(state)
   };
 },
 (dispatch) => ({
     loadHelp: () => dispatch(loadHelp()),
     updateHelp: (data) => dispatch(updateHelp(data)),
 }),
)(ManageHelpPage);

export default ManageHelpPageContainer;

