/**
 * @flow
 */


import { connect } from 'react-redux';
import MapPage from './MapPage';
import type { TAppState } from '../../../common/TAppState';
import {onLocationsLoadSuccess} from "../../../common/locations/actions";
import supercluster from 'supercluster';
import {onAccountsLoadSuccess} from "../../../common/accounts/actions";
import {onBenefitsLoadSuccess} from "../../../common/benefits/actions";

const arrangeLocation = (locationArray, key, arranged) => {

  for (var n = 0; n < locationArray.length; n++) {
    const location = locationArray[n];
    if (location && location.latitude && location.longitude && location.heading) {

      const mappedKey = location.latitude + '-' + location.longitude
      if (arranged.mapped[mappedKey]) {
        const index = arranged.mapped[mappedKey].index
        arranged.parsed[index].properties.benefits.push({name: location.heading, id: key});
      }
      else {

        const new_loc = {
          type: "Feature",
          properties: {
            scalerank: 2,
            name: location.heading,
            comment: null,
            name_alt: null,
            lat_y: location.latitude,
            long_x: location.longitude,
            region: "Norge",
            subregion: null,
            featureclass: "poi",
            benefits: [{name: location.heading, id: key}],
          },
          geometry: {
            type: "Point",
            coordinates: [
              location.longitude,
              location.latitude
            ]
          }
        };

        arranged.parsed.push(new_loc);
        arranged.mapped[mappedKey] = {index: (arranged.parsed.length - 1)}
      }

    }

  }
  return arranged;
}

const arrangeLocations = (benefits, params) => {

  if (!getIsFiltered(params)) {
    return benefits.locations
  }

  let arranged = { parsed: [], mapped: {} };

  let selectedKey = params ? params.benefitId : null;

  const locationArray = benefits.all[selectedKey].locations;
  if (locationArray) {
    arranged = arrangeLocation(locationArray, selectedKey, arranged);
  }
  console.log("not filtered - key: " + selectedKey + " arranged " + JSON.stringify(arranged))
  let cluster = supercluster({radius: 60, maxZoom: 13,});
  cluster.load(arranged.parsed);

  return cluster;
}

const getIsFiltered = (params) => {
  return params && params.benefitId;
}

const MapContainer = connect(
  (state: TAppState, ownProps) => ({
    locations: arrangeLocations(state.benefits || {}, state.params),
    isFiltered: getIsFiltered(state.params),
    isLoaded: state.benefits.isLoaded,
    benefits: state.benefits.all,
    accountId: state.auth.profile.data.accountId,
    profile: state.auth.profile.data || {},
    permissions: state.permissions,
    accounts: state.accounts.data,
      theme: state.theme.data,
  }),
  (dispatch) => ({
    onLocationsLoadSuccess: (data) => dispatch(onLocationsLoadSuccess(data)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
  }),
)(MapPage);
export default MapContainer;
