import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from "../../../../components/Buttons";
import styled from 'styled-components';
import {todayHtmlFormat} from "../../../../../common/utils/formatDate";

const ClickToRemoveBtn = styled.div`
    cursor: pointer;
    padding:10px;
    width:20px;
`;

const DateBasedPricingWrapper = styled.div`
    margin-top:10px;
    margin-bottom:10px;
    border: 1px solid #ddd;
    border-radius:10px;
`;

class PeriodBasedPricing extends Component {

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.formValues !== nextProps.formValues;
    //
    // }

    render() {

        const {fields, meta: {touched, error}, formValues, buttonText, arrayname, validator} = this.props;

        return (
            <div>
                {fields.map((item, index) => {
                    let itemVals = formValues[arrayname][index];
                    return (
                        <DateBasedPricingWrapper key={index}>
                            <ClickToRemoveBtn onClick={() => fields.remove(index)}>X</ClickToRemoveBtn>
                            <Flex py={1} px={1}>
                                <Box w={[1, 1]} pr={1}>
                                    <Field
                                        name={`${item}.title`}
                                        component={CustomFieldInput}
                                        type="text"
                                        userCard
                                        label="Title"
                                        placeholder="Ex. Easter weekend"
                                    />
                                </Box>
                            </Flex>
                            <Flex py={1} px={1}>
                                <Box w={[1, 2 / 5]} pr={1}>
                                    <Field
                                        name={`${item}.dateFrom`}
                                        component={CustomFieldInput}
                                        type="date"
                                        userCard
                                        label="Date From"
                                        validate={validator}
                                        dateRange={{
                                            max: itemVals && itemVals.dateTo ? itemVals.dateTo : null,
                                            min: todayHtmlFormat(),
                                        }}
                                    />
                                </Box>

                                <Box w={[1, 2 / 5]} pl={1}>
                                    <Field
                                        name={`${item}.dateTo`}
                                        component={CustomFieldInput}
                                        type="date"
                                        userCard
                                        label="Date To"
                                        validate={validator}
                                        dateRange={{
                                            min: itemVals && itemVals.dateFrom ? itemVals.dateFrom : todayHtmlFormat()
                                        }}
                                    />
                                </Box>
                                <Box w={[1, 1 / 5]} pl={1}>
                                    <Field
                                        name={`${item}.price`}
                                        component={CustomFieldInput}
                                        type="text"
                                        label="Price"
                                        placeholder='Price in NOK'
                                        userCard
                                    />
                                </Box>
                            </Flex>
                        </DateBasedPricingWrapper>
                    )
                })}
                <SaveButton type={'button'} onClick={() => fields.push()}>Click to add a new period</SaveButton>
            </div>
        );
    }
}

export default PeriodBasedPricing
