/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  error: null,
  isLoaded: false,
};

const handlers = {
    STATS_SUCCESS: (state, action) => {
        console.log('LOAD_STATS_SUCCESS')
        const data = action.payload
        const stats = state.data || {};
        if (data) {
            const existing = stats[data.accountId] || {}
            stats[data.accountId] = {...existing, ...data.stats }
        }
        return {...state, error: null, isLoaded: true, data: stats};
    },
  ALL_STATS_SUCCESS: (state, action) => {
    console.log('LOAD_ALL_STATS_SUCCESS')
    const data = action.payload
    const stats = state.data || {};
    if (data) {
      const existing = stats[data.accountId] || {}
      stats[data.accountId] = {...existing, ...data.stats }
    }
    return {...state, error: null, isLoaded: true, data: stats};
  },
}



export default createReducer(initialState, handlers);
