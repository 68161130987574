
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    getAppCode, getAppCodeAndroid, getAppCodeAndroidCommon,
    getAppCodeAppleCommon,
    getAppCodeAppleNotEnterprise,
    resetAppCode
} from "../../../../common/accounts/actions";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const DownloadLink = styled.a`
  font-size: 1em;
  text-decoration:none;
  display:block;
  margin: auto;
  width:300px;

`;


class FAQPage extends Component {

    constructor() {
        super();
    }
    componentDidMount() {
        if (this.props.isApple) {
            if (this.props.data.commonApp) {
                console.log("common")
                this.props.getAppCodeAppleCommon();
            } else if (!this.props.data.enterprise) {
                console.log("not ent")
                this.props.getAppCodeAppleNotEnterprise(this.props.data.accountId);
            } else {
                console.log("code")
                this.props.getAppCode(this.props.data.accountId);
            }
        }
        else {
            if (this.props.data.commonApp) {
                this.props.getAppCodeAndroidCommon();
            }
            else {
                this.props.getAppCodeAndroid(this.props.data.accountId);
            }
        }
    }

    render() {

        if (!(this.props && this.props.token && this.props.token.link)) return null
        return (<DownloadLink href={this.props.token.link}>{this.props.children}</DownloadLink>)

    }

}

const Container = connect(
    (state) => {
        return {
            token: state.accounts.token
        };
    },
    (dispatch) => ({
        resetAppCode: () => dispatch(resetAppCode()),//migrateAllAppCodes()),
        getAppCode: (accountId) => dispatch(getAppCode(accountId)),
        getAppCodeAppleNotEnterprise: (accountId) => dispatch(getAppCodeAppleNotEnterprise(accountId)),
        getAppCodeAppleCommon: () => dispatch(getAppCodeAppleCommon()),
        getAppCodeAndroid: (accountId) => dispatch(getAppCodeAndroid(accountId)),
        getAppCodeAndroidCommon: () => dispatch(getAppCodeAndroidCommon()),



    }),
)(FAQPage);
export default Container;
