/**
 * @flow
 */
import styled from 'styled-components';

const LongLine = styled.hr`
  color: ${props => props.color ? props.color : "#F41A4D"};
  width: 10em;
  flex: 1;
  border-style: solid;
  border-width: 2px;
  `;

export default LongLine;
