/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import WhitelistIcon from './UserAdminList/components/WhitelistIcon';
import Wrapper from '../../components/Typografy/Wrapper';
import theme from '../../theme';
import Link from "../../components/Typografy/Link";

const Container = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-top: 1.6em;
  padding-bottom: 1.6em;
`;

const WhitelistsProfile = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
  
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;


const UserAdminButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonSecondColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonTextColor};
  background-color: ${props => props.active ? theme.subHeader.buttonTextColor : 'none'}
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonTextColor};
  }
`;


type TProps = {
  children?: any;
}

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    const { children } = this.props;
    return (
      <Container >
        <Wrapper>
          <WhitelistsProfile>
            <WhitelistIcon />
            User Administration
          </WhitelistsProfile>
          <ButtonsContainer>
            <UserAdminButton active={this.props.isUserAdmin} to="/admin/useradmin">
              Set admins
            </UserAdminButton>
            <UserAdminButton active={!this.props.isUserAdmin} to="/admin/setpassword">
              Set password
            </UserAdminButton>
          </ButtonsContainer>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
