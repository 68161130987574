import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { polyfill } from 'smoothscroll-polyfill';

import BenefitsSection from './StartPage/BenefitsSection';
import StickyMenu from './components/StickyMenu';
import PromotionalSection from './StartPage/PromotionalSection';
import DescribeFuncSection from './StartPage/DescribeFuncSection';
import DigitalPlatformSection from './StartPage/DigitalPlatformSection';
import ContactSection from './StartPage/ContactSection';
import VideoSection from './StartPage/VideoSection';
import Footer from '../components/Footer';
import SelfServiceSection from './StartPage/SelfServiceSection';


class LandingPage extends Component {
  _sections = {}

    componentDidMount(){
        if (window.location.hash && window.location.hash.length > 0)
        {
            this.props.handleAuthentication(this.props.history)
        }
    }

  _onMenuClick = (id) => {
    ReactDOM.findDOMNode(this._sections[id]).scrollIntoView({ behavior: 'smooth'}); //eslint-disable-line
  }

  render() {
    polyfill();
    return (
      <div>
        <StickyMenu onMenuItemClick={this._onMenuClick} />
        <SelfServiceSection
          onMenuItemClick={this._onMenuClick}
          ref={e => (this._sections.video = e)}
        />
        <PromotionalSection
          ref={e => (this._sections.promotional = e)}
        />
        <DescribeFuncSection ref={e => (this._sections.describe = e)} />
        <BenefitsSection ref={e => (this._sections.benefits = e)} />
        <DigitalPlatformSection ref={e => (this._sections.platform = e)} />
        <ContactSection ref={e => (this._sections.contact = e)} />
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
