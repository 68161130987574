/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_DOMAINS_SUCCESS: (state, action) => {
        console.log('LOAD_DOMAINS_SUCCESS')
        const data = action.payload
        const domains = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const domain = {...data[key], domainId: key}
                domains[key] = domain
                list.push(domain);
            });

        }
        list.reverse()
        return {...state, error: null, isLoaded: true, data: domains, list: list};
    },
}



export default createReducer(initialState, handlers);
