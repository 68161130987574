/**
* @flow
*/

import styled from 'styled-components';
import { mx } from '../../../utils';

const PromoImageWrapper = styled.div`
  display:flex
  position: absolute;
  flex: 1;
  display: none;
  ${mx('lg')} {
    display: block;
    justify-content: flex-start;
    }
  `;

export default PromoImageWrapper;
