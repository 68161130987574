/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import BenefitIcon from './components/BenefitIcon';
import Link from '../../../components/Typografy/Link';
import Wrapper from '../../../components/Typografy/Wrapper';
import { PrimaryButton } from '../../../components/Buttons';
import theme from '../../../theme/index';
import {Field} from "redux-form";

const Container = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-top: 1.6em;
`;

const BenefitsProfile = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;

const AddBenefitButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;


const ArchivedBenefitButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonSecondColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonTextColor};
  background-color: ${props => props.active ? theme.subHeader.buttonTextColor : 'none'}
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonTextColor};
  }
`;


type TProps = {
  children?: any;
}

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    const { children, account } = this.props;
    return (
      <Container >
        <Wrapper>
          <BenefitsProfile>
            <BenefitIcon />
            {this.props.isArchive ? 'Archived': this.props.isDraft ? 'Drafted' :''} Benefits
          </BenefitsProfile>
          <ButtonsContainer>
            <AddBenefitButton to="/admin/benefits/new">
              Add benefit
            </AddBenefitButton>
            <ArchivedBenefitButton active={!(this.props.isArchive || this.props.isDraft)} to="/admin/benefits">
              Published
            </ArchivedBenefitButton>
            <ArchivedBenefitButton active={this.props.isDraft} to="/admin/benefits-draft">
              Drafts
            </ArchivedBenefitButton>

              <ArchivedBenefitButton active={this.props.isArchive} to="/admin/benefits-archive">
                Archived
              </ArchivedBenefitButton>
              {account.customModules && account.customModules.benefitReport && account.customModules.benefitReport.active && <ArchivedBenefitButton to="/admin/benefit-report">
              Report usage
            </ArchivedBenefitButton>}
          </ButtonsContainer>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
