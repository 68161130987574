/**
 * @flow
 */

import React from 'react';
import {Box, Flex,} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme';
import Link from "../../../components/Typografy/Link";
import {firebaseAuth} from "../../../../common/config/firebase_config/firebase";
import AsyncStorage from "../../../../common/utils/AsyncStorage";
import {auth0Logout} from "../../../../common/config/auth/auth0";
import {hasMultiple, hasSuperAdminAccess} from "../../../../common/auth";


const MenuLink = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 10px;
  margin: 0;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 8px;

  transition: all .2s ease-in-out;

  &:hover {
    text-decoration: none;
    background-color: ${theme.subHeader.buttonSecondColor};
  }
`;


const MenuClick = styled.div`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 10px;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 8px;
  
  transition: all .2s ease-in-out;

  &:hover {
    text-decoration: none;
    background-color: ${theme.subHeader.buttonSecondColor};
    cursor: pointer;
  }
`;

const MenuButtonsWrapper = styled.div`
	display: flex;
  	flex-direction: column;
  	gap: 5px;
`


export const logoutAccount = async (history) => {
	try {
		await firebaseAuth.signOut();
		await AsyncStorage.clear();
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('expires_at');
		localStorage.removeItem('@CoveredPro:PROFILE');
		
		window.location.href = auth0Logout

//history.push('/');
	} catch (error) {
		alert(error)
		
	}
};

const MenuItems = ({open, history, toggleMenu, profile}) => {
	return (
		<MenuButtonsWrapper className={open ? 'open' : ''} id="nav-icon3">
			<MenuClick onClick={() => logoutAccount(history)}>Logout</MenuClick>
			<MenuLink onClick={() => toggleMenu()} to="/admin/useradmin">
				User administration
			</MenuLink>
			{hasMultiple(profile) && <MenuLink onClick={() => toggleMenu()} to="/selectAccount">
				Switch accounts
			</MenuLink>}
			{hasSuperAdminAccess(profile) &&
				<MenuLink onClick={() => toggleMenu()} to="/admin/accounts">
					Manage accounts
				</MenuLink>
			}
			{hasSuperAdminAccess(profile) &&
				<MenuLink onClick={() => toggleMenu()} to="/admin/allstats">
					All stats
				</MenuLink>
			}
			{hasSuperAdminAccess(profile) &&
				<MenuLink onClick={() => toggleMenu()} to="/admin/retrieve-emails">
					Retrieve emails
				</MenuLink>
			}
			{hasSuperAdminAccess(profile) &&
				<MenuLink onClick={() => toggleMenu()} to="/admin/manage-help">
					Manage help page
				</MenuLink>
			}
		</MenuButtonsWrapper>
	);
};


const Container = styled(Flex)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const MenuContainer = styled(Box)`
  z-index: 4;
  display: ${props => props.open ? "block" : "none"};
  opacity: ${props => props.open ? 1 : 0};
  position: absolute;
  background: ${theme.nav.menuContainer};
  border-radius: 10px;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  
  transition: all .2s ease-in-out;
`;

const InfoContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;

  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;

  transition: all .2s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.9;

    background-color: rgba(0, 0, 0, 0.4);
  }

  &:active {
    opacity: 0.9;

    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 20px;
`


class AdminMenu extends React.Component {
	
	state = {
		open: false,
	};
	
	toggleMenu = () => {
		console.log("Adminmenu toggled")
		this.setState({
			open: !this.state.open,
		});
	};
	
	componentDidUpdate(prevProps, prevState) {
		if (prevState.open && !this.state.open) {
			/* $FlowIssue */
			document.body.style = 'overflow: auto';
		} else if (!prevState.open && this.state.open) {
			/* $FlowIssue */
			document.body.style = 'overflow: hidden';
		}
	}
	
	componentWillUnmount() {
// If we navigate out of this view and the menu is open, make sure to restore the overflow
		if (this.state.open) {
			/* $FlowIssue */
			document.body.style = 'overflow: auto';
		}
	}
	
	render() {
		const {open} = this.state;
		const {children} = this.props;
		return (
			<Wrapper>
				<InfoContainer onClick={this.toggleMenu} open={open}>
					{children}
				</InfoContainer>
				<MenuContainer open={open}>
					<MenuItems toggleMenu={this.toggleMenu} history={this.props.history} profile={this.props.profile}/>
				</MenuContainer>
			</Wrapper>
		);
	}
}

export default AdminMenu;
