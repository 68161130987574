/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import type { TUserAdminApiData } from '../../../../common/types/api/TUserAdmin';

import UserAdminDropper from '../UserAdminEdit/components/UserAdminDropper'

const UserAdminsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 0.5em;
`;

const UserAdminInfoContainer = styled.div`
  padding: 0 2.5em 0 2.5em;
`;

const UserAdminTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;


type TProps = {
  userAdmin: TUserAdminApiData;
  accountId: number;
  removeFromUserAdmin: any;
};

const UserAdminItem = (props: TProps) => {
  const { user, accountId, removeFromUserAdmin } = props;

  return (
      <UserAdminsCard>
        <Flex wrap justify="space-between">
          <Box w={[1, 0.8]}>
            <UserAdminInfoContainer>
              <UserAdminTitle>{user.user_metadata.firstName} {user.user_metadata.lastName}, {user.email} </UserAdminTitle>
            </UserAdminInfoContainer>
          </Box>
          <Box w={[1, 0.2]}>
            <UserAdminDropper {...props}/>
          </Box>
        </Flex>
      </UserAdminsCard>
  );
};

export default UserAdminItem;
