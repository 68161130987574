/**
 * @flow
 */

import styled from 'styled-components';
import { mx } from '../../../../utils';

const Section = styled.section`
 margin-top: -77px;
 padding-top: 77px;
 ${mx('lg')} {
   margin-top: -80px;
   padding-top: 80px;
 }
 `;

export default Section;
