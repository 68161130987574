import {
  buildAccounts,
  buildBenefits,
} from "../../../../common/config/firebase_config/endpoints";


export const getAccounts = (onAccountsLoadSuccess) => {
    return ([{
        endpoint:  buildAccounts(),
        method: 'on',
        eventType: 'value',
        callback: onAccountsLoadSuccess,
    }
    ]);
};

export const getBenefits = (accounts, accountId, onBenefitsLoadSuccess) => {

    const account = accounts[accountId]
    let queries = [{
        endpoint: buildBenefits(accountId),
        method: 'on',
        eventType: 'value',
        callback: onBenefitsLoadSuccess,
        metadata: accountId,
        config: account
    },
    ]

    return queries;
};
