
import React, { Component } from 'react';
import {Stage, Layer, Image, Image as KonvaImage} from 'react-konva';
import {isEqual, difference} from "lodash";

class KonvaImageSimple extends React.Component {
    state = {
        image: null
    };
    componentDidMount() {
        this.updateImage();
    }

   componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

       if(this.props.onScaled) {
           const scaled = this.scaled(this.props, this.state)
           const scaledPrev = this.scaled(prevProps, prevState)

           if(!isEqual(scaled, scaledPrev)) {
               console.log("Scaling of image changed - " + JSON.stringify(difference(scaled, scaledPrev)))
               this.props.onScaled(scaled)
           }
       }

   }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return true
    }

    fillRect = (p, c) => {
        return (c.width / c.height) < (p.width / p.height)
            ? {width: p.width, height: c.height * (p.width / c.width)}
            : {height: p.height, width: c.width * (p.height / c.height)};
    };

    fitRect = (p, c) => {
        return (c.width / c.height) > (p.width / p.height)
            ? {width: p.width, height: c.height * (p.width / c.width)}
            : {height: p.height, width: c.width * (p.height / c.height)};
    };

    getDims = (space, parent, child) => {
        switch (space) {
            case "fill":
                return this.fillRect(parent, child); break;

            case "fit":
            default:
                return this.fitRect(parent, child); break;
        }
    };

    scaled = (props, state) => {
        var selfDims = {width: props.width, height: props.height},
            image = state.image,
            imageDims = image && image.height && image.width ? {width: image.width, height: image.height} : selfDims,
            dims = this.getDims(props.space, selfDims, imageDims),
            pos = {x: props.x || 0, y: props.y || 0},
            scale = props.scale || 1

        const img = image || {}

        const scaled = {x: pos.x*scale, y: pos.y*scale, width:dims.width*scale,height: dims.height*scale, originalWidth: (img.width), originalHeight: img.height  }
        return scaled
    }

    updateImage() {
        const image = new window.Image();
        image.crossOrigin = "anonymous";
        image.src = this.props.src;
        image.onload = () => {
            this.setState({
                image: image
            });
        };
    }

    render() {
        const scaled = this.scaled(this.props, this.state)
        console.log("Image scale: " + JSON.stringify(scaled))
        return (
            <Image crossOrigin='anonymous' image={this.state.image} x={scaled.x} y={scaled.y} width={scaled.width} height={scaled.height}/>
        );

    }
}

export default KonvaImageSimple
