/**
 * @flow
 */

import React, {
  Component,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import FormWrapper from '../../components/Typografy/FormWrapper';
import SelectAccountWrapper from './SelectAccountWrapper';
import SelectAccountForm from './SelectAccountForm';
import FirebaseContainer from '../../../common/firebase/FirebaseContainer';
import {buildAccounts} from "../../../common/config/firebase_config/endpoints";

const Link = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 1em 1.5em;
  color: #fff;
  border-radius: 1.25em;
  margin-bottom: 1em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

const ImageContainer = styled.div`
   margin: 0 auto;
   margin-bottom: 2.5em;
`;

const FormContainer = styled.div`
   margin-top: 1.5em;
`;


const FormTitle = styled.span`
   font-size: 1.25em;
   font-weight: bold;
   color: #fff;
`;



class SelectAccount extends Component {
  _getQueries = () => {
    return ([{
      endpoint: buildAccounts(),
      method: 'on',
      eventType: 'value',
      callback: this.props.onAccountsLoadSuccess,
    }])
  };
  render() {

    if(!(this.props.profile && this.props.accounts))
    {
      return null
    }

    return (
      <FirebaseContainer queries={this._getQueries()}>

      <SelectAccountWrapper>
        <Link to="/">&#60; Main page</Link>
        <FormWrapper>
          <ImageContainer>
            <ResponsiveImage
              src="form_logo.png"
              width={160}
              height={49}
              containerStyle="margin: 0 auto"
              alt=""
            />
          </ImageContainer>
          <FormTitle> Select account </FormTitle>
          <FormContainer>
              {this.props.accounts && <SelectAccountForm
              profile={this.props.profile}
              accounts={this.props.accounts}
              history={this.props.history}
              updateSelectedAccountSuccess={this.props.updateSelectedAccountSuccess}
              updateSelectedAccountAppSuccess={this.props.updateSelectedAccountAppSuccess}
            />}
          </FormContainer>
        </FormWrapper>
      </SelectAccountWrapper>
      </FirebaseContainer>
    );
  }
}

export default SelectAccount;
