  /**
 * @flow
 */
import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../../components/Typografy/Wrapper';
import { mx } from '../../../utils';

import H2 from '../../../components/Typografy/H2';
import Section from './Section';
import Title from '../../../components/Typografy/Title';
import ShortLine from './ShortLine'

const MessageWrapper = styled(Wrapper)`
padding-top:8vh;
 padding-left: 0.5em;
  padding-right: 0.5em;
 ${mx('sm')} {
    padding-left: 0.5em;
    padding-right: 0.5em;
  } ${mx('md')} {
    padding-left: 1em;
    padding-right: 1em;
  }  ${mx('lg')} {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  `;

const Container = styled.div`
  min-height: 100vh;
  background-color: rgba(167, 167, 167, 0.78);
    &:after {
    background-size: 100vw;
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1; 
    
    
      background: url(${require('../../../assets/images/bg-shape-mini.png').default});
        ${mx('sm')} {
      background: url(${require('../../../assets/images/bg-shape-small.png').default});
        }
        ${mx('md')} {
      background: url(${require('../../../assets/images/bg-shape-small.png').default});
        }
        ${mx('lg')} {
      background: url(${require('../../../assets/images/bg-shape-medium.png').default});
        }
    }
  `;



const DescriptionWrapper = styled.div`
  margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    align-items: center;
    margin-top: 10vh;
        ${mx('lg')} {
          margin-top: 15vh;
          width: 50vw;
        }
  `;

  const FlexWrapper = styled.div`
    font-size:1.5em;
  `;

  const Headline = styled(Title)`
  padding-bottom: 0.5em;
  color: black;
  font-size:2.5em;
  `;

  const SubHeading = styled(H2)`
  color: #171717;;
  text-align: center;
  padding-bottom: 0.5em;
  font-weight: normal;
  margin-top:3vh;
  `;

  type TProps = {
    headlineText: string,
    subheadlineText: string,
    subheadline2Text: string,
    full: any;
  }


class MessageSection extends Component<void, TProps, void> {
  render() {
    return (
      <Section>
        <Container>
          <MessageWrapper>
            <DescriptionWrapper>
              <Headline>
                {this.props.headlineText}
              </Headline>
              <ShortLine />
              <SubHeading>
                {this.props.subheadlineText}
              </SubHeading>
              <SubHeading>
                {this.props.subheadline2Text}
              </SubHeading>
            </DescriptionWrapper>
          </MessageWrapper>
        </Container>
      </Section>
    );
  }
}

export default MessageSection;
