import React, {Component} from 'react';
import {Field, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import {FaImage as ImageIcon} from 'react-icons/fa'
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import Translate from '../../../../components/form/LegacyTranslate';
import {buildCategoryImageUpload, readCategoryImage} from "../../../../../common/config/firebase_config/endpoints";
import styled from "styled-components";
import Button from "../../../../components/Typografy/BaseButton";
import theme from "../../../../theme";
import ImageUploaderModal from "../../../../components/form/ImageUploaderModal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {GrDrag, MdDragHandle} from "react-icons/all";

export const ImageButton = styled(Button)`
  color: ${theme.buttons.defaultColor};
  border: 2px solid ${theme.buttons.defaultColor};
  margin-left: 0.5em;
  padding: 0.5em 0.5em;
  border-radius: 50%;
`;

const reorder = (list, startIndex, endIndex) => {
	const [removed] = list.getAll().splice(startIndex, 1);
	list.getAll().splice(endIndex, 0, removed);
	
	return list;
};

class CustomCategories extends Component<void, TProps, TState> {
	constructor(props) {
		super(props);
		
		console.log("prOps", props);
		
		this.state = {
			items: props.fields,
		}
		
		this.onDragEnd = this.onDragEnd.bind(this);
	}

    getLabel = (index) => {

        const data = this.props.accountFormValues || {}
        const category = (data.categories && data.categories.length > index) ? data.categories[index] : null

        return category ? category.name : ''

    }
	
	onDragEnd(result) {
		if (!result.destination) {
			return;
		}
		
		const items = reorder(
			this.props.fields,
			result.source.index,
			result.destination.index
		);
		
		this.setState({
			items
		});
	}

    render() {

        const {fields, meta: {touched, error}} = this.props

        console.log("Custom cat dumping account form: " + JSON.stringify(this.props.accountFormValues || {}))
        console.log("CUSTOM CAT", this.props);

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
	            <Droppable droppableId="droppable">
		            {(provided, snapshot) => (
			            <div
				            {...provided.droppableProps}
				            ref={provided.innerRef}
			            >
			                {fields.map((step, index, f) => {
								const item = f.getAll()[index];
				                return <Draggable draggableId={"item_" + item.id} key={"item_" + index} index={index}>
					                {(provided, snapshot) => (
						                <div
							                ref={provided.innerRef}
							                {...provided.draggableProps}
							                {...provided.dragHandleProps}
						                >
							                <div style={{display: "flex", width: "100%", flexDirection: "row", alignItems: "center", gap: 20}}>
								                <GrDrag/>
								                
								                <div style={{width: "100%"}}>
									                <h4>Category #{index + 1} (Click translate after changes is done)</h4>
									                <Flex
										                justify="space-between">
										                <Box w={[1, 9 / 12]}>
											                <div>
												                <Field
													                name={`${step}.name`}
													                component={CustomFieldInput}
													                type="text"
													                userCard
													                placeholder="Category name"
													                label="Name"
												                />
											                </div>
										                </Box>
										                <Box w={[1 / 14]}>
											                <Translate formValues={this.props.accountFormValues}
											                           accountId={this.props.accountId} parentField='name'
											                           label={this.getLabel(index)} field={step}/>
										                </Box>
										                <Box w={[1 / 14]}>
											                <ImageUploaderModal minHeight={300} minWidth={300} aspectRatio={1}
											                                    uploadUrl={buildCategoryImageUpload(this.props.accountId, index)}
											                                    showUrl={this.props.account ? readCategoryImage(this.props.accountId, index) : null}
											                >
												                <ImageButton><ImageIcon/></ImageButton>
											                </ImageUploaderModal>
										                </Box>
										                <Box w={[1, 2 / 12]} mx={2}>
											                <RemoveField onClick={() => fields.remove(index)}/>
										                </Box>
									                </Flex>
								                </div>
							                </div>
						                </div>
					                )}
				                </Draggable>
			                })}
				            {provided.placeholder}
			            </div>
		            )}
				</Droppable>
                <Flex justify="flex-end">
                    <SaveButton name="addCardCustomCategory" type="button" onClick={(event) => {
                        if (event) event.preventDefault();
                        fields.push({name: '', id:fields.length});
                    }}> Add category </SaveButton>
                </Flex>
            </DragDropContext>
        );
    }
}
export default CustomCategories
