/**
 * @flow
 */
import base64 from 'base64-url'

import {firebaseDb} from '../config/firebase_config/firebase';
import {
  buildWhitelistIndex,
  buildWhitelist,
} from '../config/firebase_config/endpoints';

import history from '../utils/history'
import {NotificationManager} from 'react-notifications';
import {reset} from 'redux-form';

export const onWhitelistsLoadSuccess = payload => ({
  type: 'LOAD_WHITELISTS_SUCCESS',
  payload,
});


function updateWhitelistRequest() {
  return {
    type: 'UPDATE_WHITELIST_REQUEST',
  };
}

function updateWhitelistSuccess() {
  return {
    type: 'UPDATE_WHITELIST_SUCCESS',
  };
}

function updateWhitelistError(json) {
  return {
    type: 'UPDATE_WHITELIST_ERROR',
  };
}


export const updateWhitelist = (email, whitelist) => async (dispatch, getState) => {
  try {
    dispatch(updateWhitelistRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    const emailData = (email || '').toLowerCase()

    let whitelistKey = base64.encode(emailData)

    updates[buildWhitelist(accountId, whitelistKey)] = emailData;
    updates[`${buildWhitelistIndex(whitelistKey)}/email`] = emailData;
    updates[`${buildWhitelistIndex(whitelistKey)}/accounts/${accountId}`] = true;

    console.log("update whitelist called")
    await firebaseDb.ref().update(updates);

    dispatch(updateWhitelistSuccess());
    NotificationManager.success('Success!', 'Whitelist saved!', 3000);
    dispatch(reset('WhitelistForm'));
  } catch (err) {
    console.log("update whitelist ERROR: "+ JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateWhitelistError(err));
  }
};


export const removeFromWhitelist = (email) => async (dispatch, getState) => {
  try {
    dispatch(updateWhitelistRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    let whitelistKey = base64.encode(email)

    updates[buildWhitelist(accountId, whitelistKey)] = null;
    updates[buildWhitelistIndex(whitelistKey)] = null;
    console.log("update whitelist called")
    await firebaseDb.ref().update(updates);

    dispatch(updateWhitelistSuccess());
    NotificationManager.success('Success!', 'Whitelist saved!', 3000);
//    if (!whitelist) {
    //    dispatch(replace('/whitelists/' + whitelistKey + "/edit"));
    //  }
  } catch (err) {
    console.log("update whitelist ERROR: "+ JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateWhitelistError(err));
  }
};

