/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import VerifyConfirmation from './VerifyConfirmation'

const VerifyConfirmationContainer = connect(
  (state: TAppState) => ({
    supportSignUp: state.router.location.query.supportSignUp,
    supportForgotPassword: state.router.location.query.supportForgotPassword,
    email: state.router.location.query.email,
    message: state.router.location.query.message,
    success: state.router.location.query.success,
  }), {},
)(VerifyConfirmation);


export default VerifyConfirmation;
