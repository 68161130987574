/**
 * @flow
 */


import { connect } from 'react-redux';
import MessagePage from './MessagePage';
import {
	onMessagesLoadSuccess,
	archiveMessage,
	publishMessageDraft,
	onMessagesReset, onMessageDraftsLoadSuccess
} from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';

const MessageDraftContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.draftList,
			isDraft: true,
		};
	},
	(dispatch) => ({
		onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
		onMessageDraftsLoadSuccess: (data) => dispatch(onMessageDraftsLoadSuccess(data)),
		onMessagesReset: () => dispatch(onMessagesReset()),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId)),
		publishMessageDraft: (message, accountId) => dispatch(publishMessageDraft(message, accountId)),
	}),
)(MessagePage);
export default MessageDraftContainer;
