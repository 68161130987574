/**
 * @flow
 */
import {firebaseDb} from '../config/firebase_config/firebase';
import {
    buildAccountArchive,
    buildAccount, buildAccountTheme,
    buildAccountCustomizeVendorBenefitCategory,
    buildGoogleServices
} from '../config/firebase_config/endpoints';

import {NotificationManager} from 'react-notifications';
import history from '../utils/history'

import {buildTheme} from '../theme/native/base'

export const onGoogleServicesLoadSuccess = payload => ({
    type: 'LOAD_GOOGLE_SERVICES_SUCCESS',
    payload,
});



export const updateGoogleServices = (services) => async (dispatch, getState) => {
    try {
    let updates = {};
    updates[buildGoogleServices] = services;
    console.log("update google services called")
    await firebaseDb.ref().update(updates);
    NotificationManager.success('Success!', 'Google services.json saved!', 3000);

    } catch (err) {
        console.log("update account ERROR: " + JSON.stringify(err))
    }
}


