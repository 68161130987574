/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
    data: {},
    error: null,
    isLoaded: false,
};


const handlers = {
    LOAD_ALL_EMAILS_SUCCESS: (state, action) => {
        console.log('LOAD_ALL_EMAILS_SUCCESS')
        const data = action.payload
        const emailData = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const row = {
                    email: data[key].email,
                    firstName: data[key].user_metadata && data[key].user_metadata.firstName ? data[key].user_metadata.firstName : data[key].firstName ? data[key].firstName : "",
                    lastName: data[key].user_metadata && data[key].user_metadata.lastName ? data[key].user_metadata.lastName : data[key].lastName ? data[key].lastName : "",
                    signupAccount: data[key].user_metadata && data[key].user_metadata.signupAccount ? data[key].user_metadata.signupAccount : data[key].signupAccount ? data[key].signupAccount : (data[key].email.indexOf("ringnes.no") !== -1) ? "111222333" : ""
                }
                emailData[key] = row
                list.push(row);
            });

        }
        list.reverse()
        return {...state, error: null, isLoaded: true, data: emailData, list: list, tokens: state.tokens};
    },
};

export default createReducer(initialState, handlers);
