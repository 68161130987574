/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
};





const handlers = {
  LOAD_WHITELISTS_SUCCESS: (state, action) => {
    console.log('LOAD_WHITELISTS_SUCCESS: ' + JSON.stringify(action))
    const data = action.payload
    const whitelists = {};
    let list = [];
    if(data) {
      Object.keys(data).forEach((key) => {
        //const whitelist = {...data[key], id: key}
        //whitelists[key] = whitelist
        list.push(data[key]);
      });

    }

    return { ...state, error: null, isLoaded: true, data: whitelists, list:list};
  },
};

export default createReducer(initialState, handlers);
