import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import {imageThumb} from "../../../common/config/images/endpoints";
import {Box, Flex} from "reflexbox";

const SingleMarkerDiv = styled.div`
  font-size:20px;
  font-weight:bold;
  cursor:pointer;
  position:relative;
  left:-10px;
  top:-32px;
  
`;

const SingleMarkerSpan = styled.span`
  width:50px;
  height:50pX;
`;

const ClusterMarkerDiv = styled.div`
  font-size:20px;
  font-weight:bold;
  padding-top:15px;
  padding-left:1px;
  color: white;
  text-align:center;
  background-image: url(${props => require('../../assets/clusters/m'+ props.cluster_id +'.png')});
  background-size: 50px 50px;
  width:50px;
  height:35px;
  cursor:pointer;
  position:relative;
  left:-20px;
  top:-32px;
`;

const InfoBox = styled.div`
  border:1px solid #dddddd;
  background-color: rgba(255,255,255,0.9);
  width:300px;
  z-index: 99999;
  padding:15px;
  cursor:default;
`;

const MainInfoWrapper = styled.div`
  display:flex;
`;

const InfoBoxImage = styled.img`
  width:80px;
`;

const InfoBoxHeader = styled.div`
  font-size: 20px;
  display:block;
  font-weight:bold;
`;
const InfoBoxSubHeader = styled.div`
  font-size: 15px;
  display:block;
`;

const InfoBoxLink = styled.a`
  display:block;
  font-size: 15px;
`;

const InfoBoxExit = styled.span`
  padding:5px;
  float:right;
  cursor:pointer;
`;


class Map extends Component {

  constructor() {
    super();
    this.state = {
      bounds: {
        sw: {
          lat: 0,
          lng:0,
        },
        nw: {
          lat: 100,
          lng: 0,
        },
        se: {
          lat: 0,
          lng:100,
        },
        ne: {
          lat: 100,
          lng: 100
        }
      },
      zoom: 8,
      center: {
        lat: 59.95,
        lng: 10.33
      },
      showBenefitId: "null"
    }

    this.zoomToCluster = this.zoomToCluster.bind(this);
  }

  componentDidMount() {
    this.setState({
      zoom:8
    })
  }

  zoomToCluster = (latlng) => {
    this.setState({
      zoom: this.state.zoom + 1,
      center: latlng
    });
  }

  isWithinLat = (lat, bounds) => {
    return (lat > bounds.sw.lat && lat < bounds.nw.lat);
  };

  isWithinLng = (lng, bounds) => {
    return (lng > bounds.nw.lng && lng < bounds.ne.lng);
  };

  isWithinScreen = (lat, lng, bounds) => {
    return ((this.isWithinLat(lat, bounds)) && this.isWithinLng(lng, bounds));
  };

  getClusterId = (cnt) => {
    let img_source;
    if(cnt < 10)
    {
      img_source = '1';
    }
    else if(cnt < 25)
    {
      img_source = '2';
    }
    else if(cnt < 50)
    {
      img_source = '3';
    }
    else if(cnt < 100)
    {
      img_source = '4';
    }
    else
    {
      img_source = '5';
    }
    return img_source
  }


  _renderMarker = (marker) => {
    const key = marker.geometry.coordinates[1] + '-' + marker.geometry.coordinates[0] + Math.random()
    const coordinate = {
      latitude: parseFloat(marker.geometry.coordinates[1]),
      longitude: parseFloat(marker.geometry.coordinates[0])
    }

    if (!this.isWithinScreen(coordinate.latitude, coordinate.longitude, this.state.bounds)) {
      return null;
    }
    let name = marker.properties.name;
    let benefitId = marker.properties.benefits[0].id;

    return (
      <SingleMarkerDiv
        optimized={false}
        lng={coordinate.longitude}
        lat={coordinate.latitude}
        key={key}
      >

        {this.displayInfoBox(name, benefitId, coordinate.latitude, coordinate.longitude)}
      </SingleMarkerDiv>
    )

  }

  handleMarkerClick = (benefitId) => {
    this.setState({
      showBenefitId: benefitId
    })
  }

  displayInfoBox = (name, benefitId, lat, lng) => {

    if (this.state.showBenefitId === name) {
      console.log(this.props.benefits[benefitId]);
      return (
        <InfoBox>
          <InfoBoxExit onClick={() => this.setState({showBenefitId: "null"})}>X</InfoBoxExit>
          <Flex wrap>
            <Box w={[1, 4/12]}>
              <InfoBoxImage src={imageThumb(this.props.benefits[benefitId].sourceAccount || this.props.accountId, benefitId)} />
            </Box>
            <Box w={[1, 8/12]}>
              <InfoBoxHeader>{name}</InfoBoxHeader>
              <InfoBoxSubHeader>{this.props.benefits[benefitId].heading}</InfoBoxSubHeader>
              <InfoBoxLink href={"/app/benefits/"+benefitId}>Mer informasjon</InfoBoxLink>
              <InfoBoxLink href={"https://maps.google.com/maps?daddr="+lat+","+lng+"&amp;ll="}>Naviger hit</InfoBoxLink>
            </Box>
          </Flex>
        </InfoBox>
      )
    } else {
      return (
        <SingleMarkerSpan onClick={() => this.handleMarkerClick(name)} >
          <img src={require('../../assets/images/map-marker.png')} alt={"+"} width={35} />
        </SingleMarkerSpan>
      )
    }
  }

  _renderClusterMarker = (marker) => {
    const key = marker.geometry.coordinates[1] + '-' + marker.geometry.coordinates[0] + Math.random()
    const coordinate = {
      latitude: parseFloat(marker.geometry.coordinates[1]),
      longitude: parseFloat(marker.geometry.coordinates[0])
    }
    const size = marker.properties.point_count

    if (!this.isWithinScreen(coordinate.latitude, coordinate.longitude, this.state.bounds)) {
      return null;
    }
    // console.log(marker);

    let cluster_id = this.getClusterId(size);

    return (
      <ClusterMarkerDiv
        zIndex={-1}
        optimized={false}
        style={{zIndex: -1}}
        lng={coordinate.longitude}
        lat={coordinate.latitude}
        key={key}
        onClick={() => this.zoomToCluster({lat: coordinate.latitude, lng: coordinate.longitude})}
        cluster_id={cluster_id}
      >
        {size}
      </ClusterMarkerDiv>
    )

  }

  _createMarkersForRegion = () => {

    let bbox = [this.state.bounds.nw.lng, this.state.bounds.sw.lat, this.state.bounds.se.lng, this.state.bounds.ne.lat];

    let cluster = this.props.locations.getClusters(bbox,this.state.zoom)

    let showBenefitIdIndex = 0;

    let markers = Object.keys(cluster).map((point) => {
      if (cluster[point].properties.name === this.state.showBenefitId) showBenefitIdIndex = point;
      if (cluster[point].properties.cluster) return this._renderClusterMarker(cluster[point])
      else return this._renderMarker(cluster[point]);
    });

    if (showBenefitIdIndex) {
      let marker = this._renderMarker(cluster[showBenefitIdIndex]);
      markers.push(marker);
    }

    return markers;

  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 10.33
    },
    zoom: 8
  };

  mapChange = (map) => {
    this.setState({
      bounds: map.bounds,
      zoom: map.zoom,
      center: map.center
    });
  };

  createMapOptions = () => {
    return {
      gestureHandling: 'greedy'
    };
  }

  render() {
    return (
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          onChange={this.mapChange}
        >
          {Object.keys(this.props.locations).length && this._createMarkersForRegion()}
        </GoogleMapReact>
    );
  }
}

export default Map;
