/**
 * @flow
 */


import {connect} from 'react-redux';
import {getAppCodeStatus, onAccountsLoadSuccess} from '../../../../common/accounts/actions';
import {getAllStats, getStats, updateDataForAllUsers, getStatsFromAll} from '../../../../common/stats/actions';
import type {TAppState} from '../../../../common/TAppState';
import AllStatsPage from "./AllStatsPage";

const AllStatsContainer = connect(
  (state: TAppState) => {
    return {
      accountId: state.auth.profile.data.accountId,
      stats: state.stats.data,
      tokens: state.accounts.tokens,
      accountsLoaded: state.accounts.isLoaded,
      accounts: state.accounts.data
    };
  },
  (dispatch) => ({
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    getStatsFromAll: () => dispatch(getStatsFromAll()),
    getAppCodeStatus: (data) => dispatch(getAppCodeStatus(data)),
    updateDataForAllUsers: () => dispatch(updateDataForAllUsers()),


  }),
)(AllStatsPage);
export default AllStatsContainer;
