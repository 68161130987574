/**
 * @flow
 */

import createReducer from '../../createReducer';
import type {TProfileState} from '../TAuthState';
import type {THandler} from '../../types/THandler';
import type {TProfileData} from '../../types/models/TAuth';
import * as keys from '../../storageKeys';

const initialState: TProfileState = {
    isLoading: false,
    error: null,
    data: {
        accountId: null,
        selectedAccount: null,
        accounts: {},
        email: '',
        userId: '',
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        country: '',
    },
};

export const getPrimary = function (profile) {
  if(!profile) return

  if(profile.accounts && Object.keys(profile.accounts).length > 0){
    return Object.keys(profile.accounts)[0]
  }
 return profile.accountId
};

const handlers: THandler<TProfileState> = {
    UPDATE_PROFILE_REQUEST: (state) => {
        return {...state, isLoading: true};
    },
    UPDATE_PROFILE_FAILURE: (state, action) => {
        return {...state, isLoading: false, error: action.payload};
    },
    UPDATE_PROFILE_SUCCESS: (state, action) => {
        let profile: TProfileData = action.payload;
        let selectedAccount = localStorage.getItem('selectedAccount')
        if(!selectedAccount){
          selectedAccount = getPrimary(profile)
        }
        profile.selectedAccount = selectedAccount
        profile.accountId = selectedAccount
        localStorage.setItem(keys.KEY_PROFILE, JSON.stringify(profile))
        return {...state, isLoading: false, data: profile};
    },
    UPDATE_SELECTED_ACCOUNT_PROFILE_SUCCESS: (state, action) => {
        const accountId = action.payload;
        let profile = state.data
        profile.accountId = accountId
        profile.selectedAccount = accountId
        localStorage.setItem(keys.KEY_PROFILE, JSON.stringify(profile))
        return {...state, isLoading: false, data: profile};
    },
    AUTH0_LOGOUT_SUCCESS: () => {
        return initialState;
    },
    RESTORE_STATE_SUCCESS: (state, action) => {
        return action.payload.auth.profile;
    },
};

export default createReducer(initialState, handlers);
