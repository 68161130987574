/**
 * @flow
 */

import { connect } from 'react-redux';

import Profile from './Profile';
import type { TAppState } from '../../../common/TAppState';

const ProfileContainer = connect(
  (state: TAppState) => ({
    profile: state.auth.profile.data,
  }),
  null,
)(Profile);

export default ProfileContainer;
