
// Hook for contact us form on startpage

const slackConfigContact = {
  hookUrl: 'https://hooks.slack.com/services/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',
  channel: '#contact-requests',
  username: 'MailRequest'
};

export default slackConfigContact;
