import React from 'react';
import Switch from 'react-toggle-switch'
import "react-toggle-switch/dist/css/switch.min.css"
import {
    Flex,
    Box,
} from 'reflexbox';
import styled from "styled-components";
import theme from "../theme";

const FormLabel = styled.span`
  padding-left: 0.5em;
   color: ${theme.input.textColorUserCard};
   font-size: 1.0em;
`;

const FormLine = styled.div`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${theme.input.textColorUserCard};
  line-height: 26px;
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
`;


const renderField = (field) => {
    return (
        <FormLine>
            <Flex justify="space-between">
                <FormLabel>{field.label}</FormLabel>
                <Switch onClick={() => field.input.onChange(!field.input.checked)} on={field.input.checked}/>
            </Flex>
        </FormLine>
    )

}


export default renderField;
