/**
 * @flow
 */

import createReducer from '../createReducer';
import type {TCabinState} from './TCabinState';
import type {THandler} from '../types/THandler';
import type {TBenefitData} from '../types/models/TBenefit';
import type {TBenefitResponse} from '../types/api/TBenefit';
import supercluster from 'supercluster'


const blankState = () => {
    return ({
        data: {},
        accounts: {},
        all: [],
        error: null,
        isLoaded: false,
        allBookings: {},
        allBookingsLoaded: false,
        config: {},
        configLoaded: false,
        userBookingsLoaded: false,
        userBookings: {},
    })
};

const initialState: TCabinState = blankState();


const _isLoaded = (accounts) => {
    return Object.keys(accounts).length > 0
}



const _addAccountToBenefits = (data, accountId): TBenefitData => {
    return data;
};


const _remapBenefitsBasedOnConfig = (data, config) => {

    return data

}

const _buildAllBenefits = (data: TBenefitResponse): TBenefitData => {
    const benefits = {};
    Object.keys(data).forEach((key) => {
        benefits[key] = ({...data[key], cabinId: key});
    });


    return benefits;
};

const _buildLocationsData = (data) => {
    const locations = {};
    Object.keys(data).forEach((key) => {
        locations[key] = data[key].locations;
    });
    return arrangeLocations(locations);

};

const arrangeLocation = (locationArray, key, arranged) => {

    for (var n = 0; n < locationArray.length; n++) {
        const location = locationArray[n];
        if (location && location.latitude && location.longitude && location.heading) {

            const mappedKey = location.latitude + '-' + location.longitude
            if (arranged.mapped[mappedKey]) {
                const index = arranged.mapped[mappedKey].index
                arranged.parsed[index].properties.benefits.push({name: location.heading, id: key});
            }
            else {

                const new_loc = {
                    type: "Feature",
                    properties: {
                        scalerank: 2,
                        name: location.heading,
                        comment: null,
                        name_alt: null,
                        lat_y: location.latitude,
                        long_x: location.longitude,
                        region: "Norge",
                        subregion: null,
                        featureclass: "poi",
                        benefits: [{name: location.heading, id: key}],
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            location.longitude,
                            location.latitude
                        ]
                    }
                };

                arranged.parsed.push(new_loc);
                const index = (arranged.parsed.length - 1)
                arranged.mapped[mappedKey] = {index: index}
                arranged.byBenefit[key] = index;
            }

        }

    }
    return arranged;
}

const arrangeLocations = (locations) => {

    let arranged = {
        parsed: [],
        mapped: {},
        byBenefit: {}
    };

    for (var key in locations) {
        const locationArray = locations[key];
        if (!locationArray) continue;

        arranged = arrangeLocation(locationArray, key, arranged);
    }

    let cluster = supercluster({
        radius: 60,
        maxZoom: 13,
    });
    cluster.load(arranged.parsed);

    return cluster;
}


const handlers: THandler<TCabinState> = {
    LOAD_CABINS_SUCCESS: (state, action) => {
        console.log("LOAD_CABINS_SUCCESS")


        if (!action.payload || !action.config) return state

        let accounts = state.accounts

        let benefitsMapped = _remapBenefitsBasedOnConfig(action.payload, action.config)
        accounts[action.metadata] = _addAccountToBenefits(benefitsMapped, action.metadata)

        let merged = {}
        Object.keys(accounts).map((metadata) => {
            merged = {...merged, ...accounts[metadata]}
        })

        const locations = _buildLocationsData(merged);
        const benefitsAll = _buildAllBenefits(merged);
        return {
            ...state,
            error: null,
            isLoaded: _isLoaded(accounts),
            data: benefitsAll,
            all: benefitsAll,
            locations: locations,
            accounts: accounts
        };

    },
    LOAD_USER_BOOKING_SUCCESS: (state, action) => {
        return {
            ...state,
            userBookings: action.payload,
            userBookingsLoaded: true,
        }
    },
    LOAD_BOOKING_SUCCESS: (state, action) => {
        console.log("LOAD_BOOKING_SUCCESS");
        return {
            ...state,
            bookings: action.payload
        };

    },
    LOAD_ALL_BOOKING_SUCCESS: (state, action) => {
        console.log("LOAD_ALL_BOOKING_SUCCESS");

        let data = action.payload;
        let allBookings = {};

        Object.keys(data).forEach((key) => {
            Object.keys(data[key]).forEach((bookingKey) =>
            {
                allBookings[bookingKey] = {
                    ...data[key][bookingKey],
                    cabinId: key,
                };
            })
        });

        return {
            ...state,
            allBookings: allBookings,
            allBookingsLoaded: true,
        };

    },
    UPDATE_CABIN_SUCCESS: (state, action) => {
        console.log("UPDATE_CABIN_SUCCESS")

        let cabinsAll = state.all

        let newCabin = action.payload.data
        newCabin.cabinId = action.payload.key

        cabinsAll[newCabin.cabinId] = newCabin

        return {...state, all: cabinsAll};
    },

    RESET_CABINS_SUCCESS: (state, action) => {
        console.log("RESET_CABINS_SUCCESS")
        return blankState();
    },
    LOAD_CABIN_CONFIG_SUCCESS: (state, action) => {
        console.log("LOAD_CABIN_CONFIG_SUCCESS");

        let data = action.payload;

        return {
            ...state,
            config: {
                facilities: data.facilities,
                nearby: data.nearby,
            },
            configLoaded:true,
        }

    }
};

export default createReducer(initialState, handlers);
