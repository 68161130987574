/**
 * @flow
 */

/* eslint import/prefer-default-export: 0 */

import Auth0 from 'auth0-js';
import {NotificationManager} from 'react-notifications';

import type {TDispatch} from '../../types/TDispatch';

import type {
    TSignInData,
} from '../../types/models/TAuth';
import type {
    TAuthError,
} from '../TAuthState';


import {
    signInBody,
} from '../config';
import auth0Config from '../../config/auth/auth0';
import {firebaseAuth} from '../../config/firebase_config/firebase';
import {onUpdateProfileSuccess} from '../profile/actions';
import {toProfile} from '../profile/models/toProfile';

import delegate from '../../config/firebase_config/delegation'


const redirectUri = window.location.protocol + '//' + window.location.host + '/login'

const auth0 = new Auth0.WebAuth({
    domain: auth0Config.domain,
    clientID: auth0Config.clientId,
    responseType: 'token id_token',
    scope: 'openid profile',
    redirectUri: redirectUri,
    connection: "standard"

});

const _onSignSuccess = (auth0Token: string, firebaseToken: string, access_token) => ({
    type: 'AUTH0_SIGN_IN_SUCCESS',
    payload: {
        auth0Token,
        firebaseToken,
        access_token
    },
});

const _onSignInRequest = () => ({
    type: 'AUTH0_SIGN_IN_REQUEST',
});

const _onSignInFailure = (error: TAuthError) => ({
    type: 'AUTH0_SIGN_IN_FAILURE',
    payload: error,
});

export const isAuthorized = function (profile, accountId) {
  const simple = (profile.accountId == accountId)
  const multi = (profile.accounts && profile.accounts[accountId])

  console.log('Simple? ' + simple + ", multi? " +multi)

  return simple || multi ;
};

export const handleAuthentication = (history) => async (dispatch: TDispatch) => {
    auth0.parseHash({ hash: window.location.hash }, function(err, authResult) {

        if (err) {
            return console.log(err);
        }


        auth0.client.userInfo(authResult.accessToken, function(err, user) {

            if (err) {
                return console.log(err);
            }

            console.log('userInfo: ' + JSON.stringify(user))

            if (authResult.accessToken && authResult.idToken) {
                window.location.hash = '';
                setSession(authResult);

                const profile = toProfile(authResult.idTokenPayload)
                console.log('profile: ' + JSON.stringify(user))

                const isAuthorizedTest = true//isAuthorized(profile)

                if(isAuthorizedTest)
                {
                    dispatch(onUpdateProfileSuccess(profile));

                    console.log("Signing in 3")
                    auth0SignInDirect(dispatch, authResult, profile, history);
                    console.log("Signing in 4")
                }
                else{
                    throw new Error({error: 'unauthorized', error_description: 'no access to account', status: 403})
                }

            } else if (err) {
                console.log(err);
                throw new Error({error: 'unauthorized', error_description: 'no access to account', status: 403})
            }



        });



    });
}

const setSession = (authResult) => {
    // Set the time that the Access Token will expire at
    var expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
}


export const signInAccount = () => {
        auth0.authorize({redirectUri, connection: "standard"})
};


const auth0SignInDirect = async (dispatch: TDispatch, tokens, profile, history) => {

    try {
        const delegationResult = await delegate(tokens.accessToken, profile);
        const delegationObject = await delegationResult.text()
        console.log('delegationResult: ' + delegationObject)

        const firebaseLogin = await firebaseAuth.signInWithCustomToken(delegationObject);
        const firebaseToken = await firebaseAuth.currentUser.getIdToken();
        dispatch(_onSignSuccess(tokens.idToken, firebaseToken));
        console.log("Logged in succesfully!")
        history.push('/admin/benefits');
    } catch (error) { // Problems with custom token and firebase
        dispatch(_onSignInFailure({
            status: error.code,
            message: error.message,
        }));
    }

};

