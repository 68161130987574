const values = {
    "featureGraphic" : {
        "scale" : "80",
        "x" : "187",
        "y" : "300"
    },
    "iconComposition" : {
        "logo" : {
            "scale" : "100",
            "x" : "0",
            "y" : "300"
        },
        "text" : {
            "show" : true,
            "size" : "301",
            "x" : "10",
            "y" : "480",
            "font": "Dosis",
            "letters": "PRO"
        },


    },
    "languages" : {
        "en" : true,
        "nb" : true
    },
    "primaryLanguage" : "nb",
    "splash" : {
        "scale" : "80",
        "x" : "50",
        "y" : "300"
    },
    "theme" : {
        "overrides" : {
            "colors" : {
                "accentColor" : "#F41A4D"
            },
            "darkTheme" : false,
            "logoHeight" : 80
        }
    },
    "vendorsOnCard" : [ {
        "name" : "CoveredPro"
    } ]
}


export default values