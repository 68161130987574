/**
 * @flow
 */
import {firebaseDb} from '../config/firebase_config/firebase';
import {
  buildBenefitReport,
  buildBenefitReports, buildNewBenefitReport
} from '../config/firebase_config/endpoints';

import history from '../utils/history'
import {NotificationManager} from 'react-notifications';

export const onBenefitReportsLoadSuccess = payload => ({
  type: 'LOAD_BENEFIT_REPORTS_SUCCESS',
  payload,
});

export const onAllBenefitReportsLoadSuccess = payload => ({
    type: 'LOAD_ALL_BENEFIT_REPORTS_SUCCESS',
    payload,
});

function updateBenefitReportRequest() {
  return {
    type: 'UPDATE_BENEFIT_REPORTS_REQUEST',
  };
}

function updateBenefitReportSuccess() {
  return {
    type: 'UPDATE_BENEFIT_REPORTS_SUCCESS',
  };
}

function updateBenefitReportError() {
  return {
    type: 'UPDATE_BENEFIT_REPORTS_ERROR',
  };
}


export const updateBenefitReport = (formValues, benefitReport) => async (dispatch, getState) => {
  try {
    dispatch(updateBenefitReportRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;
    const userId = store.auth.profile.data.userId;
    const userEmail = store.auth.profile.data.email;

    let updates = {};

    let reportKey = ''

    if (benefitReport && benefitReport.reportId) {
      reportKey = benefitReport.reportId;
    }
    else {
      const newMessageEndpoint = buildNewBenefitReport(accountId, userId);
      reportKey = firebaseDb.ref().child(newMessageEndpoint).push().key;
    }

    let concattedData = {...formValues, userEmail: userEmail};

    let storableData = JSON.parse(JSON.stringify(concattedData));

    updates[buildBenefitReport(accountId, userId, reportKey)] = storableData;

    console.log("update benefitReport called")
    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitReportSuccess());
    NotificationManager.success('Success!', 'Benefit report saved!', 3000);
    history.push('/admin/benefit-report')

  } catch (err) {
    console.log("update benefit-report ERROR: "+ JSON.stringify(err))

    dispatch(updateBenefitReportError());
  }
};
