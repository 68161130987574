/**
 * @flow
 */

import React, { Component } from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../components/Typografy/Wrapper';
import { mx } from '../../utils';

import Icon from './Benefits/Icon';
import SubTitle from '../components/Typography/SubTitle';
import ShortLine from './components/ShortLine';
import Title from '../components/Typography/Title';
import DescriptionWrapper from './components/DescriptionWrapper';
import Section from './components/Section';

import bgPhoto from '../../assets/images/background_photo.png';

const Container = styled.div`
  padding-bottom: 8em;
  ${mx('lg')} {
    display: flex;
    align-items: center;
    background-color: #ece4e4;
  }
`;

const Col = styled(Box)`
  text-align: center;
  padding: 1em;
  flex: 1;

  ${mx('lg')} {
    padding: 3em 3em 0;
  }
`;

const BenefitTitle = styled(SubTitle)`
  padding-top: 1em;
  ${mx('lg')} {
    min-height: 2em;
  }
`;



class Benefits extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Wrapper>
            <DescriptionWrapper>
              <Title>
                Hvorfor er ansattgoder og rabatter interessante?
              </Title>
              <ShortLine />
            </DescriptionWrapper>
            <Flex wrap justify="space-between">
              <Col col={12} sm={12} lg={3}>
                <Icon name="iconStronger" />
                <BenefitTitle>Styrket tilhørighet</BenefitTitle>
              </Col>
              <Col col={12} sm={12} lg={3}>
                <Icon name="iconGrowth" />
                <BenefitTitle>Økt verdi av ansettelsesforholdet</BenefitTitle>
              </Col>
              <Col col={12} sm={12} lg={3}>
                <Icon name="iconDistributed" />
                <BenefitTitle>
                  Økt bevissthet på goder utover lønn og bonus
                </BenefitTitle>
              </Col>
              <Col col={12} sm={12} lg={3}>
                <Icon name="iconLike" />
                <BenefitTitle>Mer fornøyde ansatte!</BenefitTitle>
              </Col>
            </Flex>
          </Wrapper>
        </Container>
      </Section>
    );
  }
}

export default Benefits;
