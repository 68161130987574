/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import SubHeader from './SubHeader';
import theme from '../../../theme/index';
import type {
  TBenefitApiData,
  TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import BenefitList from './BenefitList';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import Spinner from "../../../components/Spinner";
import {getAccounts, getCategories} from '../components/Retrievers'
import {mx} from "../../../utils";
import {Link as RouterLink} from "react-router-dom";

const LinkToCategory = styled(RouterLink)`
  margin:auto;
`;

const BenefitsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;

const BenefitBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png')});
   margin-bottom: 2em;
`;


const CategoriesContainer = styled(Flex)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display:flex;
  flex-wrap:wrap;
`;

// eslint-disable-next-line no-unused-vars
const CategoryContainer = styled(({ active, ...rest }) => <button {...rest} />)`
  min-width:120px;
  max-width:120px;
  font-family: 'Lato', sans-serif;
  padding-bottom: 0.5em;
  background: transparent;
  cursor: pointer;
  color:  ${props => props.active ?
    `${theme.categories.activeCategoryColor}` :
    `${theme.categories.categoryColor}`};
  border-bottom:  ${props => props.active ?
    `2px solid ${theme.nav.link}` :
    'none'};
  font-size: 1em;
  &:focus {
    outline:0;
  }
  border:2px solid #f41a4d;
  border-radius:5px;
  margin:0.5em;
  margin-left:auto;
  margin-right:auto;
  height:50px;
  ${mx('lg')} {
    border:none;
    max-width:300px;
    }

`;

type TState = {
  selectedId: string;
};

type TProps = {
  accountId: number;
  benefits: TBenefitApiData;
  onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
};

class BenefitPage extends React.Component<void, TProps, TState> {

  state: TState = {
    selectedId: 'all',
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        selectedId: this.props.match.params.id
      })
    }
  }

  _selectCategory = (category) => {
    this.setState({ selectedId: category.id });
    console.log(category.name);
  };

  _renderCategoryList = () => {
    if(!(this.props.categories && Array.isArray(this.props.categories))) return null

    return this.props.categories.map((category, i) => {
      return (
        <LinkToCategory to={`/app/benefits/category/${category.id}`} key={category.id}>
          <CategoryContainer
            onClick={() => this._selectCategory(category)}
            key={i}
            active={category.id === this.props.match.params.id}
          >
            {category.name}
          </CategoryContainer>
        </LinkToCategory>
      );
    });
  };

  render(){
      return( <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
          {this.props.accountsLoaded ? this.innerRender() : <Spinner/>}
      </FirebaseContainer>)
  }

  innerRender() {
    // const categoryId = this.props.match.params.id;
    const categoryId = this.state.selectedId;

    const list = this._renderCategoryList();
    const {accounts, accountId} = this.props
    return (
      <FirebaseContainer queries={getCategories(accounts, accountId, this.props.onCategoriesLoadDefaultSuccess, this.props.onCategoriesLoadSuccess)}>
      <BenefitsWrap>
        <BenefitBackground>
          <SubHeader theme={this.props.theme} isArchive={this.props.isArchive} isDraft={this.props.isDraft}/>
          <Wrapper>
            {(!this.props.isArchive && !this.props.isDraft) && <CategoriesContainer>
              <LinkToCategory to={`/app/benefits/category/all`} key={'all'}>
                <CategoryContainer
                  onClick={() => this._selectCategory({id:'all', name:"All"})}
                  key="allcategories"
                  active={ 'all' === this.state.selectedId}
                >
                  All
                </CategoryContainer>
              </LinkToCategory>

              {list}
            </CategoriesContainer>}
          </Wrapper>
        </BenefitBackground>
        <Wrapper>
          {categoryId && <BenefitList
            accountId={accountId}
            accounts={accounts}
            benefits={this.props.benefits[categoryId]}
            onBenefitsLoadSuccess={this.props.onBenefitsLoadSuccess}
            isLoaded={this.props.isLoaded}
            isArchive={this.props.isArchive}
            isDraft={this.props.isDraft}
          />}
          {/*!categoryId && <CategoryList
            categories={this.props.categories}
            account={accounts[accountId]}
          />*/}
        </Wrapper>
      </BenefitsWrap>
      </FirebaseContainer>
    );
  }
}

export default BenefitPage;
