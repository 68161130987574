/**
 * @flow
 */

import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 1em 1.5em;
  color: #fff;
  border-radius: 1.25em;
  margin-bottom: 1em;
  &:hover {
    text-decoration: none;
    transition: all 0.2s;
  }
`;

export default Link;
