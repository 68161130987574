/**
 * @flow
 */

import React from 'react';
import {Flex} from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
	TMessageApiData,
	TMessageResponse,
} from '../../../../common/types/api/TMessage';
import MessageList from './MessageList';
import SubHeader from './SubHeader';


const MessagesWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const MessageBackground = styled.div`
  background-color: rgb(20, 20, 20);
  margin-bottom: 2em;
`;


type
TProps = {
	accountId: number;
	messages: TMessageApiData;
	onMessagesLoadSuccess: (data: TMessageResponse) => void;
	onMessageDraftsLoadSuccess: (data: TMessageResponse) => void;
	onMessageArchivesLoadSuccess: (data: TMessageResponse) => void;
	isDraft: boolean;
	isArchive: boolean;
};

class MessagePage extends React.Component<void, TProps, TState> {
	componentDidMount() {
		if (this.props.isDraft || this.props.isArchive) {
			this.props.onMessagesReset();
		}
	}
	
	render() {
		console.log("isDraftArchive", this.props);
		return (
			<MessagesWrap>
				<MessageBackground>
					<SubHeader
						isDraft={this.props.isDraft}
						isArchive={this.props.isArchive}
					/>
				</MessageBackground>
				<Wrapper>
					<MessageList
						accountId={this.props.accountId}
						messages={this.props.messages}
						onMessagesLoadSuccess={this.props.onMessagesLoadSuccess}
						onMessageDraftsLoadSuccess={this.props.onMessageDraftsLoadSuccess}
						onMessageArchivesLoadSuccess={this.props.onMessageArchivesLoadSuccess}
						archiveMessage={this.props.archiveMessage}
						restoreArchivedMessage={this.props.restoreArchivedMessage}
						publishMessageDraft={this.props.publishMessageDraft}
						isDraft={this.props.isDraft}
						isArchive={this.props.isArchive}
						history={this.props.history}
					/>
				</Wrapper>
			</MessagesWrap>
		);
	}
}

export default MessagePage;
