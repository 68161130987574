/**
 * @flow
 */

import React, { Component } from 'react';
import { polyfill } from 'smoothscroll-polyfill';


import StickyMenu from './components/StickyMenu';
import Title from './components/Typography/Title';
import Wrapper from '../../components/Typografy/Wrapper';
import styled from "styled-components";
import { mx } from "../../utils";
import LandingPageForm from './LandingPageForm';



const ContentWrapper = styled(Wrapper)`
  max-width:600px;
  margin-top: 1em;
  margin-left: "auto";
  margin-right: "auto";

${mx('sm')} {
    padding-left: 1em;
    padding-right: 1em;
}
${mx('xsm')} {
    padding-left: 0.4em;
    padding-right: 0.4em;
}
${mx('xxsm')} {
    padding-left: 0.4em;
    padding-right: 0.4em;
}


`
const MainWrapper = styled(Wrapper)`

  padding-top: 100px;
  min-height: 83vh;
  padding-bottom: 2vh;

${mx('xxsm')} {
  max-width:100%;
    padding-left: 0.4em;
    padding-right: 0.4em;
}

${mx('xsm')} {
  max-width:100%;
    padding-left: 0.4em;
    padding-right: 0.4em;
}
${mx('sm')} {
    padding-left: 1em;
    padding-right: 1em;
}



`




const mainWrapper = {
  paddingTop: '100px',
  minHeight: "83vh",
  paddingBottom: "2vh"
};

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: rgb(234, 234, 234);
`;



class LandingPage extends Component {

  componentDidMount() {
    polyfill();
  }

  render() {
    return (
        <PageWrapper>
          <StickyMenu />
          <MainWrapper>
            <Title>
              Tradebroker Kundeklubb
            </Title>
            <ContentWrapper>
              Tradebroker kundeklubb er en klubb underlagt Stiftelsen <a href="https://www.tradebroker.no/">Tradebroker</a>.
              Tjenesten administreres og driftes av CoveredPro AS (Orgnr. 918 084 495). <br />
              Tradebroker Kundeklubb gir tilgang til medlemsfordeler og rabatter levert av Tradebroker. Eksempelvis rabatter på kjøp av bil, hoteller o.l. <br />
              Medlemsskapet koster NOK 29,- per måned, uten bindingstid.
                <br/>
                <br/>
                <strong style={{fontSize: 18, marginBottom: 20}}>
                OBS! Kundeklubb er en separat tjeneste fra Tradebroker Avtaler. Dersom du er her på vegne av en bedrift som allerede har en avtale med Tradebroker, kan du finne din app <a href={"https://coveredpro.com/register"}>HER.</a>
                </strong>
            </ContentWrapper>
            <ContentWrapper>
              <LandingPageForm {...this.props} />
            </ContentWrapper>
          </MainWrapper>
        </PageWrapper>
    );

  }
}

export default LandingPage;
