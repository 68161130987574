/**
 * @flow
 */

import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import ResponsiveImage from '../../../../components/Typografy/ResponsiveImage';
import Wrapper from '../../../../components/Typografy/Wrapper';
import { mx } from '../../../../utils';


const Container = styled(Flex)`
  padding-top: 1em;
  padding-bottom: 1em;
  z-index: 4;
  position: relative;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ECECEC;
  ${mx('lg')} {
    margin-bottom: 81px;
    box-shadow: 0px -5px 17px 8px #aaa;
    }
  `;

const HeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  `;

const LogoWrapper = styled.div`
  flex: 1;
`;

class StickyMenu extends Component {


  render() {
    return (
      <div>
        <Container wrap align="center" justify="space-between">
          <HeaderWrapper>
            <LogoWrapper>
              <ResponsiveImage
                src="tradebroker-logo (1).png"
                width={115}
                height={35}
                alt=""
              />
            </LogoWrapper>

          </HeaderWrapper>
        </Container>
      </div>
    );
  }
}
export default StickyMenu;
