/**
 * @flow
 */

import React, {Component} from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import Link from '../../../components/Typografy/Link';


import ArrowIcon from '../../../components/ArrowIcon';
import CabinCard from './CabinCard';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import Spinner from "../../../components/Spinner";

import {getAccounts, getCabins} from '../components/Retrievers'
import CabinImages from "./CabinImages";
import CabinAbout from "./CabinAbout";

const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png')});
  padding-top: 1.6em;
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;

type TProps = {
    accountId: number;
    benefits: TBenefitApiData;
    onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
    params: Object;
    saveRating: Function,
    isDraft: boolean,
    isArchive: boolean,
    updateBenefit: (data, benefit, boolean) => void;
    updateDraftBenefit: (data, benefit, boolean) => void
};

class CabinDetails extends Component<void, TProps, void> {


    _getItem = () => {
        const id = this.props.match.params.id;
        const cabin = find(this.props.cabins || [], b => b.cabinId === id);
        if (!cabin) return (<div></div>);



        return (
            <div>
                <Container>
                    <Wrapper>
                        <BackLink to={"/app/cabins"}>
                            <ArrowIcon/>
                            Our leisure homes
                        </BackLink>
                    </Wrapper>
                    {cabin.images && <CabinImages cabin={cabin} {...this.props} />}
                </Container>
                <Wrapper>
                    <CabinCard cabin={cabin}  {...this.props} />
                    <CabinAbout cabin={cabin} {...this.props} />
                </Wrapper>
            </div>
        );
    }


    renderInner() {
        const {accounts, accountId, onCabinsLoadSuccess, isArchive, isDraft} = this.props
        return (
            <FirebaseContainer queries={getCabins(accounts, accountId, onCabinsLoadSuccess, isArchive, isDraft)}>
                {this.props.cabins ? this._getItem() : null}
            </FirebaseContainer>
        );
        return null;
    }

    render() {
        return (
            <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
                {this.props.accountsLoaded ? this.renderInner() : <Spinner/>}
            </FirebaseContainer>
        );
    }
}


export default CabinDetails;

