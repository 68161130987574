/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import ProfileIcon from './components/ProfileIcon';
import theme from '../../theme';
import Link from "../../components/Typografy/Link";
import {NavLink} from "react-router-dom";

const ClientInfo = styled.span`
   font-size: 1em;
   color: ${theme.nav.color};
`;

const ImageContainer = styled.div`
   flex: 1;
   min-width: 2.5em;
`;

const InfoContainer = styled(Box)`
  flex: 1;
`;


const MenuButton = styled(NavLink).attrs({
    activeClassName: "active",
})`
  text-decoration: none;
  //text-transform: uppercase;
  border-radius: 0;
  padding: 0.5em 0.6em;
  color: ${theme.subHeader.buttonTextColor};
  margin-bottom: 1em;
  //border-bottom:2px solid ${theme.subHeader.buttonTextColor};
  border-bottom-color: rgba(0,0,0,0);
  margin-right: 0.5em;
  
  transition: all .2s ease-in-out;
  &:hover {
    text-decoration: none;
    color: ${theme.subHeader.buttonSecondColor};
    border-bottom:2px solid ${theme.subHeader.buttonSecondColor};
    opacity: 0.8;
  }
  
  &.active {
    color: ${theme.subHeader.buttonSecondColor};
  }
`;

class AccountMenu extends React.Component {
  render() {

    const { accountId, accounts } = this.props;

    return (
      <Flex wrap align="center" justify="space-between">
        <InfoContainer >
          { accounts[accountId].customModules && accounts[accountId].customModules.cabinBooking && <MenuButton to="/admin/cabins">
            Cabins
          </MenuButton>}
          <MenuButton to="/admin/benefits">
            Benefits
          </MenuButton>
        </InfoContainer>
        <InfoContainer >
          <MenuButton to="/admin/messages">
            Messages
          </MenuButton>
        </InfoContainer>
        <InfoContainer >
          <MenuButton to="/admin/whitelist">
            Whitelist
          </MenuButton>
        </InfoContainer>
        <InfoContainer >
          <MenuButton to="/admin/domains">
            Domains
          </MenuButton>
        </InfoContainer>
          <InfoContainer >
              <MenuButton to="/admin/stats">
                  Stats
              </MenuButton>
          </InfoContainer>
      </Flex>
    );
  }
}

export default AccountMenu;
