/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme';
import { mx } from '../../../utils';

type TMenuProps = {
  open: boolean;
}

const MenuIcon = ({ open } : TMenuProps) => {
  return (
    <MenuItemContainer className={open ? 'open' : ''} id="nav-icon3">
      <MenuItem>&#160;</MenuItem>
      <MenuItem2>&#160;</MenuItem2>
      <MenuItem3>&#160;</MenuItem3>
      <MenuItem4>&#160;</MenuItem4>
    </MenuItemContainer>
  );
};

const MenuItem = styled.span`
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: ${theme.nav.menuIcon};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1){
      top: 3px;
    }


`;
const MenuItem2 = styled.span`
     display: block;
     position: absolute;
     height: 4px;
     width: 100%;
     background: ${theme.nav.menuIcon};
     border-radius: 9px;
     opacity: 1;
     left: 0;
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
     -webkit-transition: .25s ease-in-out;
     -moz-transition: .25s ease-in-out;
     -o-transition: .25s ease-in-out;
     transition: .25s ease-in-out;
   top: 11px;
`;

const MenuItem3 = styled.span`
    display: block;
     position: absolute;
     height: 4px;
     width: 100%;
     background: ${theme.nav.menuIcon};
     border-radius: 9px;
     opacity: 1;
     left: 0;
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
     -webkit-transition: .25s ease-in-out;
     -moz-transition: .25s ease-in-out;
     -o-transition: .25s ease-in-out;
     transition: .25s ease-in-out;
     top: 11px;
 `;

const Container = styled(Flex)`
  z-index: 4;
  position: relative;
`;

const ToggleContainer = styled(Box)`
  display: flex;
  ${mx('lg')} {
    display: none;
  }
`;

const MenuButton = styled.button`
  padding: 0.5em 1em;
  color: #fff;
  border-radius: 1.5em;
  border: none;
  //text-transform: uppercase;
  background-color: ${theme.nav.link};
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus {
    outline: none;
  }
`;


const MenuContainer = styled(Box)`
  display: ${props => props.open ? 'flex' : 'none'};
  position: absolute;
  background: ${theme.nav.menuContainer};
  padding: 1.2em;
  top: 3.25em;
  left: -2em;
  right: -2em;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
  ${mx('lg')} {
    display: flex;
    width: auto;
    padding: 0;
    background: transparent;
    position: static;
    box-shadow: none;

  }
`;
const MenuItem4 = styled.span`
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: ${theme.nav.menuIcon};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
   transition: .25s ease-in-out;
   top: 19px;
  `;

const MenuItemContainer = styled.div`
     width: 30px;
     height: 30px;
     position: relative;
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
     -webkit-transition: .5s ease-in-out;
     -moz-transition: .5s ease-in-out;
     -o-transition: .5s ease-in-out;
     transition: .5s ease-in-out;
     cursor: pointer;
 `;

const Overlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: ${theme.overlay.overlayBackground};
  z-index: 1;
  opacity: 0.6;
  display: ${props => props.open ? 'block' : 'none'};;
  ${mx('lg')} {
    display: none;
  }
`;

type TProps = {
  bg?: boolean;
  children?: any;
  logo?: any;
}

type TState = {
  open: boolean;

}


class Header extends React.Component<void, TProps, TState> {

  state: TState = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  componentDidUpdate(prevProps: TProps, prevState: TState) {
    if (prevState.open && !this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    } else if (!prevState.open && this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: hidden';
    }
  }

  componentWillUnmount() {
    // If we navigate out of this view and the menu is open, make sure to restore the overflow
    if (this.state.open) {
      /* $FlowIssue */
      document.body.style = 'overflow: auto';
    }
  }

  render() {
    const { open } = this.state;
    const { children, logo } = this.props;
    return (
      <div>
        <Container wrap align="center" justify="space-between">
          { logo }
          <ToggleContainer >
            <MenuButton onClick={this.toggleMenu}>
              <MenuIcon open={open} />
            </MenuButton>
          </ToggleContainer>
          <MenuContainer open={open} >
            {children}
          </MenuContainer>
        </Container>
        <Overlay onClick={this.toggleMenu} open={open} />
      </div>
    );
  }
}
export default Header;
