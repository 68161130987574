/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_LANGUAGES_SUCCESS: (state, action) => {
        console.log('LOAD_LANGUAGES_SUCCESS')
        const data = action.payload
        const languages = {};
        let list = [];
        if (data) {
            Object.keys(data).filter(key => data[key].primary).forEach((key) => {
                const language = {...data[key], key: key}
                languages[key] = language
                list.push(language);
            });

        }

        return {...state, error: null, isLoaded: true, data: languages, list: list};
    }
}


export default createReducer(initialState, handlers);
