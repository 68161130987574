import React, { Component } from 'react';
import FontPicker from 'font-picker-react';
import {apiKey} from "../../../../../common/config/google/fonts";


export default class FontPickerComponent extends Component {
    constructor() {
        super();
        this.state = { activeFont: 'Lato' };
    }

    componentDidMount(){
        if(this.props.initialFont && typeof this.props.initialFont === 'string'){
            this.setState({activeFont: this.props.initialFont})
        }
        else if(this.props.input.value){
            this.setState({activeFont: this.props.input.value})
        }
    }


    handleChange = (font) => {
        this.setState({activeFont: font})
        if (this.props.onChange) {
            this.props.onChange(font)
        }
        if (this.props.input.onChange) {
            this.props.input.onChange(font)
        }
        if (this.props.onChangeFont) {
         this.props.onChangeFont(false)
        }
    };


    render() {
        return (
            <div>
                <FontPicker
                    apiKey={apiKey}
                    activeFont={this.state.activeFont}
                    onChange={nextFont => this.handleChange(nextFont.family)}
                    options={{variants:['regular', 'italic', '700', '700italic'], limit:500, sort: 'alphabetical'}}
                />
            </div>
        );
    }
}
