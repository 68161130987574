/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import MessageIcon from './components/MessageIcon';
import Link from '../../../components/Typografy/Link';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';

const Container = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-top: 1.6em;
`;

const MessagesProfile = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;

const AddMessageButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const MessageTabButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonSecondColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border: 2px solid ${theme.subHeader.buttonTextColor};
  background-color: ${props => props.active ? theme.subHeader.buttonTextColor : 'none'}
  margin-right: 1.5em;

  &:hover {
    text-decoration: none;
    background-color: ${theme.subHeader.buttonTextColor};
  }
`;

type
TProps = {
	children? : any;
	isDraft: boolean;
}

class SubHeader extends React.Component<void, TProps, void> {
	render() {
		const {children} = this.props;
		return (
			<Container>
				<Wrapper>
					<MessagesProfile>
						<MessageIcon/>
						Messages
					</MessagesProfile>
					<ButtonsContainer>
						<AddMessageButton to="/admin/messages/new">
							Add message
						</AddMessageButton>
						<MessageTabButton active={!(this.props.isDraft || this.props.isArchive)} to="/admin/messages">
							Published
						</MessageTabButton>
						<MessageTabButton active={this.props.isDraft} to="/admin/messages/drafts">
							Drafts
						</MessageTabButton>
						<MessageTabButton active={this.props.isArchive} to="/admin/messages/archived">
							Archived
						</MessageTabButton>
					</ButtonsContainer>
				</Wrapper>
			</Container>
		);
	}
}

export default SubHeader;
