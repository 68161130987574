/**
 * @flow
 */
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import BenefitReportEdit from './BenefitReportEdit';
import type { TAppState } from '../../../../common/TAppState';
import {onBenefitReportsLoadSuccess, updateBenefitReport} from "../../../../common/benefitreports/actions";
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {onBenefitsLoadSuccess} from "../../../../common/benefits/actions";
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";

const BenefitReportEditContainer = connect(
  (state: TAppState, dispatch) => {
    return {
      accountId: state.auth.profile.data.accountId,
      benefitReports: state.benefitReports.data || [],
      reportFormValues: getFormValues('BenefitReportForm')(state),
      userId: state.auth.profile.data.userId,
      benefits: state.benefits.data.all || [] ,
      benefitsLoaded:  state.benefits.isLoaded,
      accountsLoaded: state.accounts.isLoaded,
      accounts: state.accounts.data,
      languages: state.languages.data
    };
  },
  (dispatch) => ({
    onBenefitReportsLoadSuccess: (data) => dispatch(onBenefitReportsLoadSuccess(data)),
    updateBenefitReport: (data, report) => dispatch(updateBenefitReport(data, report)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
    onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
    onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
  })
)(BenefitReportEdit);

export default BenefitReportEditContainer;
