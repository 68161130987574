/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TStatsApiData,
  TStatsResponse,
} from '../../../../common/types/api/TStats';
import StatsList from './StatsList';
import SubHeader from './SubHeader';
import {
    buildAccounts,
    buildAllBenefitReports,
    buildBenefitReports
} from "../../../../common/config/firebase_config/endpoints";
import FirebaseContainer from "../../../../common/firebase/FirebaseContainer";
import {Box, Flex} from "reflexbox";
import {SaveButton} from "../../../components/Buttons";
import {Link as RouterLink} from "react-router-dom";
import XLSX from "xlsx";
import FileSaver from "file-saver";


const StatsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const StatsBackground = styled.div`
  background-color: rgb(20, 20, 20);
  margin-bottom: 2em;
`;

const StatsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 1em;
`;

const StatsInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const StatsTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

type TProps = {
  accountId: number;
  stats: TStatsApiData;
  onStatsLoadSuccess: (data: TStatsResponse) => void;
};

const toCSV = (benefitReports) => {
    if (benefitReports) {

        const dataRows = benefitReports.map(row => [row.date, row.fromBenefit.label, row.serviceDescription, row.comment, row.paid, row.discountPercent, row.discountValue, row.originalValue, row.userEmail ])

        const mergedRows = [["Date", "Benefit", "Product/Service", "Comment", "Paid", "Discount %", "Discount NOK", "Original Value", "User Email"]].concat(dataRows)

        const worksheet = XLSX.utils.aoa_to_sheet(mergedRows);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "BenefitReports");
        const wbout = XLSX.write(new_workbook, {type: 'binary', bookType: "xlsx"});


        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), "export_benefitReports.xlsx");


    }

}

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}


class StatsPage extends React.Component<void, TProps, TState> {
    componentDidMount() {
        this.props.getStats(this.props.accountId)
        this.props.getAppCodeStatus(this.props.accountId)
       // this.props.updateDataForAllUsers()
    }

    _getQueries = () => {
        return ([{
            endpoint: buildAllBenefitReports(this.props.accountId),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAllBenefitReportsLoadSuccess,
        },{
            endpoint: buildAccounts(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAccountsLoadSuccess,
        }
        ]);
    };

    render() {

        const {accountId, accounts} = this.props;
        const account = accounts[accountId];

        return (
          <StatsWrap>
            <StatsBackground>
              <SubHeader />
            </StatsBackground>
            <Wrapper>
              <StatsList
                accountId={this.props.accountId}
                stats={this.props.stats}
                onStatsLoadSuccess={this.props.onStatsLoadSuccess}
                tokens={this.props.tokens}
                createOneTimeCode={this.props.createOneTimeCode}
                editOneTimeCode={this.props.editOneTimeCode}
                deleteOneTimeCode={this.props.deleteOneTimeCode}
              />

                {/* <FirebaseContainer queries={this._getQueries()}>
                    <StatsCard key={"benefitReports"}>
                        <Flex wrap>
                            <Box w={[1]}>
                                {this.props.accountsLoaded && account.customModules && account.customModules.benefitReport && account.customModules.benefitReport.active && this.getBenefitReportDL()}

                            </Box>
                        </Flex>
                    </StatsCard>
                </FirebaseContainer> */}
            </Wrapper>
          </StatsWrap>
        );
    }

    getBenefitReportDL() {
        return (
            <StatsInfoContainer>
                <StatsTitle>Download all reported benefit usages</StatsTitle>
                <SaveButton onClick={() => toCSV(this.props.benefitReports)}>Download</SaveButton>
            </StatsInfoContainer>
        )
    }
}

export default StatsPage;
