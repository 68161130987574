/**
 * @flow
 */


import { connect } from 'react-redux';
import MessageDetails from './MessageDetails';
import {
	archiveMessage,
	onMessageDraftsLoadSuccess,
	publishMessageDraft
} from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";

const MessageDraftDetailsContainer = connect(
	(state: TAppState) => {
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.draftData  || [],
			account: state.accounts.account,
			accounts: state.accounts.list,
			isDraft: true,
			isArchive: false,
		};
	},
	(dispatch) => ({
		onMessageDraftsLoadSuccess: (data) => dispatch(onMessageDraftsLoadSuccess(data)),
		archiveMessage: (message, accountId) => dispatch(archiveMessage(message, accountId, true)),
		publishMessageDraft: (message, accounts, notification) => dispatch(publishMessageDraft(message, accounts, notification)),
		onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
	}),
)(MessageDetails);

export default MessageDraftDetailsContainer;

