/**
 * @flow
 */

import React, {Component} from 'react';
import {polyfill} from 'smoothscroll-polyfill';


import StickyMenu from '../SupportPage/components/StickyMenu';
import Title from './components/Typography/Title';
import Wrapper from '../../components/Typografy/Wrapper';
import styled from "styled-components";
import FirebaseContainer from "../../../common/firebase/FirebaseContainer";
import {buildAccounts} from "../../../common/config/firebase_config/endpoints";
import Spinner from "../../components/Spinner";
import {mx} from "../../utils";
import RegisterListItem from "./RegisterListItem";
import theme from "../../theme";



const ContentWrapper =  styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  gap: 20px;
`
const MainWrapper =  styled(Wrapper)`

  padding-top: 100px;
  min-height: 83vh;
  padding-bottom: 2vh;

${mx('xxsm')} {
  max-width:100%;
    padding-left: 0.4em;
    padding-right: 0.4em;
}

${mx('xsm')} {
  max-width:100%;
    padding-left: 0.4em;
    padding-right: 0.4em;
}
${mx('sm')} {
    padding-left: 1em;
    padding-right: 1em;
}



`




const mainWrapper = {
  paddingTop: '100px',
  minHeight: "83vh",
    paddingBottom:"2vh"
};

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: rgba(244, 244, 244, 0.78);

  &:after {
    background-size: 100vw;
    content: "";
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;

  }
`;

const SearchInput = styled.input`
  padding: 1em 2em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  
  transition: all .2s;
  
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`

class RegisterListPage extends Component {
	constructor(props) {
		super();
		
		this.state = {
			searchText: "",
		}
	}

    componentDidMount() {
        polyfill();
    }

    _getQueries = () => {
        return ([{
            endpoint: buildAccounts(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAccountsLoadSuccess,
        },
        ]);
    };


    _getItems = () => {
		if (this.state.searchText.length > 0) {
			return this.props.accounts.filter(a => {return a.customerUrl && !a.hideFromList && !(a.disabled) && a?.companyNameOnCard}).sort((a, b) => (a?.companyNameOnCard).localeCompare((b?.companyNameOnCard))).map((account, i) => {
				if ((account?.companyNameOnCard).toLowerCase().includes(this.state.searchText.toLowerCase())) {
					return <RegisterListItem key={i} account={account} />;
				}
			});
		}
        return this.props.accounts.filter(a => {return a.customerUrl && !a.hideFromList && !(a.disabled) && a?.companyNameOnCard}).sort((a, b) => (a?.companyNameOnCard).localeCompare((b?.companyNameOnCard))).map((account, i) => {
            return <RegisterListItem key={i} account={account} />;
        });
    };
    render() {
        return (
            <FirebaseContainer queries={this._getQueries()}>
                <PageWrapper>
                    <StickyMenu />
                    <MainWrapper>
                        <Title>
                            Velg konto
                        </Title>
                        <ContentWrapper>
	                        <SearchInput
		                        type={"text"}
		                        placeholder={"Søk etter konto..."}
		                        value={this.state.searchText}
		                        onChange={(e) => {
			                        this.setState({searchText: e.target.value})}
		                        }
	                        />
	                        
                            { (this.props.accounts && this.props.accounts.filter(a => a.customerUrl).length > 0) ? this._getItems() : <Spinner/> }
                        </ContentWrapper>
                    </MainWrapper>
                </PageWrapper>
            </FirebaseContainer>
        );

    }
}

export default RegisterListPage;
