/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import ResetPassword from './ResetPassword';
import { resetPasswordAccount } from '../../../common/auth/resetPassword/actions';

const ResetContainer = connect(
  (state: TAppState) => ({ reset: state.auth.resetPassword }),
  (dispatch) => ({ resetPasswordAccount: (data) => dispatch(resetPasswordAccount(data)) }),
)(ResetPassword);

export default ResetContainer;
