/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import BenefitIcon from './components/BenefitIcon';
import Link from '../../../components/Typografy/Link';
import Wrapper from '../../../components/Typografy/Wrapper';
import { PrimaryButton } from '../../../components/Buttons';
import theme from '../../../theme/index';

const Container = styled.div`
  max-width: 73.75em;
  margin: 0 auto;
  padding-top: 1.6em;
`;


type TProps = {
  children?: any;
}

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    return (
      <Container >
        <Wrapper>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
