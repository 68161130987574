/**
 * @flow
 */

import { connect } from 'react-redux';

import SalesAgreement from './SalesAgreementNO';

const SalesAgreementContainer = connect(
  null,
  null,
)(SalesAgreement);

export default SalesAgreementContainer;
