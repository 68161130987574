/**
 * @flow
 */


import { connect } from 'react-redux';
import BenefitPage from './BenefitPage';
import { onBenefitsLoadSuccess, benefitDataTemplate } from '../../../../common/benefits/actions/index';
import { onAccountsLoadSuccess  } from '../../../../common/accounts/actions';
import { onCategoriesLoadSuccess } from '../../../../common/categories/actions/index';
import type { TAppState } from '../../../../common/TAppState';

const BenefitContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     benefits: state.benefits.data,
     categories: state.categories.list,
     isLoaded: state.benefits.isLoaded,
     accountsLoaded: state.accounts.isLoaded,
       accounts: state.accounts.data,
       theme: state.theme.data,
   };
 },
 (dispatch) => ({
     onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
     onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
     onCategoriesLoadSuccess: (data) => dispatch(onCategoriesLoadSuccess(data)),
     onCategoriesLoadDefaultSuccess: (data) => dispatch(onCategoriesLoadSuccess(data, true)),
 }),
)(BenefitPage);
export default BenefitContainer;
