/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildAccounts } from '../../../../common/config/firebase_config/endpoints';
import type {
  TAccountApiData,
  TAccountResponse,
} from '../../../../common/types/api/TAccount';
import AccountItem from './AccountItem';
import styled from "styled-components";

type TProps = {
  accountId: number;
  accounts: Array<TAccountApiData>;
  onAccountsLoadSuccess: (data: TAccountResponse) => void;
};

const SearchInput = styled.input`
  padding: 1em 2em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  
  transition: all .2s;
  
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`

class AccountList extends React.Component<void, TProps, void> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      searchText: "",
      searchResults: [],
    }
  }
  _getQueries = () => {
    return ([{
      endpoint: buildAccounts(),
      method: 'on',
      eventType: 'value',
      callback: this.props.onAccountsLoadSuccess,
    },
    ]);
  };
  _filterItems = (items) => {
    return items.filter(acc => {
      return ((acc?.name ?? acc?.accountName).toLowerCase().includes(this.state.searchText.toLowerCase()));
    });
  }
  _getItems = () => {
    return this._filterItems(this.props.accounts).map((account, i) => {
      return <AccountItem key={i} account={account} accountId={this.props.accountId} />;
    });
  };
  render() {
    return (
      <FirebaseContainer queries={this._getQueries()}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}>
          <SearchInput
            type={"text"}
            placeholder={"Search account..."}
            value={this.state.searchText}
            onChange={(e) => {
              this.setState({searchText: e.target.value})}
            }
          />

          <div>
            { this.props.accounts ? this._getItems() : null }
          </div>
        </div>

      </FirebaseContainer>
    );
  }
}
export default AccountList;
