 const parseSearch = () => {
    let parsed = {}
    try {
        if (window.location.search) {


            const anded = window.location.search.substring(1).split("&")
            anded.forEach(param => {
                const paramParts = param.split("=")
                if (paramParts.length === 2) {
                    parsed[paramParts[0]] = paramParts[1]
                }
            })


        }
    }
    catch (e) {
        console.error(e)
    }
    return parsed

}
 export default parseSearch