import {firebaseStorage} from '../../config/firebase_config/firebase';

const storageRef = firebaseStorage.ref();

export const uploadAnyImage = (url, fileString, callback, errorCallback) => {
    const uploadTask = storageRef.child(url).put(fileString);

    uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
    }, function(error) {
        errorCallback()
    }, function() {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log('File available at', downloadURL);
            callback(downloadURL);
        });
    });
};

