/**
 * @flow
 */

import React from 'react';
import StatsItem from './StatsItem';

import Spinner from '../../../components/Spinner'
import styled from 'styled-components';

const ListWrapper = styled.div`
  padding: 30px;
  background-color:#cacaca;
  border-radius: 20px;
  margin:25px auto;
  width:25%;
`;


class AllStatsList extends React.Component<void, TProps, void> {

  _getItems = (unverified, total, tokens) => {

    let items = []

    items.push(<StatsItem key={"title"+this.props.accountId}
                          stat={{data: this.props.accountName, title: "Account"}} accountId={this.props.accountId} />)

    if (total) items.push(<StatsItem key={'total-' + this.props.accountId}
                                          stat={{data: total, title: 'Onboarded users'}}
                                          accountId={this.props.accountId}/>)
    if (unverified.unverified && unverified.unverified_users.length > 0) items.push(<StatsItem
      key={'unverified-' + this.props.accountId} stat={{data: unverified.unverified, title: 'Unverified users'}}
      accountId={this.props.accountId}/>)

    if (tokens && tokens.free) items.push(<StatsItem key={'tokens-' + this.props.accountId}
                                                     stat={{data: tokens.free, title: 'Available Apple tokens'}}
                                                     accountId={this.props.accountId}/>)


    return items

  };

  render() {
    return (
      <ListWrapper>
        {this.props.total ? this._getItems(this.props.unverified, this.props.total, this.props.tokens && this.props.tokens.data && this.props.tokens.data[this.props.accountId]) :
          <Spinner/>}
      </ListWrapper>
    );
  }
}

export default AllStatsList;
