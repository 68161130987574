/**
 * @flow
 */

type TAuth0 = {
  clientId: string;
  domain: string;
  magicLinkUri: string;
}

const auth0: TAuth0 = {
  clientId: 'NwtgZfUZOTZoYRwUCBCEIq4vuFnEjNY0',
  domain: 'coveredpro.eu.auth0.com',
  magicLinkUri: '',
};

export default auth0;

const devHost = "https://us-central1-coveredpro-dev.cloudfunctions.net"
const prodHost = "https://us-central1-my-coveredpro.cloudfunctions.net"
const host = prodHost

export const logoutReturn = window.location.protocol + '//' + window.location.host + '/'
export const auth0Logout = `https://${auth0.domain}/v2/logout?returnTo=${encodeURIComponent(logoutReturn)}&client_id=${auth0.clientId}`
export const auth0SetPassword =  `${host}/setPassword` //'http://localhost:5000/my-coveredpro/us-central1/usersAddAdmin'//
export const auth0AddAdmin =  `${host}/usersAddAdmin` //'http://localhost:5000/my-coveredpro/us-central1/usersAddAdmin'//
export const auth0RemoveAdmin =  `${host}/usersRemoveAdmin` //'http://localhost:5000/my-coveredpro/us-central1/usersRemoveAdmin'//
export const auth0UsersDelete =  `${host}/usersDelete` //'http://localhost:5000/my-coveredpro/us-central1/usersAddAdmin'//
export const auth0UsersVerify =  `${host}/usersVerify` //'http://localhost:5000/my-coveredpro/us-central1/usersAddAdmin'//
export const auth0Stat = `${host}/usersQuery`


