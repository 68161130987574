/**
 * @flow
 */

import type {TDispatch} from '../types/TDispatch';

import {firebaseDb} from "../config/firebase_config/firebase";
import type {TDataLoadState} from "./TDataLoadState";
import {buildEditableHelp} from "../config/firebase_config/endpoints";


export const onLoadHelpSuccess = payload => ({
  type: 'LOAD_HELP_DATA_SUCCESS',
  payload
});

const _onLoadHelpRequest = () => ({
  type: 'LOAD_HELP_DATA_REQUEST',
});

const _onLoadHelpFailure = (error: TDataLoadState) => ({
  type: 'LOAD_HELP_DATA_FAILURE',
  payload: error,
});

export const onUpdateHelpSuccess = payload => ({
    type: 'UPDATE_HELP_DATA_SUCCESS',
    payload
});

const _onUpdateHelpRequest = () => ({
    type: 'UPDATE_HELP_DATA_REQUEST',
});

const _onUpdateHelpFailure = (error: TDataLoadState) => ({
    type: 'UPDATE_HELP_DATA_FAILURE',
    payload: error,
});

export const loadHelp = () => async(dispatch: TDispatch) => {
  try {
    dispatch(_onLoadHelpRequest());

    let data = "";

    await firebaseDb.ref().child(buildEditableHelp()).once("value", snapshot => {
      data = snapshot.val();
    });

    dispatch(onLoadHelpSuccess(data));
  } catch (err) {
    console.log("ERROR: "+ JSON.stringify(err));

    dispatch(_onLoadHelpFailure());
  }
};

export const updateHelp = (data) => async(dispatch: TDispatch) => {
  try {

    dispatch(_onUpdateHelpRequest());

      firebaseDb.ref(buildEditableHelp()).set(data);

      dispatch(onUpdateHelpSuccess(data));

  } catch (e) {
      dispatch(_onUpdateHelpFailure());
  }
}

