/**
 * @flow
 */

import React from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../../../theme/index';

const ImageCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;

const ImageContainer = styled.div`
  overflow: hidden;
  margin: 0.5vw;
  position:relative;
  box-shadow: 0 0 0 1px rgba(0,0,0,.06), 0 2px 3px rgba(0,0,0,.11);
`;

const CabinImage = styled.img`
  width: 40vw;
`;

const HeaderLabel = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    min-height:20px;
    background-color: rgba(230,230,230,0.5);
    color: rgb(20,20,20);
    text-align:center;
    font-weight:bold;
    padding:5px;
    font-size:20px;
`;

const Arrow = styled.div`
    cursor:pointer;
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 10px;
    top: calc(50% - 25px);
    font-size: 35px;
    background-color: rgba(230,230,230,0.5);
    color: rgb(20,20,20);
    &:hover {
        background-color: rgba(230,230,230,0.8);
        transition:background-color .2s;
    }
`;

const RightArrow = styled(Arrow)`
    right: 1%;
`;

const RightArrowOuter = styled(Arrow)`
    right:0;
    height: calc(100% - 23px);
    top:0;
    width:40%;
    border-radius:0;
    background: none;
    &:hover {
        background:none;
    }
`;


const LeftArrowOuter = styled(Arrow)`
    left:0;
    height: calc(100% - 23px);
    top:0;
    width:40%;
    border-radius:0;
    background: none;
    &:hover {
        background:none;
    }
`;

const LeftArrow = styled(Arrow)`
    left: 1%;
`;

const getHeaderLabel = (image) => {
    if (image.isWinter) return "Winter";
    else if (image.isSummer) return "Summer";
    else if (image.isCommon) return "Inside";
}

const ImageWrapper = (props) => {
    const { cabin, activeImageId, setNewActiveImage } = props;

    return (

        <ImageCard>
            <Flex wrap>
                <Box w={[1, 1]}>
                    <ImageContainer>
                        <HeaderLabel>
                            {getHeaderLabel(cabin.images[activeImageId])}
                        </HeaderLabel>
                        {cabin.images.map((image, id) => {
                            return id === activeImageId ? (<CabinImage key={id} src={image.path} alt="" />) :
                                (<CabinImage key={id} src={image.path} alt="" style={{display:'none'}} />);
                        })}
                        <RightArrowOuter onClick={() => setNewActiveImage(activeImageId+1)}>
                            <RightArrow>
                                ▶
                            </RightArrow>
                        </RightArrowOuter>
                        <LeftArrowOuter onClick={() => setNewActiveImage(activeImageId-1)}>
                            <LeftArrow>
                                ◀
                            </LeftArrow>
                        </LeftArrowOuter>
                    </ImageContainer>
                </Box>
            </Flex>
        </ImageCard>
    );
};

export default ImageWrapper;
