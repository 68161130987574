/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, change, formValueSelector} from 'redux-form';
import {
  Flex,
  Box,
} from 'reflexbox';

import Wrapper from '../../../components/Typografy/Wrapper';

import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import CustomFieldTextarea from '../../../components/Typografy/CustomFieldTextarea';
import FormFooter from '../../../components/FormFooter';

import {Card, CardTitle, Container} from '../../../components/Elements'
import Locations from './components/Locations';

import type {
  TBenefitApiData,
} from '../../../../common/types/api/TBenefit';
import ToggleSwitch from '../../../components/ToggleSwitch'
import LocationSearchInput from "./components/LocationInput";
import ValueArray from "./components/ValueArray";
import AdvancedPricing from './components/AdvancedPricing';
import SortableImages from "./components/SortableImages";




type TProps = {
  accountId: string;
  initialValues: TBenefitApiData;
  hasCabin: boolean;
  isDraft: boolean;
  cabin: {
      cabinId: string;
  };
}

type TState = {}


class CabinForm extends Component<void, TProps, TState> {

  handleSubmit = (data) => {
      console.log("SUBMITTED", data);
      this.props.isDraft ? this.props.updateDraftCabin(data, this.props.cabin) : this.props.updateCabin(data, this.props.cabin)
  }

  render() {
    let cabinId = this.props.cabin ? this.props.cabin.cabinId : ''
    const { cabinFormValues } = this.props;
    console.log("FV", cabinFormValues);
    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Container>
              <Wrapper>
                  <Flex>
                      <Box w={[1/2]} pr={1}>
                          <Card wrap={true}>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Images
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  {cabinId && cabinFormValues ? <FieldArray accountId={this.props.accountId} formValues={cabinFormValues} name={`images`} arrayname={`images`} component={SortableImages} /> : <h3>Please save cabin in order to upload images</h3>}
                              </Box>
                          </Card>
                      </Box>
                      <Box w={[1/2]} pl={1}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Cabin Information
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <Field
                                      name="title"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Title"
                                      userCard
                                      label="Display title"
                                  />
                                  <Field
                                      name="location"
                                      component={LocationSearchInput}
                                      type="text"
                                      userCard
                                      placeholder="Address"
                                      label="Address search"
                                  />
                                  <Field
                                      name="beds"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Beds"
                                      userCard
                                      label="Number of beds"
                                  />
                                  <Field
                                      name="bedrooms"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Bedrooms"
                                      userCard
                                      label="Number of bedrooms"
                                  />
                                  <Field
                                      name="bathrooms"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Bathrooms"
                                      userCard
                                      label="Number of bathrooms"
                                  />
                                  <Field
                                      name="pricePerWeek"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Price"
                                      userCard
                                      label="Price per week in NOK"
                                  />
                                  <Field
                                      name="pricePerWeekend"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Price"
                                      userCard
                                      label="Price per weekend in NOK"
                                  />
                                  <Field
                                      name="pricePerDay"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Price"
                                      userCard
                                      label="Price per day in NOK"
                                  />
                              </Box>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Contact Person
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <Field
                                      name="contact.fullName"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Name"
                                      userCard
                                      label="Person name"
                                  />
                                  <Field
                                      name="contact.email"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Email"
                                      userCard
                                      label="Email address"
                                  />
                                  <Field
                                      name="contact.phone"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Phone"
                                      userCard
                                      label="Phone number"
                                  />
                              </Box>
                          </Card>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Booking settings
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <Field
                                      name="customBookingUrl"
                                      component={CustomFieldInput}
                                      type="text"
                                      placeholder="Booking link"
                                      userCard
                                      label="Booking link"
                                      disabled={cabinFormValues && cabinFormValues.useContactEmailBooking}
                                  />
                                  <Field
                                      name="useContactEmailBooking"
                                      component={ToggleSwitch}
                                      label="Use contact email as booking link"
                                      type={'checkbox'}
                                  />
                              </Box>
                          </Card>
                      </Box>
                  </Flex>
                  <Flex>
                      <Box w={[1]}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Advanced Pricing
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <Field
                                      name="useAdvancedPricing"
                                      component={ToggleSwitch}
                                      label="Use Advanced Pricing"
                                      type="checkbox"
                                  />
                                  {cabinFormValues && cabinFormValues.useAdvancedPricing && <AdvancedPricing cabinFormValues={cabinFormValues} arrayname={'advancedPricing'} />}
                              </Box>
                          </Card>
                      </Box>
                  </Flex>
                  <Flex>
                      <Box w={[1]}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Cabin Description
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <Field
                                      name="description"
                                      component={CustomFieldTextarea}
                                      type="text"
                                      placeholder="description"
                                      userCard
                                      rows={15}
                                      label="Description"
                                  />
                              </Box>
                          </Card>
                      </Box>
                  </Flex>
                  <Flex>
                      <Box w={[1, 1/2]} pr={1}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Cabin facilities
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <FieldArray buttonText={'Add Facility'} suggestions={this.props.config.facilities} formValues={cabinFormValues} name={'facilities'} arrayname={'facilities'} component={ValueArray} />
                              </Box>
                          </Card>
                      </Box>
                      <Box w={[1, 1/2]} pl={1}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Nearby
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  {cabinFormValues && <FieldArray buttonText={'Add Nearby Facility'} suggestions={this.props.config.nearby} formValues={cabinFormValues} name={'nearby'} arrayname={'nearby'} component={ValueArray} />}
                              </Box>
                          </Card>
                      </Box>
                  </Flex>
                  <Flex>
                      <Box w={[1]}>
                          <Card wrap>
                              <Box w={[1]}>
                                  <CardTitle>
                                      Nearby Locations
                                  </CardTitle>
                              </Box>
                              <Box w={[1]}>
                                  <FieldArray
                                      name="nearbyLocations"
                                      component={Locations}
                                      formValues={cabinFormValues}
                                  />
                              </Box>
                          </Card>
                      </Box>
                  </Flex>
              </Wrapper>
          </Container>
          <FormFooter to={ this.props.isDraft ? `/admin/cabins-draft/${cabinId}` : `/admin/cabins/${cabinId}`} pristine={this.props.pristine}
                      actionText={"Save"}
                      type={'submit'}
                      onSave={() => {
                          // this.props.isDraft ? this.props.updateDraftCabin(this.props.cabinFormValues, this.props.cabin) : this.props.updateCabin(this.props.cabinFormValues, this.props.cabin)
                          console.log("Clicked submit");
                      }}
          />
      </form>
    );
  }
}

const validate = values => {
    let errors = {}

    if (values.advancedPricing && values.advancedPricing.periodbased) {
        const periodArrayErrors = [];
        values.advancedPricing.periodbased.forEach((period, index) => {
            const periodErrors = {}
            if (!period) {
                return;
            }
            if (!period.title) {
                periodErrors.title = "Required";
                periodArrayErrors[index] = periodErrors
            }
            if (!period.dateFrom) {
                periodErrors.dateFrom = "Required";
                periodArrayErrors[index] = periodErrors
            }
            if (!period.dateTo) {
                periodErrors.dateTo = "Required";
                periodArrayErrors[index] = periodErrors
            }
            if (!period.price) {
                periodErrors.price = "Required";
                periodArrayErrors[index] = periodErrors
            }

            // If any error till this point, return;
            if (periodErrors.length) return;

            values.advancedPricing.periodbased.forEach((checkPeriod, checkIndex) => {
                if (index === checkIndex || !checkPeriod) return;
                if (checkPeriod.dateFrom && checkPeriod.dateTo) {
                    if ((period.dateFrom <= checkPeriod.dateTo) && (period.dateTo >= checkPeriod.dateFrom)) {
                        const collisionTitle = checkPeriod.title ? checkPeriod.title : '';
                        periodErrors.dateTo = "Date is colliding with: " + collisionTitle;
                        periodErrors.dateFrom = "Date is colliding with: " + collisionTitle;
                        periodArrayErrors[index] = periodErrors
                    }
                }
            })

        })
        if (periodArrayErrors.length) {
            errors.advancedPricing = {};
            errors.advancedPricing.periodbased = periodArrayErrors
        }
    }
    return errors
}

CabinForm = reduxForm({
    form: 'CabinForm',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(CabinForm);



export default  connect(null)(CabinForm);
