/**
 * @flow
 */

import React, { Component } from 'react';
import { polyfill } from 'smoothscroll-polyfill';


import StickyMenu from '../Verify/VerifyConfirmation/components/StickyMenu'
import MessageSection from '../Verify/VerifyConfirmation/components/MessageSection'
import Footer from '../Verify/VerifyConfirmation/components/Footer'


class PrivacyPage extends Component<void, void, void> {


  render() {
    polyfill();
    return (
      <div>
        <StickyMenu />
        <MessageSection
          headlineText={'Privacy Policy'}
          full={
            <div className="text_holder">
              <p><strong>Effective: 2. October 2023</strong></p>

              <h2>1. Introduction</h2>
              <p>
                Please take a moment to read this Privacy Policy carefully to understand how we handle your information. By accessing or using our website, or mobile applications, you acknowledge that you have read, understood, and agreed to the practices described in this Privacy Policy.
              </p>
              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make changes, we will revise the "Effective Date" at the top of this policy and notify you in accordance with applicable laws. Your continued use of our services after any changes to this Privacy Policy signifies your acceptance of those changes.
              </p>
              <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please don't hesitate to contact us using the information provided in the "Contact Us" section at the end of this document.
              </p>

              <h2>2. Information We Collect</h2>
              <p>
                When you use our mobile application, we want to assure you that we do not collect or store any personal information. Any data related to your usage of the mobile application is stored locally on your mobile device. We do not have access to or store any data that can personally identify you while using the mobile application.
              </p>
              <p>
                For the purpose of user authentication, we temporarily collect your email address. This email address is used solely for the authentication process and is not stored permanently in our system. Once you have successfully logged in, your email address is deleted from our system, ensuring your privacy is maintained.
              </p>
              <p>
                When you use our website, we collect and store your email address. This information is necessary to provide you with access to certain features and functionalities of the website, such as account management and communication.
              </p>
              <p>
                In addition to this, we collect anonymized application usage data for analytical purposes. This data is anonymized and aggregated to understand how our website and application are used and to improve its performance. This information is not personally identifiable and is used solely to enhance the user experience.
              </p>

              <h2>3. How We Use Your Information</h2>
              <p>
                We use your information solely to provide and enhance our services. This includes managing your account, managing benefits, and sending relevant notifications. We process data based on legal grounds such as contractual necessity, legitimate interests, and consent when required by law. Your data is retained for as long as needed for the stated purposes. Your privacy is our priority, and we do not share your information with third parties.
              </p>

              <h2>4. Cookies and Tracking Technologies</h2>
              <p><strong>Cookies</strong></p>
              <p>
                Cookies are small text files stored on your device that help us understand how you interact with our services. We use cookies for various purposes, including:
              </p>
              <ul>
                <li>Authentication: Cookies are essential for user authentication, ensuring your security when you log in.</li>
                <li>Preferences: We use cookies to remember your settings and preferences to provide a more personalized experience.</li>
              </ul>
              <p><strong>Tracking Technologies</strong></p>
              <p>
                In both our mobile application and on our website, we utilize Google Analytics, a web analytics service provided by Google, Inc. Google Analytics employs cookies and other tracking technologies to collect and analyze user behavior and interactions. This helps us gather insights into how our services are used, track trends, and make data-driven improvements.
              </p>
              <p>
                Google Analytics may collect various types of data, including:
              </p>
              <ul>
                <li>Usage Data: Information about your interactions with our services, such as pages viewed, time spent on the site, and features used.</li>
                <li>Device and Browser Information: Details about your device and browser, including IP address, operating system, and screen resolution.</li>
                <li>Geolocation Data: General location information based on your IP address.</li>
              </ul>
              <p>
                Please note that the information collected by Google Analytics is aggregated and anonymized, and it does not personally identify you. We use this data solely for the purpose of improving our services and providing you with a better experience.
              </p>
              <p>
                If you prefer not to have data collected by Google Analytics, you can opt-out by installing the Google Analytics Opt-out Browser Add-on available on the Google Analytics website.
              </p>
              <p>
                By using our services, you consent to the use of cookies and tracking technologies as described in this section.
              </p>

              <h2>5. Your Rights</h2>
              <p>
                As a user in the European Union (EU) region, you have specific rights regarding your personal data under the General Data Protection Regulation (GDPR). We are committed to respecting and upholding these rights:
              </p>
              <ul>
                <li><strong>Right to Access</strong>: You have the right to request access to the personal data we hold about you. This includes information about the purposes of the processing, the categories of data being processed, and who it may be shared with.</li>
                <li><strong>Right to Rectification</strong>: You can request the correction of inaccurate or incomplete personal data we have concerning you.</li>
                <li><strong>Right to Erasure (Right to Be Forgotten)</strong>: In certain circumstances, you have the right to request the deletion of your personal data. This right is not absolute and may depend on legal requirements.</li>
                <li><strong>Right to Restrict Processing</strong>: You can request the restriction of processing of your personal data under certain conditions, for example, while we investigate the accuracy of your data.</li>
                <li><strong>Right to Data Portability</strong>: You have the right to receive your personal data in a structured, commonly used, and machine-readable format and to request the transfer of your data to another data controller, where technically feasible.</li>
                <li><strong>Right to Object</strong>: You can object to the processing of your personal data for reasons related to your particular situation unless we have legitimate grounds for the processing that override your interests, rights, and freedoms.</li>
                <li><strong>Right to Withdraw Consent</strong>: Where we rely on your consent as the legal basis for processing your data, you have the right to withdraw that consent at any time.</li>
                <li><strong>Automated Decision-Making</strong>: We do not engage in automated decision-making processes that significantly affect you without your consent.</li>
              </ul>
              <p>
                In the mobile application, all personal data is stored locally. If you want to delete this data, this can be achieved by accessing the “Profile” page in the mobile application, and clicking the button to delete your data.
              </p>
              <p>
                If you would like to exercise any of these rights or have questions regarding your rights under GDPR, please contact us using the information provided in the "Contact Us" section below. We will respond to your request within the timeframe prescribed by applicable data protection laws.
              </p>
              <p>
                Please note that the exercise of these rights may be subject to certain conditions and legal requirements, and we may need to verify your identity before fulfilling your request.
              </p>

              <h2>6. Security</h2>
              <p>
                We prioritize the security of your data through comprehensive measures. All data transmissions between your device and our servers are encrypted with SSL technology, ensuring the confidentiality of your information. We enforce strict access controls, and maintain secure backups of your data. While we are dedicated to protecting your data, we also encourage your active participation in safeguarding your information, emphasizing the importance of secure passwords and responsible online behavior. Rest assured, we continually enhance our security practices to provide the utmost protection for your data.
              </p>
              <p>
                Your trust in our commitment to data security is paramount. If you ever suspect unauthorized access or have security concerns, please reach out to us promptly using the contact information provided in the "Contact Us" section.
              </p>

              <h2>7. Third-Party Links</h2>
              <p>
                Our service may include links to third-party websites or services for your convenience and reference. However, we want to make it clear that we do not endorse, control, or have responsibility for the content, privacy practices, or security of these third-party websites or services.
              </p>
              <p>
                When you click on a third-party link and leave our service, you should be aware that our privacy policy no longer applies. Your interactions with those third-party websites or services are subject to their respective privacy policies and terms of use. We encourage you to review the privacy policies of any third-party websites or services you visit to understand how they collect, use, and protect your information.
              </p>
              <p>
                While we strive to provide links to reputable and trustworthy sources, we cannot be held responsible for the actions, practices, or content of third-party websites or services. Your use of such links is at your own risk.
              </p>

              <h2>8. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy periodically to reflect changes in our data practices, legal requirements, or for operational reasons. When we make changes to this policy, we will revise the "Effective Date" at the top of this document to indicate the date of the latest revision. We encourage you to review this Privacy Policy regularly to stay informed about how we handle your personal information.
              </p>
              <p>
                If we make significant changes that affect your rights or the way we handle your data, we will provide notice of such changes through prominent notifications on our website, mobile application, or by other means as required by applicable laws and regulations. We may also send you an email or other communication to inform you of important updates.
              </p>
              <p>
                By continuing to use our services after any changes to this Privacy Policy, you acknowledge and consent to the updated practices described in the revised policy. If you do not agree with the changes, you may choose to discontinue using our services or exercise your rights as outlined in this Privacy Policy.
              </p>

              <h2>9. Contact Us</h2>
              <p>
                If you have any questions, comments, or concerns regarding this Privacy Policy or our data practices, please feel free to reach out to us. We value your privacy and are here to assist you.
              </p>
              <p><strong>Coveredpro AS<br />Org.nr. 918 084 495</strong></p>
              <p><a href="mailto:hjelp@coveredpro.com">hjelp@coveredpro.com</a></p>
            </div>
          }
        />
        <Footer />
      </div>);
  }
}

export default PrivacyPage;
