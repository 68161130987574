/**
 * @flow
 */

import type { TSignUpState } from '../TAuthState';
import createReducer from '../../createReducer';
import type { THandler } from '../../types/THandler';

const initialState: TSignUpState = {
  error: null,
  isLoading: false,
  isReset: false
};

const handlers: THandler<TSignUpState> = {
  AUTH0_RESET_PASSWORD_REQUEST: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  AUTH0_RESET_PASSWORD_FAILURE: (state, action) => {
    return { ...state, error: action.payload, isLoading: false };
  },
  AUTH0_RESET_PASSWORD_SUCCESS: (state) => {
    console.log('AUTH0_RESET_PASSWORD_SUCCESS')
    return { ...state, isLoading: false, isReset:true };
  },
};

export default createReducer(initialState, handlers);
