/**
 * @flow
 */
import {firebaseDb} from '../config/firebase_config/firebase';

import {
    buildAccountDeployJob
} from '../config/firebase_config/endpoints';

import {NotificationManager} from 'react-notifications';
import history from '../utils/history'


export const onAccountDeployConfigsLoadSuccess = payload => ({
    type: 'LOAD_CONFIGS_SUCCESS',
    payload,
});

export const triggerDeploySuccess = payload => ({
    type: 'TRIGGER_DEPLOY_SUCCESS',
    payload,
});
export const triggerDeployRequest = () => ({
    type: 'TRIGGER_DEPLOY_SUCCESS',

});
export const triggerDeployFailed = payload => ({
    type: 'TRIGGER_DEPLOY_SUCCESS',
    payload
});


export const onDeployStatusLoadSuccess = payload => ({
    type: 'LOAD_DEPLOYS_SUCCESS',
    payload,
});


function getJobName(action, platformKey, account) {
    const base = (action === 'publish') ? action : action + '_' + platformKey;
    const target = (platformKey === 'ios' && account.isEjected) ? '_ejected' : '_expo'

    return base + target
}

export const triggerDeploy = ({accounts, platforms, action, branches, androidReleaseStatus = null}) => async (dispatch, getState) => {
    try {
        dispatch(triggerDeployRequest());

        let counter = 0

        let updates = {};

         Object.keys(accounts).forEach( (accountKey) => {
             Object.keys(branches).forEach( (branchKey) => {
             //Object.keys(platforms).forEach( (platformKey) => {
                    const account = accounts[accountKey]
                    const platformKeys = Object.keys(platforms).join('-')
                 const timestamp = Date.now()
                    const endpoint = buildAccountDeployJob('open', action, platformKeys, account.accountId + '-' + branchKey + '-' + timestamp)


                    updates[endpoint] = {

                        action,
                        key: account.accountId,
                        description: `${action} ${account.accountName}${platformKeys ? ' to ' + platformKeys : ''}`,
                        branch: branchKey,
                        job_name: 'publish_expo',//getJobName(action, platformKey, account),
                        accountId: account.accountId,
                        accountName: account.accountName,
                        platforms: Object.keys(platforms),
                        timestamp,
                        ejected: account.isEjected === true,
                        appType: account.isMultiAccount ? 'multiaccount' : 'standard',
                        androidReleaseStatus: androidReleaseStatus,

                    };
                    counter++

              //  })
            })
        })

        await firebaseDb.ref().update(updates);

        dispatch(triggerDeploySuccess());
        NotificationManager.success('Success!', counter + ' deploy jobs started!', 3000);


    } catch (err) {
        console.log("Deploy ERROR: " + JSON.stringify(err))

        dispatch(triggerDeployFailed(err));
    }
}

