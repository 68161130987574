/**
 * @flow
 */

import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import SubHeader from './SubHeader';
import theme from '../../../theme/index';
import type {
  TBenefitApiData,
  TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import CabinList from './CabinList';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import Spinner from "../../../components/Spinner";
import {getAccounts, getAllBookings, getCategories} from '../components/Retrievers'
import CabinBookingList from "./CabinBookingList";

const BenefitsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;

const BenefitBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png').default});
   margin-bottom: 2em;
`;


type TState = {
  selectedId: string;
};

type TProps = {
  accountId: number;
  benefits: TBenefitApiData;
  onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
};

class CabinPage extends React.Component<void, TProps, TState> {

  componentDidMount() {
    if (this.props.isDraft || this.props.isArchive) {
      this.props.onCabinsReset();
    }
  }

  state: TState = {
    selectedId: 'all',
  };


  render(){
      return( <FirebaseContainer queries={getAccounts(this.props.onAccountsLoadSuccess)}>
          {this.props.accountsLoaded ? this.innerRender() : <Spinner/>}
      </FirebaseContainer>)
  }

  renderLists = () => {
      const {accountId, accounts, onAllBookingLoadSuccess, allBookingsLoaded, isLoaded, isArchive, isDraft} = this.props;
      return (
          <CabinList
              accountId={accountId}
              accounts={accounts}
              cabins={this.props.cabins}
              onCabinsLoadSuccess={this.props.onCabinsLoadSuccess}
              isLoaded={this.props.isLoaded}
              isArchive={this.props.isArchive}
              isDraft={this.props.isDraft}
          />
      )
      // ) : (
      //     <Flex>
      //         <Box w={[1,1/2]} p={1} flex>
      //             <CabinList
      //                 accountId={accountId}
      //                 accounts={accounts}
      //                 cabins={this.props.cabins}
      //                 onCabinsLoadSuccess={this.props.onCabinsLoadSuccess}
      //                 isLoaded={this.props.isLoaded}
      //                 isArchive={this.props.isArchive}
      //                 isDraft={this.props.isDraft}
      //             />
      //         </Box>
      //         <Box w={[1,1/2]} p={1} flex>
      //             <FirebaseContainer queries={getAllBookings(accounts, accountId, onAllBookingLoadSuccess)}>
      //                 {isLoaded && allBookingsLoaded && <CabinBookingList {...this.props}/>}
      //             </FirebaseContainer>
      //         </Box>
      //     </Flex>
      // )
  }

  innerRender() {
    const {accountId, accounts, onAllBookingLoadSuccess, allBookingsLoaded, isLoaded} = this.props;
    return (
      <BenefitsWrap>
        <BenefitBackground>
          <SubHeader isArchive={this.props.isArchive} isDraft={this.props.isDraft} account={this.props.accounts[this.props.accountId]}/>
        </BenefitBackground>
        <Wrapper>
            {this.renderLists()}
        </Wrapper>
      </BenefitsWrap>
    );
  }
}

export default CabinPage;
