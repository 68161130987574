/**
 * @flow
 */

import React, {Component} from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import H3 from '../../../components/Typografy/H3';
import SmallText from '../../../components/Typografy/SmallText';
import Modal from '../../../components/Modal';
import {mx} from '../../../utils';
import type {TBenefitApiData} from '../../../../common/types/api/TBenefit';

const Details = styled.div`
 ${mx('lg')} {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 1em;
 }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em; 
  `;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 2.0em;
  //text-transform: uppercase;
  `;

const Text = styled(SmallText)`
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  width: 100%;
  height: auto;
  `;

const QuestionTitle = styled(H3)`
  color: ${theme.benefits.questionTitle};
  font-weight: bold;
  font-size: 1.7em;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 30px;
  ${mx('lg')} {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 2.0em;
  }
  `;

const QuestionSubtitle = styled(H3)`
  color: ${theme.benefits.questionTitle};
  font-weight: normal;
  font-size: 1.5em;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 30px;
  ${mx('lg')} {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.0em;
  }
  `;


const InfoMobile = styled.button`
  font-size: 1.1em;
  color: ${theme.benefits.benefitsBox};
  //text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  background: none;
  border: none;
  font-family: 'Lato', sans-serif;
  ${mx('lg')} {
    display: none;
  }
  `;

const Info = styled.button`
  font-size: 1.1em;
  color: ${theme.benefits.benefitsBox};
  //text-transform: uppercase;
  display: none;
  background: none;
  border: none;
  ${mx('lg')} {
    display: block;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 1.3em;
  }
  `;

const RateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  `;

const RatingText = styled.span`
  font-size: 1.6em;
  font-weight: bold;
`;

const BenefitDescriptionHeadline = styled.div`
  font-size: 1.4em;
  font-weight: bold;
`;

const BenefitAbout = styled.div`
  background-color: ${theme.benefits.detailsBackground};
  padding-bottom: 2em;
  padding-top: 2em;
  `;

const DescriptionPart = styled.div`
  display:flex;
  flex-direction: column;
  ${mx('lg')} {
    display: flex;
    width: 65%;
    margin-right: 30px;
  }
  `;

const ModalBody = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
  ${mx('lg')} {
    min-width: 28em;
  }
`;

const RatingPart = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  `;

type TProps = {
  saveRating: Function;
  benefit: TBenefitApiData;
}

type TState = {
  isModalOpen: boolean;
  rating: number;
  ratingSelected?: boolean;
};

class AboutSection extends Component<void, TProps, TState> {

  state = {
    isModalOpen: false,
    rating: 0,
    ratingSelected: false,
  };

  onEditClick = () => {
    this.props.saveRating(this.state.rating).then(() => {
      this.setState({isModalOpen: false});
    });
  };

  openPopup = () => {
    this.setState({isModalOpen: true});
  };

  onCloseModal = () => {
    this.setState({isModalOpen: false});
  };



  render() {
    const {benefit} = this.props;

    if(!benefit.description_long)
    {
      return(<div></div>)
    }



    return (
      <BenefitAbout>
        <Wrapper>
          <Details>
            <DescriptionPart>
              <Header>
                <Title> Benefit details </Title>
              </Header>
              <Text>
                {benefit.description_long.split('\n').map((item, key) => {
                  if(item.startsWith('##')){
                    return <span key={key}><BenefitDescriptionHeadline>{item.substr(2)}</BenefitDescriptionHeadline><br/></span>
                  }
                  return <span key={key}>{item}<br/></span>
                })
                }
              </Text>
            </DescriptionPart>

          </Details>
        </Wrapper>
      </BenefitAbout>
    );
  }
}

export default AboutSection;
