/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {buildBenefitReports} from '../../../../common/config/firebase_config/endpoints';
import BenefitReportItem from './BenefitReportItem';

import Spinner from '../../../components/Spinner'
import type {
  TBenefitReportApiData,
  TBenefitReportResponse
} from "../../../../common/types/api/TBenefitReport";

type TProps = {
  accountId: number;
  benefitReports: Array<TBenefitReportApiData>;
  onBenefitReportsLoadSuccess: (data: TBenefitReportResponse) => void;
};

class BenefitReportList extends React.Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildBenefitReports(this.props.accountId, this.props.userId),
      method: 'on',
      eventType: 'value',
      callback: this.props.onBenefitReportsLoadSuccess,
    },
    ]);
  };
  _getItems = () => {
    return this.props.benefitReports.map((report, i) => {
      return <BenefitReportItem key={i} report={report} accountId={this.props.accountId}/>;
    });
  };
  render() {
    return (
      <FirebaseContainer queries={this._getQueries()}>
        <div>
          { this.props.benefitReports ? this._getItems() : <Spinner/> }
        </div>
      </FirebaseContainer>
    );
  }
}
export default BenefitReportList;
