/**
 * @flow
 */

import React, { Component } from 'react';
import styled from 'styled-components';

const buttonStyle = {
  width: "100%",
  cursor: "pointer",
  padding: 15,
  border: "none",
  backgroundColor: "whitesmoke",
  borderBottom: "1px dotted gray",
  margin:0,
  outline:0
};

let MyButton = styled.button`
  width: 100%;
  cursor: pointer;
  padding: 15px;
  border: none;
  border-bottom: 1px dotted gray;
  margin: 0;
  outline: 0;
  color: white;
`;

let Content = styled.div `
  padding-top:10px;
  padding-left:5px;
  padding-right:5px;
`;

let collapseStyle = {display:"none"}

class CollapsibleWrapper extends Component {

  constructor() {
    super();
    this.state = {
      collapseOpen: false
    };

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  handleCollapse() {

    this.setState({
      collapseOpen: !this.state.collapseOpen
    },this.changeVisibility());


  }

  changeVisibility() {
    if (collapseStyle.display === "none") {
      collapseStyle = {display:"block"};
    }
    else {
      collapseStyle = {display:"none"};
    }
  }

  render() {

    console.log("BtnColor:", this.props.btnColor);
    return (
      <div>
        <MyButton onClick={this.handleCollapse} style={{backgroundColor: this.props.btnColor}}>▼ {this.props.title}</MyButton>
        <Content style={collapseStyle} >
          {this.props.content}
        </Content>
      </div>
    );
  }
}

export default CollapsibleWrapper;
