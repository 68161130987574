import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import Modal from '../Modal'
import windowDimensions from 'react-window-dimensions';
import CustomFieldInput from "../Typografy/CustomFieldInput";
import {SaveButton} from '../Buttons';

import KonvaImage from './KonvaImageSimple'
import {CardTitle, Card} from '../Elements'

import {Stage, Layer} from 'react-konva'
import styled from "styled-components";
import {NotificationManager} from "react-notifications";
import {uploadAnyImage} from "../../../common/benefits/actions/uploadImage";

type TProps = {
    field: string;
    baseUrl: string;
    formValues: any;
    label: string;
    width: number;
    height: number;
    showUrl:string;
    writeUrl:string;
}
const AccountImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;

const HiddenBox = styled.div`
    visibility:hidden;
  
`;

class CanvasBuilder extends Component<void, TProps, TState> {
    state: TState = {
        edit: false,
        baseImageDims: {}
    };
    getFieldValue = (base, subfield) => {

        const data = this.props.formValues || {}
        return data[base] ? data[base][subfield] : null
    }


    componentDidUpdate(prevProps){
        if(this.props.newInitialValues && this.props.accountFormValues
            && (!(this.props.accountFormValues[this.props.field]) ||  Object.keys(this.props.accountFormValues[this.props.field]).length === 0)){
            if(this.props.change){
                this.props.change(this.props.newInitialValues)
            }
            if(this.props.onChange){
                this.props.onChange(this.props.newInitialValues)
            }

        }
    }

    render() {
        const {field, label, showUrl, baseUrl, width, height, baseScale, overlayUrl} = this.props

        let picture = showUrl;

        if (this.state.picture) {
            picture = this.state.picture;
        }

        const canvasDims = {
            width: width > 1500? 1500 : width - 100,
            height: height > 1000? 1000 : height - 100,
        }



        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        {label}
                    </CardTitle>
                </Box>
                <Box w={[1, 6 / 12]} px={2}>
                    <AccountImageBorder>
                        <img src={picture} alt="preview" style={{maxWidth: '100%'}}/>
                    </AccountImageBorder>
                </Box>
                <Box w={[1]}>
                <Flex justify="flex-end">
                    <SaveButton type="button" onClick={() => this.setState({...this.state, edit:true})}> Update image </SaveButton>
                </Flex>
                </Box>
                <Modal header={`Adjust ${label}`}
                       onSaveClick={() => this.onSave()}
                       onCancelClick={() => this.setState({edit: false})}
                       saveButtonText={"Save"}
                       cancelButtonText={"Cancel"}
                       isOpen={this.state.edit}>

                    <Flex>
                        <Box w={1/2}>
                            <Field
                                name={`${field}.x`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="X"/>
                            <Field
                                name={`${field}.y`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="Y"/>
                            <Field
                                name={`${field}.scale`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="Scale"/>
                        </Box>

                    <Box >
                        <Stage ref="stage" width={canvasDims.width} height={canvasDims.height}>
                            <Layer>
                                <KonvaImage src={baseUrl} width={canvasDims.width} height={canvasDims.height} scale={1} onScaled={(dims) => {
                                    if(!(this.state.baseImageDims.width) || dims.width !== this.state.baseImageDims.width || dims.height !== this.state.baseImageDims.height ) {
                                        this.setState({...this.state, baseImageDims:dims})
                                    }

                                }} space="fit"/>
                                <KonvaImage src={overlayUrl} x={this.getFieldValue(field, 'x')} y={this.getFieldValue(field, 'y')} scale={(this.getFieldValue(field, 'scale')/100)} width={this.state.baseImageDims.width} height={this.state.baseImageDims.height} space="fit"/>
                            </Layer>

                        </Stage>
                    </Box>

                    </Flex>
                </Modal>
                {this.state.edit && this.hiddenBox(baseUrl, overlayUrl, field)}
            </Card>
        );
    };

    hiddenBox = (baseUrl, overlayUrl, field) => {

        const { baseImageDims } = this.state
        if(!baseImageDims.originalWidth) return null

        const relativeSize = baseImageDims.originalWidth/baseImageDims.width


        return (
            <HiddenBox >
                <Stage ref="stagez" width={baseImageDims.originalWidth} height={baseImageDims.originalHeight}>
                    <Layer>
                        <KonvaImage src={baseUrl} width={baseImageDims.originalWidth} height={baseImageDims.originalHeight} scale={1} space="fit"/>
                        <KonvaImage src={overlayUrl} x={this.getFieldValue(field, 'x') * relativeSize} y={this.getFieldValue(field, 'y') * relativeSize} scale={(this.getFieldValue(field, 'scale')/100)} width={baseImageDims.originalWidth} height={baseImageDims.originalHeight} space="fit"/>
                    </Layer>

                </Stage>
            </HiddenBox>
        )
    }

    onBlob = (blob) => {
        uploadAnyImage(this.props.writeUrl, blob, this.onUrl,
            errorCb => {
                NotificationManager.error('Error', 'Error uploading image', 3000)
            });
    }

    onUrl = (url) => {
                this.setState({file: null, picture: url, edit: false});
                NotificationManager.success('Success', 'Image uploaded!', 3000);
            }


    onSave = () => {

        const KonvaStage = this.refs.stagez.getStage();

        KonvaStage.toCanvas().toBlob(this.onBlob);

    }
}



export default windowDimensions()(CanvasBuilder);
