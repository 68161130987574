/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import ProfileIcon from './components/ProfileIcon';
import theme from '../../theme';
import Link from "../../components/Typografy/Link";
import {mx} from "../../utils";

const ClientInfo = styled.span`
   font-size: 1em;
   color: ${theme.nav.color};
`;

const ImageContainer = styled.div`
   flex: 1;
   min-width: 2.5em;
`;

const InfoContainer = styled.div`
  flex: 1;
    margin-top:15px;
    margin-bottom:15px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #171717;
  left: 0;
  right: 0;
  top: 4.5em;
  padding: 1em;
  ${mx('lg')} {
    flex-direction: row;
    align-items: center;
    position: static;
    background: none;
    padding: 0;
    }
  `;

const MenuButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 0.6em;
  color: black;
  border-radius: 0.5em;
  margin-bottom: 1em;
  border:2px solid ${props => props.theme.navigationBar ? props.theme.navigationBar.iconColor : theme.subHeader.buttonSecondColor};
  margin-right: 0.5em;
  &:hover {
    text-decoration: none;
    background-color:${props => props.theme.navigationBar ? props.theme.navigationBar.iconColor : theme.subHeader.buttonSecondColor};
  }
  
`;

class AccountMenu extends React.Component {
  render() {
      const { accountId, accounts } = this.props;

      return (
      <Flex wrap align="center" justify="space-between">
        <Menu>
        <InfoContainer >
            { accounts[accountId].customModules && accounts[accountId].customModules.cabinBooking && <MenuButton to="/app/cabins">
                Cabins
            </MenuButton>}
          <MenuButton theme={this.props.theme} to="/app/benefits">
            Benefits
          </MenuButton>
        </InfoContainer>
        <InfoContainer >
          <MenuButton theme={this.props.theme} to="/app/map">
            Map
          </MenuButton>
        </InfoContainer>
        </Menu>
      </Flex>
    );
  }
}

export default AccountMenu;
