/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildMessages } from '../../../../common/config/firebase_config/endpoints';
import type {
  TMessageApiData,
  TMessageResponse,
} from '../../../../common/types/api/TMessage';
import MessageItem from './MessageItem';

import Spinner from '../../../components/Spinner'

type TProps = {
  accountId: number;
  messages: Array<TMessageApiData>;
  onMessagesLoadSuccess: (data: TMessageResponse) => void;
};

class MessageList extends React.Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildMessages(this.props.accountId),
      method: 'on',
      eventType: 'value',
      callback: this.props.onMessagesLoadSuccess,
    },
    ]);
  };
  _getItems = () => {
    return this.props.messages.map((message, i) => {
      return <MessageItem key={i} message={message} accountId={this.props.accountId} />;
    });
  };
  render() {
    return (
      <FirebaseContainer queries={this._getQueries()}>
        <div>
          { this.props.messages ? this._getItems() : <Spinner/> }
        </div>
      </FirebaseContainer>
    );
  }
}
export default MessageList;
