/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, change, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';
import Wrapper from '../../../components/Typografy/Wrapper';
import BaseButton from '../../../components/Typografy/BaseButton';
import Select from '../../../components/Select'


import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';
import CustomFieldTextarea from '../../../components/Typografy/CustomFieldTextarea';
import BenefitReportFooter from './components/BenefitReportFooter';

import type {TBenefitApiData} from "../../../../common/types/api/TBenefit";
import SelectInput from "../../../components/SelectInput";

const SaveButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const Container = styled.div`
  background: ${theme.userProfile.userCardsBackground};
  padding-top: 3.5em;
  padding-bottom: 7em;
`;

const DiscountWrapper = styled.div`
  padding-bottom:10px;
  padding-top:10px;
`;

const CalculatedWrapper = styled.div`
  margin-top:10px;
  padding:5px;
  border-bottom:1px solid black;
`;

const InfoWrapper = styled.div`
  padding-top:10px;
  padding-left:30px;
  padding-right:30px;
`;

const Card = styled(Flex)`
  margin-bottom: 2em;
  padding: 1.7em 1.8em 3em 1.8em;
  box-shadow: 0px 0px 13px 1px rgba(0,0,0,0.3);
  background: ${theme.userProfile.cardBackground};
  border-radius: 20px;
`;

const CardTitle = styled.h2`
  font-size: 1.25em;
  padding-bottom: 1em;
  //text-transform: uppercase;
  border-bottom: 1px solid  ${theme.userProfile.cardBorder};
`;

type TProps = {
    accountId: string;
    initialValues: TBenefitApiData;
    hasReport: boolean;
}

type TState = {}

const options = (props) => {
  return props.benefits.map(benefit => ({
    label: benefit.heading + " - " + benefit.provider,
    value: benefit.benefitId
  }));
}

let prevValues = {
  originalValue: null,
  discountPercent: null,
  discountValue: null
}

class BenefitReportForm extends Component<void, TProps, TState> {

  calculateSavings(discountPercent, discountValue, originalValue, paid) {

    let calculatedDiscountValue = discountValue;
    // Original + percent
    calculatedDiscountValue = !calculatedDiscountValue ? originalValue - (originalValue * (100 - discountPercent) / 100) : calculatedDiscountValue;
    // Original + paid
    calculatedDiscountValue = !calculatedDiscountValue ? originalValue - paid : calculatedDiscountValue;
    // percent + paid
    calculatedDiscountValue = !calculatedDiscountValue ? (paid / (100-discountPercent) * 100) - paid : calculatedDiscountValue;


    let calculatedDiscountPercent = discountPercent;
    // Original + discountValue
    calculatedDiscountPercent = !calculatedDiscountPercent ? discountValue / originalValue * 100 : calculatedDiscountPercent;
    // Original + paid
    calculatedDiscountPercent = !calculatedDiscountPercent ? 100 - (paid / originalValue * 100) : calculatedDiscountPercent;
    // discountValue + paid
    calculatedDiscountPercent = !calculatedDiscountPercent ? discountValue / (discountValue+paid) * 100 : calculatedDiscountPercent;


    let calculatedTotalValue = originalValue;
    // discountValue + paid
    calculatedTotalValue = !calculatedTotalValue ? discountValue + paid : calculatedTotalValue;
    // discountValue + percent
    calculatedTotalValue = !calculatedTotalValue ? discountValue / discountPercent * 100 : calculatedTotalValue;
    // paid + percent
    calculatedTotalValue = !calculatedTotalValue ? paid / (100-discountPercent) * 100 : calculatedTotalValue;


    let calculatedPaid = paid;
    // Original + percent
    calculatedPaid = !calculatedPaid ? (originalValue * (100 - discountPercent) / 100) : calculatedPaid;
    // Original + discountValue
    calculatedPaid = !calculatedPaid ? originalValue - discountValue : calculatedPaid;
    // percent + discountValue
    calculatedPaid = !calculatedPaid ? (discountValue / discountPercent * 100) - discountValue : calculatedPaid;


    let res = {
      discountValue: calculatedDiscountValue.toFixed(2),
      originalValue: calculatedTotalValue.toFixed(2),
      discountPercent: calculatedDiscountPercent.toFixed(2),
      paid: calculatedPaid.toFixed(2)
    }

    return res;
  }

  _printInfo() {
    return (
      <InfoWrapper>
        <h2>Skatte- og rapporteringsplikt for rabatter i arbeidsforhold, herunder særlig om rabatter via arbeidsgivers forretningspartnere eller tredjeparter</h2>
        Etter skatteloven er enhver økonomisk fordel mottatt i arbeidsforholdet skattepliktig, med mindre det er gitt særskilt skattefritak.
        Rabatter den ansatte mottar i arbeidsforholdet er derfor i utgangspunktet skattepliktige etter gjeldende regler.
        Når en ansatt mottar rabatt fra andre enn arbeidsgiveren (tredjeparter),
        er utgangspunktet for vurderingen av skatteplikt for rabatten om det foreligger tilstrekkelig sammenheng mellom ytelsen og arbeidsforholdet.
        Spørsmålet må avgjøres etter en konkret vurdering av hvert tilfelle.
      </InfoWrapper>
    )
  }

    render() {

        let reportId = this.props.report ? this.props.report.reportId : ''
        //
        // console.log("props");
        // console.log(this.props);

      const { originalValue, discountPercent, discountValue, paid } = this.props;

      let calculatedValues = this.calculateSavings(parseFloat(discountPercent), parseFloat(discountValue), parseFloat(originalValue), parseFloat(paid));


        return (
            <div>
                <Container>
                    <Wrapper>
                        <Flex justify="space-between" wrap>
                            <Box w={[1, 12/12]}>
                                <Card wrap>
                                    <Box w={[1]} >
                                        <CardTitle>
                                            Report Benefit Usage
                                        </CardTitle>
                                    </Box>
                                    <Box w={[1, 1/2]}>
                                        <form autoComplete="off">
                                            <div>
                                                <Field
                                                    name="date"
                                                    component={CustomFieldInput}
                                                    type="date"
                                                    placeholder="Date"
                                                    userCard
                                                    label="* Date"
                                                />
                                            </div>
                                            <div>
                                              <Field
                                                name="fromBenefit"
                                                options={options(this.props)}
                                                component={SelectInput}
                                                label="* Choose benefit"
                                              />
                                            </div>
                                          <div>
                                            <Field
                                              name="serviceDescription"
                                              component={CustomFieldInput}
                                              type="text"
                                              placeholder="Ex. Car rental"
                                              userCard
                                              label="* Service or product description"
                                            />
                                          </div>
                                          <div>

                                          </div>
                                            <Flex justify="space-between">
                                              <Box w={[1, 2/3]}>
                                                <div>
                                                  <Field
                                                    name="originalValue"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="Ex. 1 000"
                                                    userCard
                                                    label="* Service or product full value (NOK)"
                                                  />
                                                  <Field
                                                    name="discountPercent"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="Ex. 10%"
                                                    userCard
                                                    label="* Discount in percent"
                                                  />
                                                  <Field
                                                    name="discountValue"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="Ex. 100"
                                                    userCard
                                                    label="* Discount in NOK"
                                                  />
                                                  <Field
                                                    name="paid"
                                                    component={CustomFieldInput}
                                                    type="text"
                                                    placeholder="Ex. 100"
                                                    userCard
                                                    label="* What you paid in NOK"
                                                  />
                                                </div>
                                              </Box>
                                              <Box w={[1, 1/3]} mx={2} >
                                                  <div>
                                                    Calculated values
                                                  </div>
                                                <Flex justify="space-between">
                                                  <Box w={[1, 2/3]}>
                                                    <CalculatedWrapper>
                                                      Total value:
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      Discount in %:
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      Discount in NOK:
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      You paid:
                                                    </CalculatedWrapper>
                                                  </Box>
                                                  <Box w={[1, 1/3]}>
                                                    <CalculatedWrapper>
                                                      {calculatedValues.originalValue}
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      {calculatedValues.discountPercent}
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      {calculatedValues.discountValue}
                                                    </CalculatedWrapper>
                                                    <CalculatedWrapper>
                                                      {calculatedValues.paid}
                                                    </CalculatedWrapper>
                                                  </Box>
                                                </Flex>
                                              </Box>

                                            </Flex>
                                            <div>
                                                <Field
                                                    name="comment"
                                                    component={CustomFieldTextarea}
                                                    type="text"
                                                    placeholder="Note"
                                                    userCard
                                                    rows={2}
                                                    label="* Extra notes"
                                                />
                                            </div>
                                        </form>
                                    </Box>
                                  <Box w={[1, 1/2]}>
                                      <div>
                                        {this._printInfo()}
                                      </div>
                                  </Box>
                        </Card>
                    </Box>
                </Flex>
            </Wrapper>
    </Container>
        <BenefitReportFooter reportId={reportId} pristine={this.props.pristine}
                             onSave={() => {
                               let data = {...this.props.reportFormValues, ...calculatedValues}
                               this.props.updateBenefitReport(data, this.props.report)
                              }
                             }/>
    </div>
    )
        ;
    }
}

const selector = formValueSelector('BenefitReportForm') // <-- same as form name
export default connect(
   state => {
     const originalValue = selector(state, 'originalValue')
     const discountPercent = selector(state, 'discountPercent')
     const discountValue = selector(state, 'discountValue')
     const paid = selector(state, 'paid')
     return {
       originalValue,
       discountPercent,
       discountValue,
       paid
     }
   },
   {change})
(reduxForm({
    form: 'BenefitReportForm',
    field: ['date', 'fromBenefit', 'serviceDescription', 'originalValue', 'discountPercent', 'discountValue', 'comment'],
})(BenefitReportForm));

// BenefitReportForm = reduxForm({
//     form: 'BenefitReportForm',
//     field: ['date', 'fromBenefit', 'serviceDescription', 'originalValue', 'discountPercent', 'discountValue', 'comment'],
// });
//
// const selector = formValueSelector('BenefitReportForm') // <-- same as form name
// BenefitReportForm = connect(
//   state => {
//     const originalValue = selector(state, 'originalValue')
//     const discountPercent = selector(state, 'discountPercent')
//     const discountValue = selector(state, 'discountValue')
//     return {
//       originalValue,
//       discountPercent,
//       discountValue
//     }
//   },
//   {change}
// )(BenefitReportForm)
//
// export default BenefitReportForm;
