/**
 * @flow
 */

import React from 'react';
import {Flex,} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';

import DomainDropper from './components/DomainDropper'
import type {TDomainApiData} from '../../../../common/types/api/TDomain';

const DomainsCard = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
`;

const DomainInfoContainer = styled.div`
`;

const DomainTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const DomainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`


type TProps = {
  domain: TDomainApiData;
  accountId: number;
  removeFromDomain: any;
};

const DomainItem = (props: TProps) => {
  const { domain, accountId, removeFromDomain } = props;

  const domainTitle = domain ? domain.domainId.replace(/\|/g, '.') : ''

  return (
      <DomainsCard>
        <DomainWrapper>
	        <DomainTitle> {domainTitle} </DomainTitle>
	        <DomainDropper {...props}/>
        </DomainWrapper>
      </DomainsCard>
  );
};

export default DomainItem;
