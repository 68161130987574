/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import iconStronger from '../../../assets/images/icon_stronger.png';
import iconGrowth from '../../../assets/images/icon-growth.png';
import iconDistributed from '../../../assets/images/icon-distributed.png';
import iconLike from '../../../assets/images/icon_like.png';

const images = {
  iconStronger: { src: iconStronger, width: 52, height: 31 },
  iconGrowth: { src: iconGrowth, width: 53, height: 53 },
  iconDistributed: { src: iconDistributed, width: 50, height: 50 },
  iconLike: { src: iconLike, width: 47, height: 48 },
};

type Props = {
  name: string,
};

const Image = styled.img`

`;

const Container = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #f41a4d;
  width: 6.5em;
  height: 6.5em;
  align-items: center;
  `;

const WrappedContainer = styled.div`
  display: inline-block;
  `;

const Icon = ({ name }: Props) => {
  const img = images[name];
  return (
    <WrappedContainer>
      <Container>
        <Image
          src={img.src}
          width={img.width}
          height={img.height}
        />
      </Container>
    </WrappedContainer>
  );
};

export default Icon;
