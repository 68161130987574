/**
 * @flow
 */

import {connect} from 'react-redux';

import type {TAppState} from '../../../common/TAppState';
import SelectAccount from './SelectAccount';
import {updateSelectedAccountAppSuccess, updateSelectedAccountSuccess} from '../../../common/auth/profile/actions';
import {onAccountsLoadSuccess} from '../../../common/accounts/actions'


const SelectAccountContainer = connect(
  (state: TAppState) => {
    return {
      profile: state.auth.profile.data,
      accounts: state.accounts.data
    };
  }, (dispatch) => ({
    updateSelectedAccountSuccess: (data, history) => dispatch(updateSelectedAccountSuccess(data, history)),
    updateSelectedAccountAppSuccess: (data, history) => dispatch(updateSelectedAccountAppSuccess(data, history)),
    onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
  }),
)(SelectAccount);

export default SelectAccountContainer;
