import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import Modal from '../../../../components/Modal'
import windowDimensions from 'react-window-dimensions';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';

import KonvaImage from '../../../../components/form/KonvaImageSimple'
import {CardTitle, Card, Container} from '../../../../components/Elements'

import {Stage, Layer, Rect, Text} from 'react-konva'
import styled from "styled-components";
import {NotificationManager} from "react-notifications";
import {uploadAnyImage} from "../../../../../common/benefits/actions/uploadImage";
import {SketchPicker} from "react-color";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import ColorPicker from "./ColorPicker";
import FontPicker from "./FontPicker";

type TProps = {
    field: string;
    baseUrl: string;
    formValues: any;
    label: string;
    width: number;
    height: number;
    showUrl: string;
    writeUrl: string;
}
const AccountImageBorder = styled.div`
    border: 1px dashed darkgrey;
`;

const HiddenBox = styled.div`
    visibility:hidden;
  
`;
const SketchField = (props) => {
    return (<SketchPicker
        color={props.input.value}
        onChangeComplete={(val) => props.input.onChange(val.hex)}
    />)
}


const getFieldValueFromForm = (base, subfield1, subfield2, formValues) => {

    const data = formValues || {}

    if (data[base]) {
        if (subfield1 && data[base][subfield1]) {
            if (subfield2 && data[base][subfield1][subfield2]) {
                return data[base][subfield1][subfield2]
            }
            else {
                return data[base][subfield1]
            }
        }
    }

}

class CanvasBuilder extends Component<void, TProps, TState> {
    state: TState = {
        edit: false,
        baseImageDims: {}
    };

    componentDidUpdate(prevProps){
        console.log("IconBuilderComp: componentDidUpdate")
    }


    updateFont = (font) => {
      // if(this.props.updateToggle){
        //   console.log("updateFont:" +font)
        //   this.props.updateToggle(font)
      // }
    }

    showText() {


        const field = this.props.name
        return this.props.accountFormValues && this.props.accountFormValues[field] && this.props.accountFormValues[field].text && this.props.accountFormValues[field].text.show;
    }

    getFieldValue = (base, subfield1, subfield2) => {
        return getFieldValueFromForm(base, subfield1, subfield2, this.props.accountFormValues)
    }

    renderTextFields() {
        const field = this.props.name

        if (!this.showText()) return null
        return (
            <div>
                <Field
                    name={`${field}.text.x`}
                    component={CustomFieldInput}
                    type="number"
                    userCard
                    label="X" style={{marginBottom:0}}/>
                <Field name={`${field}.text.y`}
                       component={CustomFieldInput}
                       type="number"
                       userCard
                       label="Y"/>
                <Field
                    name={`${field}.text.size`}
                    component={CustomFieldInput}
                    type="number"
                    userCard
                    label="Scale"/>

                <Field
                    name={`${field}.text.color`}
                    userCard
                    label="Text Color"
                    component={ColorPicker}
                />
                <Field
                    name={`${field}.text.font`}
                    userCard
                    label="Text Font"
                    component={FontPicker}
                    onChangeFont={this.updateFont}
                    initialFont={this.getFieldValue(field, 'text','font')}
                />
                <Field
                    name={`${field}.text.letters`}
                    component={CustomFieldInput}
                    type="text"
                    userCard
                    label="Letters"/>
            </div>
        )
    }

    getLogoSkew = (dims) => {
        const field = this.props.name
        const scale = Number(this.getFieldValue(field, 'logo', 'scale') / 100)
        const scaledImageWidth = dims.width * scale

        return Number((dims.width - scaledImageWidth))

}

    render() {
        const {label, showUrl, baseUrl, width, height, minWidth, minHeight, name, overlayUrl} = this.props

        let picture = showUrl;

        if (this.state.picture) {
            picture = this.state.picture;
        }

        const canvasDims = {
            width: width > minWidth ? minWidth : width - 100,
            height: height > minHeight ? minHeight : height - 100,
        }

        const field = name



        return (
            <div>
                <SaveButton type="button" onClick={() => this.setState({...this.state, edit: true})}> Update
                    image </SaveButton>
                <Modal header={`Adjust Icon`}
                       onSaveClick={() => this.onSave()}
                       onCancelClick={() => this.setState({edit: false})}
                       saveButtonText={"Save"}
                       cancelButtonText={"Cancel"}
                       isOpen={this.state.edit}>

                    <Flex>
                        <Box w={1 / 2}>
                            <Box w={[1]}>
                                <CardTitle>
                                    Logo
                                </CardTitle>
                            </Box>
                            <Field
                                name={`${field}.backgroundColor`}
                                userCard
                                label="Background Color"
                                component={ColorPicker}
                            />                            <Field
                                name={`${field}.logo.x`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="X"
                                style={{marginBottom:0}}
                        />
                            <Field
                                name={`${field}.logo.y`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="Y"/>
                            <Field
                                name={`${field}.logo.scale`}
                                component={CustomFieldInput}
                                type="number"
                                userCard
                                label="Scale"/>

                            <Box w={[1]}>
                                <CardTitle>
                                    PRO
                                </CardTitle>
                            </Box>
                            <Field
                                name={`${field}.text.show`}
                                component={ToggleSwitch}
                                userCard
                                type="checkbox"
                                label="Show Text"/>
                            {this.renderTextFields()}
                        </Box>

                        <Box>
                            <Stage style={{border: '1px dotted gray'}} ref="stage" width={canvasDims.width} height={canvasDims.height}>
                                <Layer>
                                    <Rect width={canvasDims.width} height={canvasDims.height}
                                          fill={this.getFieldValue(field, 'backgroundColor')} x={0} y={0}/>
                                    <KonvaImage src={showUrl}
                                                x={(Number(this.getFieldValue(field, 'logo', 'x'))||0) }
                                                y={(Number(this.getFieldValue(field, 'logo','y')) || 0)}
                                                scale={(Number((this.getFieldValue(field, 'logo', 'scale') / 100))) || 100}
                                                width={canvasDims.width}
                                                height={canvasDims.height} space="fit"/>

                                    {this.showText() && <Text
                                        x={Number(this.getFieldValue(field, 'text', 'x') )}
                                        y={Number(this.getFieldValue(field, 'text','y'))}
                                        fontSize={Number(this.getFieldValue(field, 'text', 'size') )}
                                        fontFamily={this.getFieldValue(field, 'text','font')}
                                        fontWeight={700}
                                        fill={this.getFieldValue(field, 'text','color')}
                                        text={this.getFieldValue(field, 'text','letters')}
                                    />}
                                </Layer>

                            </Stage>
                        </Box>

                    </Flex>
                </Modal>
            </div>
        );
    };




    onBlob = (blob) => {
        uploadAnyImage(this.props.writeUrl, blob, this.onUrl,
            errorCb => {
                NotificationManager.error('Error', 'Error uploading image', 3000)
            });
    }

    onUrl = (url) => {
        this.setState({file: null, picture: url, edit: false});
        if(this.props.onUpdate){
            this.props.onUpdate(url)
        }
        NotificationManager.success('Success', 'Image uploaded!', 3000);
    }


    onSave = () => {

        const KonvaStage = this.refs.stage.getStage();

        KonvaStage.toCanvas().toBlob(this.onBlob);

    }
}


class CanvasText extends Component {
    getFieldValue = (base, subfield1, subfield2) => {
        return getFieldValueFromForm(base, subfield1, subfield2, this.props.accountFormValues)
    }

    render(){
        const field = this.props.field

        if(!this.props.showText) return null


       return <Text
            x={Number(this.getFieldValue(field, 'text', 'x') )}
            y={Number(this.getFieldValue(field, 'text','y'))}
            fontSize={Number(this.getFieldValue(field, 'text', 'size') )}
            fontFamily={this.getFieldValue(field, 'text','font')}
            fontWeight={700}
            fill={this.getFieldValue(field, 'text','color')}
            text={'PRO'}
        />
    }

}

export default windowDimensions()(CanvasBuilder);
