/**
 * @flow
 */


import { connect } from 'react-redux';
import AccountPage from './AccountPage';
import { onAccountsLoadSuccess } from '../../../../common/accounts/actions';
import type { TAppState } from '../../../../common/TAppState';

const AccountContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     accounts: state.accounts.list,
   };
 },
 (dispatch) => ({
   onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
 }),
)(AccountPage);
export default AccountContainer;
