/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import ProfileIcon from './components/ProfileIcon';
import theme from '../../theme';
import Wrapper from '../../components/Typografy/Wrapper';


const Container = styled.div`
  background-image: url(${require('../../assets/images/top-background.png').default});
  padding-top: 1.6em;
`;

const ButtonsContainer = styled.div`
  padding-top: 2.5em;
  padding-bottom: 1.5em;
`;


const CompanyProfile = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

class SubHeader extends React.Component {
  render() {
    return (
      <Container >
        <Wrapper>
          <CompanyProfile>
            <ProfileIcon />
            Profile
          </CompanyProfile>
          <ButtonsContainer>
            <Link to="/login">
                 Reset Password
            </Link>
            <Link to="/login">
              Log out
            </Link>
          </ButtonsContainer>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
