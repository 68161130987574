/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import VerifyService from './VerifyService';

const VerifyServiceContainer = connect(
  (state: TAppState) => ({ token: state.router.location.query.token }), {},
)(VerifyService);

export default VerifyService;
