/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TStatsApiData,
  TStatsResponse,
} from '../../../../common/types/api/TStats';
import StatsList from './StatsList';
import SubHeader from './SubHeader';
import StatsItem from "./StatsItem";
import AllStatsList from "./AllStatsList";
import AllUnverifiedList from "./AllUnverifiedList";


const StatsWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const StatsBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png').default});
   margin-bottom: 2em;
`;

const ListWrapper = styled(Wrapper)`
  display:flex;
  flex-wrap:wrap;
`;



type TProps = {
  accountId: number;
  stats: TStatsApiData;
  onStatsLoadSuccess: (data: TStatsResponse) => void;
};

class AllStatsPage extends React.Component<void, TProps, TState> {
  componentDidMount() {
    this.props.getStatsFromAll();
  }

  _getItems = (accounts) => {

    let items = []

    Object.keys(accounts).map((accountId) => {
      let accountName = this.props.accounts[accountId] === undefined ? accountName = "Undefined" : this.props.accounts[accountId].accountName;


      if (accountId !== 'all') {
        items.push(<AllStatsList
          key={accountId}
          accountId={accountId}
          unverified={this.props.stats[accountId]}
          total={this.props.stats.undefined[accountId]}
          tokens={0}
          accountName={accountName}
        />);
      }
    });

    return items

  };

  render() {
    console.log(this.props.stats);
    return (
      <StatsWrap>
        <StatsBackground>
          <SubHeader />
        </StatsBackground>
        <ListWrapper>
          {this.props.stats.undefined && this.props.accountsLoaded && this._getItems(this.props.stats)}
        </ListWrapper>
        <Wrapper>
          <AllUnverifiedList
            key={"unverified"}
            accountId={this.props.accountId}
            unverified={this.props.stats.all}
          />
        </Wrapper>
      </StatsWrap>
    );
  }
}

export default AllStatsPage;
