/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {Card, CardTitle} from '../../../../components/Elements'

import Dropzone from '../../../../components/Dropzone';
import styled from 'styled-components';


import {NotificationManager} from "react-notifications";

import Text from '../../../../components/Typografy/Text'


const DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;

const BlackText = styled(Text)`
  color: darkgray;
  margin-bottom:20px;
`;

type TProps = {

}

type TState = {}

const rABS = typeof FileReader !== "undefined" && (FileReader.prototype||{}).readAsBinaryString;


class AppcodeUploader extends Component<void, TProps, TState> {

    state: TState = {
        file: null,
        edit: false,
    };

    _onDrop = (acceptedFiles) => {
        const [file] = acceptedFiles;

        const setter = this
        const {update, accountId, account} = this.props
        const reader = new FileReader();
        reader.onload = () => {
            let data = reader.result;
            if(!rABS) data = new Uint8Array(data);
            update(data, accountId, account.accountName)
        };
        reader.onabort = () => { NotificationManager.error('Error!', 'file reading was aborted', 3000); console.log('file reading was aborted'); }
        reader.onerror = () => { NotificationManager.error('Error!', 'file reading failed', 3000); console.log('file reading failed'); }

        if(rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);

    };





showFree = () => {
        if(this.props.appCodeStatus && this.props.appCodeStatus[this.props.accountId]) {
            return (<span> - {this.props.appCodeStatus[this.props.accountId].free} free codes available</span>)
        }
        return null
    }


    render() {
        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Add Apple B2B appcodes {this.showFree()}
                    </CardTitle>
                </Box>
                <Box w={[1]} px={2}>
                            <Dropzone onDrop={this._onDrop} multiple={false}>
                                <DropzonePlaceholder>
                                    Drop the xls that you downloaded from business.apple.com here or click to select from folder on your computer.
                                </DropzonePlaceholder>
                            </Dropzone>

                </Box>
            </Card>)
    }

}

export default AppcodeUploader

