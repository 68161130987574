/**
 * @flow
 */


import React from 'react';
import styled from 'styled-components';
import {
    Route,
} from 'react-router-dom';

import {Flex, Box} from 'reflexbox';
import {NotificationContainer} from 'react-notifications';
import {mx} from '../../utils';
import Header from './components/Header';
import Footer from '../../components/Footer';
import ResponsiveImage from '../../components/Typografy/ResponsiveImage';
import UserInfo from './UserInfo';
import AccountMenu from './AccountMenu';
import theme from '../../theme';
import Wrapper from '../../components/Typografy/Wrapper';
import type {TProfileData} from '../../../common/types/models/TAuth';
import {buildAccounts} from "../../../common/config/firebase_config/endpoints";
import FirebaseContainer from '../../../common/firebase/FirebaseContainer';
import AccountDetails from "../../SuperAdmin/Accounts/AccountDetails";
import ArchivedBenefitsList from "../Benefits/BenefitsList/ArchivedBenefitContainer";
import DraftBenefitList from "../Benefits/BenefitsList/DraftBenefitContainer";
import WhitelistList from "../Whitelists/WhitelistList";
import MessageEdit from "../Messages/MessageEdit";
import AccountList from "../../SuperAdmin/Accounts/AccountList";
import BenefitDetails from "../Benefits/BenefitDetails";
import BenefitsList from "../Benefits/BenefitsList";
import BenefitEdit from "../Benefits/BenefitEdit";
import MessageList from "../Messages/MessageList";
import AccountEdit from "../../SuperAdmin/Accounts/AccountEdit";
import DomainsList from "../Domains/DomainsList";
import Profile from "../Profile";
import MessageDetails from "../Messages/MessageDetails";
import ArchivedBenefitDetails from "../Benefits/BenefitDetails/ArchivedBenefitDetailsContainer";
import DraftBenefitDetails from "../Benefits/BenefitDetails/DraftBenefitDetailsContainer";
import BenefitReportEdit from "../BenefitReport/BenefitReportEdit";
import BenefitReportList from "../BenefitReport/BenefitReportList";
import RetrieveEmails from "../../SuperAdmin/AdminTools/RetrieveEmails";
import Spinner from '../../components/Spinner'

import {signInAccount} from '../../../common/auth/signIn/actions'
import {
    hasAccount,
    hasAdminAccessToAccount,
    hasSelectedAccount,
    accountName,
    isLegacy,
    getAccount
} from '../../../common/auth'
import UpdateGoogleServicesContainer
    from "../../SuperAdmin/Accounts/AccountGoogleServices/UpdateGoogleServicesContainer";
import StatsContainer from "../Stats/StatsList/StatsContainer";
import AllStatsContainer from '../Stats/StatsList/AllStatsContainer';
import UserAdminContainer from "../UserAdministration/UserAdminList/UserAdminContainer";
import SetPasswordContainer from "../UserAdministration/SetPassword";
import CabinList from "../CabinBooking/CabinList";
import CabinListDraft from "../CabinBooking/CabinList/DraftCabinContainer";
import CabinListArchived from "../CabinBooking/CabinList/ArchivedCabinContainer";
import CabinEdit from "../CabinBooking/CabinEdit";
import CabinDetails from "../CabinBooking/CabinDetails";
import CabinDetailsDraft from "../CabinBooking/CabinDetails/DraftCabinDetailsContainer";
import CabinDetailsArchived from "../CabinBooking/CabinDetails/ArchivedCabinDetailsContainer";
import AccountDeployManagementContainer from "../../SuperAdmin/Accounts/AccountDeployManagement";
import ManageHelpPage from "../../SuperAdmin/AdminTools/ManageHelpPage";
import CabinAdmin from "../CabinBooking/CabinAdmin";
import MessageDraftContainer from "../Messages/MessageList/MessageDraftContainer";
import MessageDraftDetailsContainer from "../Messages/MessageDetails/MessageDraftDetailsContainer";
import MessageArchiveContainer from "../Messages/MessageList/MessageArchiveContainer";
import MessageArchiveDetailsContainer from "../Messages/MessageDetails/MessageArchiveDetailsContainer";
import MessageDraftEditContainer from "../Messages/MessageEdit/MessageDraftEditContainer";
import MessageArchiveEditContainer from "../Messages/MessageEdit/MessageArchiveEditContainer";


type TProps = {
    profile: TProfileData;
    children?: Element<any>,
}

const Container = styled.div`
  background-color: ${theme.nav.backgroundColor};
  padding: 20px 0;
  border-bottom: 1px solid #353535;
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
`;

const FlexContainer = styled(Flex)`
  flex: 1;
  max-width: none;
  ${mx('lg')} {
    max-width: 28em;
  }
`;

const Wrap = styled.div`
  ${mx('lg')} {
    flex: 1;
    background-color: ${theme.benefits.backgroundColor};

  }
`;

const WrapperContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;


`;

const AccountName = styled.div`
  text-decoration: none;
  //text-transform: uppercase;
  padding-left: 1em ;
  color: ${theme.subHeader.buttonTextColor};
  font-size: 24px;
    font-weight: 800;
`;

const loadImages = require.context('../../assets/images', true, /^\.\/.*\.(png|jpg|svg|gif)$/);

class Admin extends React.Component<void, TProps, void> {
    _getQueries = () => {
        return ([{
            endpoint: buildAccounts(),
            method: 'on',
            eventType: 'value',
            callback: this.props.onAccountsLoadSuccess,
        }])
    };

    componentDidMount() {
        const {profile, history, isRestored} = this.props

        console.log("isRestored: " + isRestored);



        if (!isRestored) return


        if (!hasAccount(profile)) {
            signInAccount()
        }
        else if (!hasSelectedAccount(profile)) {
            history.push("/selectAccount")
        }
        else if (!hasAdminAccessToAccount(profile, getAccount(profile))) {
            history.push("/selectAccount")
        }
    }

    componentWillReceiveProps(nextProps: TProps) {
        const {isRestored, profile} = nextProps

        if (isRestored && !hasAccount(profile)) {
            signInAccount()
        }


    }


    render() {
        const {profile} = this.props;
        if (!hasAccount(profile))
            return <Spinner/>

        if ( !hasAdminAccessToAccount(profile, profile.selectedAccount)) {
            this.props.history.push("/selectAccount");
        }

        return (
            <FirebaseContainer queries={this._getQueries()}>
                {this.renderInner()}
            </FirebaseContainer>
        )
    }

    renderInner() {
        const {profile, accounts, history, match, location, accountId, accountsLoaded} = this.props;

        const logo = (
            <FlexContainer align="center">
                    <img
                        src={loadImages(`./CoveredPro_logo.png`).default}
                        width={130}
                        height={50}
                        style={{
							objectFit: "contain",
                        }}
                        alt="coveredpro logo"
                    />
                <Box>
                    {(location.pathname.indexOf("/accounts") < 0) &&
                    <AccountName>{accountName(profile, accounts)}</AccountName>}
                </Box>
            </FlexContainer>
        );
        return (
            <WrapperContainer>
                <Container>
                    <Wrapper>
                        <Header logo={logo}>
                            {(!isLegacy(profile)) && accountsLoaded && <AccountMenu accountId={accountId} accounts={accounts} profile={profile} history={history}/>}
                            {(!isLegacy(profile)) && <UserInfo profile={profile} history={history}/>}
                        </Header>
                    </Wrapper>
                </Container>
                <Wrap>
                    <Route component={BenefitReportEdit} exact path={`${match.url}/benefit-report/new`}/>
                    <Route component={BenefitReportEdit} exact path={`${match.url}/benefit-report/:id/edit`}/>
                    <Route component={BenefitReportList} exact path={`${match.url}/benefit-report`}/>
                    <Route component={BenefitsList} exact path={`${match.url}/benefits`}/>
                    <Route component={BenefitEdit} exact path={`${match.url}/benefits/new`}/>
                    <Route component={BenefitDetails} exact path={`${match.url}/benefits/:id`}/>
                    <Route component={BenefitEdit} path={`${match.url}/benefits/:id/edit`}/>
                    <Route component={BenefitEdit} path={`${match.url}/benefits/:id/edit-draft`}/>
                    <Route component={ArchivedBenefitsList} exact path={`${match.url}/benefits-archive`}/>
                    <Route component={ArchivedBenefitDetails} path={`${match.url}/benefits-archive/:id`}/>
                    <Route component={DraftBenefitList} exact path={`${match.url}/benefits-draft`}/>
                    <Route component={DraftBenefitDetails} path={`${match.url}/benefits-draft/:id`}/>
                    <Route component={Profile} path={`${match.url}/profile`}/>
                    <Route component={MessageList} exact path={`${match.url}/messages`}/>
                    <Route component={MessageEdit} exact path={`${match.url}/messages/new`}/>
                    <Route component={MessageEdit} exact path={`${match.url}/messages/:id/edit`}/>
                    <Route component={MessageDetails} exact path={`${match.url}/messages/:id`}/>
                    <Route component={MessageDraftContainer} exact path={`${match.url}/messages/drafts`}/>
                    <Route component={MessageDraftDetailsContainer} exact path={`${match.url}/messages/drafts/:id`}/>
                    <Route component={MessageDraftEditContainer} exact path={`${match.url}/messages/drafts/:id/edit`}/>
                    <Route component={MessageArchiveContainer} exact path={`${match.url}/messages/archived`}/>
                    <Route component={MessageArchiveDetailsContainer} exact path={`${match.url}/messages/archive/:id`}/>
                    <Route component={MessageArchiveEditContainer} exact path={`${match.url}/messages/archive/:id/edit`}/>
                    <Route component={AccountList} exact path={`${match.url}/accounts`}/>
                    <Route component={UpdateGoogleServicesContainer} path={`${match.url}/accounts/googleservices`}/>
                    <Route component={AccountDeployManagementContainer} path={`${match.url}/accounts/deploy`}/>
                    <Route component={AccountDeployManagementContainer} path={`${match.url}/accounts/:id/deploy`}/>
                    <Route component={AccountEdit} exact path={`${match.url}/accounts/new`}/>
                    <Route component={AccountDetails} exact path={`${match.url}/accounts/:id`}/>
                    <Route component={AccountEdit} path={`${match.url}/accounts/:id/edit`}/>
                    <Route component={WhitelistList} path={`${match.url}/whitelist`}/>
                    <Route component={DomainsList} path={`${match.url}/domains`}/>
                    <Route component={StatsContainer} path={`${match.url}/stats`}/>
                    <Route component={AllStatsContainer} path={`${match.url}/allstats`}/>
                    <Route component={UserAdminContainer} path={`${match.url}/useradmin`}/>
                    <Route component={SetPasswordContainer} path={`${match.url}/setpassword`}/>
                    <Route component={RetrieveEmails} path={`${match.url}/retrieve-emails`}/>
                    {accountsLoaded && accounts[accountId].customModules && accounts[accountId].customModules.cabinBooking &&
                        <div>
                            <Route component={CabinList} exact path={`${match.url}/cabins`} />
                            <Route component={CabinEdit} exact path={`${match.url}/cabins/new`} />
                            <Route component={CabinEdit} exact path={`${match.url}/cabins/:id/edit`} />
                            <Route component={CabinEdit} exact path={`${match.url}/cabins/:id/edit-draft`} />
                            <Route component={CabinDetails} exact path={`${match.url}/cabins/:id`} />
                            <Route component={CabinListDraft} exact path={`${match.url}/cabins-draft`} />
                            <Route component={CabinDetailsDraft} exact path={`${match.url}/cabins-draft/:id`} />
                            <Route component={CabinListArchived} exact path={`${match.url}/cabins-archive`} />
                            <Route component={CabinDetailsArchived} exact path={`${match.url}/cabins-archive/:id`} />
                            <Route component={CabinAdmin} exact path={`${match.url}/cabins/:id/admin`} />
                        </div>
                    }
                    <Route component={ManageHelpPage} path={`${match.url}/manage-help`}/>
                </Wrap>
                <Footer/>
                <NotificationContainer/>
            </WrapperContainer>

        );
    }
}

export default Admin;

/*
                    <Route component={BenefitAccess} path={`${match.url}/benefits_access`}/>
<Route component={DomainsList}  exact path={`${match.url}/setup/categories`} />
              <Route component={DivisionEdit} path={`${match.url}/setup/categories/:id/edit`} />
            <Route component={DivisionsList} exact  path={`${match.url}/divisions`} />
            <Route component={DivisionEdit}  exact path={`${match.url}/divisions/:id/edit`} />
 */
