/**
 * @flow
 */

import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import type {
  TMessageApiData,
  TMessageResponse,
} from '../../../../common/types/api/TMessage';
import MessageList from './MessageList';
import SubHeader from './SubHeader';


const MessagesWrap = styled.div`
  background-color: ${theme.benefits.backgroundColor};
`;
const MessageBackground = styled.div`
   background-image: url(${require('../../../assets/images/top-background.png')});
   margin-bottom: 2em;
`;



type TProps = {
  accountId: number;
  messages: TMessageApiData;
  onMessagesLoadSuccess: (data: TMessageResponse) => void;
};

class MessagePage extends React.Component<void, TProps, TState> {
  render() {
    return (
      <MessagesWrap>
        <MessageBackground>
          <SubHeader />
        </MessageBackground>
        <Wrapper>
          <MessageList
            accountId={this.props.accountId}
            messages={this.props.messages}
            onMessagesLoadSuccess={this.props.onMessagesLoadSuccess}
          />
        </Wrapper>
      </MessagesWrap>
    );
  }
}

export default MessagePage;
