/**
 * @flow
 */

import createReducer from '../../createReducer';


const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_THEME_SUCCESS: (state, action) => {
        console.log('LOAD_THEME_SUCCESS')
        const data = action.payload
        let list = [];

        return {...state, error: null, isLoaded: true, data: data, list: list};
    }
}


export default createReducer(initialState, handlers);
