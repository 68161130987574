/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { mx } from '../../../../utils';


const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #171717;
  left: 0;
  right: 0;
  top: 4.5em;
  padding: 1em;
  ${mx('lg')} {
    flex-direction: row;
    align-items: center;
    position: static;
    background: none;
    padding: 0;
    }
  `;

type TProps = {
  onMenuItemClick: Function;
}

const Navbar = (props : TProps) => (
  <Menu>

  </Menu>
);

export default Navbar;
