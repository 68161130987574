/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, change} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';

import styled from 'styled-components';
import theme from '../../../theme/index';

import CustomWysiwyg from "../../../components/Typografy/CustomWysiwyg";
import FormFooter from "../../../components/FormFooter";


const Card = styled.div`
  border-radius: 20px;
  background-color: ${theme.userProfile.cardBackground};
  margin-bottom: 2em;
`;

const InfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;


class ManageHelpPageForm extends Component {

    render() {

        return (
            <Card>
                <Flex wrap>
                    <Box w={[1]}>
                        <InfoContainer>
                            <form autoComplete="off">
                                <div>
                                    <Field
                                        name="data"
                                        component={CustomWysiwyg}
                                    />
                                </div>
                            </form>
                        </InfoContainer>
                    </Box>
                    <Box w={[1]}>
                        <FormFooter to={`/admin/manage-help`} pristine={this.props.pristine}
                                    actionText={"Save"}
                                    onSave={() => this.props.updateHelp(this.props.helpForm)}/>
                    </Box>
                </Flex>
            </Card>
        );
    }
}

export default  connect(
    null, {change})
(reduxForm({
    form: 'HelpForm',
})(ManageHelpPageForm));
