/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import BenefitIcon from './components/BenefitIcon';
import theme from '../../../theme/index';
import Wrapper from '../../../components/Typografy/Wrapper';
import ArrowIcon from '../../../components/ArrowIcon';
import Link from '../../../components/Typografy/Link';


const Container = styled.div`
  background-image: url(${require('../../../assets/images/top-background.png').default});
  padding-top: 1.6em;
  padding-bottom: 1em;
`;

const BenefitEditHeader = styled.span`
  background-repeat: no-repeat;
  font-size: 1.4em;
  color: ${theme.subHeader.profileColor};
`;

const BackLink = styled(Link)`
   padding: 2em 0 2em 0;
`;
const BackLinkWrapper = styled.div`
   padding: 0 0 0.5em 0;
`;


type TProps = {
  hasBenefit: boolean;
};

class SubHeader extends React.Component<void, TProps, void> {
  render() {
    return (
      <Container >
        <Wrapper>
            <BackLinkWrapper>
                {this.props.cabinId && <BackLink to={(this.props.isDraft ? "/admin/cabins-draft/" : this.props.isArchive ? "/admin/cabins-archive/" : "/admin/cabins/") +  this.props.cabinId}>
                    <ArrowIcon />
                    Cabin
                </BackLink>}
                {!this.props.cabinId && <BackLink to={ this.props.isDraft ? "/admin/cabins-draft" : this.props.isArchive ? "/admin/cabins-archive" : "/admin/cabins"}>
                    <ArrowIcon />
                    Cabin
                </BackLink>}
            </BackLinkWrapper>
          <BenefitEditHeader>
            <BenefitIcon />
            Administrate Cabin
          </BenefitEditHeader>
        </Wrapper>
      </Container>
    );
  }
}
export default SubHeader;
