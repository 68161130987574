/**
 * @flow
 */
import { connect } from 'react-redux';

import AccountDeployManagement from './AccountDeployManagement';
import type { TAppState } from '../../../../common/TAppState';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {onAccountDeployConfigsLoadSuccess, triggerDeploy} from "../../../../common/deploy/actions";
import {getFormValues} from "redux-form";

const AccountDeployManagementContainer = connect(
  (state: TAppState, dispatch) => {
    return {
        accounts: state.accounts.data,
        config: state.deploy.data,
        accountDeployFormValues: getFormValues('AccountDeployForm')(state)
    };
  },
  (dispatch) => ({
      onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
      onAccountDeployConfigsSuccess: (data) => dispatch(onAccountDeployConfigsLoadSuccess(data)),
      triggerDeploy: (data) => dispatch(triggerDeploy(data)),
  })
)(AccountDeployManagement);

export default AccountDeployManagementContainer;
