/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
    buildBenefitsDraft,
    buildBenefits,
    buildBenefitsArchive
} from '../../../../common/config/firebase_config/endpoints';
import type {
    TBenefitApiData,
    TBenefitResponse,
} from '../../../../common/types/api/TBenefit';
import BenefitItem from './BenefitItem';
import Spinner from '../../../components/Spinner'

import { getBenefits } from '../components/Retrievers'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GrDrag, MdDragHandle } from "react-icons/all";
import { Button, SaveButton } from '../../../components/Buttons';

type TProps = {
    accountId: number;
    benefits: Array<TBenefitApiData>;
    onBenefitsLoadSuccess: (data: TBenefitResponse) => void;
    isArchive: boolean;
    isDraft: boolean;
};

const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
};

class BenefitList extends React.Component<void, TProps, void> {
    constructor(props) {
        super(props);

        this.state = {
            changeOrder: false,
            items: [],
        }

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    _getQueries = () => {
        let queries = [{
            endpoint: this.props.isArchive ? buildBenefitsArchive(this.props.accountId) : this.props.isDraft ? buildBenefitsDraft(this.props.accountId) : buildBenefits(this.props.accountId),
            method: 'on',
            eventType: 'value',
            callback: this.props.onBenefitsLoadSuccess,
            metadata: this.props.accountId,
            config: this.props.accounts
        },
        ]

        if (this.props.isDraft || this.props.isArchive) return queries;

        const account = this.props.accounts[this.props.accountId]
        if (account.vendors && account.vendors.length > 0) {
            account.vendors.forEach((vendor) => {
                if (vendor && vendor.type && vendor.type.value) {
                    queries.push({
                        endpoint: buildBenefits(vendor.type.value),
                        method: 'on',
                        eventType: 'value',
                        callback: this.props.onBenefitsLoadSuccess,
                        metadata: vendor.type.value,
                        config: this.props.accounts
                    })
                }
            })
        }


        return queries;
    };

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    _getBenefits = () => {
        const { benefits, benefitOrder = {} } = this.props;

        const orderMap = benefits.reduce((acc, benefit, index) => {
            acc[benefit.benefitId] = benefitOrder?.[benefit.benefitId] || benefits.length + index;
            return acc;
        }, {});

        const sortedBenefits = benefits.slice().sort((a, b) => {
            return orderMap[a.benefitId] - orderMap[b.benefitId];
        });

        return sortedBenefits
    }

    _getItems = () => {
        const { benefits, benefitOrder = {} } = this.props;

        const orderMap = benefits.reduce((acc, benefit, index) => {
            acc[benefit.benefitId] = benefitOrder?.[benefit.benefitId] || benefits.length + index;
            return acc;
        }, {});

        const sortedBenefits = benefits.slice().sort((a, b) => {
            return orderMap[a.benefitId] - orderMap[b.benefitId];
        });

        // Map over sorted benefits to render BenefitItems
        return sortedBenefits.map((benefit, i) => (
            <BenefitItem
                key={i}
                benefit={benefit}
                disabled={this.props.hiddenBenefits.includes(benefit.benefitId)}
                accountId={this.props.accountId}
                accounts={this.props.accounts}
                isArchive={this.props.isArchive}
                isDraft={this.props.isDraft}
                benefitViews={this.props.benefitStats[benefit.benefitId]}
            />
        ));
    };

    saveChanges() {
        const { originalOrder } = this.state;
        const { changeBenefitOrder } = this.props;
        const { items } = this.state;
    
        const currentOrderIds = items.map(item => item.benefitId);
    
        let lastChangedIndex = -1;
        originalOrder.forEach((id, index) => {
            if (id !== currentOrderIds[index]) {
                lastChangedIndex = index;
            }
        });
    
        for (let i = 0; i <= lastChangedIndex; i++) {
            const item = items[i];
            const newOrder = i + 1;
            if (originalOrder[i] !== item.benefitId) {
                console.log(`Updating order for ${item.benefitId} to ${newOrder}`);
                changeBenefitOrder(item.benefitId, newOrder);
            }
        }
    
        console.log('Updated orders up to index:', lastChangedIndex);
    }    

    render() {

        console.log("ORDER", this.props.benefitOrder);

        if (!this.props.accountId) {
            return (
                <div>
                    <h1>LASTER KONTO...</h1>
                </div>)
        }
        const { accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft } = this.props
        return (
            <FirebaseContainer queries={getBenefits(accounts, accountId, onBenefitsLoadSuccess, isArchive, isDraft, this.props.onLanguagesLoadSuccess)}>
                <div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        {this.state.changeOrder ?
                            <SaveButton onClick={() => {
                                this.setState({ changeOrder: false });
                                this.saveChanges();
                            }}>Save</SaveButton>
                            :
                            <SaveButton onClick={() => this.setState({ changeOrder: true, items: this._getBenefits(), originalOrder: this._getBenefits().map(b => b.benefitId) })}>Change order</SaveButton>
                        }
                    </div>
                    {this.props.benefits ?
                        (this.state.changeOrder ?
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {this.state.items.map((step, index, f) => {
                                                const item = step;
                                                return <Draggable draggableId={"item_" + item.benefitId} key={"item_" + item.benefitId} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", gap: 20 }}>
                                                                <GrDrag size={24}/>

                                                                <div style={{ width: "100%", pointerEvents: "none" }}>
                                                                    <BenefitItem
                                                                        key={index}
                                                                        benefit={item}
                                                                        disabled={this.props.hiddenBenefits.includes(item.benefitId)}
                                                                        accountId={this.props.accountId}
                                                                        accounts={this.props.accounts}
                                                                        isArchive={this.props.isArchive}
                                                                        isDraft={this.props.isDraft}
                                                                        benefitViews={this.props.benefitStats[item.benefitId]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            :
                            this._getItems())
                        :
                        this.props.isLoaded
                            ? <h3>No benefits found!</h3>
                            :
                            <Spinner />
                    }
                </div>
            </FirebaseContainer>
        );
    }
}

export default BenefitList;