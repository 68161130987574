/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import type { TMessageApiData } from '../../../../common/types/api/TMessage';


const MessagesCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;


const MessageInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const MessageTitle = styled.h2`
  font-size: 1.5em;
`;

const MessageText = styled.p`
  font-size: 1em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid ${theme.categories.categoryColor};
`;



type TProps = {
  message: TMessageApiData;
  onArchive: Function;
  accountId:number;
};




const MessageCard = (props: TProps) => {
  const { message, onArchive, accountId } = props;

  return (
    <MessagesCard>
      <Flex wrap>
        <Box w={[1]}>
          <MessageInfoContainer>
            <MessageTitle>{message.heading}</MessageTitle>
            <MessageText>
              {message.description}
            </MessageText>
          </MessageInfoContainer>
        </Box>
      </Flex>
    </MessagesCard>
  );
};

export default MessageCard;
