/**
 * @flow
 */

import createReducer from '../createReducer';


const initialState = {
  data: {},
  list: [],
  error: null,
  isLoaded: false,
};

const handlers = {
    LOAD_DIVISIONS_SUCCESS: (state, action) => {
        console.log('LOAD_DIVISIONS_SUCCESS')
        const data = action.payload
        const divisions = {};
        let list = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const division = {...data[key], divisionId: key}
                divisions[key] = division
                list.push(division);
            });

        }
        list.reverse()
        return {...state, error: null, isLoaded: true, data: divisions, list: list};
    },
}



export default createReducer(initialState, handlers);
