/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import WebSiteIcon from './components/WebSiteIcon';
import CallIcon from './components/CallIcon';
import MessageIcon from './components/MessageIcon';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {imageMedium} from '../../../../common/config/images/endpoints';
import {hasSuperAdminAccess} from '../../../../common/auth/index'
import CopyMenu from './components/CopyMenu';
import CustomCategoryMenu from './components/CustomCategoryMenu';
import Switch from 'react-toggle-switch'
import H3 from "../../../components/Typografy/H3";
import {mx} from "../../../utils";

const BenefitsCard = styled.div`
  border-radius: 20px;
  color: ${theme.benefits.textColor};
  
`;

const ImageContainer = styled.div`
  overflow: hidden;
  margin: 0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  background-color:white;
`;

const BenefitImage = styled.img`
  width: 100%;
  overflow:hidden;
`;


const BenefitInfoContainer = styled.div`
  padding: 1em;
  padding-bottom:0.2em;
  text-align:center;
  // background-color:#222222;
  // box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  margin:0.5vw;
  color: #222222;
  border-bottom: 1px solid #222222;
`;

const BenefitTitle = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  `;

const BenefitText = styled.p`
  font-size: 1em;
  font-style:italic;
  // border-bottom: 1px solid ${theme.categories.categoryColor};
`;


const LinkContainer = styled.div`
  text-align: right;
  margin-bottom: 2em;
`;


const GhostButton = styled.a`
  color: black;
  display:block;
  margin: 0.5vw;
  padding: 1em;
  text-align:left;
  background-color: white;
  text-decoration: none;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
  & * {
    color: black;
  }
`;

type TProps = {
  benefit: TBenefitApiData;
  onArchive: Function;
  accountId:number;
};

const BenefitCard = (props: TProps) => {
  const { benefit, profile, accountId, isArchive, accounts, isDraft, updateBenefit, updateDraftBenefit } = props;

  const _web = (url:string) => {
    return url.startsWith("http") ? url : 'http://' + url;
  }

  const isFromVendor = benefit.sourceAccount && (benefit.sourceAccount !== accountId)

  return (

    <BenefitsCard>
      <Flex wrap>
        <Box w={[1, 1]}>
            <BenefitInfoContainer>
                <BenefitTitle>{benefit.heading}</BenefitTitle>
                <BenefitText>
                    {benefit.description}
                </BenefitText>
            </BenefitInfoContainer>
        </Box>
        <Box w={[1, 3/12]}>
          <ImageContainer>
            <BenefitImage src={imageMedium(benefit.sourceAccount || accountId, benefit.benefitId)} alt="" />
          </ImageContainer>
        </Box>
        <Box w={[1, 9/12]}>

          <LinkContainer>
            {benefit.contactWebsite && <GhostButton href={_web(benefit.contactWebsite)}>
            <WebSiteIcon  />
              {benefit.contactWebsite}
            </GhostButton> }
            {benefit.contactPhone &&<GhostButton href={`tel:${benefit.contactPhone}`}>
              <CallIcon />
              {benefit.contactPhone}
            </GhostButton>}
            {benefit.contactEmail && <GhostButton href={`mailto:${benefit.contactEmail}`}>
            <MessageIcon />
              {benefit.contactEmail}
            </GhostButton>}
          </LinkContainer>
        </Box>
      </Flex>
    </BenefitsCard>
  );
};

export default BenefitCard;
