/**
 * @flow
 */

 import styled from 'styled-components';

 import bgShape from '../../assets/images/bg_shape.svg';

 const SelectAccountWrapper = styled.div`
 background-color: #141414;
 min-height: 100vh;
 padding: 3em 0;
 
`;


 export default SelectAccountWrapper;
