/**
 * @flow
 */


import { connect } from 'react-redux';
import MessageDetails from './MessageDetails';
import { onMessagesLoadSuccess } from '../../../../common/messages/actions';
import type { TAppState } from '../../../../common/TAppState';

const MessageDetailsContainer = connect(
 (state: TAppState) => {
   return {
     accountId: state.auth.profile.data.accountId,
     messages: state.messages.data  || [],
   };
 },
 (dispatch) => ({
   onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
 }),
)(MessageDetails);

export default MessageDetailsContainer;

