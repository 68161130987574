/**
 * @flow
 */

import React, {Component} from 'react';
import {
    Flex,
    Box,
} from 'reflexbox';
import {SaveButton} from '../../../../components/Buttons';
import {Card, CardTitle} from '../../../../components/Elements'

import Dropzone from '../../../../components/Dropzone';
import styled from 'styled-components';


import {NotificationManager} from "react-notifications";

import Text from '../../../../components/Typografy/Text'


const DropzonePlaceholder = styled.p`
  font-size: 1em;
  color: darkgray;
  text-align: center;
  margin: 60px;
`;

const BlackText = styled(Text)`
  color: darkgray;
  margin-bottom:20px;
`;

type TProps = {

}

type TState = {}

class ImageUploader extends Component<void, TProps, TState> {

    state: TState = {
        file: null,
        edit: false,
    };

    _onDrop = (acceptedFiles) => {
        const [file] = acceptedFiles;

        const setter = this
        const reader = new FileReader();
        reader.onload = () => {
            const fileAsString = reader.result;
            // do whatever you want with the file content
            setter.props.update(JSON.parse(fileAsString))
        };
        reader.onabort = () => { NotificationManager.error('Error!', 'file reading was aborted', 3000); console.log('file reading was aborted'); }
        reader.onerror = () => { NotificationManager.error('Error!', 'file reading failed', 3000); console.log('file reading failed'); }

        reader.readAsText(file);

    };




    render() {
        return (
            <Card wrap={true}>
                <Box w={[1]}>
                    <CardTitle>
                        Update google services file
                    </CardTitle>
                </Box>
                <Box w={[1]}>
                    <BlackText>
                        Update google services file by logging in to https://console.firebase.google.com/. At the top left, where it says "Project overview", press the cogwheel to the right of the text. In the context menu that now appears, press "Project settings".
                        You should now see several lists - look for "Your apps" and a button that says "Add app".
                    </BlackText>
                    <BlackText>
                         Make sure that you add all ANDROID apps that are active with Coveredpro. You can add for iOS too, but for now, the Android side is most important.
                        The apps should have the correct "App nickname" (App name), and most importantly, the precise correct "Package name".
                        This must be "com.coveredpro.apps.[company].[appid]" as entered in the account configuration.
                    </BlackText>
                    <BlackText>
                        Next, download google-services.json and upload it here.
                    </BlackText>
                </Box>
                <Box w={[1]} px={2}>
                            <Dropzone onDrop={this._onDrop} multiple={false}>
                                <DropzonePlaceholder>
                                    Drop google-services.json here or click to select from folder on your computer.
                                </DropzonePlaceholder>
                            </Dropzone>

                </Box>
            </Card>)
    }

}

export default ImageUploader

