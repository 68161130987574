/**
 * @flow
 */

import type { TCabinResponse } from '../../types/api/TCabin';



export const onCabinsLoadSuccess = (payload: TCabinResponse, metadata, config) => ({
    type: 'LOAD_CABINS_SUCCESS',
    payload,
    metadata,
    config
});

export const onCabinConfigSuccess = (payload) => ({
    type: 'LOAD_CABIN_CONFIG_SUCCESS',
    payload
})

export const onCabinsReset = () => ({
    type: 'RESET_CABINS_SUCCESS',

});
