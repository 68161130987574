/**
 * @flow
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';


import {Box, Flex,} from 'reflexbox';

import Wrapper from '../../../components/Typografy/Wrapper';

import {CardTitle, Container} from '../../../components/Elements'
import Select from "../../../components/Select";
import styled from "styled-components";
import theme from "../../../theme";
import {SaveButton} from "../../../components/Buttons";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import CustomFieldInput from '../../../components/Typografy/CustomFieldInput';

export const Card = styled(Flex)`
  margin-bottom: 2em;
  padding: 1.7em 1.8em 3em 1.8em;
  box-shadow: 0px 0px 13px 1px rgba(0,0,0,0.3);
  background: ${theme.userProfile.cardBackground};
  border-radius: 20px;
  width: 100%
`;


const getFieldValueFromForm = (base, subfield1, subfield2, formValues) => {

    const data = formValues || {}

    if (data[base]) {
        if (subfield1 && data[base][subfield1]) {
            if (subfield2 && data[base][subfield1][subfield2]) {
                return data[base][subfield1][subfield2]
            } else {
                return data[base][subfield1]
            }

        }
        return true
    }

    return false
}


const accountsOptions = (accounts, self) => {
    if (!accounts || accounts.length === 0) return []

    if (self && accounts[self]) {
        return [{
            label: accounts[self].name,
            value: self
        }]
    }

    let core = [{label: 'All active accounts', value: 'ALL'}]

    Object.keys(accounts)
        .filter((acccountId) => {
            return !(accounts[acccountId].disabled)
        })
        .forEach((acccountId) => core.push({
            label: accounts[acccountId].name,
            value: accounts[acccountId].accountId
        }));

    return core
}

const actionOptions = (actions) => {
    if (!actions) return []

    let core = []

    Object.keys(actions)
        .forEach((id) => core.push({
            label: actions[id],
            value: id
        }));

    return core
}

const platformOptions = (actions) => {
    if (!actions) return []

    let core = [{label: 'All platforms', value: 'ALL'}]

    Object.keys(actions)
        .forEach((id) => core.push({
            label: actions[id],
            value: id
        }));

    return core
}


const releaseOptions = (actions) => {
    if (!actions) return []

    let core = []

    Object.keys(actions)
        .forEach((id) => core.push({
            label: actions[id],
            value: id
        }));

    return core
}

const branchOptions = (actions) => {
    if (!actions) return []

    let core = [{label: 'All branches', value: 'ALL'}]

    Object.keys(actions)
        .forEach((id) => core.push({
            label: actions[id],
            value: id
        }));

    return core
}


const Accounts = ({accountId, accounts}) => {
    if (accountId && accounts && accounts[accountId]) {
        return null
    }


    return (<Box w={[1, 3 / 12]}>
        <div>
            <Field
                name={`accounts`}
                component={Select}
                options={accountsOptions(accounts)}
                label="Accounts"/>
        </div>
    </Box>);
}

const Branches = ({config, accountDeployFormValues}) => {
    if((accountDeployFormValues || {}).action === 'deploy') return null

    return (<Box w={[1, 3 / 12]}>
        <div>
            <Field
            name={`branches`}
            component={Select}
            options={branchOptions(config.branches)}
            label="Branches"/>
        </div>
    </Box>);
}

const Action = ({config}) => {
    return (<Box w={[1, 3 / 12]}>
        <div>
            <Field
                name={`action`}
                component={Select}
                options={actionOptions(config.actions)}
                label="Action"/>
        </div>
    </Box>);
}

const Platforms = ({config, accountDeployFormValues}) => {
    if((accountDeployFormValues || {}).action === 'publish') return null


    return (<Box w={[1, 3 / 12]}>
        <div>
            <Field
                name={`platforms`}
                component={Select}
                options={platformOptions(config.platforms)}
                label="Platforms"/>
        </div>
    </Box>);
}

const AndroidReleaseStatus = ({config, accountDeployFormValues}) => {
    if((accountDeployFormValues || {}).action === 'publish') return null
    if(!(((accountDeployFormValues || {}).platforms) || []).includes("android")) return null


    return (<Box w={[1, 3 / 12]}>
        <div>
            <Field
                name={`androidReleaseStatus`}
                component={Select}
                options={releaseOptions(config.androidReleaseStatus)}
                label="Android Release Status"/>
        </div>
    </Box>);
}

class AccountDeployForm extends Component<void, TProps, TState> {
    state: TState = {
        pristine: true,
    };

    forceUpdate = () => {
        this.setState({pristine: false})
    }
    getFieldValue = (base, subfield1, subfield2) => {
        return getFieldValueFromForm(base, subfield1, subfield2, this.props.accountDeployFormValues)
    }

    deploy() {
        let storableData = JSON.parse(JSON.stringify(this.props.accountDeployFormValues));
        const action = storableData.action

        let accounts = {}
        if (this.props.accountId && this.props.accounts && this.props.accounts[this.props.accountId]) {
            accounts[this.props.accountId] = this.props.accounts[this.props.accountId]
        } else if (storableData.accounts === 'ALL') {
            Object.keys(this.props.accounts).filter(key => {
                return !this.props.accounts[key].disabled
            }).forEach(key => {
                accounts[key] = this.props.accounts[key]
            })
        } else {
            accounts[storableData.accounts] = find(this.props.accounts || [], b => b.accountId === storableData.accounts);
        }

        let platforms = {}
        if (action === 'publish') {
            platforms = {undefined: 'undefined'}
        } else if (storableData.platforms === 'ALL') {
            platforms = this.props.config.platforms
        } else {
            platforms[storableData.platforms] = storableData.platforms
        }
        let androidReleaseStatus = undefined
        if (action === 'deploy' && platforms.android) {
            androidReleaseStatus = storableData.androidReleaseStatus
        }
        let branches = {}
        if (action === 'deploy') {
            const b = this.props.config.branches
            const bKeys = Object.keys(b)
            const topKey = bKeys.length > 0 ? bKeys.sort()[bKeys.length - 1] : 0
            branches[topKey] = b[topKey]
        }
        else if (storableData.branches === 'ALL') {
            branches = this.props.config.branches
        } else {
            branches[storableData.branches] = storableData.branches
        }

        this.props.triggerDeploy({
            accounts,
            platforms,
            action,
            branches,
            androidReleaseStatus
        })

    }

    //{this.props.hasAccount && (!this.getFieldValue('disabled')) && this.renderWithAccount()}
    render() {

        return (
            <div>
                <Container>
                    <Wrapper>
                        <Flex>
                            {this.renderAccountInfo()}
                        </Flex>
                    </Wrapper>
                </Container>
            </div>

        );
    }

    renderAccountInfo() {
        return <Card wrap={true}>
            <Box w={[1]}>
                <CardTitle>
                    Deploy {this.props.accountId && this.props.accounts && this.props.accounts[this.props.accountId] && this.props.accounts[this.props.accountId].name}
                </CardTitle>
            </Box>
            <Box w={[1]}>
                <form autoComplete="off">
                    <div>
                        <Flex
                            justify="start">
                            <Accounts {...this.props} />
                            <Action {...this.props} />
                            <Branches {...this.props} />
                            <Platforms {...this.props} />
                            <AndroidReleaseStatus {...this.props} />

                        </Flex>
                    </div>


                </form>
            </Box>
            <Box w={[1]}>
                <Flex justify="flex-end">
                    <SaveButton type="button" onClick={() => this.deploy()}> Add to queue </SaveButton>
                </Flex>
            </Box>

        </Card>;
    }

}

export default connect(
    null, {change})
(reduxForm({
    form: 'AccountDeployForm',
})(AccountDeployForm));
