/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/index';
import CoveredProIcon from '../../../../components/Typografy/CoveredProIcon';

const AccountIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  color: ${theme.icon.subHeaderIcon};
`;

const AccountIcon = () => (
  <AccountIconStyle>&#xe801;</AccountIconStyle>
);

export default AccountIcon;
