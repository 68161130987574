/**
 * @flow
 */

import React, {Component} from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import { Box, Flex } from 'reflexbox';
import {reduxForm, Field, submit} from "redux-form";
import { connect } from 'react-redux';
import CustomFieldDate from './components/CustomFieldDate';
import theme from "../../../theme";
import SelectInput from "./components/CustomFieldSelectPeriod";
import {SaveButton} from "../../../components/Buttons";
import {confirmAlert} from "react-confirm-alert";
import {
    addDaysToDate,
    enDate,
    getNumDays,
    isWeekendInRange,
    todayHtmlFormat
} from "../../../../common/utils/formatDate";

const BookingWrapper = styled.div`
  padding-bottom: 2em;
  `;

const FromToWrapper = styled(Flex)`
    border: 1px solid #aaa;
    border-radius:10px;
`;

const FormLabel = styled.span`
   padding-left: 0.8em;
   color: ${theme.userProfile.label};
   font-size: 0.9em;
`;

const DetailWrapper = styled.div`
    margin-top:10px;
`;

const DetailLabel = styled.div`
    font-size:.8em;
`;

const DetailValue = styled.div`
    font-size:1.1em;
`;

const options = (props) => {
    let options = [];
    props.cabin.advancedPricing && props.cabin.advancedPricing.periodbased.forEach((item, index) => {
        if (item.dateTo > todayHtmlFormat()) {
            options.push({
                label: item.title + ` ( ${enDate(new Date(item.dateFrom), true)} to ${enDate(new Date(item.dateTo), true)} )`,
                value: index
            })
        }
    });
    options.unshift({
        label: <em>Custom period</em>,
        value: 'custom'
    });
    return options;
}



class BookingModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {}
        }
    }


    formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    getFromToFields = () => {
        const {bookingFormValues, cabin} = this.props;

        return bookingFormValues && bookingFormValues.selectedPeriod && bookingFormValues.selectedPeriod.value === 'custom' ? (
            <div>
                <Flex>
                    <Box w={1/2}>
                        <FormLabel>Check-in</FormLabel>
                    </Box>
                    <Box w={1/2}>
                        <FormLabel>Check-out</FormLabel>
                    </Box>
                </Flex>
                <FromToWrapper>
                    <Box w={1/2}>
                        <Field
                            component={CustomFieldDate}
                            type={'date'}
                            name={'dateFrom'}
                            label={'Check-in'}
                            dateRange={{
                                max: bookingFormValues && bookingFormValues.dateTo ? bookingFormValues.dateTo : null,
                                min: todayHtmlFormat(),
                            }}
                        />
                    </Box>
                    <Box w={1/2}>
                        <Field
                            component={CustomFieldDate}
                            type={'date'}
                            name={'dateTo'}
                            label={'Check-out'}
                            borderLeft={true}
                            dateRange={{
                                min: bookingFormValues && bookingFormValues.dateFrom ? bookingFormValues.dateFrom : todayHtmlFormat(),
                            }}
                        />
                    </Box>
                </FromToWrapper>
            </div>
        ) : (bookingFormValues && bookingFormValues.selectedPeriod && !isNaN(bookingFormValues.selectedPeriod.value)) ? (
            <div>
                <Flex>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                From
                            </DetailLabel>
                            <DetailValue>
                                {enDate(new Date(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateFrom))}
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                To
                            </DetailLabel>
                            <DetailValue>
                                {enDate(new Date(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateTo))}
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                </Flex>
            </div>
        ) : null
    };

    calculatePricing = (dateFrom, dateTo) => {
        const { cabin } = this.props;
        let price = 0;
        let dateFromObj = new Date(dateFrom);
        let dateToObj = new Date(dateTo);

        let msBetween = dateToObj.getTime() - dateFromObj.getTime();
        let numDays = (msBetween / (1000*60*60*24)) + 1;

        let numWeeks = parseInt(numDays/7);

        if (numWeeks) {
            let restDays = numDays % 7;
            for (let i = 0; i < numWeeks; i++) {
                let startOffset = i * 7;
                let start = addDaysToDate(dateFromObj, startOffset);

                let checkDatebased = false;

                if (cabin && cabin.advancedPricing && cabin.advancedPricing.datebased && cabin.advancedPricing.datebased.length) {
                    cabin.advancedPricing.datebased.forEach((item) => {
                        if (item.dateFrom && item.dateTo) {
                            if ((start >= new Date(item.dateFrom)) && (start <= new Date(item.dateTo))) {
                                item.pricing.forEach((innerItem) => {
                                    if (innerItem.priceSpan === "Price per week") {
                                        price += parseInt(innerItem.price);
                                        checkDatebased = true;
                                        return true;
                                    }
                                })
                            }
                        }
                    })
                }

                if (!checkDatebased) {
                    price += parseInt(cabin.pricePerWeek);
                }
            }
            price += this.calculatePricing(addDaysToDate(dateTo, -restDays+1), dateTo);
        } else {
            let hasFri, hasSat, hasSun = false;
            let friday;
            let extras = {};
            for (let i = 0; i < numDays; i++) {
                let checkDate = addDaysToDate(dateFrom,i);
                let checkDay = checkDate.getDay();
                if (checkDay === 5) {
                    hasFri = true;
                    friday = checkDate;
                    extras[checkDay] = checkDate;
                }
                else if (checkDay === 6) {
                    hasSat = true;
                    extras[checkDay] = checkDate;
                }
                else if (checkDay === 0) {
                    hasSun = true;
                    extras[checkDay] = checkDate;
                }
                else {
                    extras[checkDay] = checkDate;
                }
            }
            if (hasFri && hasSat && hasSun) {
                extras[5] = undefined;
                extras[6] = undefined;
                extras[0] = undefined;
                let checkDatebased = false;
                if (cabin && cabin.advancedPricing && cabin.advancedPricing.datebased && cabin.advancedPricing.datebased.length) {
                    cabin.advancedPricing.datebased.forEach((item) => {
                        if (item.dateFrom && item.dateTo) {
                            if ((friday >= new Date(item.dateFrom)) && (friday <= new Date(item.dateTo))) {
                                item.pricing.forEach((innerItem) => {
                                    if (innerItem.priceSpan === "Price per weekend") {
                                        price += parseInt(innerItem.price);
                                        checkDatebased = true;
                                        return true;
                                    }
                                })
                            }
                        }
                    })
                }
                if (!checkDatebased) {
                    price += parseInt(cabin.pricePerWeekend);
                }
            }
            Object.keys(extras).forEach((key) => {
                let checkDate = extras[key];
                if (checkDate === undefined) return;
                let checkDatebased = false;
                if (cabin && cabin.advancedPricing && cabin.advancedPricing.datebased && cabin.advancedPricing.datebased.length) {
                    cabin.advancedPricing.datebased.forEach((item) => {
                        if (item.dateFrom && item.dateTo) {
                            if ((checkDate >= new Date(item.dateFrom)) && (checkDate <= new Date(item.dateTo))) {
                                item.pricing.forEach((innerItem) => {
                                    if (innerItem.priceSpan === "Price per day") {
                                        price += parseInt(innerItem.price);
                                        checkDatebased = true;
                                        return true;
                                    }
                                })
                            }
                        }
                    })
                }
                if (!checkDatebased) {
                    price += parseInt(cabin.pricePerDay);
                }
            })
        }
        return price;
    }

    getPrice = () => {
        const { bookingFormValues, cabin } = this.props;
        if (bookingFormValues && bookingFormValues.selectedPeriod && !isNaN(bookingFormValues.selectedPeriod.value)) {
            return (
                <Flex>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                Price ( Estimated )
                            </DetailLabel>
                            <DetailValue>
                                {this.formatPrice(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].price)} kr
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                    <Box w={1/2}>
                        <DetailWrapper>
                            <DetailLabel>
                                No. days
                            </DetailLabel>
                            <DetailValue>
                                {getNumDays(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateFrom, cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateTo)} day(s).
                            </DetailValue>
                        </DetailWrapper>
                    </Box>
                </Flex>

            )
        } else if (bookingFormValues && bookingFormValues.selectedPeriod && isNaN(bookingFormValues.selectedPeriod.value)) {
            if (bookingFormValues.dateFrom && bookingFormValues.dateTo) {
                return (
                    <Flex>
                        <Box w={1/2}>
                            <DetailWrapper>
                                <DetailLabel>
                                    Price ( Estimated )
                                </DetailLabel>
                                <DetailValue>
                                    {this.calculatePricing(bookingFormValues.dateFrom, bookingFormValues.dateTo)} kr.
                                </DetailValue>
                            </DetailWrapper>
                        </Box>
                        <Box w={1/2}>
                            <DetailWrapper>
                                <DetailLabel>
                                    No. days
                                </DetailLabel>
                                <DetailValue>
                                    {getNumDays(bookingFormValues.dateFrom, bookingFormValues.dateTo)} day(s).
                                </DetailValue>
                            </DetailWrapper>
                        </Box>
                    </Flex>
                )
            } else {
                return (
                    <DetailWrapper>
                        <DetailLabel>
                            Price ( Estimated )
                        </DetailLabel>
                        <DetailValue>
                            Please specify dates
                        </DetailValue>
                    </DetailWrapper>
                )
            }
        }
    };

    handleSubmit = (data) => {
        const { bookingFormValues, cabin } = this.props;
        console.log("Submitting with data", data);

        if (cabin.useContactEmailBooking && cabin.contact && cabin.contact.email) {

            let dateString = "Dato fra til";
            let contactString = `Kontakt meg:`;
            let priceString = `Kalkulert pris: `;


            if (this.props.profile) {
                contactString = `Kontakt meg: ${this.props.profile.firstName} ${this.props.profile.lastName}, ${this.props.profile.email}`;
            }

            if (bookingFormValues && bookingFormValues.selectedPeriod && bookingFormValues.selectedPeriod.value === 'custom') {
                dateString = `Dato fra ${enDate(new Date(bookingFormValues.dateFrom))} til ${enDate(new Date(bookingFormValues.dateTo))}`;
                priceString = `Kalkulert pris: ${this.calculatePricing(bookingFormValues.dateFrom, bookingFormValues.dateTo)}`;
            } else if (bookingFormValues && bookingFormValues.selectedPeriod && !isNaN(bookingFormValues.selectedPeriod.value)) {
                dateString = `Dato fra ${enDate(new Date(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateFrom))} til ${enDate(new Date(cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateTo))}`;
                priceString = `Kalkulert pris: ${cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].price}`;
            }

            window.open(
                `mailto:${cabin.contact.email}?subject=[Hyttebooking] Forespør leie&body=Hei, jeg ønsker å forespørre leie på ${cabin.title}.%0D%0A${dateString}%0D%0A${contactString}%0D%0A${priceString}`,
                '_blank'
            )
        } else {
            window.open(
                cabin.customBookingUrl,
                '_blank'
            )
        }
        //
        // let from, to, price;
        // if (bookingFormValues && bookingFormValues.selectedPeriod && bookingFormValues.selectedPeriod.value === 'custom') {
        //     from = bookingFormValues.dateFrom;
        //     to = bookingFormValues.dateTo;
        //     price = this.calculatePricing(bookingFormValues.dateFrom, bookingFormValues.dateTo)
        //
        // } else if (bookingFormValues && bookingFormValues.selectedPeriod && !isNaN(bookingFormValues.selectedPeriod.value)) {
        //     from = cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateFrom;
        //     to = cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].dateTo;
        //     price = cabin.advancedPricing.periodbased[bookingFormValues.selectedPeriod.value].price;
        // }
        // console.log("=== SUBMIT FROM", from);
        // console.log("=== SUBMIT TO", to);
        // let toSubmit = {
        //     start_date: from,
        //     end_date: to,
        //     status: 1,
        //     priceOnBooking: price,
        //     user_email: this.props.profile.email,
        //     user_name: this.props.profile.lastName + ", " + this.props.profile.firstName,
        // }
        // this.props.requestBooking(toSubmit, cabin.cabinId);
    }

    confirmBooking = () => {
        confirmAlert({
            title: 'Confirm booking request',
            message: 'Are you sure you want to book?',
            buttons: [{
                label: 'Confirm',
                // onClick: () => this.handleSubmit(this.props.bookingFormValues)
                onClick: this.props.handleSubmit(this.handleSubmit)
            },
                {
                    label: 'Cancel'
                }
            ]
        })
    };

    render() {
        const {cabin, bookingFormValues} = this.props;
        console.log("BOOKING VALS", this.props.bookingFormValues);
        console.log("PROPS", cabin)

        if(!cabin)
        {
            return(<div></div>)
        }
        return (
            <BookingWrapper>
                <form>
                    <Wrapper>
                        <Field
                            name="selectedPeriod"
                            options={options(this.props)}
                            component={SelectInput}
                            label="Select period"
                        />
                        {this.getFromToFields()}
                        {this.getPrice()}
                        {/*<SaveButton disabled type={'button'} style={{width: '100%', marginTop: 15}} onClick={() => this.confirmBooking()}>Request Booking</SaveButton>*/}
                        <SaveButton disabled={this.props.pristine || this.props.invalid} type={'button'} style={{width: '100%', marginTop: 15}} onClick={() => this.confirmBooking()}>Request Booking</SaveButton>
                    </Wrapper>
                </form>
            </BookingWrapper>
        );
    }
}

const validate = (values, props) => {
    let errors = {}

    console.log("--Validate Props", props);

    if (!values.selectedPeriod) {
        errors.selectedPeriod = "Please select an option";
        return errors;
    }

    if (values.selectedPeriod && isNaN(values.selectedPeriod.value)) {
        if (!values.dateFrom) {
            errors.dateFrom = "Required";
        }
        if (!values.dateTo) {
            errors.dateTo = "Required";
        }

        // If any error till this point, return;
        if (errors.length) return;

        props.cabin && props.cabin.advancedPricing && props.cabin.advancedPricing.periodbased && props.cabin.advancedPricing.periodbased.forEach((checkPeriod) => {
            if (checkPeriod.dateFrom && checkPeriod.dateTo) {
                if ((values.dateFrom <= checkPeriod.dateTo) && (values.dateTo >= checkPeriod.dateFrom)) {
                    const collisionTitle = checkPeriod.title ? checkPeriod.title : '';
                    errors.dateTo = "Date is overlapping: " + collisionTitle;
                    errors.dateFrom = "Date is overlapping: " + collisionTitle;
                }
            }
        })
    }
    return errors
}

BookingModule = reduxForm({
    form: 'CabinBookingForm',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(BookingModule);

export default connect()(BookingModule);
