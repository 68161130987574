import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {
    Flex,
    Box,
} from 'reflexbox';
import CustomFieldInput from "../../../../components/Typografy/CustomFieldInput";
import {SaveButton} from '../../../../components/Buttons';
import RemoveField from '../../../../components/RemoveField';
import Translate from '../../../../components/form/Translate';
import ImageUploaderModal from '../../../../components/form/ImageUploaderModal';

import {buildCategoryImageUpload, readCategoryImage} from '../../../../../common/config/firebase_config/endpoints'
import CustomCategories from "./CustomCategories";
import SingleValueDivisions from "./SingleValueDivisions";
import MultiValueDivision from "./MultiValueDivision";

class Divisions extends Component<void, TProps, TState> {

    getLabel = (index) => {

        const data = this.props.accountFormValues || {}
        const division = (data.divisions && data.divisions.multiValue && data.divisions.multiValue.length > index) ? data.divisions.multiValue[index] : null

        return division ? division.name : ''

    }




    render() {

        const {fields, meta: {touched, error}} = this.props

        return (
            <div>
                {fields.map((step, index) =>
                    <div key={index}>
                        <h4>Division #{index + 1}</h4>
                        <Flex
                            justify="space-between">
                            <Box w={[1, 7 / 12]}>
                                <div>
                                    <Field
                                        name={`${step}.name`}
                                        component={CustomFieldInput}
                                        type="text"
                                        userCard
                                        placeholder="Division name"
                                        label="Name"/>
                                    <FieldArray name={`${step}.values`} accountId={this.props.accountId} accounts={this.props.accounts} accountFormValues={this.props.accountFormValues} component={MultiValueDivision}/>
                                </div>
                            </Box>
                            <Box w={[1, 2 / 12]} mx={2}>
                                <Translate formValues={this.props.accountFormValues} accountId={this.props.accountId} parentField='name' label="Division" field={step}/>
                            </Box>
                            <Box w={[1, 2 / 12]} mx={2}>
                                <RemoveField onClick={() => fields.remove(index)}/>
                            </Box>
                        </Flex>
                    </div>
                )}
                <Flex justify="flex-end">
                    <SaveButton onClick={(event) => {
                        if (event) event.preventDefault();
                        fields.push({name: ''});
                    }}> Add division </SaveButton>
                </Flex>

            </div>
        );
    }
}
export default Divisions
