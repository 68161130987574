/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import ResponsiveImage from './Typografy/ResponsiveImage';
import Wrapper from './Typografy/Wrapper';
import SmallText from './Typografy/SmallText';
import { mx } from '../utils';
import theme from '../theme';
import FooterLogo from "../assets/images/footer-logo.png";

const FooterWrapper = styled.div`
  background-color: #101010;
  padding-top: 2em;
  padding-bottom: 2em;
`;

const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
`;
const ImageContainer = styled.div`
   flex: 1;
   display: none;
   ${mx('lg')} {
    display: flex;
   }
`;

const FooterCopyright = styled(SmallText)`
  color: ${theme.footer.textColor};
`;

const FooterLinks = styled.a`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const FooterLink = styled.a`
  text-decoration: none;
  color: white;
  opacity: 0.6;
  
  transition: all .2s;
  
  &:hover {
    opacity: 0.5;
  }
  
  &:active {
    opacity: 0.3;
  }
`

const Footer = () => (
  <FooterWrapper>
    <Wrapper>
      <FooterFlex>
          <img
            src={FooterLogo}
            style={{
                marginRight: "auto",
                marginLeft: 10,
                width: 109,
                height: 33,
            }}
            alt={""}
          />
          <FooterLinks>
              <FooterLink href={"/terms/no"}>Terms Of Service</FooterLink>
              <FooterLink href={"/privacy/no"}>Privacy Policy</FooterLink>
          </FooterLinks>
        <FooterCopyright>
            © {new Date().getFullYear()} CoveredPro. All Rights Reserved
        </FooterCopyright>
      </FooterFlex>
    </Wrapper>
  </FooterWrapper>
);


export default Footer;
