/**
 * @flow
 */

import React, { useState } from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import WebSiteIcon from './components/WebSiteIcon';
import CallIcon from './components/CallIcon';
import MessageIcon from './components/MessageIcon';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import Link from '../../../components/Typografy/Link';
import RawLink from '../../../components/Typografy/RawLink';
import BaseButton from '../../../components/Typografy/BaseButton';
import { Button, PrimaryButton, PublishButton } from "../../../components/Buttons";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {imageMain, imageMedium} from '../../../../common/config/images/endpoints';
import {hasSuperAdminAccess} from '../../../../common/auth/index'
import CopyMenu from './components/CopyMenu';
import CustomCategoryMenu from './components/CustomCategoryMenu';
import Switch from 'react-toggle-switch'
import Modal from "../../../components/Modal";
import { Field } from "redux-form";

const BenefitsCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;

const ImageContainer = styled.div`
  padding-right: 1.5em;
  overflow: hidden;
  margin: 0.5vw;
`;

const BenefitImage = styled.img`
  width: 100%;
`;


const BenefitInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;

const BenefitTitle = styled.h2`
  font-size: 1.5em;
`;

const BenefitText = styled.p`
  font-size: 1em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid ${theme.categories.categoryColor};
`;


const LinkContainer = styled.div`
  text-align: right;
  margin-bottom: 2em;
  padding-right: 2.5em;
`;




const EditBenefitButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  border:2px solid ${theme.subHeader.buttonSecondColor};
  margin-right: 1.5em;
  &:hover {
    text-decoration: none;
    background-color:${theme.subHeader.buttonSecondColor};
  }
`;

const ArchiveBenefitButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const RegisterUseButton = styled(Link)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: #9090ff;
  border: none;
  &:hover {
    text-decoration: none;
    cursor:pointer;
  }
`;

const OCSButton = styled.a`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: #9090ff;
  border: none;
  &:hover {
    text-decoration: none;
    cursor:pointer;
  }
`;

const SourceText = styled.div`
  font-size: 0.8em;
  font-style: italic;
  padding-right: 1.0em;
  margin-top:-2.8em;
  margin-bottom:1em;
  color: ${theme.categories.categoryColor};
`;

const ToggleLabel = styled.span`
  padding-left:10px;
  padding-right:10px;
  vertical-align:super;
  font-size: 20px;
`;

const ToggleWrapper = styled.div`
  text-align:right;
  margin-right:20%;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  margin: none;
  width: 10ch;
`

type TProps = {
  benefit: TBenefitApiData;
  onArchive: Function;
  accountId: number;
};

const archive = (props: TProps) => {
  confirmAlert({
    title: 'Confirm to archive',
    message: 'Are you sure you want to archive this benefit?',
    buttons: [{
      label: 'Confirm',
      onClick: () => props.onArchive(props.accountId, props.benefit)
    },
    {
      label: 'Cancel'
    }
    ]
  })
};


const unArchive = (props: TProps) => {
  confirmAlert({
    title: 'Confirm to unarchive',                        // Title dialog
    message: 'Are you sure you want to unarchive this benefit?',               // Message dialog
    buttons: [{
      label: 'Confirm',
      onClick: () => props.onArchive(props.accountId, props.benefit)
    },
    {
      label: 'Cancel'
    }
    ]
  })
};

const publish = (props: TProps) => {
  confirmAlert({
    title: 'Confirm to publish',
    message: 'Are you sure you want to publish this benefit? It will become visible in the app',
    buttons: [{
      label: 'Confirm',
      onClick: () => props.onArchive(props.accountId, props.benefit)
    },
    {
      label: 'Cancel'
    }
    ]
  })
};

const disable = (props: TProps) => {
  confirmAlert({
    title: 'Confirm to disable',
    message: 'Are you sure you want to disable this benefit? It will become invisible in the app',
    buttons: [{
      label: 'Confirm',
      onClick: () => props.onArchive(props.accountId, props.benefit)
    },
    {
      label: 'Cancel'
    }
    ]
  })
};

const BenefitCard = (props: TProps) => {
  const { benefit, profile, accountId, isArchive, accounts, isDraft, updateBenefit, benefitOrder, updateDraftBenefit, changeVendorBenefitVisibility, changeBenefitOrder } = props;

  const [order, setOrder] = useState(benefitOrder?.[benefit.benefitId] ?? "");

  const account = accounts[accountId];

  const _web = (url: string) => {
    return url.startsWith("http") ? url : 'http://' + url;
  }

  console.log("profile:");
  console.log(profile);
  const getEmailLink = (benefit) => {
    let body = benefit.ocsBody.split("\n").join("%0A");
    body = body
      .split("%firstName%").join(profile.firstName)
      .split("%lastName%").join(profile.lastName)
      .split("%fullName%").join(profile.firstName + " " + profile.lastName)
      .split("%email%").join(profile.email)
      .split("%company%").join(accounts[accountId].companyNameOnCard);
    return "mailto:" + benefit.ocsRecipient + "?subject=" + benefit.ocsSubject + "&body=" + body;
  }

  const isFromVendor = benefit.sourceAccount && (benefit.sourceAccount !== accountId)

  return (

    <BenefitsCard>
      {!isFromVendor && isDraft && !isArchive && <ToggleWrapper><ToggleLabel>Benefit is not published</ToggleLabel><Switch onClick={() => updateBenefit(benefit, benefit, true)} on={!isDraft} /></ToggleWrapper>}
      {!isFromVendor && !isDraft && !isArchive && <ToggleWrapper><ToggleLabel>Benefit is published</ToggleLabel><Switch onClick={() => updateDraftBenefit(benefit, benefit, true)} on={!isDraft} /></ToggleWrapper>}
      <Flex wrap>
        <Box w={[1, 3 / 12]}>
          <ImageContainer>
            <BenefitImage src={imageMain(benefit.sourceAccount || accountId, benefit.benefitId) + '&' + new Date().getTime()} alt="" />
          </ImageContainer>
        </Box>
        <Box w={[1, 7 / 12]}>
          <BenefitInfoContainer>
            <BenefitTitle>{benefit.heading}</BenefitTitle>
            <BenefitText>
              {benefit.description}
            </BenefitText>
          </BenefitInfoContainer>
          {/* <BenefitInfoContainer style={{display: "flex", flexDirection: "column", gap: 10}}>
            <strong>Order:</strong>
            <div style={{display: "flex", gap: 10, alignItems: "center"}}>
              <Input
                type="number"
                value={order}
                onChange={e => setOrder(parseInt(e.target.value))}
              />
              <PrimaryButton
                onClick={() => {
                  if (order === "" || isNaN(order)) {
                    changeBenefitOrder(benefit.benefitId, null);
                  } else {
                    changeBenefitOrder(benefit.benefitId, +order)
                  }
                }}
                style={{margin: 0}}
              >
                Save
              </PrimaryButton>
            </div>
          </BenefitInfoContainer> */}
          <LinkContainer>
            {isFromVendor && <Flex justify="flex-end">
              <SourceText>Source: {accounts[benefit.sourceAccount].customerName}</SourceText>
            </Flex>}
            <Flex wrap align="center" justify="flex-end">
              {isFromVendor && <ToggleWrapper><ToggleLabel>Hidden</ToggleLabel><Switch onClick={() => changeVendorBenefitVisibility(benefit.benefitId, !props.hiddenStatus)} on={props.hiddenStatus} /></ToggleWrapper>}
              {benefit.useOneClickSignup && <OCSButton href={getEmailLink(benefit)}>
                Signup for benefit
              </OCSButton>}
              {account.customModules && account.customModules.benefitReport && account.customModules.benefitReport.active && <RegisterUseButton to={{ pathname: '/admin/benefit-report/new', fromBenefitData: benefit }}>
                Register use
              </RegisterUseButton>}
              {isFromVendor && <CustomCategoryMenu {...props} />}
              {!isFromVendor && !isDraft && !isArchive && <EditBenefitButton to={'/admin/benefits/' + benefit.benefitId + '/edit'}>
                Edit
              </EditBenefitButton>}
              {!isFromVendor && isDraft && !isArchive && <EditBenefitButton to={'/admin/benefits/' + benefit.benefitId + '/edit-draft'}>
                Edit
              </EditBenefitButton>}
              {!isFromVendor && (isArchive ?
                <ArchiveBenefitButton onClick={() => unArchive(props)}>
                  Unarchive
                </ArchiveBenefitButton> :
                <ArchiveBenefitButton onClick={() => archive(props)}>
                  Archive
                </ArchiveBenefitButton>)}
              {!isFromVendor && hasSuperAdminAccess(profile) && <CopyMenu {...props} />}
            </Flex>
            {benefit.contactWebsite && <RawLink href={_web(benefit.contactWebsite)}>
              <WebSiteIcon />
              Website
            </RawLink>}
            {benefit.contactPhone && <RawLink href={`tel:${benefit.contactPhone}`}>
              <CallIcon />
              Call  ({benefit.contactPhone})
            </RawLink>}
            {benefit.contactEmail && <RawLink href={`mailto:${benefit.contactEmail}`}>
              <MessageIcon />
              Email
            </RawLink>}
          </LinkContainer>
        </Box>
      </Flex>
    </BenefitsCard>
  );
};

export default BenefitCard;
