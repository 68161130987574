/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import { buildDomains } from '../../../../common/config/firebase_config/endpoints';
import type {
  TDomainApiData,
  TDomainResponse,
} from '../../../../common/types/api/TDomain';
import DomainItem from './DomainItem';

import Spinner from '../../../components/Spinner'

type TProps = {
  accountId: number;
  domain: Array<TDomainApiData>;
  onDomainsLoadSuccess: (data: TDomainResponse) => void;
};

class DomainsList extends React.Component<void, TProps, void> {
  _getQueries = () => {
    return ([{
      endpoint: buildDomains(),
      method: 'on',
      eventType: 'value',
      callback: this.props.onDomainsLoadSuccess,
    },
    ]);
  };
  _getItems = () => {
    return this.props.domain.filter(domain => (domain.account === this.props.accountId) || domain.accounts && Object.keys(domain.accounts).includes(this.props.accountId)).map((domain, i) => {
      console.log("listing " + JSON.stringify(domain))
      return <DomainItem key={i} domain={domain} accountId={this.props.accountId} removeFromDomain={this.props.removeFromDomain} />;
    });
  };
  render() {
    return (
      <FirebaseContainer queries={this._getQueries()}>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          { this.props.domain ? this._getItems() : <Spinner/> }
        </div>
      </FirebaseContainer>
    );
  }
}
export default DomainsList;
