

/**
 * @flow
 */


import { connect } from 'react-redux';
import LandingPage from './LandingPage';
import { getFormValues } from 'redux-form';

const LandingPageContainer = connect(
    (state) => {
        return {
            tbClubFormValues: getFormValues('LandingPageForm')(state),
        };
    },
    (dispatch) => ({

}),
)(LandingPage);
export default LandingPageContainer;
