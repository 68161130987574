
import React from 'react'
import CanvasBuilder from '../../../../components/form/CanvasBuilder'

import {imageAccountFeatureGraphicTemplate} from '../../../../../common/config/images/endpoints'
import {readAccountFeatureGraphic, buildAccountFeatureGraphic, readAccountLogo} from '../../../../../common/config/firebase_config/endpoints'

const SplashBuilder = (props) => {

    return(

        <CanvasBuilder {...props} field="featureGraphic"
                       baseUrl={imageAccountFeatureGraphicTemplate()}
                       overlayUrl={readAccountLogo(props.accountId)}
                       formValues={props.accountFormValues}
                       label={"Feature Graphic"}
                       showUrl={readAccountFeatureGraphic(props.accountId)}
                       writeUrl={buildAccountFeatureGraphic(props.accountId)} baseScale={0.3}
 />
    )

}

export default SplashBuilder
