/**
 * @flow
 */

import React, {Component} from 'react';
import styled from 'styled-components';
import Wrapper from '../../../components/Typografy/Wrapper';
import theme from '../../../theme/index';
import H3 from '../../../components/Typografy/H3';
import SmallText from '../../../components/Typografy/SmallText';
import {mx} from '../../../utils';
import type {TBenefitApiData} from '../../../../common/types/api/TBenefit';
import { Flex, Box } from 'reflexbox';
import BookingModule from "./BookingModule";
import MapLocation from "./MapLocation";

const Details = styled.div`
  background-color:white;
  margin: 0.5vw;
  box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);

 ${mx('lg')} {
   display: flex;
   align-items: center;
   justify-content: space-between;
 }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.theme.benefitDetails ? props.theme.benefitDetails.backgroundColor : '#222222'};
  color: white;
  padding: 1em;
  `;

const BookingHeader = styled(Header)`
    cursor:pointer;
    background-color: green;
`;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 1.5em;
  //text-transform: uppercase;
  `;

const Text = styled(SmallText)`
  font-size: 1.1em;
  color: ${theme.benefits.titleColor};
  height: auto;
  padding:1em;
  `;

const CabinDescriptionHeadline = styled.div`
  font-size: 1.4em;
  font-weight: bold;
`;

const AboutWrapper = styled.div`
  background-color: ${theme.benefits.backgroundColor}
  padding-bottom: 2em;
  `;

const DescriptionPart = styled.div`
  display:flex;
  flex-direction: column;
  ${mx('lg')} {
    display: flex;
    width: 100%;
  }
  `;

const DetailWrapper = styled.div`
    margin-top:10px;
`;

const DetailLabel = styled.div`
    font-size:.8em;
`;

const DetailValue = styled.div`
    font-size:1.1em;
`;

const ExpandArrow = styled.div`
    padding-right:15px;
`;

const MapWrapper = styled.div`
    height:300px;
`;

class CabinAbout extends Component {

    formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    render() {
        const {cabin, theme} = this.props;

        if(!cabin.description)
        {
            return(<div></div>)
        }
        return (
            <AboutWrapper>
                <Wrapper>
                    <Details>
                        <DescriptionPart>
                            <Header theme={theme}>
                                <Title> Location </Title>
                            </Header>
                            <MapWrapper>
                                <MapLocation cabin={cabin} />
                            </MapWrapper>
                        </DescriptionPart>

                    </Details>
                    <Details>
                        <DescriptionPart>
                            <Header theme={theme}>
                                <Title> Contact and booking </Title>
                            </Header>
                            <Text>
                                <Flex>
                                    <Box w={[1, 1/2]} p={1}>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Contact person
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.contact && cabin.contact.fullName || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Phone
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.contact && cabin.contact.phone || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Email
                                            </DetailLabel>
                                            <DetailValue>
                                                <a href={`mailto:${cabin.contact && cabin.contact.email}`}>{cabin.contact && cabin.contact.email || 'n/a'}</a>
                                            </DetailValue>
                                        </DetailWrapper>
                                    </Box>
                                    <Box w={[1, 1/2]} p={1}>
                                        <DetailWrapper>
                                            <BookingModule initialValues={{
                                                selectedPeriod: {
                                                    value:'custom',
                                                    label:'Custom Period'
                                                }
                                            }} {...this.props} />
                                        </DetailWrapper>
                                    </Box>
                                </Flex>
                            </Text>
                        </DescriptionPart>

                    </Details>
                    <Details>
                        <DescriptionPart>
                            <Header theme={theme}>
                                <Title> Details </Title>
                            </Header>
                            <Text>
                                <Flex>
                                    <Box w={[1, 1/2]} p={1}>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Bedrooms
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.bedrooms || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Beds
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.beds || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Bathrooms
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.bathrooms || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Address
                                            </DetailLabel>
                                            <DetailValue>
                                                {cabin.location.address || 'n/a'}
                                            </DetailValue>
                                        </DetailWrapper>
                                    </Box>
                                    <Box w={[1, 1/2]} p={1}>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Price per week {cabin.advancedPricing && '- May vary'}
                                            </DetailLabel>
                                            <DetailValue>
                                                {this.formatPrice(cabin.pricePerWeek)} kr
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Price per weekend {cabin.advancedPricing && '- May vary'}
                                            </DetailLabel>
                                            <DetailValue>
                                                {this.formatPrice(cabin.pricePerWeekend)} kr
                                            </DetailValue>
                                        </DetailWrapper>
                                        <DetailWrapper>
                                            <DetailLabel>
                                                Price per day {cabin.advancedPricing && '- May vary'}
                                            </DetailLabel>
                                            <DetailValue>
                                                {this.formatPrice(cabin.pricePerDay)} kr
                                            </DetailValue>
                                        </DetailWrapper>
                                    </Box>
                                </Flex>
                            </Text>
                        </DescriptionPart>

                    </Details>

                    <Details>
                        <DescriptionPart>
                            <Header theme={theme}>
                                <Title> Cabin description </Title>
                            </Header>
                            <Text>
                                {cabin.description.split('\n').map((item, key) => {
                                    if(item.startsWith('##')){
                                        return <span key={key}><CabinDescriptionHeadline>{item.substr(2)}</CabinDescriptionHeadline><br/></span>
                                    }
                                    return <span key={key}>{item}<br/></span>
                                })
                                }
                            </Text>
                        </DescriptionPart>

                    </Details>
                    <Flex>
                        {cabin.facilities && cabin.facilities.length > 0 && <Box w={[1, 1/2]}>
                            <Details>
                                <DescriptionPart>
                                    <Header theme={theme}>
                                        <Title> Cabin facilities </Title>
                                    </Header>
                                    <Text>
                                        <ul>
                                            {cabin.facilities.map((facility) => {
                                                return facility && <li key={facility}>{facility}</li>
                                            })}
                                        </ul>
                                    </Text>
                                </DescriptionPart>

                            </Details>
                        </Box>}
                        {cabin.nearby && cabin.nearby.length > 0 && <Box w={[1, 1/2]}>
                            <Details>
                                <DescriptionPart>
                                    <Header theme={theme}>
                                        <Title> Nearby </Title>
                                    </Header>
                                    <Text>
                                        <ul>
                                            {cabin.nearby.map((facility) => {
                                                return facility && <li key={facility}>{facility}</li>
                                            })}
                                        </ul>
                                    </Text>
                                </DescriptionPart>

                            </Details>
                        </Box>}
                    </Flex>
                </Wrapper>
            </AboutWrapper>
        );
    }
}

export default CabinAbout;
