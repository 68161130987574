/**
 * @flow
 */

import type { TSignUpState } from '../TAuthState';
import createReducer from '../../createReducer';
import type { THandler } from '../../types/THandler';

const initialState: TSignUpState = {
  error: null,
  isLoading: false,
  success:false,
};

const handlers: THandler<TSignUpState> = {
  AUTH0_SIGN_UP_REQUEST: (state) => {
    return { ...state, error: null, isLoading: true, success:false };
  },
  AUTH0_SIGN_UP_FAILURE: (state, action) => {
    return { ...state, error: action.payload, isLoading: false, success:false };
  },
  AUTH0_SIGN_UP_SUCCESS: (state) => {
    return { ...state, isLoading: false, success:true };
  },
  AUTH0_SIGN_UP_RESET: (state) => {
    return { ...state, isLoading: false, success:false };
  },
};

export default createReducer(initialState, handlers);
