/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import Wrapper from '../../../../components/Typografy/Wrapper';
import BaseButton from '../../../../components/Typografy/BaseButton';
import Link from '../../../../components/Typografy/Link';
import { mx } from '../../../../utils';
import theme from '../../../../theme/index';



const SaveButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;




const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top:1em

`;
const ButtonsContainer = styled.div`
   flex: 1;
   display: none;
   ${mx('lg')} {
    display: flex;
   }
`;

const Saver = (props) => (
    <Wrapper>
      <FooterFlex>
        <ButtonsContainer>
          {!props.pristine && <SaveButton onClick={() => props.setPassword(props.setPasswordFormValues, props.accountId)}> Set Password </SaveButton> }
        </ButtonsContainer>
      </FooterFlex>
    </Wrapper>
);


export default Saver;
