/**
 * @flow
 */
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import AccountEdit from './AccountEdit';
import type { TAppState } from '../../../../common/TAppState';
import {
    getAppCodeStatus,
    onAccountsLoadSuccess,
    updateAccount,
    updateAppCodes,
    updateMembershiptTemplate
} from '../../../../common/accounts/actions';
import {onLanguagesLoadSuccess} from "../../../../common/languages/actions";

const AccountEditContainer = connect(
  (state: TAppState, dispatch) => {
    return {
        languages: state.languages.data,
      accountId: state.auth.profile.data.accountId,
      accounts: state.accounts.data ,
       accountsLoaded:  state.accounts.isLoaded,
        appCodeStatus: state.accounts.tokens,
        accountFormValues: getFormValues('AccountForm')(state)
    };
  },
  (dispatch) => ({
      onLanguagesLoadSuccess: (data) => dispatch(onLanguagesLoadSuccess(data)),
      onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
      updateAccount: (data, account) => dispatch(updateAccount(data, account)),
      updateAppCodes: (data,  accountId, accountName) => dispatch(updateAppCodes(data,  accountId, accountName)),
      updateMembershiptTemplate: (data,  accountId, accountName) => dispatch(updateMembershiptTemplate(data,  accountId, accountName)),
      getAppCodeStatus: (data, account) => dispatch(getAppCodeStatus( account)),

  })
)(AccountEdit);

export default AccountEditContainer;
