/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, change, formValueSelector } from 'redux-form';

import {
    Flex,
    Box,
} from 'reflexbox';

import Wrapper from '../../components/Typografy/Wrapper';

import CustomFieldInput from '../../components/Typografy/CustomFieldInput';
import CustomFieldInputCheckbox from '../../components/Typografy/CustomFieldInputCheckbox'
import { VippsButton, VippsWrapper } from './components/VippsButton';
import { PrimaryButton } from '../../components/Buttons';
import { NotificationContainer, NotificationManager } from "react-notifications";

import { Card, CardTitle, Container } from '../../components/Elements'
import Footer from "../../components/Footer";
import getHeaders from "../../../common/config/auth/headers";
import { resendBody } from "../../../common/auth/config";
import {confirmAlert} from "react-confirm-alert";

class LandingPageForm extends Component {

    requestMembership = () => {
        confirmAlert({
            title: 'Tradebroker Kundeklubb',
            message: 'Dette skjemaet gjelder ikke de som allerede har medlemskap via Tradebroker Avtaler. Er du sikker på at du ønsker å melde deg i Kundeklubb som en enkeltperson?',
            buttons: [{
                label: 'Jeg vil bli medlem',
                onClick: () => {
                    fetch(`https://europe-west3-my-coveredpro.cloudfunctions.net/createKundeklubbUser`, {
                        method: 'POST',
                        headers: getHeaders(),
                        body: JSON.stringify({
                            fullName: this.props.tbClubFormValues?.name || '',
                            email: this.props.tbClubFormValues?.email || '',
                            phone: this.props.tbClubFormValues?.phone || '',
                            consent: !!(this.props.tbClubFormValues?.gdprConsent && this.props.tbClubFormValues?.salesConsent)
                        })
                    }).then(res => {
                        NotificationManager.success('', 'Forespørsel sendt!', 3000);
                    }).catch(err => {
                        NotificationManager.error('', 'En feil oppstod, prøv igjen eller kontakt hjelp@coveredpro.com', 5000);
                    });
                }
            },
            {
                label: 'Avbryt'
            }
            ]
        })

    };

    render() {

        console.log("Render");

        return (
            <div>
                <NotificationContainer />
                <Wrapper>
                    <Flex wrap>
                        <Box w={[1]}>
                            <Card wrap={true}>
                                <Box w={[1]}>
                                    <Field
                                        name="name"
                                        component={CustomFieldInput}
                                        placeholder="Ola Nordmann"
                                        type="text"
                                        userCard
                                        label="Fullt navn"
                                    />
                                    <Field
                                        name="email"
                                        component={CustomFieldInput}
                                        placeholder="name@example.com"
                                        type="text"
                                        userCard
                                        label="E-postadresse"
                                    />
                                    <Field
                                        name="phone"
                                        component={CustomFieldInput}
                                        placeholder="99900999"
                                        type="text"
                                        userCard
                                        label="Telefonnummer (uten +47)"
                                    />
                                    <Field
                                        name="gdprConsent"
                                        component={CustomFieldInputCheckbox}
                                        type="checkbox"
                                        label={["Jeg bekrefter å ha lest ", <a target="_blank" href={'https://coveredpro.com/privacy/no'}>personvernserklæringen</a>]}
                                    />
                                    <Field
                                        name="salesConsent"
                                        component={CustomFieldInputCheckbox}
                                        type="checkbox"
                                        label={["Jeg bekrefter å ha lest ", <a target="_blank" href={'https://coveredpro.com/tradebroker-kundeklubb/sales-agreement'}>salgsbetingelsene</a>]}
                                    />

                                    <Box>
                                        Pris 29,- per måned. Kan avsluttes når som helst. Klikk under for å sende medlemsforespørsel. Forespørselen vil normalt bli behandlet i løpet av  24 timer.
                                    </Box>
                                    <VippsWrapper>
                                        <PrimaryButton onClick={this.requestMembership}>Send forespørsel</PrimaryButton>
                                    </VippsWrapper>
                                </Box>
                            </Card>
                        </Box>
                    </Flex>
                </Wrapper>
            </div>
        );
    }
}


LandingPageForm = reduxForm({
    form: 'LandingPageForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(LandingPageForm);



export default connect(null)(LandingPageForm);
