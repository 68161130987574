/**
* @flow
*/

import styled from 'styled-components';

import logInIcon from '../../../assets/images/log_in_icon.png';
import ratingIcon from '../../../assets/images/rating_icon.png';
import mapIcon from '../../../assets/images/map_icon.png';
import employeeCardIcon from '../../../assets/images/employee_card_icon.png';
import newsIcon from '../../../assets/images/icon_news.png';

const images = {
  logInIcon: { src: logInIcon, width: 36, height: 57 },
  ratingIcon: { src: ratingIcon, width: 57, height: 57 },
  mapIcon: { src: mapIcon, width: 43, height: 57 },
  employeeCardIcon: { src: employeeCardIcon, width: 57, height: 44 },
  newsIcon: {src: newsIcon, width: 57, height: 44}
};

const FuncIcon = styled.i`
  background-image: url(${props => images[props.name].src});
  background-size: contain;
  width: ${props => images[props.name].width}px;
  height: 57px;
  display: inline-block;
  background-repeat: no-repeat;
  padding-bottom: 2em;
  margin-top: 2em;
  `;

export default FuncIcon;
