/**
 * Responsive/Fluid image React component that also prevents reflow (page jumping around)
 *
 * Based on ideas from:
 * @link http://andmag.se/2012/10/responsive-images-how-to-prevent-reflow/
 * @link http://nicolasgallagher.com/flexible-css-cover-images/
 * @link http://demosthenes.info/blog/586/CSS-Fluid-Image-Techniques-for-Responsive-Site-Design
 *
 * @flow
 */

import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

type TProps = {
  height: number;
  maxWidth?: number;
  src: string;
  width: number;
  alt: string,
  containerStyle?: string,
}

const loadImages = require.context('../../assets/images', true, /^\.\/.*\.(png|jpg|svg|gif)$/);

const ResponsiveImage = (props: TProps) => {
  const ratio = `${(props.height / props.width) * 100}%`;
  const maxWidth = `${(props.maxWidth ? props.maxWidth : props.width)}px`;

  const Container = styled.div`
    max-width: ${maxWidth};
    ${props.containerStyle || ''}
  `;

  const RatioContainer = styled.div`
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: ${ratio};
  `;

  const Image = styled.img`
    width: 100%;
  `;

  return (
    <Container>
      <RatioContainer>
        <Image src={loadImages(`./${props.src}`).default} alt={props.alt} />
      </RatioContainer>
    </Container>
  );
};

ResponsiveImage.propTypes = {
  height: PropTypes.number.isRequired,
  maxWidth: PropTypes.number,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  containerStyle: PropTypes.string,
};

export default ResponsiveImage;
