/**
 * @flow
 */


import {connect} from 'react-redux';
import AccountDetails from './AccountDetails';
import {onAccountsLoadSuccess} from '../../../../common/accounts/actions';
import {onDeployStatusLoadSuccess} from '../../../../common/deploy/actions';
import type {TAppState} from '../../../../common/TAppState';
import {archiveAccount} from '../../../../common/accounts/actions';

const AccountDetailsContainer = connect(
    (state: TAppState) => {
        return {
            accountId: state.auth.profile.data.accountId,
            accounts: state.accounts.data,
            accountsLoaded: state.accounts.isLoaded,
            deploys: state.deploy.deploys
        };
    },
    (dispatch) => ({
        onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
        onDeployStatusLoadSuccess: (data) => dispatch(onDeployStatusLoadSuccess(data)),
        onArchive: (accountId, account) => dispatch(archiveAccount(accountId, account)),
    }),
)(AccountDetails);

export default AccountDetailsContainer;

