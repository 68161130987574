/**
 * @flow
 */
import styled from 'styled-components';
import theme from '../../theme';

const FormInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.25em;
  padding-bottom: 0.5em;
  padding-left: 0.6em;
  font-size: 1.15em;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  color: ${props => props.userCard ? `${theme.input.textColorUserCard}` : `${theme.input.textColor}`};
  ${props => props.userCard && 'line-height: 26px'};
  transition: all 0.2s ease-in-out;
  &:focus{
    outline: none;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  }
  &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
  }
  ${props => props.withError && 'border-bottom: 1px solid #D30F3D;'}
  ${props => props.disabled && 'opacity: 0.2;'}
`;

export default FormInput;
