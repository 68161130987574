/**
 * @flow
 */
import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../../components/Typografy/Wrapper';
import { mx } from '../../../utils';

import H2 from '../../../components/Typografy/H2';
import Section from '../../Verify/VerifyConfirmation/components/Section';
import Title from '../../Verify/VerifyConfirmation/components/Typography/Title';
import ShortLine from '../../Verify/VerifyConfirmation/components/ShortLine'

const MessageWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  `;

const Container = styled.div`
  min-height: 100vh;
  background-color: rgba(234, 234, 234, 0.78);

`;



const DescriptionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  align-items: center;
  margin-bottom: 2vh;
  margin-top: 2vh;

  ${mx('lg')} {
    margin-top: 2vh;
    width: 80%;
  }
`;

const FlexWrapper = styled.div`
    font-size:1.5em;
  `;

const Headline = styled(Title)`
  padding-bottom: 0.5em;
  
  font-size:3em;
  `;

const SubHeading = styled(H2)`
  color: #171717;;
  text-align: center;
  padding-bottom: 0.5em;
  font-weight: normal;
  margin-top:3vh;
  `;

type TProps = {
  headlineText: string,
  subheadlineText: string,
  subheadline2Text: string,
  full: any;
}


class MessageSection extends Component<void, TProps, void> {
  render() {
    return (
      <Section>
        <Container>
          <MessageWrapper>
            <DescriptionWrapper>
              <Headline>
                {this.props.headlineText}
              </Headline>
              <ShortLine />
	            {this.props.subheadlineText ? <SubHeading>
		            {this.props.subheadlineText}
	            </SubHeading> : null}
	            {this.props.subheadline2Text ? <SubHeading>
		            {this.props.subheadline2Text}
	            </SubHeading> : null}
            </DescriptionWrapper>
              <FlexWrapper>
                {this.props.full}
              </FlexWrapper>
          </MessageWrapper>
        </Container>
      </Section>
    );
  }
}

export default MessageSection;
