/**
 * @flow
 */
 
import styled from 'styled-components';
import theme from '../../../../theme/index';
import CoveredProIcon from '../../../../components/Typografy/CoveredProIcon';

const LinkStyleIcon = styled(CoveredProIcon)`
  margin-right: 0.25em;
  font-size: 1em;
  color: ${theme.icon.arrowIcon};
  backgroundColor: ${theme.icon.benefitsLinksIcon};
  padding: 0.5em;
  border-radius: 50%;
  margin-right: 0.5em;
  margin-left: 1em;
`;


export default LinkStyleIcon;
