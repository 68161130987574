/**
 * @flow
 */

import createReducer from '../createReducer';

const initialState = {
  data: {},
  list: [],
  error: null,
  isLoading: false,
};

const handlers = {
  LOAD_ACCOUNT_DATA_SUCCESS: (state, action) => {
    console.log('LOAD_ACCOUNT_DATA:' + JSON.stringify(action.payload));
    const data = action.payload;
    const account_data = {};
    let list = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        const account = {...data[key]}
        account_data[key] = account;
        list.push(account);
      });

    }
    list.reverse();
    return {
      ...state,
      error: null,
      isLoading: true,
      data: account_data,
      list: list
    };
  },
};

export default createReducer(initialState, handlers);
