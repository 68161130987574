/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme/index';
import CoveredProIcon from '../../../../components/Typografy/CoveredProIcon';

const DomainIconStyle = styled(CoveredProIcon)`
  margin-right: 0.25em;
  color: ${theme.icon.subHeaderIcon};
`;

const DomainIcon = () => (
  <DomainIconStyle>&#xe801;</DomainIconStyle>
);

export default DomainIcon;
