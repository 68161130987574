/**
 * @flow
 */
import styled from 'styled-components';
import H2 from '../../../../components/Typografy/H2';

const Title = styled(H2)`
  color: #171717;
  text-align: center;
  font-size: 2.4em;
  padding: 1em 0;
  `;

export default Title;
