import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import {Box, Flex} from "reflexbox";
import {imageThumb} from "../../../../common/config/images/endpoints";

const SingleMarkerDiv = styled.div`
  font-size:20px;
  font-weight:bold;
  position:relative;
  left:-10px;
  top:-32px;
  
`;

const SingleMarkerSpan = styled.span`
  width:50px;
  height:50pX;
  cursor: pointer;
`;

const InfoBox = styled.div`
  border:1px solid #dddddd;
  background-color: rgba(255,255,255,0.9);
  width:300px;
  z-index: 99999;
  padding:15px;
  cursor:default;
`;


const InfoBoxSubHeader = styled.div`
  font-size: 15px;
  display:block;
`;

const InfoBoxLink = styled.a`
  display:block;
  font-size: 15px;
`;

const InfoBoxExit = styled.span`
  padding:5px;
  float:right;
  cursor:pointer;
`;



class MapLocation extends Component {

    constructor() {
        super();
        this.state = {
            zoom: 8,
            showIB: false,
        }
    }

    _renderMarker = (location, index, isCabin) => {
        const coordinate = {
            latitude: parseFloat(location.latitude),
            longitude: parseFloat(location.longitude)
        }

        return (
            <SingleMarkerDiv
                optimized={false}
                lng={coordinate.longitude}
                lat={coordinate.latitude}
                key={"key" + index}
            >

                {this.displayInfoBox(index, isCabin,)}
            </SingleMarkerDiv>
        )

    }

    displayInfoBox = (index, isCabin) => {

        return this.state.showIB && this.state.showIB === 'cabin' && isCabin ? (
            <InfoBox>
                <InfoBoxExit onClick={() => this.setState({showIB: false})}>X</InfoBoxExit>
                <Flex wrap>
                    <Box w={[1, 1]}>
                        <InfoBoxSubHeader>{this.props.cabin.title}</InfoBoxSubHeader>
                        <InfoBoxLink href={"https://maps.google.com/maps?daddr="+this.props.cabin.location.latitude+","+this.props.cabin.location.longitude+"&amp;ll="}>Naviger hit</InfoBoxLink>
                    </Box>
                </Flex>
            </InfoBox>
        ) : this.state.showIB !== false && this.state.showIB === index ? (
            <InfoBox>
                <InfoBoxExit onClick={() => this.setState({showIB: false})}>X</InfoBoxExit>
                <Flex wrap>
                    <Box w={[1, 1]}>
                        <InfoBoxSubHeader>{this.props.cabin.nearbyLocations[this.state.showIB].heading}</InfoBoxSubHeader>
                        <InfoBoxLink href={"https://maps.google.com/maps?daddr="+this.props.cabin.nearbyLocations[index].latitude+","+this.props.cabin.nearbyLocations[index].longitude+"&amp;ll="}>Naviger hit</InfoBoxLink>
                    </Box>
                </Flex>
            </InfoBox>
        ) : (
            <SingleMarkerSpan onClick={() =>
                this.setState({
                    showIB: isCabin ? 'cabin' : index,
                })
            }>
                <img src={require('../../../assets/images/map-marker.png').default} alt={"+"} width={35} />
            </SingleMarkerSpan>
        );
    }

    mapChange = (map) => {
        this.setState({
            zoom: map.zoom,
        });
    };

    render() {
        console.log("STATE", this.state);
        console.log("CABIN", this.props.cabin);
        return (
            <GoogleMapReact
                center={{
                    lng: this.props.cabin.location.longitude,
                    lat: this.props.cabin.location.latitude,
                }}
                zoom={this.state.zoom}
                onChange={this.mapChange}
            >
                {this._renderMarker(this.props.cabin.location, null, true)}
                {this.props.cabin.nearbyLocations && this.props.cabin.nearbyLocations.map((loc, index) => {
                    return loc && loc.latitude && loc.longitude && (this.state.showIB === index || this.state.showIB === false ) && this._renderMarker(loc, index, false);
                })}
            </GoogleMapReact>
        );
    }
}

export default MapLocation;
