/**
 * @flow
 */

import { connect } from 'react-redux';

import type { TAppState } from '../../../common/TAppState';
import Login from './Login';
import { signInAccount, handleAuthentication } from '../../../common/auth/signIn/actions';

const LoginContainer = connect(
  (state: TAppState) => ({
      signIn: state.auth.signIn,
      isRestored: state.restoreState.isRestored,
      profile: state.auth.profile.data,
      accounts: state.accounts.data
  }),
  (dispatch) => ({
    signInAccount: (data, history) => dispatch(signInAccount(data, history)),
      handleAuthentication: (history) => dispatch(handleAuthentication(history)),
  }),
)(Login);

export default LoginContainer;
